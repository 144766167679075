// export const serverHost = 'https://uat.gspark.cc/saascloud';
// export const socketHost = 'wss://uat.gspark.cc';

export const serverHost = '/saascloud';
// export const socketHost = 'wss://sit.rlinkiot.com';
export const socketHost = process.env.VUE_APP_SOCKET_PATH;
export const BaseURL = serverHost + '/saas';
// export const serverHost = 'https://ruge.gspark.cc/saascloud';
// export const socketHost = 'wss://ruge.gspark.cc';
/**
 * 环境信息
 */
export const envInfo = {
  // 应用名称
  appName: 'zh-CN=Rlink;en-US=Rlink',
  // 支持的国际化语言
  supportLang: {
    'zh-CN': '中文',
    'en-US': 'English',
  },
  // 模版最大限制(单位:M)
  templateMaxSize: 2,
  // 菜单配置
  sitemap: {
    // 首页配置
    home: {
      // 对应的菜单ID，不与其它菜单ID重复即可
      sitemapId: -999,
      href: '/',
      // 租户首页链接
      // tenantHome: '/ ',
      // tenantHome: '/personal',//租户首页修改成dashboard
      tenantHome: '/iot/dashboard',
      // 平台首页链接
      platformHome: '/personal',
      // 国际化
      displayText: 'zh-CN=首页;en-US=Home',
    },
    // 设置中心配置，用于获取指定面包屑
    personal: {
      // 对应的菜单ID，不与其它菜单ID重复即可
      sitemapId: -998,
      // 设置中心链接
      href: '/personal',
      // 国际化
      displayText: 'zh-CN=设置中心;en-US=Personal center',
    },
    // 菜单黑名单
    blacklist: [
      // 'zh-CN=系统首页;en-US=System Home',
      // 'zh-CN=基础管理;en-US=Iot Configuratio',
      // 'zh-CN=运维管理;en-US=FMS',
    ],
  },
  // cookie的回写路径,支持多域名单点登录
  cookieDomain: [
    `${serverHost}/saas/sso`,
    // 本地调试配置
    // 'http://127.0.0.1:8090/saas/base',
    // , 'http://127.0.0.1:8109/saas/schedule'
  ],
  // 分布式后台服务地址后缀(设置中心Excel导入导出列表文件下载使用)
  bgSuffixPath: {
    ithings: '/saascloud/saas/things',
    isso: '/saascloud/saas/sso',
    gbase: '/saascloud/saas/base',
    gorder: '/saascloud/saas/order',
    ginspection: '/saascloud/saas/inspection',
    gmaintenance: '/saascloud/saas/maintenance',
    iorganization: '/saascloud/saas/organization',
    goperate: '/saascloud/saas/operate',
    gcustomer: '/saascloud/saas/customer',
    gcustomergs: '/saascloud/saas/customergs',
    gcustomerjw: '/saascloud/saas/customerjw',
    'wanwu-data': '/saascloud/saas/wanwu-data',
    idoris: `/saascloud/saas/doris`,
    igrade: `/saascloud/saas/grade`,
    istation: `/saascloud/saas/station`,
    gcrm: `/saascloud/saas/crm`,
    lego: `/saascloud/saas/lego`,
  },
  // 后台服务地址列
  bgApp: {
    ssoPath: `${serverHost}/saas/sso`, //http://127.0.0.1:8082/saas/sso
    securityPath: `${serverHost}/saas/security`, //`http://127.0.0.1:8081/saas/security`,
    archivePath: `${serverHost}/saas/archive`, //"http://127.0.0.1:8091/saas/archive",
    registryPath: `${serverHost}/saas/registry`,
    lookupPath: `${serverHost}/saas/lookup`,
    opsPath: `${serverHost}/saas/ops`,
    sitemapPath: `${serverHost}/saas/sitemap`,
    loggerPath: `${serverHost}/saas/logger`, //`${serverHost}/saas/logger`,
    clockyPath: `${serverHost}/saas/clocky`,
    mediaPath: `${socketHost}/media`,
    sprayPath: `${socketHost}/saas/spray`,
    organizationPath: `${serverHost}/saas/organization`,
    apiPath: `${serverHost}/saas/api`, //'http://127.0.0.1:8101/saas/api',//`${serverHost}/saas/api`,
    consolePath: `${serverHost}/saas/console`, //`${serverHost}/saas/console`,//'http://127.0.0.1:8100/saas/console',
    notifyPath: `${serverHost}/saas/notify`,
    tenantPath: `${serverHost}/saas/tenant`,
    tagPath: `${serverHost}/saas/tag`,
    workflowPath: `${serverHost}/saas/workflow`,
    thingsPath: `${serverHost}/saas/things`, //'http://127.0.0.1:8107/saas/things',//`${serverHost}/saas/things`,
    rulesPath: `${serverHost}/saas/rules`, //'http://127.0.0.1:8108/saas/rules',//`${serverHost}/saas/rules`,
    setPath: `${serverHost}/saas/set`, //'http://127.0.0.1:8112/saas/set',
    schedulePath: `${serverHost}/saas/schedule`, //'http://127.0.0.1:8109/saas/schedule',//`${serverHost}/saas/schedule`,
    visualizationPath: `${serverHost}/saas/view`,
    customerServicePath: `${serverHost}/saas/customer/web`,
    customerWXServicePath: `${serverHost}/saas/customer/wx`,
    customerServiceGSPath: `${serverHost}/saas/customergs/web`,
    customerServicelegoPath: `${serverHost}/saas/lego/web`,
    customerlegoInvoicePath: `${serverHost}/saas/invoice`,
    customerlegoPath: `${serverHost}/saas/lego`,
    customerServiceJWPath: `${serverHost}/saas/customerjw/web`,
    customerWXJWPath: `${serverHost}/saas/customerjw/wx`,
    personnelmanagePath: `${serverHost}/saas/crm`,
    crmWebPath: `${serverHost}/saas/crm/web`,
    placePath: `${serverHost}/saas/wanwu-manage/web/place`,
    sitePath: `${serverHost}/saas/wanwu-manage/web/site`,
    historyPath: `${serverHost}/saas/wanwu-data/property`,
    allPlacePath: `${serverHost}/saas/wanwu-manage/wx`, //'https://sit.gspark.cc/saascloud/saas/wanwu-manage/wx/getAllPlace
    defaultPath: `${serverHost}/saas/base`, // 默认的服务地址
    basePath: `${serverHost}/saas/base`, // 基础业务的服务地址
    yookaPath: `${serverHost}/saas/yooka`, //视频服务器地址
    inspectionPath: `${serverHost}/saas/inspection`, //巡检服务地址
    maintenancePath: `${serverHost}/saas/maintenance`, //维保服务地址
    orderPath: `${serverHost}/saas/order`, //工单服务地址
    pumpPath: `${serverHost}/saas/pump`, //抽取中心
    nanhaiPath: `${serverHost}/saas/nanhai`, //南海IOT数据中心
    operatePath: `${serverHost}/saas/operate`, //BMS服务
    wechatPath: `${serverHost}/saas/wechat`, //微信服务
    dorisPath: `${serverHost}/saas/doris`,
    stationBmsPath: `${serverHost}/saas/station/bms`,
    stationPath: `${serverHost}/saas/station`,
    customerWXPath: `${serverHost}/saas/customer/wx`,
    customerPath: `${serverHost}/saas/customer`,
    customerWXGSPath: `${serverHost}/saas/customergs/wx`,
    customerWXlegoPath: `${serverHost}/saas/lego/wx`,
    customerGSPath: `${serverHost}/saas/customergs`,
    customerjwPath: `${serverHost}/saas/customerjw`,
    orgPath: `${serverHost}/saas/organization`,
    appPath: `${serverHost}/saas/apps`,
    gradePath: `${serverHost}/saas/grade`,
    schedulePath: `${serverHost}/saas/scheduling`,
    bmsPath: `${serverHost}/saas/bms`,
    bmsRviewPath: `${serverHost}/saas/rview`,
    rspacePath: `${serverHost}/saas/bms`, //获取Rspace项目集合
    smsPath: `${serverHost}/saas/sms`, //'http://127.0.0.1:8057/saas/sms',//`${serverHost}/saas/bms`
    biPath: `${serverHost}/saas/bi`,
    iotPath: `${serverHost}/saas/iotModels`,
    repeaterPath: `${serverHost}/saas/repeater`,
    cockpitDataPath: `${serverHost}/saas/cockpitData`,
    legoPath: `${serverHost}/saas/legoBuilder`,
    legoWeb: `${serverHost}/saas/lego/web`,
    legoWX: `${serverHost}/saas/lego/wx`,
    legoCSH: `${serverHost}/saas/lego/csh`,
  },
};
