import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

export function getSiteMapList(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/user/list',
    // url: 'http://192.168.2.87:8084/saas/sitemap/sitemap/user/list',
    method: 'get',
    params,
  });
}

export function getSiteMapZtreeList(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/ztree/list',
    method: 'get',
    params,
  });
}

export function getTenantSiteMapZtreeList(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/tenant/sitemap/ztree/list',
    method: 'get',
    params,
  });
}

/**
 * 新建菜单
 * @param params
 */
export function createSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/create',
    method: 'post',
    data: params,
  });
}

export function createTenantSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/tenant/sitemap/create',
    // url: 'http://192.168.2.87:8084/saas/sitemap/tenant/sitemap/create',
    method: 'post',
    data: params,
  });
}

/**
 * 更新菜单
 * @param params
 */
export function updateSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/update',
    method: 'post',
    data: params,
  });
}

export function updateTenantSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/tenant/sitemap/update',
    // url: 'http://192.168.2.87:8084/saas/sitemap/tenant/sitemap/update',
    method: 'post',
    data: params,
  });
}

/**
 * 更新菜单节点
 * @param params
 */
export function updateSiteMapNode(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/updateNode',
    method: 'post',
    data: params,
  });
}

export function updateTenantSiteMapNode(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/tenant/sitemap/updateNode',
    // url: 'http://192.168.2.87:8084/saas/sitemap/tenant/sitemap/updateNode',
    method: 'post',
    data: params,
  });
}

/**
 * 删除菜单
 * @param params
 */
export function deleteSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/sitemap/delete',
    method: 'post',
    data: params,
  });
}

export function deleteTenantSiteMap(params) {
  return request({
    url: envInfo.bgApp.sitemapPath + '/tenant/sitemap/delete',
    // url: 'http://192.168.2.87:8084/saas/sitemap/tenant/sitemap/delete',
    method: 'post',
    data: params,
  });
}

/**
 * 查询图标编码
 * @param params
 */
export function findIconCodeList(params) {
  return request({
    url:
      envInfo.bgApp.lookupPath +
      `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  });
}
