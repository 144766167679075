//后台资源权限点国际化
export default {
  ruge: {
    resource: {
      common: '平台*通用',
      baseSetting: '平台*基础设置',
      userSetting: '平台*用户设置',
      iotManagement: '平台*物联管理',
      ops: '平台*运维工具',
      tenantBaseSetting: '租户*基础设置',
      tenantUserSetting: '租户*用户设置',
      tenantIotManagement: '租户*物联网平台',
      iotAPIManagement: '租户*开放能力',
      tenantBasicManagement: '租户*基础管理',
      tenantOrderManagement: '租户*工单管理',
      tenantArrangeShiftManagement: '租户*日程排班',
      tenantInspectionManagement: '租户*巡检管理',
      tenantMaintenanceManagement: '租户*维保管理',
      tenantBMSManagement: '租户*BMS管理',
      tenantWanWuManagement: '租户*万物传感',
      tenantStatisticsManagement: '租户*报表统计',
      tenantGsAppletManagement: '租户*锦绣小程序',
      tenantJwAppletManagement: '租户*金威小程序',
      tenantCrmManagement: 'CRM*CRM管理',
      tenantDataManagement: '租户*数据管理',
    },
    operation: {
      querySiteList: '场地配置查询',
      editSite: '场地配置管理',
      querySiteDestinedList: '场地预定查询',
      siteDestined: '场地预定管理',
      addParking: '新增车场配置',
      editParking: '编辑车场配置',
      searchInvestmentInfo: '招商信息查询',
      manageInvestmentInfo: '招商信息管理',
      manageCSHConfig: '客服热线配置',
      manageMiniprogram: '小程序配置',
      confirmAlarm: '告警确认',
      findSitemap: '查询菜单',
      managePermission: '权限管理',
      searchDimension: '查询纬度',
      searchOrg: '组织查询',
      manageOrg: '组织管理',
      manageTenant: '租户管理',
      manageUser: '用户管理',
      tenantSetting: '租户设置',
      manageSitemap: '菜单管理',
      manageLookup: '字典管理',
      manageRegistry: '注册表管理',
      manageTag: '标签管理',
      manageNotify: '通知管理',
      manageLogger: '日志管理',
      manageJob: '定时管理',
      findLogger: '日志查询',
      manageTemplate: '短信模板管理',
      manageRemind: '待办',
      manageAsyncTask: '异步管理',
      manageCache: '缓存管理',
      manageConsul: '微服务管理',
      deviceControl: '设备控制',
      searchProduct: '产品控制',
      manageProduct: '产品管理',
      searchRule: '规则查询',
      manageRule: '规则管理',
      manageConsumerGroup: '消费组管理',
      manageView: '可视化管理',
      manageSchedule: '时间表',
      searchLog: '日志查询',
      deviceAPI: '设备读控',
      managerGateway: '网关管理',
      gatewayAPI: '网关控制',
      managerMqtt: 'MQTT配置',
      findMqtt: 'MQTT查询',
      mqttAPI: 'MQTT控制',
      edgeManage: '网关管理',
      manageDriver: '驱动管理',
      manageApp: '应用管理',
      manageProcDef: '流程部署',
      searchDevice: '设备查询',
      manageDevice: '设备管理',
      searchCategory: '系统分类查询',
      manageCategory: '系统分类管理',
      searchMonitor: '监控点查询',
      manageMonitor: '设备绑点',
      searchDeviceMap: '地图查询',
      manageDeviceMap: '地图管理',
      searchMaterielCategory: '物料分类查询',
      manageMaterielCategory: '物料分类管理',
      searchMateriel: '物料查询',
      manageMateriel: '物料管理',
      searchPosition: '位置查询',
      managePosition: '位置管理',
      searchProject: '项目查询',
      manageProject: '项目管理',
      searchControlSource: '控制源查询',
      manageControlSource: '控制源管理',
      setPoint: '点位控制',
      orderSetting: '工单设置',
      orderSearch: '工单查询',
      inspectionSearch: '巡检查询',
      inspectionManage: '巡检管理',
      maintenanceSearch: '维保查询',
      maintenanceManage: '维保管理',
      manageScheduling: '排班管理',
      searchScheduling: '排班查询',
      searchComplaintAdvice: '投诉建议查询',
      manageComplaintAdvice: '投诉建议管理',
      searchCustomerActivity: '园区活动查询',
      manageCustomerActivity: '园区活动管理',
      searchCustomerEnterprise: '入园企业查询',
      manageCustomerEnterprise: '入园企业管理',
      searchCustomerNotice: '园区公告查询',
      manageCustomerNotice: '园区公告管理',
      searchServiceGuide: '办事指南查询',
      manageServiceGuide: '办事指南管理',
      deleteAppletGuide: '删除装修指引',
      manageSite: '站点管理',
      managePlace: '地点管理',
      searchInspectionStatistics: '巡检统计',
      searchOrderStatistics: '工单统计',
      searchElectricStatistics: '电能耗统计',
      searchSummarizeStatistics: '综合分析看板',
      searchWaterStatistics: '水能耗统计',
      managePaidService: '服务类别管理',
      searchPaidService: '服务类别查询',
      queryCustomerCompany: '企业黄页查询',
      manageCustomerCompany: '企业黄页管理',
      searchConferenceConfig: '会议预约查询',
      manageConferenceConfig: '会议预约管理',
      searchCustomerMonthlyCard: '停车月卡管理',
      exportCustomerInfo: '客户导入导出',
      cancelInvestmentStatus: '客户取消状态',
      editInvestmentStatus: '客户更换状态',
      assignCustomer: '公海踢回',
      deleteCustomerInfo: '删除客户',
      transfer: '转移客户',
      addEmployee: '新增人员',
      editEmployee: '编辑人员',
      delEmployee: '删除人员',
      editHomeStatus: '房源修改状态',
      addTemplate: '新增模板',
      editTemplate: '编辑模板',
      delTemplate: '删除模板',
      addCustomerInfo: '新增客户',
      editCustomerInfo: '编辑客户',
      editRoom: '编辑房源',
      sysLeadsInfo: '线索管理',
      manageBITemplate: '指标模板管理',
      manageCard: '指标管理',
      manageCardGroup: '指标分组管理',
      manageSendNumLimit: '短信上限设置',
      repeaterServer: '中继服务',
      manageMiniprogram: '小程序管理',
      jxCarParkManage: '锦绣停车场',
      manageCSHConfig: '客服热线配置',
      editSite: '场地次卡管理',
      querySiteDestinedList: '场地预定查询',
      querySiteList: '场地查询',
      siteDestined: '场地预定管理',
    },
    sso: {
      operation: {
        login: '登录',
        register: '注册',
        forgetpwd: '忘记密码',
        searchSsoUser: '账号查询',
        manageSsoUser: '账号管理',
      },
    },
  },
};
