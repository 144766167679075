import { envInfo } from '@/constants/envInfo';

/**
 * 下载Excel模版
 * @param {String} type 模版类型
 * @param {String} rootPath 下载根路径,跨站下载
 */
export function downTemplate(type, rootPath) {
  let downTag = document.createElement('a');
  downTag.download = '';
  downTag.href =
    rootPath + '/param/excel/download?dlType=ExcelTemplate&excelType=' + type;
  downTag.click();
}

/**
 * 下载文档中心文件
 * @param {string|number} fid 文件ID
 */
export function downFile(fid) {
  let downTag = document.createElement('a');
  downTag.download = '';
  downTag.href =
    envInfo.bgApp.archivePath +
    '/param/archive/download?dlType=DefaultDownload&fi=' +
    fid;
  downTag.click();
}

/**
 * 下载EXCEL文件
 * @param {string | number} excelId excel文件ID
 * @param {string} flag "IMP":导入文件, "EXP":导出文件, "ERR":错误提示
 */
export function downExcel(excelId, flag, rootPath) {
  let downTag = document.createElement('a');
  downTag.download = '';
  downTag.href =
    rootPath +
    '/param/excel/download?dlType=Excel&excelFlag=' +
    flag +
    '&excelId=' +
    excelId;
  downTag.click();
}
/**
 * 获取预览视频地址
 * @param {string | number} 文件ID
 */
export function getVedioSrc(fid) {
  let src = '';
  if (fid) {
    src =
      window.location.origin +
      envInfo.bgApp.archivePath +
      '/param/archive/download?dlType=DefaultDownload&fi=' +
      fid;
  }
  return src;
}
/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export function download(url, filename) {
  getBlob(url, function (blob) {
    saveAs(blob, filename);
  });
}

/**
 * 下载 - Promise
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export function downloadPromise(url, filename) {
  return new Promise((resolve) => {
    getBlob(url, function (blob) {
      saveAs(blob, filename);
      resolve();
    });
  });
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb}
 */
function getBlob(url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.withCredentials = true;
  xhr.send();
}

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}