<template>
  <div>
    <div class="el-badge-container" @click="drawerClick">
      <el-badge :hidden="unreadCount === 0" :value="unreadCount" :max="99">
        <img src="../../assets/images/menu_message_icon.png" alt="" />
        <!-- <i
        class="el-icon-bell right-menu-icon"
        :style="{
          color: $store.state.app.displayLayout === 1 ? '#2a4158' : '#b4c5d5',
        }"
        @click="drawerClick"
      ></i> -->
      </el-badge>
    </div>
    <el-drawer
      style="line-height: 16px"
      :visible.sync="drawer"
      :before-close="
        () => {
          drawer = false;
        }
      "
      :direction="direction"
    >
      <template slot="title">
        <span><title-icon />{{ $t("personal.setting9") }}</span>
      </template>
      <template v-if="notifyList.length > 0">
        <div class="data-list">
          <el-row
            v-for="(item, index) in notifyList"
            class="data-row"
            :key="index"
          >
            <el-col :span="2" :offset="1" style="margin: 14px 10px 0px 16px">
              <el-avatar :size="50" :src="getAvatar(item)">
                <img :src="defaultAvatar" />
              </el-avatar>
            </el-col>
            <el-col :span="20">
              <span class="notify-sender"> {{ item.senderName }} </span>
              <span class="notify-time">
                {{ item.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
              </span>
              <el-button type="text" @click="removeNotify(item)">
                <i class="el-icon-delete delete-btn"></i>
              </el-button>
              <div @click="searchNotifyDetails(item)">
                <el-link
                  class="notify-info"
                  v-bind:class="{ unread: item.readStatus == 1 }"
                  :underline="false"
                  >{{ item.notifyTitle }}
                </el-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </template>
      <template v-else>
        <div class="no-data">{{ $t("commons.noData") }}</div>
      </template>
      <el-button-group class="notify-btn-group">
        <el-button class="notify-btn" @click="showMore">
          {{ $t("commons.more") }}
        </el-button>
        <el-button class="notify-btn" @click="clearALL">
          {{ $t("commons.clearAll") }}
        </el-button>
      </el-button-group>
    </el-drawer>

    <information-details
      :detailDrawer.sync="detailDrawer"
      :informationInfo="informationInfo"
    />
  </div>
</template>

<script>
import {
  getRecordInformation,
  updateNotifySignRead,
  logicDeleteRecord,
  clearAllRecord,
  getRecordUnreadCount,
} from "@/api/ruge/notify/notify";
import InformationDetails from "./components/informationDetails";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "Information",
  components: { InformationDetails },
  data() {
    return {
      // 默认头像
      defaultAvatar: require("@/assets/images/defaultAvatar.png"),
      drawer: false,
      direction: "rtl",
      notifyList: [],
      listLoading: false,
      detailDrawer: false,
      informationInfo: null,
      unreadCount: 0,
    };
  },
  watch: {
    drawer: {
      handler(val) {
        this.changeHandler(val);
        this.$emit("messageChange", val);
      },
      immediate: true,
    },
  },
  created() {
    this.getRecordUnreadCount();
  },
  methods: {
    changeHandler(val) {
      this.$emit("messageChange", val);
    },
    //点击铃铛，打开侧边栏
    drawerClick() {
      if (!this.drawer) {
        this.drawer = true;
        this.getRecordInformation();
      }
    },
    getRecordInformation() {
      this.listLoading = true;
      getRecordInformation({ current: 1, rowCount: 20 })
        .then((response) => {
          this.notifyList = response.rows;
          this.getRecordUnreadCount();
        })
        .catch((error) => {
          this.listLoading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    //查询通知消息
    searchNotifyDetails(item) {
      console.log("item", item);
      this.informationInfo = item;
      this.detailDrawer = true;
      if (item.readStatus == 1) {
        updateNotifySignRead({
          userAccount: this.$store.getters.userAccount,
          batchNo: item.batchNo,
        })
          .then(() => {
            item.readStatus = 2;
          })
          .then(() => {
            this.getRecordUnreadCount();
          });
      }
    },
    // 查询未读消息数
    getRecordUnreadCount() {
      getRecordUnreadCount()
        .then((response) => {
          this.unreadCount = response;
        })
        .catch((error) => {
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    // 获取用户头像
    getAvatar(item) {
      return envInfo.bgApp.ssoPath + "/param/avatar?u=" + item.sendUserAccount;
    },
    // 删除单个通知
    removeNotify(item) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          logicDeleteRecord({ notifyRecordId: item.notifyRecordId }).then(
            () => {
              // 成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              this.getRecordInformation();
            }
          );
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 查看更多，跳转消息查询页面
    showMore() {
      this.$router.push("/personal/common?id=9");
      this.drawer = false;
    },
    // 清空消息
    clearALL() {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          clearAllRecord().then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            // 清空列表
            this.notifyList = [];
            // 未读数清零
            this.unreadCount = 0;
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style scoped>
.el-badge-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.el-badge {
  display: flex;
  align-items: center;
}
.el-icon-bell {
  font-size: 18px;
  position: relative;
  top: -1px;
  left: 2px;
  cursor: pointer;
}
.information-icon >>> svg {
  width: 22px;
  height: 22px;
  cursor: pointer;
  fill: #48576a;
  vertical-align: 11px;
}

.notify-sender {
  font-size: 14px;
}

.notify-time {
  font-size: 14px;
  color: #909399;
}

.notify-info {
  font-size: 15px;
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unread {
  font-weight: 600;
  color: #303133;
}

.data-list {
  border-top: 1px solid #f1f3f8;
  height: 800px;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.data-row {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f3f8;
}

.notify-btn-group {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.notify-btn {
  margin-top: 10px;
  width: 50%;
  height: 50px;
  justify-content: center;
  border-radius: 0px;
}

.el-avatar {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
</style>
