import { Message } from 'element-ui';
import i18n from '@/lang';

/*************** 定义公共的属性和方法 **********************/

/**
 * 是否为外部链接
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * 拼接动态排序字段(用于ElementUI Table控件)
 * @param column
 * @returns {*}
 */
export function buildSortStr(column) {
  // 转换为标准的排序方式
  let buildOrder = function(order) {
    return order === 'descending' ? 'desc' : 'asc';
  };
  // 排序默认为空
  let sortStr = null;
  if (column.prop) {
    sortStr = column.prop + ':' + buildOrder(column.order);
  }
  return sortStr;
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function() {
  if (document.addEventListener) {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler);
      }
    };
  }
})();

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function() {
  if (document.removeEventListener) {
    return function(element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  } else {
    return function(element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler);
      }
    };
  }
})();

/**
 * 复制内容到粘贴板
 * content : 需要复制的内容
 * message : 复制完后的提示，默认提示"已复制到剪贴板中"
 */
export function copy2Clip(content, message) {
  let aux = document.createElement('input');
  aux.setAttribute('value', content);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
  Message({
    message: message || i18n.t('message.copySuccess'),
    type: 'success',
    duration: 3 * 1000,
  });
}

/**
 * 获取URL参数
 * paramName : 参数名称
 */
export function getQueryParam(paramName) {
  let reg = new RegExp('(^|&)' + paramName + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  console.log('r', r);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
/**
 * 图片路径转成canvas
 * @param {图片url} url
 */
async function imgToCanvas(url) {
  // 创建img元素
  const img = document.createElement('img');
  img.src = url;
  img.setAttribute('crossOrigin', 'anonymous'); // 防止跨域引起的 Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
  await new Promise((resolve) => {
    img.onload = resolve;
  });
  // 创建canvas DOM元素，并设置其宽高和图片一样
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  // 坐标(0,0) 表示从此处开始绘制，相当于偏移。
  canvas.getContext('2d').drawImage(img, 0, 0);
  return canvas;
}

/**
 * canvas添加水印
 * @param {canvas对象} canvas
 * @param {水印文字} text
 */
function addWatermark(canvas, texts) {
  console.log('canvas.width', canvas.width);
  const [text1, text2] = texts;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white';
  ctx.textBaseline = 'middle';
  ctx.font = `${canvas.width / 40}px Arial`;
  ctx.textAlign = 'start';
  // ctx.shadowOffsetX = 2; //用来设定阴影在 X轴的延伸距
  // ctx.shadowOffsetX = 2; //用来设定阴影在 Y轴的延伸距
  ctx.shadowBlur = 2; //设定阴影的模糊程度 默认0
  ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
  ctx.fillText(text2, 10, canvas.width / 40);
  ctx.fillText(text1, 10, 2 * (canvas.width / 40));
  return canvas;
}

/**
 * canvas转成img
 * @param {canvas对象} canvas
 */
function convasToImg(canvas) {
  // 新建Image对象，可以理解为DOM
  const image = new Image();
  // canvas.toDataURL 返回的是一串Base64编码的URL
  // 指定格式 PNG
  image.src = canvas.toDataURL('image/png');
  return image;
}

// 封装方法
export async function watermark(imgUrl, texts) {
  // 1.图片路径转成canvas
  const tempCanvas = await imgToCanvas(imgUrl);
  // 2.canvas添加水印
  const canvas = addWatermark(tempCanvas, texts);
  // 3.canvas转成img
  const img = convasToImg(canvas);
  return img;
}

