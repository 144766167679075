var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.to,
    _vm._b({ tag: "component" }, "component", _vm.linkProps(_vm.to), false),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }