var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bread-crumb-comp" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showGoBack,
            expression: "showGoBack",
          },
        ],
        staticClass: "back-part",
      },
      [
        _c(
          "span",
          { staticClass: "go-back", on: { click: _vm.goBackHandler } },
          [
            _c("img", {
              staticClass: "go-back-icon",
              attrs: {
                src: require("../../assets/images/go_back_icon.png"),
                alt: "",
              },
            }),
            _c("span", { staticClass: "go-back-span" }, [_vm._v("返回")]),
          ]
        ),
        _c("el-divider", { attrs: { direction: "vertical" } }),
      ],
      1
    ),
    _c("div", { staticClass: "detail-part" }, [
      _c("span", { staticClass: "detail-part-label" }, [
        _vm._v(_vm._s(_vm.curRouter)),
      ]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.curDetail,
              expression: "curDetail",
            },
          ],
        },
        [_vm._v(_vm._s(" - " + _vm.curDetail))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }