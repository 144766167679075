<template>
  <el-tooltip
    :content="tenantName"
    placement="right"
    effect="dark"
    :open-delay="openDelay"
  >
    <div class="projectInfo" :class="projectInfoMini">
      <template v-if="customLogoPath != ''">
        <img
          class="logo"
          :src="customLogoPath"
          :onerror="sidebar.open ? defaultBigAvatar : defaultSmallAvatar"
          alt=""
        />
      </template>
      <template v-else-if="tenantName != ''">
        <div class="tenant-font">
          <div class="tenant-avatar">
            <span>{{ tenantName.charAt(0) }}</span>
          </div>
        </div>
      </template>
      <template v-else>
        <img class="logo" :src="logoPath_" alt="" />
        <span class="projectName">{{ logoMsg_.projectName }}</span>
      </template>
    </div>
  </el-tooltip>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { mapGetters } from "vuex";
export default {
  name: "LogoToggle",
  data() {
    return {
      openDelay: 1500,
      status: this.showStatus,
      logoMsg_: this.logoMsg,
      logoPath_: "",
      projectInfoMini: "",
      customLogoPath: null,
      SmallLogo: require("@/assets/images/defaultSmallAvatar.png"),
      BigLogo: require("@/assets/images/defaultBigAvatar.png"),
      defaultSmallAvatar:
        'this.src="' + require("@/assets/images/defaultSmallAvatar.png") + '"',
      defaultBigAvatar:
        'this.src="' + require("@/assets/images/defaultBigAvatar.png") + '"',
    };
  },
  props: ["showStatus", "logoMsg"],
  watch: {
    showStatus: {
      handler(status) {
        if (status) {
          // true:  收起菜单
          if (this.customLogoPath || this.tenantName) {
            this.projectInfoMini = "projectInfoMini";
          } else {
            console.log("yyy1");
            this.logoPath_ = this.logoPath[1];
            if (this.logoMsg_.logoFileName !== "") {
              this.logoPath_ = require("@/assets/images/" +
                this.logoMsg_.logoFileName +
                "_icon.png");
            }
          }
        } else {
          // false: 展开菜单
          if (this.customLogoPath || this.tenantName) {
            this.projectInfoMini = "";
          } else {
            console.log("yyy3");
            this.logoPath_ = this.logoPath[0];
            if (this.logoMsg_.logoFileName !== "") {
              this.logoPath_ = require("@/assets/images/" +
                this.logoMsg_.logoFileName +
                ".png");
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    currentTenant: {
      handler(val) {
        let curTenantList = this.tenantList.filter(
          (item) => item.tenantId == val
        )[0];
        if (!curTenantList) return;
        this.customLogoPath = this.sidebar.opened
          ? curTenantList.tenantAvatarOpen
            ? envInfo.bgApp.tenantPath +
              "/param/tenant/avatar?p=" +
              curTenantList.tenantAvatarOpen
            : this.BigLogo
          : curTenantList.tenantAvatar
          ? envInfo.bgApp.tenantPath +
            "/param/tenant/avatar?p=" +
            curTenantList.tenantAvatar
          : this.SmallLogo;
      },
      immediate: true,
      deep: true,
    },
    "$store.getters.constomLogo": {
      handler(val) {
        console.log("constomLogo", val);
        if (this.sidebar.opened) return;
        this.customLogoPath =
          envInfo.bgApp.tenantPath + "/param/tenant/avatar?p=" + val;
      },
    },
    "$store.getters.constomLogoOpen": {
      handler(val) {
        console.log("constomLogoOpen", val);
        if (!this.sidebar.opened) return;
        this.customLogoPath =
          envInfo.bgApp.tenantPath + "/param/tenant/avatar?p=" + val;
      },
    },
  },
  computed: {
    ...mapGetters(["currentTenant", "tenantList", "sidebar"]),
    // customLogoPath() {
    //   let customLogo = this.$store.getters.constomLogo;
    //   let customLogoPath = "";
    //   if (customLogo) {
    //     customLogoPath =
    //       envInfo.bgApp.tenantPath + "/param/tenant/avatar?p=" + customLogo;
    //   }
    //   return customLogoPath;
    // },
    tenantName() {
      let tenantList = this.$store.getters.tenantList;
      let currentTenant = this.$store.getters.currentTenant;
      if (currentTenant && tenantList) {
        for (let i = 0; i < tenantList.length; i++) {
          if (tenantList[i].tenantId == currentTenant) {
            return tenantList[i].tenantName;
          }
        }
      }
      return "";
    },
  },
};
</script>

<style>
.projectInfo {
  /*padding: 10px 20px 0px;*/
  /*cursor: pointer;*/
  height: 56px;
  display: flex;
  align-items: center;
  margin: auto 14px;
  display: flex;
}

.projectInfo .logo {
  width: 140px;
  height: 32px;
  border: none;
}
.projectInfo .projectName {
  display: block;
  font-size: 14px;
  text-align: center;
  color: rgb(191, 203, 217);
}

.projectInfo .tenant-avatar {
  height: 3.5rem;
  width: 100%;
  display: inline-block;
  background: #5091f9;
  text-align: center;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.projectInfo .tenant-font {
  color: #fcf9f9;
  overflow-x: hidden;
  position: relative;
  font-family: "微软雅黑", Arial, "宋体";
  vertical-align: top;
  line-height: 1.6;
  font-size: 36px;
  font-weight: 700;
}

/* 收起后 */
.projectInfoMini {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectInfoMini .logo {
  width: 32px;
  height: 32px;
  display: block;
  margin: 0 auto;
}
.projectInfoMini .projectName {
  display: none;
}

.projectInfoMini .tenant-avatar {
  height: 2.5rem;
  width: 100%;
  display: inline-block;
  background: #5091f9;
  text-align: center;
  border-radius: 0px;
}

.projectInfoMini .tenant-font {
  color: #fcf9f9;
  overflow-x: hidden;
  position: relative;
  font-family: "微软雅黑", Arial, "宋体";
  vertical-align: top;
  line-height: 2.6;
  font-size: 17px;
  font-weight: 700;
}
</style>
