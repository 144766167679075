<template>
  <div class="short-menu-dialog">
    <el-dialog
      :visible.sync="dataset"
      width="900px"
      :before-close="handleClose"
    >
      <div class="dialog-title">
        <span class="title-label"> 快捷菜单编辑 </span>
        <i class="el-icon-close" @click="handleClose"></i>
      </div>
      <div class="menu-container">
        <div class="left-part">
          <div class="title-line">菜单列表</div>
          <div class="card-container">
            <div class="search-line">
              <el-input
                size="small"
                clearable
                v-model="searchKey"
                @input="searchChange"
                suffix-icon="el-icon-search"
              ></el-input>
            </div>
            <div class="tree-container">
              <div v-for="(item, index) in treeList" :key="index">
                <div
                  class="common-label cursor-pinter"
                  v-show="item.show"
                  :style="{ paddingLeft: `${(item.nodeLevel - 1) * 20}px` }"
                  @click="menuOpenClick(item)"
                >
                  <span class="first-title">
                    <span v-show="item.nodes">
                      <span v-if="item.opened"
                        ><i class="el-icon-arrow-down"></i
                      ></span>
                      <span v-else><i class="el-icon-arrow-up"></i></span>
                    </span>
                    {{ generateTitle(item.displayText) }}
                  </span>
                </div>
                <template v-if="item.opened">
                  <div
                    v-for="(secondItem, secondIndex) in item.nodes"
                    :key="secondIndex"
                  >
                    <div
                      class="common-label cursor-pinter"
                      v-show="secondItem.show"
                      @click.prevent="labelLineClick(secondItem)"
                      :style="{
                        paddingLeft: `${(secondItem.nodeLevel - 1) * 20}px`,
                      }"
                    >
                      <span class="second-title">
                        <span class="seconde-menu-image">
                          <svg-icon
                            v-if="secondItem.icon"
                            :icon-class="secondItem.icon"
                          />
                          <svg-icon v-else icon-class="zuzhiguanli" />
                        </span>
                        {{ generateTitle(secondItem.displayText) }}
                      </span>
                      <el-checkbox
                        v-if="!secondItem.nodes && secondItem.href"
                        v-model="secondItem.checked"
                        :disabled="
                          !secondItem.checked && selectedList.length > 9
                        "
                      ></el-checkbox>
                    </div>
                    <div
                      v-for="(thirdItem, thirdIndex) in secondItem.nodes"
                      :key="thirdIndex"
                    >
                      <div
                        class="common-label cursor-pinter"
                        v-show="thirdItem.show"
                        @click.prevent="labelLineClick(thirdItem)"
                        :style="{
                          paddingLeft: `${(thirdItem.nodeLevel - 1) * 20}px`,
                        }"
                      >
                        <span class="second-title">
                          <span class="seconde-menu-image">
                            <svg-icon
                              v-if="thirdItem.icon"
                              :icon-class="thirdItem.icon"
                            />
                            <svg-icon v-else icon-class="zuzhiguanli" />
                          </span>
                          {{ generateTitle(thirdItem.displayText) }}
                        </span>
                        <el-checkbox
                          v-if="!thirdItem.nodes && thirdItem.href"
                          v-model="thirdItem.checked"
                          :disabled="
                            !thirdItem.checked && selectedList.length > 9
                          "
                        ></el-checkbox>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <img
          class="transfer-icon"
          src="@/assets/images/short_menu_icon.png"
          alt=""
        />
        <div class="right-part">
          <div class="title-line">
            已选菜单 （{{ selectedList.length }}/10）
          </div>
          <div class="card-container">
            <div class="select-container">
              <div
                class="single-select"
                v-for="(item, index) in selectedList"
                :key="index"
              >
                <span>
                  <span class="seconde-menu-image">
                    <svg-icon v-if="item.icon" :icon-class="item.icon" />
                    <svg-icon v-else icon-class="zuzhiguanli" />
                  </span>
                  {{ generateTitle(item.displayText) }}
                </span>
                <span class="remove-span" @click="removeHandler(item)">
                  <i class="el-icon-close"></i>
                  移除
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-line">
        <el-button type="primary" @click="menuSubmit">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { generateTitle } from "@/utils/i18n";
import { getTenantSiteMapZtreeList } from "@/api/ruge/sitemap/sitemap";
export default {
  name: "short-menu-dialog",
  props: {
    dataset: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      currentUser: localStorage.getItem("application:lu"),
      currentTenant: this.$store.getters.currentTenant,
      searchKey: null,
      treeList: [],
      selectedList: [],
      treeInit: false,
    };
  },
  watch: {
    treeList: {
      handler(list) {
        if (!this.treeInit) return;
        this.selectedList = [];
        this.listCheckHandler(list);
      },
      deep: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    generateTitle,
    menuSubmit() {
      const localMenu = localStorage.getItem("shortcut-mune-store");
      if (localMenu) {
        let parseLocalMenu = JSON.parse(localMenu);
        parseLocalMenu[`${this.currentUser}-${this.currentTenant}`] =
          this.selectedList;
        localStorage.setItem(
          "shortcut-mune-store",
          JSON.stringify(parseLocalMenu)
        );
      } else {
        const selected = JSON.stringify({
          [`${this.currentUser}-${this.currentTenant}`]: this.selectedList,
        });
        localStorage.setItem("shortcut-mune-store", selected);
      }
      this.$eventBus.$emit("fresh-short-menu");
      this.handleClose(true);
    },
    initDatas() {
      try {
        const localDatas = localStorage.getItem("shortcut-mune-store");
        if (localDatas) {
          let localMenu =
            JSON.parse(localDatas)[
              `${this.currentUser}-${this.currentTenant}`
            ] || [];
          this.selectedList = localMenu;
        }
      } catch (error) {
        console.log("初始化本地数据失败：", error);
      }
      getTenantSiteMapZtreeList({ parentCode: "rootNo", status: 1 })
        .then((res) => {
          let result = res.filter((item) => {
            return item.status === 1;
          });
          this.setOpenKey(result);
          this.treeList = result;
          console.log("xxx", this.treeList);
        })
        .finally(() => {
          this.treeInit = true;
          this.selectedList.forEach((item) => {
            this.setItemChecked(item.sitemapCode);
          });
        });
    },
    labelLineClick(item) {
      if (!item.href) return;
      if (this.selectedList.length > 9 && !item.checked) return;
      item.checked = !item.checked;
    },
    handleClose(fresh) {
      this.searchKey = null;
      this.treeFilterHandler(null, this.treeList, true);
      this.$emit("close", fresh);
    },
    searchChange(value, list = this.treeList) {
      if (!value) {
        this.treeFilterHandler(null, this.treeList, true);
      } else {
        this.treeFilterHandler(value);
        console.log("this.treeList", this.treeList);
        this.setParentOpen();
      }
    },
    setParentOpen(list = this.treeList) {
      list.forEach((item) => {
        if (item.nodes && item.nodes.length) {
          if (item.nodes.filter((item) => item.show).length > 0) {
            item.show = true;
            if (item.parentCode !== "rootNo") {
              this.setNodeShowByCode(item.parentCode);
            }
          }
          this.setParentOpen(item.nodes);
        }
      });
    },
    setNodeShowByCode(sitemapCode, list = this.treeList) {
      list.forEach((item) => {
        if (item.sitemapCode === sitemapCode) {
          item.show = true;
        }
        if (item.node && item.nodes.length) {
          this.setNodeShowByCode(sitemapCode);
        }
      });
    },
    treeFilterHandler(value, list = this.treeList, resetFlag) {
      list.forEach((item) => {
        if (resetFlag) {
          item.show = true;
        } else {
          if (item.displayText.includes(value) && !item.nodes && item.href) {
            item.show = true;
          } else {
            item.show = false;
          }
        }
        if (item.nodes) {
          this.treeFilterHandler(value, item.nodes, resetFlag);
        }
      });
    },
    menuOpenClick(item) {
      item.opened = !item.opened;
    },
    removeHandler(item) {
      this.removeSelectByMapCode(item.sitemapCode);
    },
    removeSelectByMapCode(sitemapCode, list = this.treeList) {
      list.forEach((item) => {
        if (item.sitemapCode === sitemapCode) {
          item.checked = false;
        }
        if (item.nodes) {
          this.removeSelectByMapCode(sitemapCode, item.nodes);
        }
      });
    },
    setOpenKey(list) {
      list.forEach((item) => {
        item.opened = true;
        item.checked = false;
        item.show = true;
        if (item.nodes) {
          this.setOpenKey(item.nodes);
        }
      });
    },
    setItemChecked(sitemapCode, list = this.treeList) {
      list.forEach((item) => {
        if (item.sitemapCode === sitemapCode) {
          item.checked = true;
        }
        if (item.nodes) {
          this.setItemChecked(sitemapCode, item.nodes);
        }
      });
    },
    listCheckHandler(list) {
      if (list && list.length) {
        list.forEach((item) => {
          if (item.checked) {
            // this.checkAndPush(item);
            this.selectedList.push(item);
          }
          if (item.nodes) {
            this.listCheckHandler(item.nodes);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.short-menu-dialog {
  .dialog-title {
    position: relative;
    text-align: center;
    margin: 30px 0 20px;
    .title-label {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #252d3d;
    }
    .el-icon-close {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
    }
  }
  .menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-line {
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #152c5b;
      margin-bottom: 14px;
    }
    .card-container {
      width: 100%;
      height: 520px;
      background: #fcfdff;
      border-radius: 10px;
      border: 1px solid #dde7f8;
      padding: 12px 30px;
    }
    .transfer-icon {
      height: 24px;
      width: 24px;
    }
    .left-part {
      width: 350px;
      .search-line {
        margin-bottom: 12px;
      }
      .tree-container {
        height: calc(100% - 45px);
        overflow: auto;
        .cursor-pinter {
          cursor: pointer;
        }
        .common-label {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          padding-right: 20px;
          &:hover {
            .second-title {
              color: #2a61ff;
            }
          }
        }
        .first-title {
          font-size: 14px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #252d3d;
        }
        .second-title {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #5d687c;
          .seconde-menu-image {
            width: 20px;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
    .right-part {
      width: 350px;
      .card-container {
        padding: 0;
      }
      .select-container {
        padding: 20px 30px;
        height: 470px;
        overflow: auto;
        .single-select {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .seconde-menu-image {
            width: 20px;
            display: inline-block;
            text-align: center;
          }
          .remove-span {
            color: #ff0000;
            cursor: pointer;
          }
        }
      }
    }
  }
  .button-line {
    margin: 40px 0;
    text-align: center;
  }
  .el-button + .el-button {
    margin-left: 30px;
  }
  ::v-deep(.el-dialog__header) {
    display: none;
  }
}
</style>