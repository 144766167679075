var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shortcut-container" },
    [
      _c("img", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          src: require("@/assets/images/shotcut_menu_icon.png"),
          alt: "",
        },
        on: { click: _vm.openShormenu },
      }),
      _c(
        "el-drawer",
        {
          staticStyle: { "line-height": "16px" },
          attrs: {
            size: 400,
            visible: _vm.shormenuShow,
            "before-close": () => {
              _vm.shormenuShow = false
            },
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shormenuShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_c("title-icon"), _vm._v("快捷菜单")], 1),
          ]),
          _c(
            "span",
            { staticClass: "edit-label", on: { click: _vm.openMenuDialog } },
            [_vm._v(" 编辑 ")]
          ),
          _vm.menuList.length
            ? _c(
                "div",
                { staticClass: "menu-list" },
                _vm._l(_vm.menuList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "single-one",
                      on: {
                        click: function ($event) {
                          return _vm.routePathJump(item.href)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon-line" },
                        [
                          item.icon
                            ? _c("svg-icon", {
                                attrs: { "icon-class": item.icon },
                              })
                            : _c("svg-icon", {
                                attrs: { "icon-class": "zuzhiguanli" },
                              }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "label-line" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.generateTitle(item.displayText)) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "nodata-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "nodata-add",
                    on: { click: _vm.openMenuDialog },
                  },
                  [
                    _c("div", { staticClass: "icon-line" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                    ]),
                    _c("div", { staticClass: "label-line" }, [
                      _vm._v(
                        "\n          您暂未添加快捷菜单，点击“+”立即添加快捷菜单\n        "
                      ),
                    ]),
                  ]
                ),
              ]),
        ],
        2
      ),
      _vm.menuDialogShow
        ? _c("ShortMenuDialog", {
            attrs: { dataset: _vm.menuDialogShow },
            on: { close: _vm.dialogClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }