import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 加载设备工单列表
 * @param params
 */
export function getOrderPage(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/orderFill/conditionalQuery',
    method: 'get',
    params,
  });
}
/**
 * 我协助的
 * @param params
 */
export function assistedList(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/order/assistedList',
    method: 'get',
    params,
  });
}
/**
 * 加载公司列表
 * @param params
 */
export function getCompanyList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/company/list',
    method: 'get',
    params,
  });
}
/**
 * 根据公司id加载项目
 * @param params
 */
export function getProjectList(params) {
  return request({
    url: envInfo.bgApp.basePath + `/tenant/project/list`,
    method: 'get',
    params,
  });
}
/**
 * 根据公司id加载责任部门
 * @param params
 */
export function getResDeptName(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/tenant/dept/list`,
    method: 'get',
    params,
  });
}
/**
 * 加载人员
 * @param params
 */
export function getEmployeeList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + `/tenant/employee/empList`,
    method: 'get',
    params,
  });
}
/**
 * 根据工单id查询所有信息
 * @param params
 */
export function getOrderDetail(params) {
  return request({
    url: envInfo.bgApp.orderPath + `/tenant/orderFill/getDetails`,
    method: 'get',
    params,
  });
}
/**
 * 获取所在位置
 *
 */
export function getPosition(pid = '') {
  let reqUrl = envInfo.bgApp.basePath + '/tenant/position/tree';
  if (pid) reqUrl = reqUrl + '?projectId=' + pid;
  return request({
    url: reqUrl,
    method: 'get',
  });
}

/**
 * 导出工单查询
 * @param params
 */
export function exportOrder(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/orderFill/export',
    method: 'get',
    params,
  });
}

/**
 * 导出我协助的工单
 * @param params
 */
export function exportAssistOrder(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/order/export',
    method: 'get',
    params,
  });
}

/**
 * 上传图片
 * @param params
 */
export function uploadImages(params) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url:
      envInfo.bgApp.archivePath + '/param/archive/upload?ulType=DefaultUpload',
    method: 'post',
    data: params,
  });
}

/**
 * 获取审核列表
 * @param params
 */
export function getVerifyList(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/order/toExamineList',
    method: 'get',
    params,
  });
}

/**
 * 获取审核列表
 * @param params
 */
export function toExamineListForPC(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/order/toExamineListForPC',
    method: 'get',
    params,
  });
}

/**
 * 获取公司项目列表
 * @param params
 */
export function findCompanyProjectList(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/findCompanyProjectList',
    method: 'get',
    params,
  });
}

/**
 * 获取模板
 * @param params
 */
export function getConfigList(params) {
  return request({
    url: envInfo.bgApp.orderPath + '/tenant/orderConfig/getConfigList',
    method: 'get',
    params,
  });
}

/**
 * 获取报单部门
 * @param params
 */
export function getReportDeptList(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/dept/list',
    method: 'get',
    params,
  });
}

/**
 * 获取当前登录人部门等信息
 * @param params
 */
export function getCurrentUserInfo(params) {
  return request({
    url: envInfo.bgApp.organizationPath + '/tenant/employee/getCurrent',
    method: 'get',
    params,
  });
}
