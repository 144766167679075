<template>
	<div class="">
		<el-dialog title="视频预览" :visible.sync="$store.state.app.isVedioSrc!==''" @close='closeModel' :close-on-click-modal="false">
			<div>
				<video id="video" :src='$store.state.app.isVedioSrc' controls="controls" :autoPlay='false' width="100%" preload="auto" u="image"></video>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeModel">取 消</el-button>
				<el-button type="primary" @click="closeModel">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		components: {},
		props: {
			prevSrc: { //与组件绑定的搜索条件的值
				type: String,
				default: 'https://vd3.bdstatic.com/mda-mhebhzqmteh89yw9/sc/cae_h264/1629015951292281308/mda-mhebhzqmteh89yw9.mp4?v_from_s=hkapp-haokan-hnb&auth_key=1659076196-0-0-c79430ec34f37bf2b62fd2800fed776a&bcevod_channel=searchbox_feed&pd=1&cd=0&pt=3&logid=3595811094&vid=4407842106025144817&abtest=103525_1-103578_1-103747_2&klogid=3595811094'
			},
		},
		data() {
			return {}
		},
		created() {

		},
		methods: {
			closeModel() {
				console.log('关闭视频预览')
				this.$store.commit('setVedioSrc', '')
			}
			

		}
	}
</script>
<style lang="scss">
#video{
    max-height: 60vh;
}
</style>