<template>
	<div class="mainBox" :class="mClass">
		<div class="m-body" :class="{padding0:noPadding}" @scroll="listenScroll">
			<slot></slot>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			noPadding:{
				type:Boolean,
				default:false,
			},
			mClass:{
				type:String,
				default:'',
			},
			isScroll:{
				type:Boolean,
				default:false,
			},
		},
		watch: {
		 	noPadding: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		        	
		      	}
		   	},
		},
		created(){
		},
		methods:{
			listenScroll(){
				if(this.isScroll){
					this.$emit('scroll')
				}
			},
		},
	};
</script>
<style lang="scss">
	
	.mainBox{
		font-size: 14px;
		background: rgb(241,242,244);
		padding: 20px;
		box-sizing: border-box;
		height: calc(100vh - 56px);
		overflow-y: auto;
		/*min-height: calc(100vh - 50px);*/
		.m-body{
			background: white;
			height: 100%;
			box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
			border-radius: 4px;
			padding: 16px;
			box-sizing: border-box;
		    min-height: 100%;
    		overflow: auto;
		}
		.padding0{
			padding: 0 !important;
		}
		.pTitle{
			line-height: 46px;
			font-size: 16px;
			padding-left: 15px;
			font-weight: bold;
			.sline{
				display: inline-block;
				width: 5px;
				height: 16px;
				background: #409EFF;
				margin-right: 10px;
				vertical-align: text-top;
			}
		}
		.yHover:hover{
			background: #E9E9E9;
		}
		.isActive{
			color: #409EFF !important;
			background: rgb(240,247,255) !important;
		}
		.txEllipsis{
			width: 100%;
		 	overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
		}
	}
	.noShadow.mainBox .m-body{
		box-shadow: none;
	}
	.lgMbox.mainBox{
		background: none;
		padding: 10px 40px 20px 40px;
		.m-body{
			padding: 20px;
			border-radius: 10px;
			.legoBtns{
				.el-button{
					min-width: 80px;
					min-height: 36px;
					border: 1px solid #2A61FF;
					border-radius: 8px;
					font-weight: bold;
				}
				.el-button.btBlue{
					color: #2A61FF;
				}
				.el-button.btGray{
					border: 1px solid #B5BECE;
					color: #B5BECE;
				}
				
				
			}
		}
	}
</style>