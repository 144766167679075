/**
 * 国际化配置 => 简体中文
 */
export default {
  facilityHome: {
    deviceTotal: '设备总数/台',
    orderCompleteNumber: '工单完成数/条',
    inspectionCompleteNumber: '巡检完成数/条',
    maintenanceCompleteNumber: '维保完成数/条',
    maintenanceLowerHairNumber: '维保下发数/条',
    jobCompleteRate: '任务完成率统计',
    orderCompleteTrend: '工单填报/完成数趋势',
    orderCompleteRank: '工单完成量排行',
    deviceTypeOrder: '设备类型工单统计',
    imStatistics: '巡检完成数统计',
    fillNumber: '填报数',
    completeNumber: '完成数',
    order: '工单',
    inspection: '巡检',
    maintenance: '维保',
    number: '数量',
    actualCompleteNumber: '完成数',
    shouldFinishNumber: '填报数',
    item: '条',
  },
  base: {
    category: {
      addRoot: '添加根类型',
      systemCategory: '系统分类',
      parentCategory: '上一级分类',
      code: '编码',
      workTime: '维修工时',
      desc: '备注',
      system: '所属系统',
      notConfigured: '没有配置',
    },
    device: {
      level: '设备级别',
      advanced: '高级查找',
      number: '设备编号',
      select: '选择设备',
      name: '设备名称',
      position: '设备位置',
      dutyDept: '责任部门',
      factoryCode: '设备编号',
      principal: '负责人',
      type: '设备类型',
      label: '设备标签',
      deviceEui: '设备EUI',
      protocol: '协议模式',
      add: '添加设备',
      edit: '编辑设备',
      copy: '复制设备',
      deviceManager: '设备管理',
      monitorManager: '监控点管理',
      monitor: '监控点',
      monitorPath: '控制路径',
      historyTable: '历史表名',
      startIcon: '运行图标',
      stopIcon: '停止图标',
      addMonitor: '添加监控点',
      editMonitor: '编辑监控点',
      addIcon: '添加图标',
      editIcon: '编辑图标',
      cameraNo: '摄像编号',
      fetchUrl: '视频地址',
      userName: '用户名',
      password: '访问密码',
      proxyIP: '代理IP',
      proxyPort: '代理端口',
      deviceIP: '设备IP',
      devicePort: 'PTZ端口',
      channelNum: '通道号',
      editCamera: '编辑视频',
      addCamera: '新增视频',
      message: {
        unOpenIOT: '您还没有开通IOT模块，请先开通后再配置监控点',
      },
    },
    monitor: {
      monitorPath: '点位路径',
      name: '监控点名',
      historyTable: '历史表名',
      controlSource: '控制源',
      alarmLevel: '告警级别',
      prompt: '提示',
      commonly: '一般',
      serious: '严重',
      monitorType: '点位类型',
      alarmFlag: '是否告警',
      autoOrder: '生成工单',
      industryType: '工业类型',
      alarmVoteValue: '告警值',
      lowValue: '过低临界',
      heightValue: '过高临界',
      alarmVoteDelay: '告警延迟',
      toleranceValue: '告警容差',
      isShow: '地图标记',
      desc: '备注',
      telecommand: '遥信',
      telemetering: '遥测',
      pleaseSelect: '请选择点位！',
      validMessage: {
        number: '请输入数字！',
      },
    },
    source: {
      name: '源名称',
      userAccount: '源认证用户',
      userToken: '源认证密码',
      type: '源类型',
    },
    position: {
      name: '位置',
      positionName: '位置名称',
      manager: '位置管理',
      subPosition: '上级位置名称',
      code: '位置编码',
      label: '位置标签',
      desc: '备注',
      devicePosition: '设备位置',
      project: '所属项目',
      selectProject: '选择项目',
      prompt: '请选择或添加一个位置',
    },
    project: {
      name: '项目名称',
      name1: '项目',
      code: '编码',
      label: '项目标签',
      desc: '描述',
      list: '项目列表',
      add: '添加项目',
      edit: '编辑项目',
      city: '所在城市',
      address: '详细地址',
      floorSpace: '占地面积(m²)',
      location: '经纬度',
      pleaseSelectCity: '请选择城市!',
      locationNotExist: '经纬度不存在，请设置经纬度!',
      searchProject: '请输入项目名称',
    },
    employee: {
      user: '账号',
      userName: '姓名',
      phone: '手机',
      email: '邮箱',
      organiza: '组织',
      add: '添加人员',
      edit: '编辑人员',
      prompt: '账号已绑定组织',
    },
    company: {
      name: '公司',
      companyName: '公司名称',
      companyCode: '公司编码',
      companyEmployee: '公司人员',
      organiza: '组织结构',
      create: '添加公司',
      edit: '编辑公司',
      delete: '删除公司',
      details: '公司详情',
      area: '区域',
      prompt: '请选择公司或部门',
      label: '公司标签',
    },
    dept: {
      name: '部门',
      subDept: '下级部门',
      supDept: '上一级部门',
      subText: '当前部门不包含下级部门，可点击',
      createSon: '添加子部门',
      deptName: '部门名称',
      deptCode: '部门编码',
      create: '添加部门',
      edit: '编辑部门',
      delete: '删除部门',
      details: '部门详情',
      deptEmployee: '部门人员',
      notSupDept: '无上级部门',
      responsibleDept: '责任部门',
      responsiblePerson: '责任人员',
      handlePerson: '处理人员',
      dutyName: '岗位',
    },
    materiel: {
      list: '物料列表',
      materielName: '物料名称',
      name: '物料',
      edit: '编辑物料',
      create: '添加物料',
      materielCode: '物料编码',
      code: '编码',
      type: '物料分类',
      typeName: '物料分类名称',
      mode: '规格型号',
      model: '型号',
      specification: '规格',
      unit: '单位',
      desc: '描述',
      collarDetails: '工单',
      label: '物料标签',
      category: {
        edit: '编辑物料分类',
        create: '添加物料分类',
        label: '分类标签',
      },
    },
  },
  maintenance: {
    template: {
      name: '维保模版',
      resultManager: '维保结果管理',
      itemNamePrompt: '维保项是否使用模版名称？',
    },
    code: '维保编码',
    list: '维保列表',
    generate: '生成维保',
    dynamic: '维保动态',
    details: '维保详情',
    company: '维保公司',
    self: '自维',
    external: '外维',
    contact: '联系人',
    name: '维保名称',
    planName: '维保计划',
    cycle: '维保周期',
    type: '维保类型',
    status: '维保状态',
    claim: '维保要求',
    position: '维保位置',
    ifScan: '是否扫码',
    addItem: '新建维保项',
    addDevice: '新建维保设备',
    editItem: '编辑维保项',
    editDevice: '编辑维保设备',
    result: '维保结果',
    device: '维保设备',
    item: '维保项',
    ifFailure: '是否失效',
    checkDuty: '核查岗位',
    serviceTerm: '服务期限',
    waitSuperviseCheck: '待督办审核',
    waitCheckDuty: '待核查审核',
    timePrompt: '开始时间:',
  },
  inspection: {
    plan: {
      add: '新建计划',
      edit: '编辑计划',
      name: '计划名称',
      inspectionName: '巡检名称',
      cycle: '计划周期',
      executeDept: '责任部门',
      executeDuty: '执行岗位',
      superviseDuty: '督办岗位',
      executePerson: '执行人员',
      executePosition: '执行位置',
      jobCycle: '任务周期',
      ifEnable: '是否启用',
      list: '计划清单',
      advanceNotify: '提前通知',
      effectiveTime: '生效时间',
      invalidTime: '失效时间',
      day: '日',
      crossDay: '跨天',
    },
    template: {
      templateName: '模版名称',
      name: '巡检模版',
      manager: '模版管理',
      pleaseInputName: '请输入模版名称',
      pleaseSelectLib: '请选择模版库',
      editLib: '编辑模版库',
      add: '新建模版',
      edit: '编辑模版',
      notRemark: '无备注',
      systemLib: '系统模版库',
      lib: '模版库',
      libManager: '模版库管理',
      remark: '模版备注',
      isNotes: '是否备注',
      checkResult: '检查结果',
      checkItem: '检查项',
      chooseItem: '选择项',
      checkContent: '检查内容',
      scoreContent: '评分内容',
      scoreStandard: '评分标准',
      maxScore: '最高分',
      copyContent: '抄录内容',
      scoreItem: '评分项',
      editItem: '编辑选项',
      copyItem: '抄录项',
      resultItem: '结果项',
      resultManager: '巡检结果管理',
      recodeResult: '记录结果',
      qualified: '合格',
      failed: '不合格',
      ifCopyPrompt: '确定复制模版吗？',
      notNull: '模版名称不能为空',
      containPrompt: '模板库内包含有模板！',
      itemNamePrompt: '巡检项是否使用模版名称？',
      liveSituation: '现场照片/视频',
    },
    report: {
      reportCardName: '巡检报表',
      detailCardName: '报表详情',
      selectInspItem: '选择巡检项',
      pleaseChoose: '请选择',
      locationHint: '巡检位置为空，请重新选择巡检计划',
      startTimeHint: '开始时间不能大于结束时间',
      endTimeHint: '结束时间不能小于开始时间',
      reselectPosition: '该位置下无巡检项，请重新选择位置',
      inspeItemName: '巡检项名称',
      inspeNumber: '巡检编号',
      liveVideo: '现场视频',
    },
    code: '巡检编码',
    list: '巡检列表',
    generate: '生成巡检',
    dynamic: '巡检动态',
    details: '巡检详情',
    name: '巡检名称',
    planName: '巡检计划',
    cycle: '巡检周期',
    status: '巡检状态',
    claim: '巡检要求',
    position: '巡检位置',
    ifScan: '是否扫码',
    addItem: '新建巡检项',
    addDevice: '新建巡检设备',
    editItem: '编辑巡检项',
    editDevice: '编辑巡检设备',
    result: '巡检结果',
    device: '巡检设备',
    item: '巡检项',
    ifPhoto: '是否拍照/视频',
    leastOne: '至少保留一个',
    ifEnablePrompt: '确定启用计划么？',
    ifStopPrompt: '确定暂停计划么？',
    invalidTimePrompt: '生效时间不能大于失效时间!',
    unGreatDate: '不能大于结束时间:',
    monthCyclePrompt: '每月周期时间，开始时间:',
    weekCyclePrompt: '每周周期时间，开始时间:',
    dayCyclePrompt: '每日周期时间，在非跨天情况下，开始时间:',
    onceCyclePrompt: '每次周期时间，开始时间:',
    monthPrompt: '每月周期时间，不能大于4天',
    invalidHint: '结束时间不能大于失效时间！',
  },
  order: {
    orderCode: '工单编码',
    other: '其他',
    materielDetail: '物料领用明细',
    workTimeConfigure: '工时设置',
    configure: '工单设置',
    makeMe: '自己做单',
    uploadLimit: '当前限制最多上传 9 个文件！',
    submitSuccess: '工单提交成功',
    cancelPrompt: '工单还未提交，确认关闭？',
    fill: '填报工单',
    place: '报单',
    list: '工单列表',
    details: '工单详情',
    completeDetail: '完成说明',
    completeState: '完成情况',
    dynamic: '工单动态',
    approve: '报单审核',
    waitReceive: '待领取',
    unabsorbed: '未分配',
    waitExecute: '待执行',
    executing: '执行中',
    completed: '已完成',
    completedWaitCheck: '验收待审核',
    declareCheckFailure: '报单审核失败',
    completeCheckFailure: '完成审核不通过',
    waitAcceptance: '待验收',
    applyReject: '报单被驳回',
    acceptanceReject: '验收被驳回',
    alreadyCloseOrder: '已关单',
    workOrderName: '模板名称',
    alreadyRevoke: '报单已撤销',
    orderConfigName: '工单模版',
    serveTypeStr: '服务类型',
    scenePhoto: '现场照片',
    sign: '签字',
    signTime: '签到时间',
    generateTime: '生成时间',
    declareTime: '报单时间',
    declareDept: '报单部门',
    declarePeople: '报单人员',
    level: '工单级别',
    status: '工单状态',
    faultDesc: '问题描述',
    faultPhoto: '故障照片',
    faultReason: '故障原因',
    serviceDept: '责任部门',
    servicePeople: '维修人员',
    serviceMethod: '维修方法',
    completedTime: '要求完成时间',
    submitTime: '完成提交时间',
    completedPhoto: '完成照片',
    qualityLevel: '品质级别',
    questionDesc: '问题描述',
    livePhoto: '现场情况',
    qualityType: '品质类型',
    advertising: '广告宣传',
    clearService: '清洁服务',
    buildDecoration: '土建/装饰',
    beautyChemical: '美陈美化',
    device: '设备设施',
    operationEnvironment: '运营环境',
    merchantService: '商户品质/服务',
    completedHappening: '完成说明',
    assistDept: '协助部门',
    assistPerson: '协助人员',
    workMatters: '工作事项',
    assistMatters: '协助事项',
    safetyLevel: '安全级别',
    dangerLevel: '隐患级别',
    safetyQuestion: '安全问题',
    safetyType: '安全类型',
    performanceManager: '履职管理',
    merchantManager: '商户管理',
    publicSafety: '公共安全',
    fireSafety: '消防安全',
    quality: '环境品质',
    sporadicMaintenance: '零星维修',
    cleaningHygiene: '保洁卫生',
    greenConservation: '绿化保养',
    vulnerabilityInformation: '弱电信息',
    customerDeclaration: '客户报单',
    orderType: '工单类型',
    constructSafety: '施工安全',
    appDept: '申请部门',
    appPerson: '申请人员',
    appTime: '申请时间',
    auditDept: '审核部门',
    auditPerson: '审核人员',
    auditTime: '审核时间',
    transfer: '转派',
    assist: '协助',
    delayed: '延时',
    materiel: '物料',
    complete: '完成',
    config: {
      declareIfReview: '报单是否审核',
      declareSuccessDesc: '(说明：如果关闭，报单成功后，不需要审核，直接派送)',
      approveSequence: '审核是否有序',
      approveSequenceDesc:
        '(说明：开启为有序，报单审核或者完成审核时，需要按顺序审核；关闭为无序，审核没有顺序)',
      orderSuccessReview: '工单完成审核',
      orderReportReview: '工单报单审核',
      isSign: '工单是否签到',
      isAutograph: '工单是否签名',
      isCloseAudit: '工单是否关单',
      executeDeptSupervisor: '责任部门主管',
      declarePerson: '报单人',
      designationPerson: '指定人',
      designationDept: '指定部门主管',
      deviceTip: '该部门暂未设置负责人',
      deviceOrder: '设备设施类工单',
      fireSafetyOrder: '消防安全类工单',
      qualityOrder: '环境品质类工单',
      orderExecuteHour: '工单执行标准工时',
      workConnectOrder: '工作联络工单',
      orderExecuteDept: '工单责任部门',
      orderAssistDept: '工单协助部门',
      saveConfig: '保存设置',
      ordinary: '普通',
      urgent: '紧急',
      alarm: '告警',
      defDept: '默认责任部门',
      generalSafety: '一般安全隐患',
      largerSafety: '较大安全隐患',
      majorSafety: '重大安全隐患',
      observedItem: '观察项',
      generalFailed: '一般不合格',
      majorFailed: '严重不合格',
      dangerLevelDesc: '(说明：按隐患级别)',
      orderLevelDesc: '(说明：按工单级别)',
      waitReceiveConfig1: '待领取状态下的工单如果未被领取，每',
      waitReceiveConfig2: '分钟视为一次超时。',
      waitExecuteConfig1: '待执行状态的工单超过工时',
      waitExecuteConfig2: '分钟，还未开始执行视为一次超时，之后每过',
      waitExecuteConfig3: '分钟视为一次超时。',
      executingConfig1:
        '执行中的工单截止要求完成时间还未提交视为一次超时，之后每过',
      executingConfig2: '分钟视为一次超时。',
      overtimeConfig1: '第',
      overtimeConfig2: '次超时，上报岗位负责人。',
      overtimeConfig3: '次超时，上报部门负责人。',
      overtimeConfig4: '次超时，上报上级部门负责人，最多越',
      overtimeConfig5: '级上报。',
      addDeviceBySelf: '执行人添加设备',
      configTips:
        '(说明：如果开启，在工单执行时，执行人员在执行工单找不到相关设备时，可以自行添加设备)',
      timeoutAudit1: '超过',
      timeoutAudit2: '小时，自动审核',
      autoAudit: '自动审核',
      reportAutoAudit: '报单自动审核',
      completeAutoAudit: '完成自动审核',
      receiveTopLimit: '抢单上限',
      receiveTopLimitTips: '(说明：超过这个数量，用户将不能再领取新的工单)',
      pleaseSelectProject: '请选择项目',
      personIsNullTip: '指定人不能为空!',
      deptIsNullTip: '指定部门主管不能为空!',
      completeIfReview: '完成是否审核',
      completeIfReviewTip: '(说明：如果关闭，执行完成后，不需要审核，直接完成)',
      materielIfReview: '物料是否审核',
      materielIfReviewTip: '(说明：如果关闭，物料申请后，不需要审核，直接领用)',
      orderMaterielAudit: '工单物料审核',
      delayedIfReview: '延时是否审核',
      delayedIfReviewTip: '(说明：如果关闭，延时申请后，不需要审核，直接延时)',
      orderDelayedAudit: '工单延时审核',
      assistIfReview: '协助是否审核',
      assistIfReviewTip: '(说明：如果关闭，协助申请后，不需要审核，直接派发)',
      orderAssistAudit: '工单协助审核',
      assistPersonAudit: '协助人审核',
      assistPersonDeptAudit: '协助人部门主管',
      assistPersonIfReview: '协助人是否审核',
      assistPersonIfReviewTip:
        '(说明：如果关闭，协助申请后，协助人不需要审核，直接派发)',
      transferIfReview: '转派是否审核',
      transferIfReviewTip: '(说明：如果关闭，转派申请后，不需要审核，直接转派)',
      orderTransferAudit: '工单转派审核',
      recipientAudit: '接收人审核',
      recipientDeptAudit: '接收人部门主管',
      recipientIfReview: '接收人是否审核',
      recipientIfReviewTip:
        '(说明：如果关闭，转派申请后，接收人不需要审核，直接转派)',
    },
    statistics: {
      orderStatistics: '工单统计',
      reportsNumber: '填报工单(个)',
      completeNumber: '已完成工单(个)',
      receiveOvertimeNumber: '超时领取工单(个)',
      implementOvertimeNumber: '超时执行工单(个)',
      completeOvertimeNumber: '超时完成工单(个)',
      completeOrderTrend: '完成工单趋势',
      completeOrderStatistics: '完成工单统计',
      reportOrderTrend: '填报工单趋势',
      reportOrderStatistics: '填报工单统计',
    },
  },
  ychDemo: {
    textName1: '文本名称',
    selectName: '下拉选项',
    deviceName: '设备名称',
  },
};
