var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LogoToggle", {
        staticClass: "logo",
        attrs: { showStatus: _vm.isCollapse, logoMsg: _vm.logoMsg },
      }),
      _c(
        "el-menu",
        {
          staticClass: "navbar",
          attrs: {
            "default-active": _vm.curActive,
            "background-color": _vm.variables.menuBg,
            "text-color": _vm.variables.menuText,
            "active-text-color": _vm.variables.topMenuActiveText,
            mode: "horizontal",
          },
          on: { select: _vm.handleSelect },
        },
        _vm._l(_vm.sitemapListCustomer, function (sitemap) {
          return _c("navmenu", {
            key: sitemap.sitemapId,
            staticClass: "navmenu",
            attrs: { item: sitemap },
          })
        }),
        1
      ),
      _c("rightmenu", { staticClass: "right-menu" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }