import {
  connect,
  send
} from '@/utils/socket';
import {
  Base64
} from 'js-base64';

const message = {
  state: {
    status: '', //连接状态
    subscribe: null, //订阅点
    wsMessage: {
      //不同的消息类型在此拓展
      orientationMessage: null, //定向消息
      propertyMessage: null, //属性消息
      statusMessage: null, //状态消息
      alarmMessage: null, //告警消息
      // alarmSceneMessage: null //场景告警消息
    },
    exportNoticeList: [], //导出通知列表
    alarmNoticeList: [], //报警通知列表
    alarmSceneNoticeList: [], //场景告警通知列表
  },
  mutations: {
    SET_CONNECT: (state, status) => {
      state.status = status;
    },
    SET_SUBSCRIBE: (state, subscribe) => {
      state.subscribe = subscribe;
    },
    SET_WSMESSAGE: (state, message) => {
      let _jsonMsg = JSON.parse(message);
      if (typeof _jsonMsg.content_ == 'string') {
        state.wsMessage[_jsonMsg.type_] = JSON.parse(_jsonMsg.content_);
      } else {
        state.wsMessage[_jsonMsg.type_] = _jsonMsg.content_;
      }
    },
    //增加导出任务通知列表
    PUSH_EXPORT_NOTICE: (state, exportTask) => {
      state.exportNoticeList.push(exportTask);
    },
    REMOVE_EXPORT_NOTICE: (state, i) => {
      state.exportNoticeList.splice(i, 1);
    },
    //增加告警通知列表
    PUSH_ALARM_NOTICE: (state, noticeTask) => {
      state.alarmNoticeList.push(noticeTask);
    },
    REMOVE_ALARM_NOTICE: (state, i) => {
      state.alarmNoticeList.splice(i, 1);
    },
    //增加场景告警通知列表
    // PUSH_SCENE_ALARM_NOTICE: (state, noticeTask) => {
    //     state.alarmSceneNoticeList.push(noticeTask);
    // },
    // REMOVE_SCENE_ALARM_NOTICE: (state, i) => {
    //     state.alarmSceneNoticeList.splice(i,1);
    // },
  },
  actions: {
    //设置消息连接状态
    SetSocketState({
      commit
    }, status) {
      commit('SET_CONNECT', status);
    },
    //设置订阅值
    SetSubscribe({
      commit
    }, subscribe) {
      send(subscribe);
      commit('SET_SUBSCRIBE', subscribe);
    },
    //设置消息
    SetMessage({
      commit
    }, data) {
      commit('SET_WSMESSAGE', data);
    },
    //初始化连接
    InitConnect(store) {
      //connect(store.getters.baseAuthToken);
      connect(Base64.encode(store.getters.baseAuthToken));
    },
    //导出任务通知
    PushExportNotice({
      commit
    }, notice) {
      commit('PUSH_EXPORT_NOTICE', notice);
    },
    RemoveExportNotice({
      commit
    }, index) {
      commit('REMOVE_EXPORT_NOTICE', index);
    },
    PushAlarmNotice({
      commit
    }, notice) {
      commit('PUSH_ALARM_NOTICE', notice);
    },
    RemoveAlarmNotice({
      commit
    }, index) {
      commit('REMOVE_ALARM_NOTICE', index);
    },
    // PushSceneAlarmNotice({commit}, notice){
    //     commit('PUSH_SCENE_ALARM_NOTICE', notice);
    // },
    // RemoveSceneAlarmNotice({commit}, index){
    //     commit('REMOVE_SCENE_ALARM_NOTICE', index);
    // }
  },
};

export default message;