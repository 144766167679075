exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#b4c5d5",
	"menuActiveText": "#2a4158",
	"subMenuActiveText": "#b4c5d5",
	"menuBg": "#2a4158",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "250px"
};