const rule = {
  state: {
    stationSelected: false, //规则动作中，是否已选流转到station
  },
  mutations: {
    SET_STATION_SELECTED: (state, status) => {
      state.stationSelected = status;
    },
  },
};
export default rule;
