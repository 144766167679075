<template>
	<!--新增或修改工单-->
	<div class="O-addOrEdit">
		<el-dialog class='diyModel' :title="orderId?'修改工单':'新建工单'" :visible.sync="$store.state.app.orderAddEdit.open" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div  v-loading='detailLoading'>
				<div class="formMain" ref="formMain">
					<TForm class="m_main" ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					</TForm>
				</div>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 提交</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import TForm from '@/components/YTable/TForm.vue';
	import { listToTree } from "@/utils/tree";
	import TreeSelect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import pinyin from "js-pinyin";
	import {getPosition} from "@/api/business/order/tenant/order";
	import {getCompanyList,getProjectList} from "@/api/business/order/tenant/order";
	import { getOrderDetail } from "@/api/business/order/tenant/order";
	export default {
		props: {},
		components: {TForm},
		computed: {
			orderId(){
				return this.$store.state.app.orderAddEdit.dataId;
			},
			showOrderType(){
				return !(this.workType&&this.workType.length>0);
			},
			dialogOpen(){
				return this.$store.state.app.orderAddEdit.open;
			},
		},
		watch:{
			orderId:{
				deep: true,
				handler(n,o){
					console.log('n',n)
					this.isHidePersonIts();
					if(n){
						this.resetTFrom();
						this.getDetailById();
					}
				},
			},
			dialogOpen:{
				deep:true,
				handler(n,o){
					if(!n){
						this.resetTFrom();
					}
					if(this.$store.state.app.orderAddEdit.orderDatas){
						this.resetTFrom();
					}
				}
			},
		},
		data() {
			return {
				detailLoading:false,
				editPositionList: [],//空间位置数据
				htmlDatas:{
					projectId:'',
				},
	  			isHttping:false,
	  			tempArrs:[],//模板选项
	  			impDeptArrs:[],//责任部门
	  			workType:[],//工单类型
	  			formlist:[
	  				{name: '所属项目',keys: 'projectId',value: '',type: 'select',options:[],flex:13,isMust:true,changMethod:this.changeProject,notClear:true,disabled:false},
	  				{name: '工单模版',keys: 'orderConfigId',value: '',type: 'select',options:[],flex:13,isMust:true,changMethod:this.changeOrderTemp,disabled:false},
	  				{name: '所在位置',keys: 'positionId',value: '',type: 'cascader',options:[],flex:13,checkProps:{
	  					value:'positionId',label:'positionName',children:'children',checkStrictly:true,multiple:false,emitPath: false,
	  				}},
	  				{name: '设备名称',keys: 'deviceId',value: '',type: 'devices',flex:13,changMethod:this.changeDevice},
	  				{name: '问题描述',keys: 'contentDesc',value: '',type: 'input',flex:24,isMust:true},
	  				{name: '责任部门',keys: 'resDeptId',value: '',type: 'select',options:[],flex:13,isMust:true,changMethod:this.changeOrderDeptId},
	  				{name: '工单类型',keys: 'orderTypeStr',value: '',type: 'select',options:[],flex:13,isHide:true},
	  				{name: '执行人',keys: 'handlePerson',value: '',type: 'select',flex:13,options:[],isHide:true},
	  				{name: '工单级别',keys: 'orderLevel',value: 0,type: 'select',options:[
	  					{name:'普通',code:0},
	  					{name:'紧急',code:1},
	  					{name:'告警',code:2},
	  				],flex:13,isMust:true},
	  				{name: '现场情况图片/视频',keys: 'subResource',flex:24,imgWidth:'80px',imgHeight:'80px',diyClass:'mgBottm',
	  				value:[],type: 'imgVideo',allowAdd:true,tips:'上传现场图片或视频(最多9项)',tipleft:'118px'},
	  				{name: '自己做单',keys: 'isSelf',value: 'N',type: 'switch',flex:13,},
	  			],
			}
		},
		
		created() {
			this.isHidePersonIts();
			this.initProjectArrs();
			this.resetTFrom();
			
		},
		mounted() {
			this.getDetailById();
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			isHidePersonIts(){
				//判断执行人是否显示隐藏
				for(let it of this.formlist){
					if(it.keys=='handlePerson'){
						if(this.orderId){
							it.isHide=false;
						}else{
							it.isHide=true;
						}
					}
					if(it.keys=='orderTypeStr'){
						if(it.options){
							let len=it.options.length;
							if(len>0){
								it.isHide=false;
							}else{
								it.isHide=true;
							}
						}
					}
					if(it.keys=='projectId'||it.keys=='orderConfigId'){
						
						if(this.orderId){
							it.disabled=true;
						}else{
							it.disabled=false;
						}
						console.log('it.keys',this.orderId,it)
					}
				}
			},
			resetTFrom(){
				console.log('进行了重置')
				if(this.$refs.htmlFrom) this.$refs.htmlFrom.resetFields();
				this.htmlDatas={};
	  			for(let item of JSON.parse(JSON.stringify(this.formlist))) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  			let orderInfo=this.$store.state.app.orderAddEdit.orderDatas;
	  			if(orderInfo){
	  				for(let keys in this.htmlDatas){
	  					this.htmlDatas[keys]=orderInfo[keys];
	  				}
	  				this.initOptionsByData(orderInfo);
	  			}
	  			this.$nextTick(() => {
					if(this.$refs.formMain) this.$refs.formMain.scrollTop = 0
				})
	  		},
			closeMypup(){
				this.$store.commit('setAllDeviceArrs',[]);
				this.$store.commit('setOrderAddEdit',{type:'close'});//关闭新建工单弹窗
			},
			initProjectArrs(){
				//加载项目数据
				getProjectList({
						companyId: null
					}).then((res) => {
					res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);//添加选项数据到所属项目
				});
			},
			changeProject(val,it){
				console.log(val,it)
				//项目切换
				this.$store.dispatch('getAllDevices',val);//获取部门人员数据
				this.htmlDatas.orderConfigId='';
				this.htmlDatas.positionId='';
				this.htmlDatas.deviceId='';
				this.initComplist(val);
				this.initPositionTree(val);
			},
			async initEmplistById(deptId){
				let res=await this.ApiHttp('/organization/tenant/employee/empList?deptId='+deptId);
				if(res){
					res.map(it=>{
						it.code=it.userId;
						it.name=it.userName;
					})
					this.common.insertOptions(this.formlist,'handlePerson',res);
				}
			},
			async initPositionTree(projectId){
				let res=await this.ApiHttp('/base/tenant/position/selectPositionTree?projectId='+projectId);
				if(res){
					this.common.insertOptions(this.formlist,'positionId',res);//添加选项数据到所属项目
				}
			},
			changeDevice(val,it){
				//选择设备  把位置赋值到所在位置中
				
				for(let item of this.$store.state.app.allDeviceArrs){
					if(val==item.deviceId){
						this.htmlDatas.positionId=item.positionId;
						break;
					}
					
					
					
				}
				
			},
			changeOrderTemp(val,it){
				//切换选择的模板
				this.htmlDatas.resDeptId='';
				this.initDeplist(val);
				this.getOrderTypeByConfigId(val);
				this.getDefDept(val);
			},
			changeOrderDeptId(val,it){
				//切换责任部门选择  重新获取部门下的执行人
				this.htmlDatas.handlePerson='';
				this.initEmplistById(val);
			},
			async initComplist(projectId){
				if(!projectId) return;
				let res=await this.ApiHttp('/order/tenant/orderConfig/getConfigListForMini?projectId='+projectId);
				let Arr1=[];
				res.map(it=>{
//					it.serviceTypeName=this.servNamesObj[it.serviceType];
					it.code=it.orderConfigId;
					it.name=it.configName;
					it.configStatusName=it.configStatus=="ENABLE"?'已启用':'已禁用';
					if(!it.description) it.description='无';
					if(it.configStatus=='ENABLE'){
						Arr1.push(it);
					}
				})
				this.tempArrs=Arr1;
				this.common.insertOptions(this.formlist,'orderConfigId',Arr1);//添加选项数据到所属项目
			},
			
			async initDeplist(orderConfigId){
				if(!orderConfigId) return;
				let res=await this.ApiHttp('/order/tenant/orderConfig/getImpDept?orderConfigId='+orderConfigId);
				res.map(it=>{
					it.code=it.deptId;
					it.name=it.deptName;
				})
				this.impDeptArrs=res;
				this.common.insertOptions(this.formlist,'resDeptId',res);//添加选项数据到所属项目
//				this.getDefDept(orderConfigId);
			},
			async getDefDept(orderConfigId){
				//获取默认部门
				let res=await this.ApiHttp('/order/tenant/orderFill/getDefDeptByProjectId?orderConfigId='+orderConfigId);
				if(res){
					this.htmlDatas.resDeptId=res.defDeptId;
				}
			},
			async getDetailById(){
				if(!this.orderId) return;
				this.detailLoading = true;
		      	getOrderDetail({ orderId: this.orderId }).then((res) => {
		          	res.closeDateStr=this.common.getTimeMmss(res.closeDate);
		          	this.htmlDatas={...res};
		          	this.initOptionsByData(res);
		        }).finally(() => {
	          		this.detailLoading = false;
		        });
			},
			initOptionsByData(res){
				//通过数据 初始各种下拉选项 
				this.$store.dispatch('getAllDevices',res.projectId);//获取项目下的设备
	          	this.initDeplist(res.orderConfigId);
	          	this.initComplist(res.projectId);
				this.initPositionTree(res.projectId);
				this.initEmplistById(res.resDeptId);
			},
			async getOrderTypeByConfigId(orderConfigId){
				if(!orderConfigId) return;
				let res=await this.ApiHttp('/order/tenant/orderConfig/getConfigDetails?orderConfigId='+orderConfigId);
				if(res){
					res.orderTypeList=res.orderTypeList||[];
	       			console.log('9999',JSON.parse(JSON.stringify(res)))
	       			res.orderTypeList.map(it=>{
	       				it.code=it.orderTypeName;
	       			})
	       			this.workType=res.orderTypeList;
	       			if(this.workType.length>0){
	       				if(!this.htmlDatas.orderTypeStr){
	       					//没有类型的话  默认选中第一个
	       					this.changeOrderType(this.workType[0])
	       				}
	       			}
	       			this.common.insertOptions(this.formlist,'orderTypeStr',res.orderTypeList);//添加选项数据到所属项目
       				this.isHidePersonIts()
				}
			},
			changeOrderType(item){
				this.htmlDatas.currentType = item.orderTypeId;
      			this.htmlDatas.orderTypeStr= item.orderTypeName;
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					source:'0',
					orderType: "1",
					...this.htmlDatas,
					problemDesc:this.htmlDatas.contentDesc,
				}
				let httpUrl='/order/tenant/orderFill/enterOrder';
				if(this.orderId){
					//有ID 代表执行的是修改操作
					params.orderId=this.orderId;
					httpUrl='/order/tenant/orderFill/modifyOrder';
					if(!params.handlePerson){
						const {orderStatus}=this.htmlDatas;
						if(orderStatus&&orderStatus!==1){
			    			this.$message({type:'warning',message:'请选择执行人'});
				    		return;//待行中或i执行中 必须要有执行人 否则不允许提交
			    		}
					}
				}else{
					//再次提交的时候 数据过来会有绑定的工单id 不能传给后台
					if(params.subResource){
						params.subResource=params.subResource.map(it=>{
							return {
								fileId: it.fileId,
								fileType: it.fileType,
								thumbnailId: it.thumbnailId,
							}
						})
					}
					params.handlePerson='';//不能再提交执行人
				}
//				console.log('From-Datas',this.htmlDatas)
				let res = await this.ApiHttp(httpUrl,params,'post');
				if(res){
					this.$message({type:'success',message:'操作成功'});
					this.$refs.htmlFrom.resetFields();
					this.$store.commit('setOrderAddEdit',{type:'init'});//关闭新建工单弹窗
					this.$store.commit('setAllDeviceArrs',[]);
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.O-addOrEdit{
	.formMain{
		max-height:600px;
		overflow-y: auto;
	}	
	::-webkit-scrollbar {  
	    display: none;
	}  
}
</style>
<style type="text/css">
	.O-addOrEdit{
		.Y-imgsVedio{
			margin-bottom: 20px;
		}
	}
	.mgBottm{
		margin-bottom: 20px;
	}
</style>