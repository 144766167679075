import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

// https://sit.gspark.cc/saascloud/saas/rules/rule/homeTotal
// https://sit.gspark.cc/saascloud/saas/things/device/homeTotal
// https://sit.gspark.cc/saascloud/saas/things/product/homeTotal
/**
 * 获取总览 - 产品
 * @param params
 */
export function getDashboardProductTotal(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/product/homeTotal',
    method: 'get',
    params,
  });
}

/**
 * 获取总览 - 设备
 * @param params
 */
export function getDashboardDeviceTotal(params) {
  return request({
    url: envInfo.bgApp.thingsPath + '/device/homeTotal',
    method: 'get',
    params,
  });
}

/**
 * 获取总览 - 规则
 * @param params
 */
export function getDashboardRuleTotal(params) {
  return request({
    url: envInfo.bgApp.rulesPath + '/rule/homeTotal',
    method: 'get',
    params,
  });
}
