 <template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :show-timeout="200"
      :collapse="isCollapse"
      :default-active="decodeURIComponent($route.fullPath)"
      router
      background-color="#415c7e"
      :text-color="variables.menuText"
      :active-text-color="variables.menuActiveText"
      mode="vertical"
      element-loading-background="#304156"
      v-loading="listenSitemap"
    >
      <SidebarItem v-for="sitemap in sitemapList" :key="sitemap.sitemapId" :item="sitemap" />
    </el-menu>
  </el-scrollbar>
</template> 
 <script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import variables from "@/styles/variables.scss";
export default {
  name: "SidebarTop",
  components: { SidebarItem },
  computed: {
    ...mapGetters(["sitemapList", "sidebar"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
    variables() {
      return variables;
    },
    // 监听菜单状态
    listenSitemap() {
      return !this.$store.state.user.isSitemapInit;
    }
  }
};
</script>

