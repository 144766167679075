var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "hover" },
      on: { command: _vm.handleSetTenant },
    },
    [
      _c("div", [
        _c("img", {
          staticClass: "menu_icon",
          attrs: {
            src: require("../../assets/images/menu_tenant_icon.png"),
            alt: "",
          },
        }),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.tenantList, function (item) {
          return _c(
            "el-dropdown-item",
            {
              key: item.tenantId,
              style: {
                color: _vm.currentTenant == item.tenantId && "#1a4cec",
                background: _vm.currentTenant == item.tenantId && "none",
                fontWeight: _vm.currentTenant == item.tenantId && "bold",
              },
              attrs: {
                disabled: _vm.currentTenant == item.tenantId,
                command: item,
              },
            },
            [_vm._v(_vm._s(item.tenantName))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }