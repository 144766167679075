var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("prevVedio"),
      _vm.displayLayout === 3
        ? _c(
            "div",
            [
              !_vm.hideLayout
                ? _c("NavbarTop", { staticClass: "header-container" })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bread-crumb-container" },
                [_c("Breadcrumb")],
                1
              ),
              _c("div", { staticClass: "mainTop" }, [
                _c(
                  "div",
                  { staticClass: "main" },
                  [
                    _c("NavMain", { staticClass: "navmain" }),
                    _c("app-main", { staticClass: "appmain" }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c("div", { staticClass: "layout-container" }, [
            !_vm.hideLayout
              ? _c(
                  "div",
                  {
                    staticClass: "side-bar-container",
                    style: {
                      width: _vm.isCollapse ? "60px" : "250px",
                    },
                  },
                  [_c("SideBarNew")],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "layout-main-container",
                style: {
                  height: _vm.hideLayout && "100vh",
                  width: _vm.hideLayout
                    ? "100vw"
                    : `calc(100vw - ${_vm.isCollapse ? 60 : 250}px)`,
                },
              },
              [!_vm.hideLayout ? _c("NavbarNew") : _vm._e(), _c("app-main")],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }