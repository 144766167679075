<template>
  <el-dropdown trigger="hover" class="international" @command="handleSetLanguage">
    <div>
      <svg-icon class-name="international-icon" icon-class="language" />
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="(item,key) in supportLang" :disabled="language===key" :key="key" :command="key">{{item}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { envInfo } from '@/constants/envInfo'

export default {
  computed: {
    language() {
      return this.$store.getters.language
    },
    supportLang() {
      return envInfo.supportLang;
    },
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.dispatch('setLanguage', lang)
      this.$message({
        message: this.$t('message.switchLangSuccess'),
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>
.international-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: -5px!important;
}
</style>

