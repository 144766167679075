import router from './router';
import store from './store';
//import {Message} from 'element-ui'
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import Cookies from 'js-cookie';
import { checkSession } from '@/api/ruge/login.js';
import { generateTitle } from '@/utils/i18n';
import dataReporting from '@/utils/dataReporting.js';
// 初始化FAQ
import FAQ from '@/utils/FAQ.js';

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // console.log('from', from);
  // if (from.path === '/bms/dashboardEdit' && from.fullPath.includes('save')) {
  //   if (window.confirm(`确定不要数据了吗？!`)) {
  //     next(); // 继续跳转
  //   } else {
  //     next(false); // 取消跳转
  //   }
  // }
  // 如果跳转404，打印出找不到的路由，方便定位问题 ok
  if (to.path === '/404') {
    console.log(`Path[${to.redirectedFrom}] not found.`);
  }
  // 如果是登录页面，不走下面初始化逻辑
  if (['/login', '/forget', '/oa'].includes(to.path)) {
    next();
    return;
  }
  NProgress.start(); // start progress bar
  // 每次路由跳转前，检查token是否失效
  if (!(await checkToken())) {
    return;
  }
  if (!store.getters.isHomeInit) {
    // 判断当前用户空间信息没有初始化，则初始化
    console.log('init tenant');
    FAQ.initFAQ();
    store.dispatch('InitTenantList').then((tenantList) => {
      if (store.getters.currentTenant) {
        store
          .dispatch('SetCurrentTenant', {
            tenantId: store.getters.currentTenant,
            productKey: store.getters.currentProductKey,
          })
          .then(() => {
            loadRouter(to, next);
          });
        let tenantAvatar = tenantList.filter(
          (t) => t.tenantId == store.getters.currentTenant
        )[0].tenantAvatar;
        store.dispatch('ChangeTenantAvator', tenantAvatar).then();
      } else {
        loadRouter(to, next);
      }
    });
  } else {
    next();
  }
});
// 路由跳转后上报pv数据
router.afterEach((to) => {
  pageViewHandler(to);
});

function pageViewHandler(to) {
  if (['/personal/basic', '/personal/common'].includes(to.path)) {
    dataReporting('设置中心');
    return;
  }
  if (store.getters.sitemapList.length) {
    const { path } = to || {};
    if (to.meta && to.meta.detail) {
      const detailName = generateTitle(to.meta.name);
      let params = getAllPaths(to.meta.goBackRouter, store.getters.sitemapList);
      params += `-${detailName}`;
      dataReporting(params);
    } else {
      const params = getAllPaths(path, store.getters.sitemapList);
      dataReporting(params);
    }
  } else {
    setTimeout(() => {
      pageViewHandler(to);
    }, 2000);
  }
}

function getAllPaths(path, sitemapList) {
  let result = '';
  for (let first = 0; first < sitemapList.length; first++) {
    const { href, displayText, nodes } = sitemapList[first];
    if (href && href === path) {
      result = generateTitle(displayText);
      break;
    }
    if (nodes && nodes.length) {
      for (let second = 0; second < nodes.length; second++) {
        if (nodes[second].href === path) {
          result = `${generateTitle(displayText)}-${generateTitle(
            nodes[second].displayText
          )}`;
          break;
        }
        if (nodes[second].nodes && nodes[second].nodes.length) {
          for (let third = 0; third < nodes[second].nodes.length; third++) {
            if (nodes[second].nodes[third].href === path) {
              result = `${generateTitle(displayText)}-${generateTitle(
                nodes[second].displayText
              )}-${generateTitle(nodes[second].nodes[third].displayText)}`;
              break;
            }
          }
        }
      }
    }
  }
  return result;
}

function checkToken() {
  return new Promise((resolve) => {
    checkSession()
      .then(() => {
        resolve(true);
      })
      .catch(() => {});
  });
}

function loadRouter(to, next) {
  console.log('init permission');
  store
    .dispatch('GetHavePermissions')
    .then((res) => {
      // 拉取 权限
      console.log('userAccount: ', res.userAccount);
      // 用户的权限列表
      const permissions = res.haveOperationList;
      // 根据权限生成可访问的路由表
      store
        .dispatch('GenerateRoutes', {
          permissions,
        })
        .then(() => {
          router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表

          //初始化WebSocket连接
          store
            .dispatch('InitConnect')
            .then(() => {
              console.log('init websocket connect to ' + res.userAccount);
            })
            .catch((err) => {
              console.error('init websocket connect failed.', err);
              // Message.error(err || 'init websocket connect failed.')
            });
          next({
            ...to,
            replace: true,
          }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        });
      // 初始化菜单
      console.log('init sitemap start...');
      store
        .dispatch('InitSitemap')
        .then(() => {
          console.log('init sitemap end...');
          // const allMenuList = store.getters.sitemapList;
          // treeToArr(allMenuList);
          // console.log('aaaaa1', to);
          // allMenuDataMap.push('/iot/device/detail');
          // console.log('aaaaa2', allMenuDataMap);
          // if (!allMenuDataMap.includes(to.path)) {
          //   console.log('该租户没有这个页面，马上跳转到设置中心');
          //   next('/personal/basic');
          // }

          // 初始化完，如果当前的path是 /home/index 说明是/重定向的，那直接跳到租户设置的首页
          const toPath = to.path;
          if (toPath === '/home/index') {
            const tenantHomePage = store.getters.tenantHomePage || '/personal';
            console.log('tenantHomePage', tenantHomePage);
            next(tenantHomePage);
          }
        })
        .catch((err) => {
          console.error('init sitemap failed...', err);
          // Message.error(err || 'init sitemap failed.')
        });
    })
    .catch((err) => {
      store.dispatch('FedLogOut').then(() => {
        //Message.error(err || 'Check failed, please later retry')
        console.error('Security service off line...', err);
      });
    });
}
let allMenuDataMap = [];

function treeToArr(data) {
  data.forEach((item) => {
    allMenuDataMap.push(item.href);
    if (item.nodes && item.nodes.length !== 0) {
      treeToArr(item.nodes);
    }
  });
}

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
