/********************* 国际化相关工具类 ******************************/

import store from '@/store';

/**
 * 按本地国际化设置处理显示的内容
 * @param str eg. zh-CN=系统管理;en-US=System Manager
 * @param firstFlag 是否截取首字母
 */
export function generateTitle(str, firstFlag) {
  let title = str;
  if (!title) return '';
  let ltexts = title.split(';');
  // 如果配置了多种语言
  if (ltexts.length > 1) {
    // 本地语言
    const lang = store.getters.language;
    for (let ltext of ltexts) {
      let texts = ltext.split('=');
      // 显示当前语言的内容
      if (lang === texts[0]) {
        title = texts[1];
        break;
      }
    }
  } else {
    // 如果只配置了一种语言，则显示此语言的内容
    let texts = title.split('=');
    if (texts.length > 1) {
      title = texts[1];
    }
  }
  return firstFlag ? title.substring(0, 1) : title;
}
