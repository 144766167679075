<template>
  <div class="full-screen-contaainer" @click="clickHandler">
    <!-- <a
      v-if="!isFullscreen"
      class="right-menu-icon"
      :class="
        $store.state.app.displayLayout === 1
          ? 'screenfull-svg-left'
          : 'screenfull-svg'
      "
      @click="clickHandler"
      ><svg-icon icon-class="full-screen"
    /></a>
    <a
      v-else
      class="right-menu-icon"
      :class="
        $store.state.app.displayLayout === 1
          ? 'screenfull-svg-left'
          : 'screenfull-svg'
      "
      @click="clickHandler"
      ><svg-icon icon-class="full-screen-cancel"
    /></a> -->
    <img
      v-if="!isFullscreen"
      src="../../assets/images/menu_screen_icon.png"
      alt=""
    />
    <img
      v-else
      src="../../assets/images/menu_screen_cancel.png"
      @click="clickHandler"
      alt=""
    />
  </div>
</template>

<script>
import screenfull from "screenfull";

export default {
  name: "Screenfull",
  data() {
    return {
      // 是否是全屏
      isFullscreen: false,
    };
  },
  methods: {
    clickHandler() {
      if (!screenfull.enabled) {
        this.$message({
          message: "you browser can not work",
          type: "warning",
        });
        return false;
      }
      screenfull.onchange(() => {
        this.isFullscreen = screenfull.isFullscreen;
      });
      // 监听全屏切换事件
      screenfull.toggle();
    },
  },
};
</script>

<style scoped>
.full-screen-contaainer {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.screenfull-svg >>> svg {
  height: 16px;
  width: 16px;
  cursor: pointer;
  fill: #b4c5d5;
}
screenfull-svg-left >>> svg {
  height: 16px;
  width: 16px;
  cursor: pointer;
  fill: #2a4158;
}
</style>

