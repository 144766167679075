<template>
  <div class="bread-crumb-comp">
    <div class="back-part" v-show="showGoBack">
      <span class="go-back" @click="goBackHandler">
        <img
          class="go-back-icon"
          src="../../assets/images/go_back_icon.png"
          alt=""
        />
        <span class="go-back-span">返回</span>
      </span>
      <el-divider direction="vertical"></el-divider>
    </div>
    <div class="detail-part">
      <span class="detail-part-label">{{ curRouter }}</span>
      <span v-show="curDetail">{{ " - " + curDetail }}</span>
    </div>
  </div>
</template>

<script>
import { generateTitle } from "@/utils/i18n";
import { getUrlParams } from "@/utils/utils.js";
export default {
  name: "breadcrumbNew",
  data() {
    return {
      curRouter: "",
      showGoBack: false,
      curDetail: "",
      goBackRouter: "",
      curRoutePath: "",
      rugeRouter: require("@/routers/ruge"),
      businessRouter: require("@/routers/business"),
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.curRoutePath = val;
        if (
          this.$store.state.user.sitemapList &&
          this.$store.state.user.sitemapList.length !== 0
        ) {
          this.routeChangeHandler();
        }
      },
      immediate: true,
    },
    // 刷新页面第一次进来时，可能sitemapList还没初始化完成，所以还要再调用一次routeChangeHandler
    "$store.state.user.sitemapList": {
      handler(val) {
        this.routeChangeHandler();
      },
    },
  },
  created() {
    this.$eventBus.$off("setDetailAlias");
    this.$eventBus.$on("setDetailAlias", (name) => {
      this.curDetail = name;
    });
    this.$eventBus.$off("setDetailTitle");
    this.$eventBus.$on("setDetailTitle", (name) => {
      this.curRouter = name;
    });
  },
  methods: {
    generateTitle,
    routeChangeHandler() {
      let val = this.curRoutePath;
      this.curDetail = "";
      this.showGoBack = val.meta.detail;
      if (val.meta.detail) {
        this.goBackRouter = val.meta.goBackRouter;
        this.curRouter = generateTitle(val.meta.name);
        this.curDetail = this.$route.query.detailAlias;
      } else {
        // 设置中心得单独处理
        if (["/personal/basic", "/personal/common"].includes(val.path)) {
          this.curRouter = generateTitle(
            "zh-CN=设置中心;en-US=Personal Center"
          );
          return;
        }
        const urlParams = getUrlParams();
        let tempName = this.findSitemapMatched(
          this.$store.state.user.sitemapList,
          val.path,
          urlParams
        );
        if (!tempName) return;
        this.curRouter = generateTitle(
          tempName[tempName.length - 1].displayText
        );
      }
    },
    goBackHandler() {
      //之前在router 配置的goBackRouter属性无效   新增此代码-ych
      const { meta } = this.$route;
      if (meta && meta.goBackRouter) {
        if (meta.keepVal) {
          this.$router.go(-1);
        }
        this.$router.push({
          path: meta.goBackRouter,
          query: { goBackFlag: true },
        });
        return;
      }
      //end-ych

      if (this.goBackRouter) {
        this.$router.push({
          path: this.goBackRouter,
          query: { goBackFlag: true },
        });
      } else {
        this.$router.back(-1);
      }
    },
    commonFrameMatch(sitemap, path, query) {
      const targetUrl = decodeURIComponent(query.targetUrl);
      let matched;
      let find = function (sitemap, path) {
        for (let item of sitemap) {
          if (item.urlType === "embedded" && targetUrl === item.href) {
            matched = item.matched;
            break;
          }
          let nodes = item.nodes;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
    // 获取当前菜单的matched
    findSitemapMatched(sitemap, path, query) {
      if (path === "/common/frame") {
        return this.commonFrameMatch(sitemap, path, query);
      }
      if (path === "/bms/commonDashboard") {
        path =
          `${path}?` +
          Object.keys(query)
            .map((item) => `${item}=${query[item]}`)
            .join("&");
      }
      let matched;
      let find = function (sitemap, path) {
        for (let item of sitemap) {
          if (
            item.href &&
            item.href === path // 优先做路径完全匹配
            // || (item.href.indexOf('?') !== -1 && item.href.split('?')[0] === path) // 当路径中有问号带参时，忽略参数做匹配
          ) {
            matched = item.matched;
            break;
          }
          let nodes = item.nodes;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
    findDetailMatched(sitemap, path) {
      const _this = this;
      let matched;
      //   过滤iot的路由
      // sitemap = sitemap.filter((item) => item.path === "/iot");
      let find = function (sitemap, path, parentPath) {
        for (let item of sitemap) {
          if (
            item.path &&
            ("/iot/" + item.path === path ||
              parentPath + "/" + item.path === path) // 优先做路径完全匹配
            // || (item.href.indexOf('?') !== -1 && item.href.split('?')[0] === path) // 当路径中有问号带参时，忽略参数做匹配
          ) {
            matched = item.meta.name;
            _this.goBackRouter = item.meta.goBackRouter;
            break;
          }
          let nodes = item.children;
          if (nodes && nodes.length > 0) {
            find(nodes, path, parentPath);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
  },
};
</script>

<style lang="less" scoped>
.bread-crumb-comp {
  display: flex;
  margin-left: 40px;
  .back-part {
    font-size: 18px;
    .go-back {
      cursor: pointer;
      .go-back-icon {
        margin-right: 5px;
        position: relative;
        top: 1px;
      }
      .go-back-span {
        font-weight: bold;
        color: #2a61ff;
        position: relative;
      }
    }
  }
  .detail-part {
    font-size: 18px;
    font-weight: bold;
    color: #152c5b;
    font-family: Source Han Sans SC-Bold, Source Han Sans SC;
    .detail-part-label {
      font-weight: bold;
      color: #152c5b;
      font-family: Source Han Sans SC-Bold, Source Han Sans SC;
    }
  }
  ::v-deep .el-divider--vertical {
    height: 10px;
    margin: 0 10px;
  }
}
</style>