<template>
  <el-dropdown trigger="hover" class="international" @command="handleSetTenant">
    <div>
      <img
        class="menu_icon"
        src="../../assets/images/menu_tenant_icon.png"
        alt=""
      />
      <!-- <a
        :class="
          $store.state.app.displayLayout === 1
            ? 'switch-svg-left'
            : 'switch-svg'
        "
        class="right-menu-icon"
        ><svg-icon icon-class="switch-org"
      /></a> -->
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="item in tenantList"
        :disabled="currentTenant == item.tenantId"
        :style="{
          color: currentTenant == item.tenantId && '#1a4cec',
          background: currentTenant == item.tenantId && 'none',
          fontWeight: currentTenant == item.tenantId && 'bold',
        }"
        :key="item.tenantId"
        :command="item"
        >{{ item.tenantName }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  computed: {
    tenantList() {
      return this.$store.getters.tenantList;
    },
    currentTenant() {
      return this.$store.getters.currentTenant;
    },
  },
  methods: {
    handleSetTenant(tenant) {
      let prevTenantId = this.$store.getters.currentTenant;
      let tenantId = tenant.tenantId;
      this.$store
        .dispatch("SetCurrentTenant", {
          tenantId: tenantId,
          productKey: tenant.productKey,
          menu: true,
        })
        .then(() => {
          if (prevTenantId == "-1" || tenantId == "-1") {
            location.replace(
              location.href.substring(0, location.href.indexOf("#/") + 1)
            );
          }
          // 切换租户把hash干掉
          location.href =
            location.protocol + "//" + location.host + location.pathname + "#/";
          location.reload();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.international {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .menu_icon {
    position: relative;
    top: 5px;
  }
  .el-dropdown-selfdefine {
    height: 40px;
    width: 100%;
    text-align: center;
    line-height: 40px;
  }
}
.international-icon {
  font-size: 26px;
  cursor: pointer;
  vertical-align: -5px !important;
}
// .switch-svg >>> svg {
//   height: 19px;
//   width: 19px;
//   cursor: pointer;
//   fill: #b4c5d5;
//   top: 1px;
//   position: relative;
// }
// .switch-svg-left >>> svg {
//   height: 19px;
//   width: 19px;
//   cursor: pointer;
//   fill: #2a4158;
//   top: 1px;
//   position: relative;
// }
</style>
