import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import router from './modules/router';
import message from './modules/message';
import workflow from './modules/workflow';
import rule from './modules/rule';
import getters from './getters';
import notify from './modules/notify';
import lego from './modules/lego';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    router,
    message,
    workflow,
    rule,
    notify,
    lego,
  },
  getters,
});

export default store;
