import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
//获取租户分页列表
export function getTenantList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/find/page',
    method: 'get',
    params,
  });
}

//获取当前租户列表
export function getCurrentTenantList() {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/findCurrentTenantList',
    method: 'get',
  });
}

//设置当前租户
export function setCurrentTenant(tenantId, productKey) {
  return request({
    url: envInfo.bgApp.ssoPath + '/param/decided/tenant',
    method: 'post',
    data: { tenantId: tenantId, productKey: productKey },
  });
}

//保存租户
export function saveTenant(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/create',
    method: 'post',
    data: params,
  });
}

//删除租户
export function deleteTenant(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/delete/' + params.tenantId,
    method: 'delete',
  });
}

//更新租户
export function updateTenant(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/update',
    method: 'put',
    data: params,
  });
}

//上传租户图像
export function updateTenantAvatar(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/setting/upload/avatar',
    method: 'post',
    data: params,
  });
}

//删除租户图像
export function deleteTenantAvatar(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/setting/delete/avatar',
    method: 'post',
    data: params,
  });
}

//更新租户名
export function updateTenantName(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/setting/update',
    method: 'put',
    data: params,
  });
}

///////////////==========系统管理员
//获取租户账号
export function _sys_getMountAccounts(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/sys/user/account/page',
    method: 'get',
    params,
  });
}

//挂载租户
export function _sys_mountAccount(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/sys/user/mountAccount',
    method: 'post',
    data: params,
  });
}

//删除租户
export function _sys_deleteTenantMount(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/sys/user/deleteMount',
    method: 'delete',
    data: params,
  });
}

///////////////==========租户管理员
//获取租户账号
export function _tenant_getMountAccounts(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/account/page',
    method: 'get',
    params,
  });
}

//挂载租户
export function _tenant_mountAccount(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/mountAccount',
    method: 'post',
    data: params,
  });
}

//删除租户
export function _tenant_deleteTenantMount(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/tenant/user/deleteMount/',
    method: 'delete',
    data: params,
  });
}

////微信小程序管理

export function queryShowAppList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/applet/queryShowAppList',
    method: 'get',
    params,
  });
}

/**
 * 创建小程序/公众号信息
 * @param params
 */
export function editAppLet(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/applet/editAppLet',
    method: 'post',
    data: params,
  });
}

/**
 * 删除设备
 * @param params
 */
export function deleteAppLet(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/applet/deleteAppLet/' + params.id,
    method: 'delete',
  });
}

/**
 * 更新设备启用状态
 * @param params
 */
export function updateEnableStatus(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/applet/update/enableStatus',
    method: 'post',
    data: params,
  });
}

// 查询短信发送日志
export function findSmsSendLog(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/sms/findSmsSendLog',
    method: 'get',
    params,
  });
}

export function findTemplateByParam(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/template/findTemplateByParam',
    method: 'get',
    params,
  });
}

/**
 * 创建短信模板
 * @param params
 */
export function addTemplate(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/template/addTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 删除短信模板
 * @param params
 */
export function deleteTemplate(params) {
  return request({
    url:
      envInfo.bgApp.smsPath +
      '/template/updateDeleteTemplate/' +
      params.templateId,
    method: 'delete',
  });
}

/////// 租户

// // 查询短信发送日志
// export function tenantFindSmsSendLog(params) {
//   return request({
//     url: envInfo.bgApp.smsPath + '/tenant/sms/findSmsSendLog',
//     method: 'get',
//     params,
//   });
// }

export function tenantFindTemplateByParam(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/tenant/template/findTemplateByParam',
    method: 'get',
    params,
  });
}

/**
 * 创建短信模板
 * @param params
 */
export function tenantAddTemplate(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/tenant/template/addTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 创建短信模板
 * @param params
 */
export function tenantEditTemplate(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/tenant/template/editTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 删除短信模板
 * @param params
 */
export function tenantDeleteTemplate(params) {
  return request({
    url:
      envInfo.bgApp.smsPath +
      '/tenant/template/updateDeleteTemplate/' +
      params.templateId,
    method: 'delete',
  });
}

/**
 * 根据租户ID 获取短信发送上限
 * @param params
 */
export function queryInfoByTenantId(tenantId) {
  return request({
    url:
      envInfo.bgApp.smsPath +
      '/sendNumLimit/queryInfoByTenantId?tenantId=' +
      tenantId,
    method: 'get',
  });
}

/**
 * 根据租户ID 获取短信发送上限
 * @param params
 */
export function sendNumLimitEdit(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/sendNumLimit/edit',
    method: 'post',
    data: params,
  });
}

/**
 * 租户-获取短信模板列表
 * @param params
 */
export function tenantGetTemplateList(params) {
  return request({
    url: envInfo.bgApp.smsPath + '/tenant/template/findTemplateList',
    method: 'get',
    params,
  });
}

/**
 * 平台-获取短信模板列表
 * @param params
 */
export function systemGetTemplateList(params) {
  console.log('xxxxx-params', params);
  return request({
    url: envInfo.bgApp.smsPath + '/template/findTemplateList',
    method: 'get',
    params,
  });
}
