import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 接入点位总数
 * @param params
 */
export function getPropertyTotal(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/propertyTotal',
    method: 'get',
    params,
  });
}
/**
 * 当日告警总数
 * @param params
 */
export function getTodayAlarmTotal(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/todayAlarmTotal',
    method: 'get',
    params,
  });
}
/**
 * 近15天设备告警趋势图
 * @param params
 */
export function getNear15AlarmTrend(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/near15AlarmTrend',
    method: 'get',
    params,
  });
}
/**
 * 近30天各项目告警数量占比
 * @param params
 */
export function getNear30AlarmProportion(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/near30AlarmProportion',
    method: 'get',
    params,
  });
}
/**
 * 工单类型占比
 * @param params
 */
export function getOrderTypeProportion(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/orderTypeProportion',
    method: 'get',
    params,
  });
}
/**
 * 工单生成量/完成量趋势
 * @param params
 */
export function getNear15OrderTrend(params) {
  return request({
    url: envInfo.bgApp.stationBmsPath + '/near15OrderTrend',
    method: 'get',
    params,
  });
}

/**
 * 自定义 - 自定义卡片列表
 * @param params
 */
export function getCustomCardList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/list',
    method: 'get',
    params,
  });
}

/**
 * 自定义 - 自定义卡片新增
 * @param params
 */
export function addCustomCard(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/saveCard',
    method: 'post',
    data: params,
  });
}

/**
 * 自定义 - 删除卡片
 * @param params
 */
export function deleteCard(cardId) {
  return request({
    url: envInfo.bgApp.biPath + '/card/delete/' + cardId,
    method: 'delete',
  });
}

/**
 * 自定义 - 模板列表
 * @param params
 */
export function getTemplateList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/list',
    method: 'get',
    params,
  });
}

/**
 *  自定义 - 保存模板
 *  @param templateName 模版名称
 *  @param templateType 模版类型：示例：home首页
 *  @param templateConfig 模版配置 JSON
 */
export function saveTemplate(params) {
  return request({
    url: envInfo.bgApp.biPath + '/Template/saveTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 自定义 - 获取用户自定义首页
 * @param params
 */
export function saveUserPortal(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/userCustom/saveUserCustom',
    method: 'post',
    data: params,
  });
}

/**
 * 自定义 - 获取用户自定义首页
 * @param params
 */
export function getUserPortal(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/userCustom/findUserCustom',
    method: 'get',
    params,
  });
}

/**
 * 自定义 - 获取用户自定义模板列表
 * @param params
 */
export function getCustomTemplateList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/list',
    method: 'get',
    params,
  });
}

/**
 * 自定义 - 获取用户自定义模板详情
 * @param params
 */
export function getCustomTemplateDetail(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/cardList',
    method: 'get',
    params,
  });
}

/**
 * 自定义 - 获取用户自定义模板详情 - 无权限过滤
 * @param params
 */
export function getTemplateDetailManage(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/manageCardList',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 整体平均满意度
 * @param params
 */
export function getCustomerRating(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/avgSatisfied',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 近30天工单事件完成占比分析
 * @param params
 */
export function getOrderCompleteRatePro(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/orderCompleteRatePro',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 近30天投诉建议完成占比分析
 * @param params
 */
export function getComplaintCompletePro(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/complaintCompletePro',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 一二三期月卡缴费次数占比
 * @param params
 */
export function getMonthCardRate(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/monthCardRate',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 近30天投诉数量趋势
 * @param params
 */
export function getComplaintTrend(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/complaintTrend',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 投诉建议top5
 * @param params
 */
export function getCompleteTop(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/completeTop',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 近30天三期充电桩使用时长趋势
 * @param params
 */
export function getChargeStationTimeCount(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/chargeStationTimeCount',
    method: 'get',
    params,
  });
}

/**
 * 客户总览 - 近15天临停车缴费次数趋势
 * @param params
 */
export function getParkPayCount(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/appletData/parkPayCount',
    method: 'get',
    params,
  });
}
