
<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <!-- 展开/收缩按钮 start -->
    <div
      class="collapse-container"
      :class="isCollapse ? 'collapse-close' : 'collapse-open'"
      @click="collapseHandler"
    >
      <el-tooltip
        popper-class="tooltipsClassName"
        class="item"
        effect="dark"
        placement="right"
      >
        <span slot="content">{{ isCollapse ? "展开" : "收缩" }}</span>
        <i v-if="isCollapse" class="el-icon-arrow-right collapse-icon"></i>
        <i v-else class="el-icon-arrow-left collapse-icon"></i>
      </el-tooltip>
    </div>
    <!-- 展开/收缩按钮 end -->
    <el-menu
      :show-timeout="100"
      :default-active="curActive"
      :collapse="isCollapse"
      :background-color="variables.menuBg"
      :text-color="variables.menuText"
      :active-text-color="variables.menuActiveText"
      v-loading="listenSitemap"
      element-loading-background="rgba(48, 65, 86, 0.5)"
      mode="vertical"
    >
      <LogoToggle :showStatus="isCollapse" :logoMsg="logoMsg"></LogoToggle>

      <sidebar-item
        v-for="sitemap in sitemapList"
        :key="sitemap.sitemapId"
        :item="sitemap"
      />
      <!-- 菜单最下面固定 设置中心 -->
      <div class="divider-border"></div>
      <app-link to="/personal">
        <el-menu-item index="/personal" class="submenu-title-noDropdown">
          <item
            icon="home"
            :title="generateTitle('zh-CN=设置中心;en-US=Personal Center')"
          />
        </el-menu-item>
      </app-link>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import variables from "@/styles/variables.scss";
import SidebarItem from "./SidebarItem";
import LogoToggle from "@/components/LogoToggle";
import { generateTitle } from "@/utils/i18n";
import Item from "./Item";
import AppLink from "./Link";

export default {
  name: "Sidebar",
  data() {
    return {
      // 传入 logo 文件名
      logoMsg: {
        logoFileName: "defaultLogo",
        projectName: "",
      },
      curActive: decodeURIComponent(this.$route.fullPath),
      rugeRouter: require("@/routers/ruge"),
    };
  },
  components: {
    SidebarItem,
    LogoToggle,
    Item,
    AppLink,
  },
  watch: {
    $route: {
      handler(val) {
        // 如果有meta.detail，说明是详情页，则取找他的父级，然后给父级对应的菜单高亮
        if (val.meta.detail) {
          let tempName = this.findDetailMatched(
            this.rugeRouter.default,
            val.path
          );
          this.curActive = tempName;
        } else {
          this.curActive = val.path;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["sitemapList", "sidebar"]),
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    // 监听菜单状态
    listenSitemap() {
      return !this.$store.state.user.isSitemapInit;
    },
  },
  methods: {
    generateTitle,
    collapseHandler() {
      try {
        document.getElementsByClassName("tooltipsClassName")[0].style.display =
          "none";
      } catch (error) {
        console.log("隐藏tooltips失败：", error);
      }
      this.$store.commit("TOGGLE_SIDEBAR");
    },
    findDetailMatched(sitemap, path) {
      let matched;
      //   过滤iot的路由
      sitemap = sitemap.filter((item) => item.path === "/iot");
      let find = function (sitemap, path) {
        for (let item of sitemap) {
          if (
            item.path &&
            "/iot/" + item.path === path // 优先做路径完全匹配
            // || (item.href.indexOf('?') !== -1 && item.href.split('?')[0] === path) // 当路径中有问号带参时，忽略参数做匹配
          ) {
            matched = item.meta.goBackRouter;
            break;
          }
          let nodes = item.children;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
  },
};
</script>
<style scoped>
.divider-border {
  height: 1px;
  margin: 20px 10%;
  border-top: 1px solid #8493a3;
}
.user-menu-icon {
  color: #bfcbd9;
  font-size: 14px;
}
.collapse-container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 2px 4px 8px 0px rgba(14, 23, 31, 0.05);
  border: 1px solid #e7e8eb;
  background-color: #fff;
  position: fixed;
  top: 156px;
  left: 0;
  transition: left 0.28s;
  z-index: 1001;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.collapse-close {
  left: 52px;
}
.collapse-open {
  left: 168px;
}
.collapse-icon {
  font-size: 14px;
  color: #303133;
  font-weight: bold;
}
</style>