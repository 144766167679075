var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title:
          _vm.informationInfo != null ? _vm.informationInfo.notifyTitle : "",
        "before-close": _vm.handleClose,
        size: "50%",
        visible: _vm.detailDrawer,
      },
      on: {
        "update:visible": function ($event) {
          _vm.detailDrawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "info-drawer-container" },
        [
          _c("hr", { staticClass: "info-hr" }),
          _c(
            "el-row",
            { staticClass: "info-sender" },
            [
              _c("label", { staticClass: "info-label" }, [
                _vm._v(_vm._s(_vm.$t("notify.manager.sender"))),
              ]),
              _vm.informationInfo != null
                ? [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(_vm._s(_vm.informationInfo.senderName)),
                    ]),
                  ]
                : _vm._e(),
              _c("label", { staticClass: "info-label" }, [
                _vm._v(_vm._s(_vm.$t("notify.manager.sendTime"))),
              ]),
              _vm.informationInfo != null
                ? [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.informationInfo.notifyTime,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        )
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.drawerLoading,
                  expression: "drawerLoading",
                },
              ],
            },
            [
              _vm.informationInfo != null
                ? [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }