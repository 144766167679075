<template>
  <svg-icon :icon-class="iconName" :class-name="svgClass"/>
</template>

<script>
export default {
  name: 'TitleIcon',
  props: {
    iconClass: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      if (this.iconClass) {
        return this.iconClass
      } else {
        return 'title'
      }
    },
    svgClass() {
      if (this.className) {
        return 'title-icon ' + this.className
      } else {
        return 'title-icon'
      }
    }
  }
}
</script>

<style scoped>
  .title-icon {
    margin-right: 4px;
  }
</style>
