<template>
    <div>
        <el-dialog class="alarm-confirm-dialog" :visible.sync="alarmConfirmDialog" width="35%" :close-on-click-modal="false" :before-close="closeAlarmDialog" :show-close="true" v-loading="confirmLoading">
            <template slot="title">
                <span class="alarm-confirm-title"><title-icon/>{{ $t('iot.alarm.alarmConfirm') }}</span>
                <template v-if="!isHistoryConfirm">
                    <el-link type="primary" style="float: right;margin-top: 20px;" @click="drawerClick">{{$t('iot.alarm.relationAlarms')}}>></el-link>
                </template>
            </template>
            <!--确认告警弹窗-->
            <el-form :inline="true" label-width="80px" label-position="left">
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('iot.position.project')">
                            <span>{{confirmForm.projectName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('iot.alarm.occurTime')">
                            <span>{{ new Date(new Number(confirmForm.occurTime))| dateFormat('YYYY-MM-DD HH:mm:ss')}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('iot.device.name')">
                            <span>{{confirmForm.deviceName}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('iot.device.position')">
                            <el-tooltip :content="confirmForm.positionName" placement="top">
                                <span>{{confirmForm.positionName}}</span>
                            </el-tooltip>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="$t('iot.alarm.isIsolated')">
                            <el-select v-model="isolateDuration" :placeholder="$t('iot.alarm.nonIsolate')">
                                <el-option
                                        v-for="item in timeSelect"
                                        :label="item.label"
                                        :value="item.value"
                                        :key="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="$t('commons.remark')">
                            <el-input type="textarea" :rows="3" maxlength="160" :show-word-limit="true" style="width: 400px" :placeholder="$t('commons.pleaseInput')" v-model="confirmForm.confirmComment"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button style="background-color: #5fbeaa;color: #fff;" @click="confirmAlarm(1)">{{$t('iot.alarm.faultConfirm')}}</el-button>
                <el-button style="background-color: #f0ad4e;color: #fff;" @click="confirmAlarm(2)">{{$t('iot.alarm.misreportConfirm')}}</el-button>
                <el-button style="background-color: #d43f3a;color: #fff;" @click="confirmAlarm(3)">{{$t('iot.alarm.fireConfirm')}}</el-button>
            </div>
        </el-dialog>

        <el-drawer
                style="line-height: 16px;"
                size="50%"
                :visible.sync="drawer"
                :direction="'rtl'">
            <template slot="title">
                <span class="alarm-confirm-title"><title-icon/>{{$t('iot.alarm.relationAlarms')}}</span>
            </template>
            <div class="drawer-container">
                <el-table
                        v-loading="listLoading"
                        :data="alarmList"
                        element-loading-text="loading"
                        border
                        fit
                        highlight-current-row
                        stripe>
                    <el-table-column
                            :label="$t('commons.index')"
                            type="index"
                            width="50">
                    </el-table-column>
                    <el-table-column :label="$t('iot.alarm.alarmTime')" width="155px">
                        <template slot-scope="scope">
                            <template v-if="scope.row.alarmTime != null">
                                {{ new Date(new Number(scope.row.alarmTime))| dateFormat('YYYY-MM-DD HH:mm:ss')}}
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('iot.alarm.value')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.value}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('commons.status')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.toState }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('iot.alarm.comment')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.msgText }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination v-show="listQuery.total>0" :total="listQuery.total" :page.sync="listQuery.current"
                            :limit.sync="listQuery.rowCount"
                            @pagination="getAlarmPage"/>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import { confirmAlarm, batchConfirmAlarm, getHistoryAlarmPage } from '@/api/ruge/iot/alarm/alarm'
    import { batchIsolateDevice } from '@/api/ruge/iot/device/isolate'

    import Pagination from '@/components/Pagination'

    export default {
        name: "ConfirmAlarm",
        components:{
            Pagination,
        },
        props: {
            confirmAlarmEvent: {
                type: Function
            },
            //是否显示
            alarmConfirmDialog: {
                type: Boolean
            },
            // 告警信息
            alarmConfirmRecord: {
                type: Object,
                required: true
            },
            // 是否历史报警确认
            isHistoryConfirm: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            alarmUuid: function () {
                this.initAlarmInfo();
            },
            occurTime: function() {
                this.initAlarmInfo();
            }
        },
        computed: {
            alarmUuid() {
                return this.alarmConfirmRecord?this.alarmConfirmRecord.alarmUuid:null;
            },
            occurTime() {
                if(this.alarmConfirmRecord) {
                    if(this.alarmConfirmRecord.alarmTime) {
                        return this.alarmConfirmRecord.alarmTime;
                    } else {
                        return this.alarmConfirmRecord.normalTime;
                    }
                }
                return null
            }
        },
        data() {
            return {
                drawer: false,
                listLoading:false,
                timeSelect: [
                    {label: this.$t('iot.alarm.nonIsolate'), value: ''},
                    {label: this.$t('commons.min30'), value: '30'},
                    {label: this.$t('commons.min60'), value: '60'},
                    {label: this.$t('commons.min120'), value: '120'},
                    {label: this.$t('commons.min180'), value: '180'},
                    {label: this.$t('commons.min360'), value: '360'},
                    {label: this.$t('commons.min720'), value: '720'},
                    {label: this.$t('commons.min1440'), value: '1440'},
                ],
                alarmList:[],
                listQuery:{
                    current:1,
                    total: 0,
                    rowCount:20,
                    confirmFlag:0,
                    deviceEUI: null,
                    monitorType: null
                },
                confirmForm :{
                    alarmUuid : null,
                    alarmLevel : null,
                    alarmTime : null,
                    deviceName : null,
                    positionName : null,
                    descName : null,
                    projectName : null,
                    confirmComment: '',

                },
                isolateDuration: '',
                confirmLoading: false,
            }
        },
        methods: {
            //打开报警明细侧边栏
            drawerClick() {
                if (this.confirmForm) {
                    this.drawer = true;
                    this.listQuery.deviceEUI = this.confirmForm.deviceEUI;
                    this.listQuery.monitorType = this.confirmForm.monitorType;
                    this.getAlarmPage();
                }
            },
            getAlarmPage(){
                this.listLoading = true;
                getHistoryAlarmPage(this.listQuery).then(data =>{
                    this.listQuery.total = data.total;
                    this.alarmList = data.rows;
                    this.listLoading = false;
                })
            },
            initAlarmInfo(){
                this.confirmForm = this.alarmConfirmRecord;
                this.confirmForm.occurTime = this.occurTime;
            },
            confirmAlarm(alarmLogic){
                this.$confirm(this.$t('iot.alarm.comfirmMsg'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.confirmLoading = true;
                    let params = {
                        alarmUuid:this.alarmConfirmRecord.alarmUuid,
                        alarmLevel:this.alarmConfirmRecord.alarmLevel,
                        projectId: this.alarmConfirmRecord.projectId,
                        confirmComment : this.confirmForm.confirmComment,
                        confirmFlag: alarmLogic
                    }
                    if(this.isolateDuration && this.isolateDuration !== '') {//需要隔离
                        batchIsolateDevice({
                            deviceEUIList: [this.confirmForm.deviceEUI],
                            isolateDuration: this.isolateDuration
                        }).then();
                    }
                    if(this.isHistoryConfirm){
                        //是历史报警确认（单个报警）
                        confirmAlarm(params).then(() => {
                            this.$emit('update:alarmConfirmDialog', false)
                            // 成功提示
                            this.$message({
                                message: this.$t('iot.alarm.confirmSuccess'),
                                type: 'success'
                            });
                            this.confirmAlarmEvent && this.confirmAlarmEvent();
                        }).finally(() => {
                            this.confirmLoading = false;
                        });
                    }else{
                        params.deviceEUI = this.alarmConfirmRecord.deviceEUI
                        params.monitorType = this.alarmConfirmRecord.monitorType;
                        //实时报警确认（批量确认）
                        batchConfirmAlarm(params).then(() => {
                            this.$emit('update:alarmConfirmDialog', false)
                            // 成功提示
                            this.$message({
                                message: this.$t('iot.alarm.confirmSuccess'),
                                type: 'success'
                            });
                            this.confirmAlarmEvent && this.confirmAlarmEvent({deviceEUI: params.deviceEUI, monitorType: params.monitorType});
                        }).finally(() => {
                            this.confirmLoading = false;
                        });
                    }
                }).catch(error => {
                    console.log(`未确认，原因 => ${error}`)
                });
            },
            closeAlarmDialog(){
                this.$emit('update:alarmConfirmDialog', false)
            },
        }
    }
</script>

<style scoped>

    .alarm-confirm-dialog >>> .el-dialog__body {
        padding: 10px 0px;
        margin-left: 30px;
    }

    .alarm-confirm-title {
        font-size: 16px;
    }

    .drawer-container {
        margin: 0 20px;
        height: calc(100vh - 90px);
        overflow: scroll;
    }
</style>
