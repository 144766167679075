var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "el-badge-container", on: { click: _vm.drawerClick } },
        [
          _c(
            "el-badge",
            {
              attrs: {
                hidden: _vm.unreadCount === 0,
                value: _vm.unreadCount,
                max: 99,
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/menu_message_icon.png"),
                  alt: "",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticStyle: { "line-height": "16px" },
          attrs: {
            visible: _vm.drawer,
            "before-close": () => {
              _vm.drawer = false
            },
            direction: _vm.direction,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              [_c("title-icon"), _vm._v(_vm._s(_vm.$t("personal.setting9")))],
              1
            ),
          ]),
          _vm.notifyList.length > 0
            ? [
                _c(
                  "div",
                  { staticClass: "data-list" },
                  _vm._l(_vm.notifyList, function (item, index) {
                    return _c(
                      "el-row",
                      { key: index, staticClass: "data-row" },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { margin: "14px 10px 0px 16px" },
                            attrs: { span: 2, offset: 1 },
                          },
                          [
                            _c(
                              "el-avatar",
                              { attrs: { size: 50, src: _vm.getAvatar(item) } },
                              [_c("img", { attrs: { src: _vm.defaultAvatar } })]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c("span", { staticClass: "notify-sender" }, [
                              _vm._v(" " + _vm._s(item.senderName) + " "),
                            ]),
                            _c("span", { staticClass: "notify-time" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.notifyTime,
                                      "YYYY-MM-DD HH:mm:ss"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeNotify(item)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete delete-btn",
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchNotifyDetails(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    staticClass: "notify-info",
                                    class: { unread: item.readStatus == 1 },
                                    attrs: { underline: false },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.notifyTitle) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "no-data" }, [
                  _vm._v(_vm._s(_vm.$t("commons.noData"))),
                ]),
              ],
          _c(
            "el-button-group",
            { staticClass: "notify-btn-group" },
            [
              _c(
                "el-button",
                { staticClass: "notify-btn", on: { click: _vm.showMore } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("commons.more")) + "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                { staticClass: "notify-btn", on: { click: _vm.clearALL } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("commons.clearAll")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("information-details", {
        attrs: {
          detailDrawer: _vm.detailDrawer,
          informationInfo: _vm.informationInfo,
        },
        on: {
          "update:detailDrawer": function ($event) {
            _vm.detailDrawer = $event
          },
          "update:detail-drawer": function ($event) {
            _vm.detailDrawer = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }