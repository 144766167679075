/* Layout */
import Layout from '@/views/ruge/layout/Layout';

/**
 * 业务系统相关路由
 */
const businessRouter = [
  //租户首页
  {
    path: '/tenant/home',
    component: Layout,
    redirect: '/tenant/home',
    children: [
      {
        path: 'facilityHome/index',
        name: 'FacilityHome',
        component: () => import('@/views/business/facilityHome/index2'),
        //      component: () => import('@/views/business/facilityHome'),
        //        import('@/views/business/home/facilityHome/tenant/indexNew'),
      },
      {
        path: 'facilityHome2',
        name: 'FacilityHome',
        component: () => import('@/views/business/facilityHome/index2'),
        //        import('@/views/business/home/facilityHome/tenant/indexNew'),
      },
    ],
  },
  {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    children: [
      {
        path: 'index',
        name: 'Home',
        component: () => import('@/views/ruge/home/index'),
      },
    ],
  },
  //位置管理
  {
    path: '/position',
    component: Layout,
    redirect: '/position/manager',
    children: [
      {
        path: 'manager',
        name: 'PositionManager',
        component: () => import('@/views/ruge/iot/position/index'),
      },
    ],
  },
  //项目管理
  {
    path: '/project',
    component: Layout,
    redirect: '/project/manager',
    children: [
      {
        path: 'manager',
        name: 'projectManager',
        component: () => import('@/views/ruge/iot/project/index'),
      },
    ],
  },
  //项目管理
  {
    path: '/tenant/project',
    component: Layout,
    redirect: '/tenant/project/list',
    children: [
      {
        path: 'list',
        name: 'projectList',
        component: () => import('@/views/business/base/project/tenant/index'),
      },
    ],
  },
  //有偿服务项列表
  {
    path: '/paidManager',
    component: Layout,
    redirect: '/paidManager/list',
    children: [
      {
        path: 'list',
        name: 'paidManager',
        component: () => import('@/views/business/base/paidServices'),
      },
    ],
  },
  //空间管理
  {
    path: '/tenant/base/position',
    component: Layout,
    redirect: '/tenant/base/position/manager',
    children: [
      {
        path: 'manager',
        name: 'tenantPositionManager',
        component: () => import('@/views/business/base/spaceManagement'),
        //      component: () => import('@/views/business/base/position/tenant/index'),
      },
      {
        path: 'manager2',
        name: 'spaceManagement',
        component: () =>
          import('@/views/business/base/spaceManagement/templete.vue'),
      },
    ],
  },
  //系统分类管理
  {
    path: '/tenant/base/category',
    component: Layout,
    redirect: '/tenant/base/category/manager',
    children: [
      {
        path: 'manager',
        name: 'CategoryManager',
        component: () => import('@/views/business/base/category/tenant/index'),
      },
    ],
  },
  //设备清单
  {
    path: '/tenant/base/device',
    component: Layout,
    redirect: '/tenant/base/device/manager',
    children: [
      {
        path: 'manager',
        name: 'DeviceManager',
        component: () => import('@/views/business/base/device/tenant/index'),
      },
      {
        path: 'isolate',
        name: 'DeviceIsolate',
        component: () =>
          import('@/views/business/base/device/tenant/deviceIsolate'),
      },
      {
        path: 'icon',
        name: 'DeviceIcon',
        component: () =>
          import('@/views/business/base/device/tenant/deviceIcon'),
      },
    ],
  },
  //rwork-告警记录
  {
    path: '/tenant/base/alarmRecords',
    component: Layout,
    redirect: '/tenant/base/alarmRecords/list',
    children: [
      {
        path: 'list',
        name: 'alarmRecords',
        component: () => import('@/views/business/base/alarmRecords'),
      },
      //			{
      //		        path: 'detail',
      //		        // 如果是详情页，需要配置detail:true
      //		        meta: {
      //		          detail: true,
      //		          name: 'zh-CN=告警记录详情;en-US=alarmRecords Detail',
      //		          goBackRouter: '/tenant/base/alarmRecords/list',
      //		        },
      //		        name: 'alarmRecordsDetail',
      //		        component: () => import('@/views/business/base/alarmRecords/detail'),
      //	      	},
    ],
  },
  //物料分类
  {
    path: '/tenant/base/materielcategory',
    component: Layout,
    redirect: '/tenant/base/materielcategory/manager',
    children: [
      {
        path: 'manager',
        name: 'MaterielCategoryManager',
        component: () =>
          import('@/views/business/base/materielcategory/tenant/index'),
      },
    ],
  },
  //物料管理
  {
    path: '/tenant/base/materiel',
    component: Layout,
    redirect: '/tenant/base/materiel/manager',
    children: [
      {
        path: 'manager',
        name: 'MaterielManager',
        component: () => import('@/views/business/base/materielManger/parts'),
        //      component: () => import('@/views/business/base/materielManger'),
        // component: () => import('@/views/business/base/materiel/tenant/index'),
      },
      {
        path: 'parts',
        name: 'MaterielManager',
        component: () => import('@/views/business/base/materielManger/parts'),
      },
      {
        path: 'partsDetail',
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=资产配件详情;en-US=partsDetail',
          goBackRouter: '/tenant/base/materiel/manager',
        },
        name: 'partsDetail',
        component: () =>
          import('@/views/business/base/materielManger/parts/detail'),
      },
    ],
  },
  // 规则配置
  {
    path: '/rwork/ruleConfig',
    component: Layout,
    redirect: '/rwork/ruleConfig',
    children: [
      {
        path: '/rwork/ruleConfig',
        name: 'ruleConfig',
        component: () => import('@/views/business/base/ruleConfig'),
      },
    ],
  },

  //地图管理
  {
    path: '/map',
    component: Layout,
    redirect: '/map/manager',
    children: [
      {
        path: 'manager',
        name: 'mapManager',
        component: () => import('@/views/ruge/iot/map/manager'),
      },
      {
        path: 'exhibition',
        name: 'mapExhibition',
        component: () => import('@/views/ruge/iot/map/exhibition'),
      },
    ],
  },
  {
    path: '/map/details',
    component: Layout,
    redirect: '/map/details/manager',
    children: [
      {
        path: 'manager',
        name: 'detailsManager',
        component: () => import('@/views/ruge/iot/map/details/manager'),
      },
    ],
  },
  //*******************************工单模块*************************************
  //工单设置
  {
    path: '/tenant/order/orderConfig',
    component: Layout,
    redirect: '/tenant/order/orderConfig/list',
    children: [
      {
        path: 'list',
        name: 'OrderConfig',
        component: () => import('@/views/business/order/orderConfig'),
      },
    ],
  },
  {
    path: '/tenant/order/orderConfig',
    component: Layout,
    redirect: '/tenant/order/orderConfig/list2',
    children: [
      {
        path: 'list2',
        name: 'OrderConfigList2',
        component: () =>
          import('@/views/business/order/orderConfig-old/tenant/orderConfig'), //原先版本
      },
    ],
  },
  //物料领用
  {
    path: '/tenant/order/orderQuery/materialDrawing',
    component: Layout,
    redirect: '/tenant/order/orderQuery/materialDrawing/list',
    children: [
      {
        path: 'list',
        name: 'materialDrawing',
        component: () =>
          import(
            '@/views/business/order/orderQuery/materialDrawing/tenant/index'
          ),
      },
    ],
  },

  //工单查询
  {
    path: '/rwork/combinationOrder', //组合工单
    component: Layout,
    redirect: '/rwork/combinationOrder/list',
    children: [
      {
        path: 'list',
        name: 'combinationOrder',
        component: () => import('@/views/business/order/combination'),
      },
    ],
  },

  {
    path: '/tenant/order/orderQuery/order',
    component: Layout,
    redirect: '/tenant/order/orderQuery/order/list',
    children: [
      {
        path: 'list',
        name: 'orderManager',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/order'),
      },
      {
        path: 'all',
        name: 'orderManagerAll',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/all'),
      },

      //    {
      //      path: 'detail',
      //      // 如果是详情页，需要配置detail:true
      //      meta: {
      //        detail: true,
      //        name: 'zh-CN=工单详情;en-US=Order Detail',
      //        goBackRouter: '/tenant/order/orderQuery/order/all',
      //      },
      //      name: 'orderDeatil',
      //      component: () =>import('@/views/business/order/orderQuery/order/tenant/detail'),
      //	  },
      {
        path: 'unclaimed',
        name: 'orderManagerUnclaimed',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/unclaimed'),
      },
      {
        path: 'unexecute',
        name: 'orderManagerUnexecute',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/unexecute'),
      },
      {
        path: 'executing',
        name: 'orderManagerExecuting',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/executing'),
      },
      {
        path: 'beAccepted',
        name: 'orderManagerBeAccepted',
        component: () =>
          import('@/views/business/order/orderQuery/order/tenant/beAccepted'),
      },
    ],
  },
  // 审核
  {
    path: '/tenant/order/verify',
    component: Layout,
    redirect: '/tenant/order/verify/list',
    children: [
      {
        path: 'list',
        name: 'orderVerifyList',
        component: () => import('@/views/business/order/verify'),
      },
    ],
  },
  //工单统计
  {
    path: '/tenant/order/orderStatistics',
    component: Layout,
    redirect: '/tenant/order/orderStatistics/list',
    children: [
      {
        path: 'list',
        name: 'orderStatisticsManager',
        component: () =>
          import(
            '@/views/business/order/orderStatistics/tenant/orderStatistics'
          ),
      },
    ],
  },
  //*******************************巡检模块*************************************
  //巡检模版
  {
    path: '/tenant/inspection/template',
    component: Layout,
    redirect: '/tenant/inspection/template/manager',
    children: [
      {
        path: 'manager',
        name: 'InspectionTemplate',
        component: () =>
          import('@/views/business/inspection/template/tenant/index'),
      },
    ],
  },
  //巡检计划
  {
    path: '/tenant/inspection/plan',
    component: Layout,
    redirect: '/tenant/inspection/plan/manager',
    children: [
      {
        path: 'manager',
        name: 'InspectionPlan',
        component: () =>
          import('@/views/business/inspection/plan/tenant/index'),
      },
    ],
  },
  //巡检查询
  {
    path: '/tenant/inspection/inspectionSearch',
    component: Layout,
    redirect: '/tenant/inspection/inspectionSearch/list',
    children: [
      {
        path: 'list',
        name: 'inspectionSearchManager',
        component: () =>
          import('@/views/business/inspection/query/tenant/inspectionQuery'),
      },
      {
        path: 'detail',
        // 如果是详情页，需要配置detail:true
        name: 'inspectionDeatil',
        meta: {
          detail: true,
          name: 'zh-CN=巡检详情;en-US=Inspection Detail',
          goBackRouter: '/tenant/inspection/inspectionSearch/list',
        },
        component: () =>
          import('@/views/business/inspection/query/tenant/inspectionDetail'),
      },
    ],
  },
  //保养查询
  {
    path: '/tenant/inspection/maintainQuery',
    component: Layout,
    redirect: '/tenant/inspection/maintainQuery/list',
    children: [
      {
        path: 'list',
        name: 'maintainSearch',
        component: () =>
          import('@/views/business/inspection/query/tenant/maintainQuery'),
      },
      {
        path: 'detail',
        // 如果是详情页，需要配置detail:true
        name: 'maintainDeatil',
        meta: {
          detail: true,
          name: 'zh-CN=维保详情;en-US=maintain Detail',
          goBackRouter: '/tenant/inspection/maintainQuery/list',
        },
        component: () =>
          import('@/views/business/inspection/query/tenant/inspectionDetail'),
      },
    ],
  },
  //巡检报表
  {
    path: '/tenant/inspection/reportForm',
    component: Layout,
    redirect: '/tenant/inspection/reportForm/list',
    children: [
      {
        path: 'list',
        name: 'InspectionReport',
        component: () =>
          import(
            '@/views/business/inspection/report/reportInspection/reportInspection'
          ),
      },
    ],
  },

  {
    //设备台账管理
    path: '/rwork/deviceLedger',
    component: Layout,
    redirect: '/rwork/deviceLedger/list',
    children: [
      {
        path: 'list',
        name: 'enterprise',
        component: () => import('@/views/business/deviceLedger/listQuery'),
      },
      {
        path: 'ledgerDetail',
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=设备台账查询;en-US=ledgerDetail',
          goBackRouter: '/rwork/deviceLedger/list',
        },
        name: 'deviceDetail',
        component: () =>
          import('@/views/business/deviceLedger/listQuery/detail'),
      },
    ],
  },
  //rwork-企业管理
  {
    path: '/rwork/enterprise',
    component: Layout,
    redirect: '/rwork/enterprise/list',
    children: [
      {
        path: 'list',
        name: 'enterprise',
        component: () => import('@/views/business/enterpriseNew'),
      },
      {
        path: 'detail',
        name: 'enterpriseDeatil',
        meta: {
          detail: true,
          name: 'zh-CN=企业详情;en-US=Enterprise Detail',
          goBackRouter: '/rwork/enterprise/list',
        },
        component: () => import('@/views/business/enterpriseDetail'),
      },
      {
        path: 'userInfos',
        name: 'userInfos',
        component: () => import('@/views/business/enterprise/userInfos'),
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: () => import('@/views/business/enterprise/userManagement'),
      },
      {
        path: 'entrance',
        name: 'entrance',
        component: () => import('@/views/business/enterprise/entrance'),
      },
      {
        path: 'sendUp',
        // 如果是详情页，需要配置detail:true 企业入驻发起页
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=发起入驻;en-US=entrance sendUp',
          goBackRouter: '/rwork/enterprise/entrance',
        },
        name: 'entranceSendUp',
        component: () =>
          import('@/views/business/enterprise/entrance/model/AddSendUp'),
      },
      {
        path: 'deliverySite',
        // 企业入驻-执行场地交付
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=场地交付;en-US=delivery site',
          goBackRouter: '/rwork/enterprise/entrance',
        },
        name: 'deliverySite',
        component: () =>
          import('@/views/business/enterprise/entrance/model/deliverySite'),
      },
      {
        path: 'detail',
        // 如果是详情页，需要配置detail:true 企业入驻详情页
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=企业入驻详情;en-US=entrance detail',
          goBackRouter: '/rwork/enterprise/entrance',
        },
        name: 'entranceDetail',
        component: () => import('@/views/business/enterprise/entrance/detail'),
      },
      {
        path: 'retreat',
        name: 'retreat',
        component: () => import('@/views/business/enterprise/retreat'),
      },
      {
        path: 'toRetreat',
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=发起退租;en-US=entrance toRetreat',
          goBackRouter: '/rwork/enterprise/retreat',
        },
        name: 'entranceToRetreat',
        component: () =>
          import('@/views/business/enterprise/retreat/model/AddRetreat'),
      },
      {
        path: 'retreatDetail',
        meta: {
          detail: true,
          keepVal: true,
          name: 'zh-CN=企业退租详情;en-US=retreatDetail',
          goBackRouter: '/rwork/enterprise/retreat',
        },
        name: 'retreatDetail',
        component: () => import('@/views/business/enterprise/retreat/detail'),
      },
      {
        path: 'flowBpnm',
        name: 'entrance',
        component: () => import('@/views/business/FlowBpmn'),
      },
    ],
  },
  {
    path: '/rwork/flowBpnm',
    name: 'flowBpnm',
    component: () => import('@/views/business/FlowBpmn'),
  },
  {
    path: '/rwork/shifts',
    component: Layout,
    redirect: '/rwork/schedule',
    children: [
      {
        path: 'schedule',
        name: 'schedule',
        component: () => import('@/views/business/shifts/schedule'),
      },
      {
        path: 'shiftSetting',
        name: 'shiftSetting',
        component: () => import('@/views/business/shifts/shiftSetting'),
      },
      {
        path: 'attendanceSettings',
        name: 'attendanceSettings',
        component: () => import('@/views/business/shifts/attendanceSettings'),
      },
      {
        path: 'attendanceGroupSettings',
        name: 'attendanceGroupSettings',
        component: () =>
          import('@/views/business/shifts/attendanceGroupSettings'),
      },
      {
        path: 'holidayManagement',
        name: 'holidayManagement',
        component: () => import('@/views/business/shifts/holidayManagement'),
      },
    ],
  },
];

export default businessRouter;
