var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        content: _vm.tenantName,
        placement: "right",
        effect: "dark",
        "open-delay": _vm.openDelay,
      },
    },
    [
      _c(
        "div",
        { staticClass: "projectInfo", class: _vm.projectInfoMini },
        [
          _vm.customLogoPath != ""
            ? [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: _vm.customLogoPath,
                    onerror: _vm.sidebar.open
                      ? _vm.defaultBigAvatar
                      : _vm.defaultSmallAvatar,
                    alt: "",
                  },
                }),
              ]
            : _vm.tenantName != ""
            ? [
                _c("div", { staticClass: "tenant-font" }, [
                  _c("div", { staticClass: "tenant-avatar" }, [
                    _c("span", [_vm._v(_vm._s(_vm.tenantName.charAt(0)))]),
                  ]),
                ]),
              ]
            : [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: _vm.logoPath_, alt: "" },
                }),
                _c("span", { staticClass: "projectName" }, [
                  _vm._v(_vm._s(_vm.logoMsg_.projectName)),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }