<template>
  <div class="navbar">
    <breadcrumb class="breadcrumb-container" />
    <Rightmenu class="right-menu" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadcrumbNew";
import Rightmenu from "./NavbarTop/rightMenuNew.vue";

export default {
  data() {
    return {};
  },
  components: {
    Breadcrumb,
    Rightmenu,
  },
  methods: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 0px !important;
  background: #f1f6fd;
  .hamburger-container {
    line-height: 56px;
    height: 56px;
    float: left;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    margin-right: 40px;
    // line-height: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    float: right;
    position: absolute;
    top: 3px;
    right: 0;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }

    .right-menu-item {
      margin: 0 8px;
    }

    .information {
      height: 20px;
    }

    .international {
      vertical-align: top;
    }

    .theme-switch {
      vertical-align: 15px;
    }

    .avatar-container {
      height: 50px;
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
