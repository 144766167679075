// moment.js 日期处理类库 see. http://momentjs.cn/
import moment from 'moment';

// moment设置为中文 XXX 先写死，之后需要国际化
moment.locale('zh-cn');

/******************* 全局过滤器 *********************************/

/**
 * 日期格式化过滤器
 * @param dateStr 所有可以被moment初始化的日期
 * @param pattern 转换格式
 * @returns {string}
 */
export function dateFormat(dateStr, pattern = 'YYYY-MM-DD') {
  if (dateStr === undefined || dateStr === null || dateStr === '') {
    return '-';
  }
  return moment(dateStr).format(pattern);
}

/**
 * 日期格式化过滤器
 * @param dateStr 所有可以被moment初始化的日期
 * @param pattern 转换格式
 * @returns {string}
 * 备注: 设备-设备影子历史（上报时间），设备-时间（上报时间），设备-服务（下发时间，响应时间），设备-设备日志（上报时间）
 * 以上时间需要 -8H
 */
export function dateFormatSpaciel(dateStr, pattern = 'YYYY-MM-DD') {
  if (dateStr === undefined || dateStr === null || dateStr === '') {
    return '-';
  }
  dateStr = new Date(dateStr).getTime();
  dateStr -= 8 * 60 * 60 * 1000; // 减去8H
  return moment(dateStr).format(pattern);
}

/**
 * 日期格式化过滤器(用于UTC格式)
 * @param dateStr 所有可以被moment初始化的日期
 * @param pattern 转换格式
 * @returns {string}
 */
export function dateFormatUTC(dateStr, pattern = 'YYYY-MM-DD') {
  if (dateStr === undefined || dateStr === null) {
    return '';
  }
  return moment(dateStr).utc().format(pattern);
}

/**
 * UTC时区偏移
 * @param {} dateStr
 * @param {*} pattern
 * @param {*} offset
 */
export function dateFormatUTCOffset(
  dateStr,
  pattern = 'YYYY-MM-DD',
  offset = 8
) {
  if (dateStr === undefined || dateStr === null) {
    return '';
  }
  return moment(dateStr).utcOffset(offset).format(pattern);
}

/**
 * 人肉UTC时区+8偏移 moment().utcOffset(8)没用？？？！！
 * @param {} dateStr
 * @param {*} pattern
 */
export function dateUTC8Format(dateStr, pattern = 'YYYY-MM-DD') {
  if (dateStr === undefined || dateStr === null) {
    return '';
  }
  let utcDate = moment(dateStr).toDate().getTime() + 8 * 60 * 60 * 1000;
  return moment(utcDate).format(pattern);
}

export function addCommas(num) {
  return Number(num).toLocaleString();
}
