import { findUserCustomList } from '@/api/ruge/bms/indicatorManagement';
import { saveUserPortal } from '@/api/ruge/bmsNew/dashboard.js';

/**
 * home: 自定义首页
 * templateEdit: 首页编辑 - 数据模板tab页
 * customerEdit: 首页编辑 - 自定义tab页
 * ------------------------------------------
 * 1: 已读
 * 0: 未读
 *
 * {
 *    Home: 1,
 *    TemplateList: 1,
 *    CustomList: 1,
 * }
 *
 */
/**
 * 
  设置已读：FAQ.updateFAQ("home", 1)
  查询是否需要弹出FAQ：FAQ.isNeedFAQ("home") 
    true:  未读，需要弹出FAQ
    false: 已读不需要
 */
const FAQ = {
  datas: {},
  async initFAQ() {
    const result = await findUserCustomList({ customType: 'FAQ' });
    this.datas = this.classifyFAQ(result);
  },
  isNeedFAQ(type) {
    return !this.datas[type];
  },
  updateFAQ(type, result) {
    this.datas[type] = result;
    const params = {
      customType: 'FAQ',
      extendConfig: JSON.stringify(this.datas),
      cardIds: [],
    };
    saveUserPortal(params);
  },
  // 聚合所有租户的数据
  classifyFAQ(resourceList) {
    const temp = {};
    resourceList.forEach((item) => {
      if (item.extendConfig) {
        try {
          const parsedConfig = JSON.parse(item.extendConfig);
          for (let key in parsedConfig) {
            if (parsedConfig[key] === 1) {
              temp[key] = parsedConfig[key];
            }
          }
        } catch (error) {}
      }
    });
    return temp;
  },
};
export default FAQ;
