var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navmain" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened,
        },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "bread" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }