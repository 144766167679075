<!--表单封装组件-->
<template>
    <el-form :model="model" :rules="rules" ref="model" :disabled='disabled' :label-position='labelPosition' :label-width="labelWidth" class="Y-myFrom" :class='formClass'>
        <el-row :gutter="rowGutter" :class='{lableRow:lableRow}'>
            <template v-for='(it,dex) in formlist'>
                <el-col v-if="!it.isHide" class="lineh58" :span="it.flex?it.flex:24" :key='dex' :class='it.diyClass'>
                 	<template v-if="it.type=='txRow'">
                        <div class="t_row" :class='{hideLabel:it.labelHide}'>
                        	<div class="tr_name" v-if="!it.hideName">{{it.name}}</div>
                        	<div class="tr_val">{{model[it.keys]|| '-'}}</div>
                        </div>
                    </template>
                    <template v-if="it.type=='text'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <div class="txEllip">
                            	<el-tooltip class="item" effect="dark" :content="model[it.keys]" >
      								<span>{{model[it.keys] || '-'}}</span>
    							</el-tooltip>
                        	</div>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='input'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <template  v-if="!it.isNum">
                            	<el-input v-model="model[it.keys]" @blur='listenBlur(it)' @input="listenInput(it)" :ref="it.keys" :show-word-limit='it.maxlen?true:false' :disabled="it.disabled" clearable :placeholder="'请填写'+it.name" :class="{ activeBeizhu:it.beizhu }" :maxlength="it.maxlen?it.maxlen:150"></el-input>
                            </template>
                            <template v-else>
                            	<el-input v-model="model[it.keys]" @blur='listenBlur(it)' @input="listenInput(it)" :ref="it.keys" :disabled="it.disabled" clearable :placeholder="'请填写'+it.name" :class="{ activeBeizhu:it.beizhu }" :maxlength="it.maxlen?it.maxlen:150" oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
                            </template>
                            <span v-if="it.beizhu">{{it.beizhu}}</span>
                        </el-form-item>
                    </template>
                    <!-- 添加一个switch组件 -->
                    <template v-if="it.type=='switch'">
                        <el-form-item :label="it.name" :prop="it.keys">
                            <el-switch v-model="model[it.keys]"   active-value="Y" inactive-value="N"></el-switch>
                            <span v-if='it.tips' :class="it.tipClass">{{it.tips}}</span>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='select'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <el-select v-model="model[it.keys]" :ref="it.keys" :disabled="it.disabled" filterable :multiple="it.multiple" :clearable='!it.notClear' :collapse-tags='!it.noTags' :placeholder="'请选择'+it.name" @change='listenChange(it)'>
                                <!--<el-option label="请选择" value=""></el-option> -->
                                <el-option v-for="(item,dex2) in it.options" :key="dex2" :label="item.name" :value="item.valCode?item.valCode:item.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='devices'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <el-select v-model="model[it.keys]" :ref="it.keys" :disabled="it.disabled" filterable :multiple="it.multiple" clearable collapse-tags :placeholder="'请选择'+it.name" @change='listenChange(it)'>
                                <!--<el-option label="请选择" value=""></el-option> -->
                                <el-option v-for="(item,dex2) in $store.state.app.allDeviceArrs" :key="dex2" :label="item.deviceName" :value="item.deviceId">
                                	{{item.deviceName}}
                                 	<span class='txBlue' v-if="item.isSmartDevice=='Y'">智能</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='cascader'">
                    	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<el-cascader v-model="model[it.keys]" :disabled="it.disabled" @change='listenChange(it)' :options="it.options" :props="it.checkProps?it.checkProps:checkProps"  style='width: 100%;'></el-cascader>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='upload'">
                    		<el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <el-upload ref="formUpload" :id='it.keys'
												      :action="it.upPath?it.upPath:sameUpurl"
												      :file-list="model[it.keys]"
												      :on-change="(file,fileList)=>fileChange(file,fileList,it.keys)"
												      :on-error="uploadFail"
												      :with-credentials="true"
												      :auto-upload="false"
												    >
												     <el-button size="small" type="primary">{{it.btnTxt||'点击上传'}}</el-button>
												    </el-upload>
                        </el-form-item>
                    </template>
                    
                    <template v-if="it.type=='upload2'">
                    		<el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <el-upload ref="formUpload2" :id='it.keys'
												      :action="it.upPath?it.upPath:sameUpurl"
												      :file-list="model[it.keys]"
												      :on-success="(rs,f,fArr)=>autoUpFileChange(rs,f,fArr,it.keys)"
												      :on-remove="(file,fileList)=>autoUpFileRemove(file,fileList,it.keys)"
												      :on-error="uploadFail"
												      :with-credentials="true"
												    >
												     <el-button size="small" type="primary">{{it.btnTxt||'点击上传'}}</el-button>
												    </el-upload>
                        </el-form-item>
                    		
                    </template>
                    
                    
                    <template v-if="it.type=='stEndTime'">
                    	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <el-date-picker v-model="model[it.keys]" :ref="it.keys" :disabled="it.disabled" type="daterange" range-separator="-" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='time'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <el-date-picker v-model="model[it.keys]" :ref="it.keys" :disabled="it.disabled" :type="it.pickerType?it.pickerType:'date'" :value-format="it.format?it.format:'yyyy-MM-dd'" :placeholder="'请选择'+it.name" style='width: 100%;'></el-date-picker>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='check'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <el-checkbox-group v-model="model[it.keys]" :disabled="it.disabled">
                                <el-checkbox  v-for='(ck,dex3) in it.options' :key='dex3' :label="ck.code">{{ck.name}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='checkD'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <el-radio v-model="model[it.keys]" :disabled="it.disabled" v-for='(ck,dex3) in it.options' :key='dex3' :label="ck.code">{{ck.name}}</el-radio>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='employe'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                            <el-cascader v-model="model[it.keys]" :show-all-levels='false' :disabled="it.disabled" filterable @change='listenChange(it)' :options="$store.state.app.employeeArrs" :props="it.checkProps?it.checkProps:empProps"  style='width: 100%;' :collapse-tags='true' >
                            	<template slot-scope="{ node, data }">
								    <span v-if="!node.isLeaf">
								    	{{ data.deptName?data.deptName:data.companyName }}
								    </span>
								    <span v-else>
								    	{{ data.userName }}
								    </span>
							  	</template>
                            </el-cascader>
                        </el-form-item>
                    </template>
                    
                    <template v-if="it.type=='labelRadio'" >
                    	<!--作用于 工单配置-->
                        <el-form-item  v-if="!it.isHide" :label="it.name" :prop="it.keys">
                        	<el-radio-group v-model="model[it.keys]" :disabled="it.disabled" @change='listenChange(it)'>
	                            <el-radio class="lbRadio"  v-for='(ck,dex3) in it.options' :key='dex3' :label="ck.val" border>
	                            	{{ck.name}} 
	                            	<div class="r_tip">
	                            		<div v-html="ck.tips"></div>
	                            		<div v-if="it.isAudit&&it.auditkey&&ck.val=='Y'">
	                            			<div v-if="it.checkType=='select'">
		                            			<div v-if="model[it.keys]=='Y'">
		                            				<span>请选择 &nbsp; </span> 
			                            			<el-select v-model="model[it.auditkey]" filterable style='width: 200px;margin-top: 10px;'  :multiple="it.multiple"  collapse-tags placeholder="请选择" >
			                            				<!--<el-option  label="请选择" value=""></el-option>-->
						                                <el-option v-for="(item,dex2) in it.auditOptions" :key="dex2" :label="item.name" :value="item.valCode?item.valCode:item.code"></el-option>
						                            </el-select>
			                            		</div>
		                            		</div>
		                            		
		                            		<!--<div v-if="it.checkType=='cascader'">
		                            			<div v-if="model[it.keys]=='Y'">
		                            				<span>请选择 cascader &nbsp; </span> 
		                            				<el-cascader v-model="model[it.auditkey]" :options="it.auditOptions" :props="it.checkProps?it.checkProps:checkProps" @change='caderChange1(it.auditkey)' clearable style='width: 200px;margin-top: 10px;'></el-cascader>
			                            		</div>
		                            		</div>-->
		                            		<div v-if="it.checkType=='autoTime'" style="margin-top: 10px">
	                        					超过 
	                        					<el-input v-if='it.inpIsNum' oninput="value=value.replace(/[^0-9]/g,'')" class='w90' v-model="model[it.auditkey]" placeholder="请输入" :maxlength="it.inpMaxlen?it.inpMaxlen:20" />
	                        					<el-input v-else class='w90' v-model="model[it.auditkey]" placeholder="请输入" :maxlength="it.inpMaxlen?it.inpMaxlen:20" />
	                        					分钟，自动审核通过
		                            		</div>
	                            		</div>
	                            		
	                            	</div>
	                            </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    
                    <template v-if="it.type=='lbRadioCader'" >
                    	<!--作用于 工单配置 labelRadio 联动-->
                        <el-form-item  v-if="!it.isHide" :label="it.name" :prop="it.keys">
                        	<el-radio-group class='line36' v-model="model[it.keys]" :disabled="it.disabled" @change='it.auditVal=[];listenChange(it)'>
	                            <el-radio v-for='(ck,dex3) in it.options' :key='dex3' :label="ck.code">{{ck.name}}</el-radio>
	                            <template v-if="model[it.keys]&&model[it.keys]>0">
	                            	<template v-if="getlbRadioOptions(it).length>0">
	                            		<el-select v-model="it.auditVal" filterable multiple  :class='it.selectClass' :collapse-tags='!it.notags' :multiple-limit='getlbRadioOptions(it,"limit1")' placeholder="请选择" style='width: 280px;'>
		                    				<!--<el-option  label="请选择" value=""></el-option>-->
	                    						<el-option v-for="(item,dex2) in getlbRadioOptions(it)"  :key="dex2" :label="item.name" :disabled="item.disabled" :value="item.valCode?item.valCode:item.code"></el-option>
		                                </el-select>
	                            	</template>
	                            </template>
                            </el-radio-group>
                        </el-form-item>
                    </template>
                    <template v-if="it.type=='textarea'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <el-input type="textarea" :maxlength="it.maxlen?it.maxlen:255" show-word-limit :ref="it.keys" :disabled="it.disabled" @blur='listenBlur(it)' :autosize="it.autosize ? it.autosize : { minRows: 2, maxRows: 3}"  :placeholder="'请填写'+it.name" v-model="model[it.keys]"></el-input>
                        </el-form-item>
                    </template>
                    
                    <template v-if="it.type=='imgVideo'">
                    	<el-form-item v-if='it.formItem' :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
	                    	<div class="t_row">
	                        	<div class="tr_name">
	                        		<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
	                        	</div>
	                        	<div class="tr_val">
	                        		<TImgVideo :allowAdd='it.allowAdd' :itWidth='it.imgWidth' :itHeight='it.imgHeight' :model="model[it.keys]" :maxlimt='it.maxlimt?it.maxlimt:9'></TImgVideo>
	                        		<span v-if="!it.allowAdd&&model[it.keys]&&model[it.keys].length<1">-</span>
	                        	</div>
	                        </div>
                        </el-form-item>
                    	<div v-else class="t_row">
                        	<div class="tr_name">{{it.name}} 
                        	<span v-if='it.tips'  class="tipTx" :style="{left:it.tipleft}"><span v-html="it.tips"></span></span>
                        	</div>
                        	<div class="tr_val">
                        		<TImgVideo :allowAdd='it.allowAdd' :itWidth='it.imgWidth' :itHeight='it.imgHeight' :model="model[it.keys]" :maxlimt='it.maxlimt?it.maxlimt:9'></TImgVideo>
                        		<span v-if="!it.allowAdd&&model[it.keys]&&model[it.keys].length<1">-</span>
                        	</div>
                        </div>
                    </template>
                    <template v-if="it.type=='slot'">
                        <el-form-item :class='{hideLabel:it.labelHide}' :label="it.name" :prop="it.keys">
                            <slot :name="it.slotName" :$index="dex" :current="it"></slot>
                        </el-form-item>
                    </template>
                </el-col>
            </template>
        </el-row>
    </el-form>
</template>
<script>
import TImgVideo from '@/components/YTable/TImgVideo.vue';
export default {
	components: {TImgVideo},
    props:{
        model:{ //表单数据对象
            type:Object,
            default:()=>{
                return {}
            },
        },
        rules:{ //表单验证规则
            type:Object,
            default:()=>{
                return {}
            },
        },
        formlist:{//表单结构对象
            type:Array,
            default:()=>{
                return []
            },
        },
        formClass:{
            type:String,
            default:'',
        },
        labelPosition:{//label的宽度
            type:String,
            default:'right',
        },
        labelWidth:{//label的宽度
            type:String,
            default:'100px',
        },
        rowGutter:{//栅格间隔
            type:Number,
            default:0,
        },
        autoCheck:{
        	type:Boolean,
        	default:false,
        },
        disabled:{
        	type:Boolean,
        	default:false,
        },
        lableRow:{
        	//lable是否为一行
        	type:Boolean,
        	default:false,
        },
    },
    watch:{
		model:{
			deep: true,
			handler(n,o){
				this.initMyForm();
			},
		},
	},
    data(){
    	return{
    		sameUpurl:'/saascloud/saas/archive/param/archive/upload?ulType=DefaultUpload',
    		checkProps:{ multiple: true,value:'code',label:'name',children:'children',checkStrictly:true},
    		empProps:{ multiple: false,value:'userId',label:'userName',children:'children',emitPath: false,},
    		notEmploye:true,
    	}
    },
    methods:{
    	getlbRadioOptions(data,limit1){
    		let code=this.model[data.keys];
    		let res=[];
    		data.options.map(it=>{
    			if(code==it.code){
    				if(limit1){
    					res=it.limit1?1:0;//判断是否只能选一个
    				}else{
    					res=it.childs?it.childs:[];
    				}
    				
    			}
    		})
    		return res
    	},
		caderChange1(keys){
			let valArr=this.model[keys];
			let Arr=[];
			let len=valArr.length;
			if(len>0){
				let type=valArr[len-1][0];
				valArr.map(it=>{
					if(it[0]==type) Arr.push(it);
				})
				this.model[keys]=Arr;
			}
			
			
		},
        validate(callback){
            this.$refs.model.validate(callback);
        },
        resetFields(){
            this.$refs.model.resetFields();
        },
        listenBlur(it){
        	this.listenChange(it)
        },
        listenInput(it){
        	if(!it.inputMethod) return;
    		let val=this.model[it.keys];
    		it.inputMethod(val,it)
        },
        listenChange(it){
    		if(!it.changMethod) return;
    		let val=this.model[it.keys];
    		it.changMethod(val,it)
    	},
        initValidateType(yzType){
        	//通过传入固定的类型yzType=[phone,idCard,email]，校验是否正确
        	let result = (rule, value, callback) => {
				let isOk;
				let typeStr='值。';
				if(yzType=='phone'){
					typeStr='电话号码。';
					isOk=this.common.checkPhone(value);
				}else if(yzType=='idCard'){
					typeStr='身份证号。';
					isOk=this.common.checkIdCard(value);
				}else if(yzType=='email'){
					typeStr='邮箱号。';
					isOk=this.common.checkEmail(value);
				}
				if(!isOk) {
					let mesg='请输入正确的'+typeStr;
					callback(new Error(mesg))
				} else {
					callback()
				}
        	}
        	return result;
        },
        initMyForm(){
        	if(this.autoCheck){
	    		for(let item of this.formlist){
					let isRequired=false;
					let checkType='change';
					let strType='请选择';
					if(item.isMust) isRequired=true;
					if(item.type=='input'||item.type=='textarea'){
						checkType='blur'
						strType='请输入';
					}
					if(item.trigger){
						checkType=item.trigger;
					}
					if(item.yzType){
						this.rules[item.keys]=[{required: isRequired,trigger: 'blur',validator: this.initValidateType(item.yzType)}];
					}else{
						this.rules[item.keys]=[{required: isRequired,message: strType+item.name,trigger: checkType}];
					}
				}
	    	}
        	if(this.notEmploye){
        		for(let its of this.formlist){
	        		if(its.type=='employe'){
	        			this.notEmploye=false;
						this.$store.dispatch('getEmployeeDatas');
						break;
					}
	        	}
        		
        	}
        	
        	
        },
        uploadFail() {
					this.$message({ type: "error", message: this.$t("message.uploadFial") });
	  		},
	  		fileChange(myFile, fileArrs,keys){
	  		 	this.model[keys]=fileArrs;
		    	console.log('fileChange',myFile,fileArrs,keys)
		    },
		    autoUpFileChange(rs,myFile,fileArrs,keys){
//		    	console.log('上传',rs,myFile,fileArrs,keys)
		    	let Arr1=[];
		    	for(let it of fileArrs){
		    		if(!it.response||it.response.length<1) continue;
		    		const {fileId,contentType,thumbnailId}=it.response[0];
//		    		console.log('上传11',fileId,contentType,thumbnailId)
		    		it.fileName=it.name;
		    		it.documentName=it.name;
		    		it.fileId=fileId;
		    		it.contentType=contentType;
		    		it.fileType=contentType;
						it.documentFormat= contentType;//格式
						it.thumbnailId=thumbnailId?thumbnailId:null;
						delete it.raw;
						delete it.status;
						delete it.response;
						delete it.percentage;
		    	}
		    	this.model[keys]=fileArrs;
		    },
		    autoUpFileRemove(myFile,fileArrs,keys){
		    	this.model[keys]=fileArrs;
		    },
    },
    created(){
    	this.initMyForm();
	},
}
</script>
<style lang="scss">
	.Y-myFrom{
		.el-select{
			width: 100%;
		}
		.el-form-item{
			margin-bottom: 20px;
			.txEllip{
				overflow:hidden;
			    text-overflow:ellipsis;
			    white-space:nowrap
			}
		}
		.hideLabel{
			.el-form-item__label{
				display: none;
			}
			.el-form-item__content{
			    margin-left: 0 !important;
			}
		} 
		.line36{
    		line-height: 36px;
    		padding-left: 14px;
    	}
		.lbRadio.el-radio{
			position: relative;
			height: inherit;
		    vertical-align: text-top;
			width: 308px;
			min-height: 72px;
	    	font-size: 15px !important;
	    	padding: 14px !important;
	    	.el-radio__input{
	    		position: relative;
	    	}
	    	.r_tip{
	    		font-size: 12px;
	    		line-height: 16px;
	    		color: #97A8BE;
	    		padding: 10px 0 0 24px;
	    	}
	    	.w90{
	    		width: 90px;
	    		margin: 0 8px;
	    	}
	    	
	    }
	    .tipRed{
	    	color: #fb7878;
	    	margin-left: 12px;
	    }
	    .t_row{
	    	position: relative;
	    	line-height: 24px;
	    	.tr_name{
	    		font-size: 12px;
	    		line-height: 22px;
	    		/*color: #909399;*/
	    		color: #4E5969;
	    		font-weight: bold;
	    	}
	    	.tr_val{
	    		font-size: 14px;
	    		color: #2F3941;
	    		font-weight: bold;
	    	}
	    }
	    .lableRow{
	    	.el-form-item__label{
		    	width: 100% !important;
		    }
	    	.el-form-item__content{
	    		margin-left: 0 !important;
	    	}
	    } 
	}
	
    .activeBeizhu{
        width: 80%;
    }
    .tipTx{
    	position: absolute;
	    left: 100px;
	    top: 1px;
	    line-height: 20px;
	    font-size: 12px;
	    color: #909399;
    }
    .wd50 .el-form-item{
		width: 50%;
	}
    .legoTform{
    	.tipTx{
		    color: #5D687C;
	    }
	    .el-form-item__label{
			font-weight: 600;
			font-size: 14px;
			color: #252D3D;
			font-style: normal;
			text-transform: none;
			padding-bottom: 6px;
		}
		.t_row .tr_name{
			font-size: 14px;
			color: #252D3D;
		}
    	.Y-imgsVedio{
				.add_icon{
					color: #B5BECE !important;
				}
				.diyAdd{
					border: 1px dashed #B5BECE;
					border-radius:10px;
					background: #F7F8FA;
				}
			} 
    	
    	
    	
    }
</style>