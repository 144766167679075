var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _c("information-details", {
        attrs: {
          informationDialog: _vm.informationDialog,
          informationInfo: _vm.informationInfo,
        },
        on: {
          "update:informationDialog": function ($event) {
            _vm.informationDialog = $event
          },
          "update:information-dialog": function ($event) {
            _vm.informationDialog = $event
          },
        },
      }),
      _c("confirm-alarm", {
        attrs: {
          alarmConfirmRecord: _vm.alarmConfirmRecord,
          alarmConfirmDialog: _vm.alarmConfirmDialog,
        },
        on: {
          "update:alarmConfirmDialog": function ($event) {
            _vm.alarmConfirmDialog = $event
          },
          "update:alarm-confirm-dialog": function ($event) {
            _vm.alarmConfirmDialog = $event
          },
        },
      }),
      _vm.$store.state.app.orderAddEdit.open ? _c("addOrEditOrder") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }