<template>
  <div v-if="!item.hidden&&isCurrentMenu(item)" class="menu-wrapper">
    <Sidemenu v-for="(child,index) in item.nodes" :item="child" :key="index" class="nest-menu" />
  </div>
</template>
<script>
import Sidemenu from "./Sidemenu";
export default {
  name: "SidebarItem",
  components: { Sidemenu },
  props: {
    // 菜单节点
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    // 获取当前菜单的matched
    findSitemapMatched(sitemap, path) {
      let matched;
      let find = function(sitemap, path) {
        for (let item of sitemap) {
          if (
            item.href &&
            item.href === path // 优先做路径完全匹配
          ) {
            matched = item.matched;
            break;
          }
          let nodes = item.nodes;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
    //如果通过激活一级菜单,显示对应子菜单
    //如果通过输入地址进入菜单,分析输入的地址,定位当前路由
    //return true 时侧边栏显示, return flase时隐藏
    isCurrentMenu(item) {
      if (this.$store.state.app.selectMenuId) {
        if (item.sitemapId == this.$store.state.app.selectMenuId && item.nodes) {
          return true;
        } else {
          return false;
        }
      } else {
        let matched = this.findSitemapMatched(
          this.$store.getters.sitemapList,
          decodeURIComponent(this.$route.fullPath)
        );

        if (matched && item.sitemapId == matched[0].sitemapId && item.nodes) {
          this.$store.commit("SET_SELECT_MENU_ID", matched[0].sitemapId + "");
          return true;
        } else {
          this.$store.commit("SET_SELECT_MENU_ID", matched[0].sitemapId + "");
          return false;
        }
      }
    }
  }
};
</script>
<style >
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>