var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "hover" },
      on: { command: _vm.handleSetLanguage },
    },
    [
      _c(
        "div",
        [
          _c("svg-icon", {
            attrs: {
              "class-name": "international-icon",
              "icon-class": "language",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.supportLang, function (item, key) {
          return _c(
            "el-dropdown-item",
            {
              key: key,
              attrs: { disabled: _vm.language === key, command: key },
            },
            [_vm._v(_vm._s(item))]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }