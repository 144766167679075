import Cookies from 'js-cookie';
import Vue from 'vue'
const app = {
  state: {
    sidebar: {
      opened: Cookies.get('sidebarStatus')
        ? !!+Cookies.get('sidebarStatus')
        : true,
      withoutAnimation: false,
    },
    device: 'desktop',
    language: Cookies.get('language') || 'zh-CN',
    size: Cookies.get('size') || 'medium',
    tenantAvatar: '',
    tenantAvatarOpen: '',
    displayLayout: +Cookies.get('displayLayout') || 1,
    theme: +Cookies.get('theme') || 1,
    //当前选中的一级菜单的id,用于显示相应子菜单,当没有配置菜单时为null
    selectMenuId: null,
    isVedioSrc: '', //视频预览地址路劲
   	orderAddEdit:{
   	//工单新增或者修改
   		type:'add',
	   	open:false,
	   	dataId:'',
   	},
   	orderUpdate:1,//工单是否更新
   	employeeArrs:[],//部门下 人员数据
   	allDeviceArrs:[],//所有设备
  },
  mutations: {
    // 开关左边菜单，1:开，0:关
    TOGGLE_SIDEBAR: (state) => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
        Cookies.set('sidebarStatusBak', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
        Cookies.set('sidebarStatusBak', 0);
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    OPEN_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1);
      state.sidebar.opened = true;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set('language', language);
    },
    SET_SIZE: (state, size) => {
      state.size = size;
      Cookies.set('size', size);
    },
    SET_TENANT_AVATAR: (state, tenantAvatar) => {
      state.tenantAvatar = tenantAvatar;
    },
    SET_TENANT_AVATAR_OPEN: (state, tenantAvatarOpen) => {
      state.tenantAvatarOpen = tenantAvatarOpen;
    },
    // 显示布局
    SET_DISPLAY_LAYOUT: (state, displayLayout) => {
      state.displayLayout = displayLayout;
      Cookies.set('displayLayout', displayLayout);
    },
    // 颜色主题
    SET_DISPLAY_THEME: (state, theme) => {
      state.theme = theme;
      Cookies.set('theme', theme);
    },
    SET_SELECT_MENU_ID: (state, param) => {
      const key = param;
      state.selectMenuId = key;
    },
    setEmployeeArrs:(state, val) => {
      state.employeeArrs = val;
    },
    setAllDeviceArrs:(state, val) => {
      state.allDeviceArrs = val;
    },
    setVedioSrc: (state, val) => {
      state.isVedioSrc = val;
    },
    setOrderAddEdit: (state, val) => {
    	if(val){
    		let type=val.type;
    		if(type=='close'){
    			state.orderAddEdit = {
				   	open:false,
				   	type:'add',
				   	dataId:'',
		   		};
    		}else{
    			if(type=='init'){
    				state.orderUpdate++;
    				state.orderAddEdit.open=false;
    			}else{
    				state.orderAddEdit = val;//新增或者编辑 打开弹框
    			}
    		}
    	}else{
    		//关闭弹框
    		state.orderAddEdit = {
					type:'add',
			   	open:false,
			   	dataId:'',
		   	};
    	}
      
    },
  },
  actions: {
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR');
    },
    CloseSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    OpenSideBar({ commit }, { withoutAnimation }) {
      commit('OPEN_SIDEBAR', withoutAnimation);
    },
    ToggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device);
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setSize({ commit }, size) {
      commit('SET_SIZE', size);
    },
    ChangeTenantAvator({ commit }, tenantAvatar) {
      commit('SET_TENANT_AVATAR', tenantAvatar);
    },
    ChangeTenantAvatorOpen({ commit }, tenantAvatar) {
      commit('SET_TENANT_AVATAR_OPEN', tenantAvatar);
    },
    setDisplayLayout({ commit }, displayLayout) {
      commit('SET_DISPLAY_LAYOUT', displayLayout);
    },
    setDisplayTheme({ commit }, theme) {
      commit('SET_DISPLAY_THEME', theme);
    },
		getEmployeeDatas:(ctx,params={})=>{
			return new Promise((resolve, reject) => {
				let rqData={
					...params,
					isloading:false,	
				}
				Vue.prototype.ApiHttp('/organization/tenant/employee/getEmployeeTree',rqData,'get').then(res=>{
					
					const fileNodes=function(Arr){
						Arr.map(item=>{
							if(item.children){
								fileNodes(item.children);
							}else{
								if(!item.deptId){
									item.disabled=true;
								}
							}
						})
					}
					fileNodes(res);
					ctx.commit('setEmployeeArrs',res);
					resolve(res);
				}).catch(Error => {
					reject(Error);
				})
			});
		},
		getAllDevices:(ctx,projectId)=>{
			return new Promise((resolve, reject) => {
				let params={
					current:1,
					rowCount:100000,
					isloading:false,
				}
				if(projectId){
					params.projectId=projectId;
				}
				let Arrs=ctx.state.allDeviceArrs;
				if(!projectId&&Arrs.length>0){
					resolve(Arrs);
				}else{
					Vue.prototype.ApiHttp('/base/tenant/device/page',params,'get').then(res=>{
						ctx.commit('setAllDeviceArrs',res.rows);
						resolve(res);
					}).catch(Error => {
						reject(Error);
					})
				}
				
			});
		},
		getAllProjects:(ctx,projectId)=>{
			return new Promise((resolve, reject) => {
				let params={
					isloading:false,
				}
				Vue.prototype.ApiHttp('/base/tenant/project/list',params,'get').then(res=>{
					resolve(res);
				}).catch(Error => {
					reject(Error);
				})
			});
		},
  },
};

export default app;
