<template>
  <!--<el-dialog class="info-dialog" width="30%" :visible.sync="informationDialog" :before-close="closeInformationDialog">-->
  <el-drawer
    :title="informationInfo != null ? informationInfo.notifyTitle : ''"
    :before-close="handleClose"
    size="50%"
    :visible.sync="detailDrawer"
  >
    <div class="info-drawer-container">
      <hr class="info-hr" />
      <el-row class="info-sender">
        <label class="info-label">{{ $t("notify.manager.sender") }}</label>
        <template v-if="informationInfo != null">
          <span class="info-span">{{ informationInfo.senderName }}</span>
        </template>
        <label class="info-label">{{ $t("notify.manager.sendTime") }}</label>
        <template v-if="informationInfo != null">
          <span class="info-span">{{
            informationInfo.notifyTime | dateFormat("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </template>
      </el-row>
      <el-row v-loading="drawerLoading">
        <template v-if="informationInfo != null">
          <div v-html="content"></div>
        </template>
      </el-row>
    </div>
  </el-drawer>
  <!--</el-dialog>-->
</template>

<script>
import { findNotifyByTemplateId } from "@/api/ruge/notify/notify";

export default {
  name: "InformationDetails",
  props: {
    // 消息详细
    informationInfo: {
      type: Object,
    },
    detailDrawer: {
      type: Boolean,
    },
  },
  data() {
    return {
      content: null,
      drawerTitle: "",
      drawerLoading: false,
    };
  },
  watch: {
    isShow(val) {
      this.isOpen(val);
    },
  },
  computed: {
    isShow() {
      return this.detailDrawer;
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:detailDrawer", false);
    },
    isOpen(val) {
      if (val) {
        this.drawerLoading = true;
        findNotifyByTemplateId({
          notifyTemplateId: this.informationInfo.notifyTemplateId,
        })
          .then((res) => {
            this.content = res;
          })
          .finally(() => {
            this.drawerLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info-drawer-container {
  padding: 20px;
}
</style>
<style scoped>
.info-dialog >>> .el-dialog__header {
  padding: 16px 40px 0px 20px;
  line-height: 26px;
}

.info-dialog >>> .el-dialog__body {
  padding: 0px 20px;
}

.info-label {
  font-size: 14px;
  margin-right: 5px;
}

.info-span {
  font-size: 14px;
  color: #909399;
  margin-right: 50px;
}

.info-p {
  font-size: 14px;
  color: #606266;
  margin: 0px 0px 30px;
  line-height: 28px;
}

.info-hr {
  background-color: #e0e2e6;
  border: none;
  height: 1px;
}

.info-sender {
  line-height: 32px;
}
</style>
