<template>
  <div class="rlink-button-component" v-loading="loading" @click="clickHandler">
    <div
      :class="[
        'button-container',
        `button-component-${type}`,
        mini && 'mini',
        disabled && 'button-disabled',
        plain && 'button-plain',
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "rlink-button-component",
  props: {
    // default | cancel | danger | dangerPlain | normal | normalPlain
    type: {
      type: String,
      default() {
        return "normal";
      },
    },
    disabled: {
      default() {
        return false;
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    plain: {
      type: Boolean,
      default() {
        return false;
      },
    },
    mini: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    clickHandler() {
      if (!this.loading && !this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rlink-button-component {
  text-align: center;
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
  z-index: 1;
  .button-container {
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    height: 36px;
    border-radius: 8px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    &.mini {
      height: 25px;
      padding: 0 15px;
    }
  }
  .button-component-normal {
    color: #2a61ff;
    border: 1px solid #2a61ff;
    background: #ffffff;
    &:hover {
      border: 1px solid #527fff;
      color: #527fff;
    }
    &:active {
      border: 1px solid #2651ce;
      color: #2651ce;
    }
    &.button-disabled {
      border: 1px solid #abbbea;
      color: #abbbea;
      cursor: not-allowed;
    }
    &.button-plain {
      color: #ffffff;
      background: #2a61ff;
      border: 1px solid #2a61ff;
      &:hover {
        background: #527fff;
        border: 1px solid #527fff;
      }
      &:active {
        background: #2652ce;
        border: 1px solid #2652ce;
      }
      &.button-disabled {
        background: #d1dcfb;
        border: 1px solid #d1dcfb;
        cursor: not-allowed;
      }
    }
  }
  .button-component-default {
    color: #252d3d;
    border: 1px solid #b5bece;
    background: #ffffff;
    &:hover {
      border: 1px solid #b5bece;
      background: #f5f7fa;
    }
    &:active {
      border: 1px solid #b5bece;
      background: #ecf0f7;
    }
    &.button-disabled {
      border: 1px solid #e0e7f4;
      color: #e0e7f4;
      cursor: not-allowed;
    }
  }
  .button-component-cancel {
    border: 1px solid #b5bece;
    color: #b5bece;
    &:hover {
      border: 1px solid #d3d7de;
      color: #d3d7de;
    }
    &:active {
      border: 1px solid #c1ccdf;
      color: #c1ccdf;
    }
    &.button-disabled {
      border: 1px solid #e0e7f4;
      color: #e0e7f4;
      cursor: not-allowed;
    }
  }
  .button-component-danger {
    border: 1px solid #f13636;
    color: #f13636;
    &:hover {
      border: 1px solid #f57474;
      color: #f57474;
    }
    &:active {
      border: 1px solid #e95e5e;
      color: #e95e5e;
    }
    &.button-disabled {
      border: 1px solid #f0a2a2;
      color: #f0a2a2;
      cursor: not-allowed;
    }
    &.button-plain {
      color: #ffffff;
      background: #e44531;
      &:hover {
        background: #f57474;
      }
      &:active {
        background: #e95e5e;
      }
      &.button-disabled {
        background: #f0a2a2;
        border: 1px solid #f0a2a2;
        cursor: not-allowed;
      }
    }
  }
}
.rlink-button-component + .rlink-button-component {
  margin-left: 10px;
}
</style>