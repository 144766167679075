import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 发送通知
 * @param params
 */
export function pushNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/notify/pushNotify`,
        method: 'post',
        data: params
    })
}

/**
 * 通知查询
 * @param params
 */
export function getNotifyPage(params) {
  return request({
    url: envInfo.bgApp.notifyPath + '/notify/page',
    method: 'get',
    params
  })
}

/**
 * 新增通知
 * @param params
 */
export function insertNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/notify/create',
        method: 'post',
        data: params
    })
}

/**
 * 修改通知
 * @param params
 */
export function updateNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/notify/update',
        method: 'post',
        data: params
    })
}

/**
 * 查询通知信息
 * @param params
 */
export function findNotifyById(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/tenant/notify/find/${params.notifyId}`,
        method: 'get',
    })
}

/**
 * 查询通知模版内容
 * @param params
 */
export function findNotifyByTemplateId(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/findContent/${params.notifyTemplateId}`,
        method: 'get',
    })
}

/**
 * 删除通知
 * @param params
 */
export function deleteNotify(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/notify/delete/${params.notifyId}`,
        method: 'delete',
    })
}

/**
 * 查询通知发送记录
 * @param params
 */
export function getNotifyDetailPage(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/notifyPage',
        method: 'get',
        params
    })
}

/**
 * 查询接收到的消息记录
 * @param params
 */
export function getRecordInformation(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/information',
        method: 'get',
        params
    })
}

/**
 * 查询未读消息数
 */
export function getRecordUnreadCount() {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/unreadCount',
        method: 'get'
    })
}

/**
 * 查看记录，标记已读状态
 * @param params
 */
export function updateNotifySignRead(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/signRead',
        method: 'post',
        data: params
    })
}

/**
 * 逻辑清空用户通知
 * @param params
 */
export function clearAllRecord() {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/clearAllRecord`,
        method: 'delete',
    })
}

/**
 * 物理清空用户通知
 * @param params
 */
export function destroyAllRecord() {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/deleteAllRecord`,
        method: 'delete',
    })
}

/**
 * 逻辑删除用户通知
 * @param params
 */
export function logicDeleteRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/logicDeleteRecord/${params.notifyRecordId}`,
        method: 'delete',
    })
}

/**
 * 逻辑批量删除用户通知
 * @param params
 */
export function batchDeleteRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/batchLogicDeleteRecord',
        method: 'delete',
        params
    })
}

/**
 * 物理删除用户通知
 * @param params
 */
export function destroyRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/deleteRecord/${params.notifyRecordId}`,
        method: 'delete',
    })
}

/**
 * 物理批量删除用户通知
 * @param params
 */
export function batchDestroyRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/batchDeleteRecord',
        method: 'delete',
        params
    })
}

/**
 * 还原
 * @param params
 */
export function restoreRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + `/user/notify/reduction/${params.notifyRecordId}`,
        method: 'put'
    })
}

/**
 * 批量还原
 * @param params
 */
export function batchRestoreRecord(params) {
    return request({
        url: envInfo.bgApp.notifyPath + '/user/notify/batchReduction',
        method: 'put',
        data: params
    })
}
