var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "model",
      staticClass: "Y-myFrom",
      class: _vm.formClass,
      attrs: {
        model: _vm.model,
        rules: _vm.rules,
        disabled: _vm.disabled,
        "label-position": _vm.labelPosition,
        "label-width": _vm.labelWidth,
      },
    },
    [
      _c(
        "el-row",
        { class: { lableRow: _vm.lableRow }, attrs: { gutter: _vm.rowGutter } },
        [
          _vm._l(_vm.formlist, function (it, dex) {
            return [
              !it.isHide
                ? _c(
                    "el-col",
                    {
                      key: dex,
                      staticClass: "lineh58",
                      class: it.diyClass,
                      attrs: { span: it.flex ? it.flex : 24 },
                    },
                    [
                      it.type == "txRow"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "t_row",
                                class: { hideLabel: it.labelHide },
                              },
                              [
                                !it.hideName
                                  ? _c("div", { staticClass: "tr_name" }, [
                                      _vm._v(_vm._s(it.name)),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "tr_val" }, [
                                  _vm._v(_vm._s(_vm.model[it.keys] || "-")),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      it.type == "text"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "txEllip" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.model[it.keys],
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.model[it.keys] || "-")
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      it.type == "input"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                !it.isNum
                                  ? [
                                      _c("el-input", {
                                        ref: it.keys,
                                        refInFor: true,
                                        class: { activeBeizhu: it.beizhu },
                                        attrs: {
                                          "show-word-limit": it.maxlen
                                            ? true
                                            : false,
                                          disabled: it.disabled,
                                          clearable: "",
                                          placeholder: "请填写" + it.name,
                                          maxlength: it.maxlen
                                            ? it.maxlen
                                            : 150,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.listenBlur(it)
                                          },
                                          input: function ($event) {
                                            return _vm.listenInput(it)
                                          },
                                        },
                                        model: {
                                          value: _vm.model[it.keys],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, it.keys, $$v)
                                          },
                                          expression: "model[it.keys]",
                                        },
                                      }),
                                    ]
                                  : [
                                      _c("el-input", {
                                        ref: it.keys,
                                        refInFor: true,
                                        class: { activeBeizhu: it.beizhu },
                                        attrs: {
                                          disabled: it.disabled,
                                          clearable: "",
                                          placeholder: "请填写" + it.name,
                                          maxlength: it.maxlen
                                            ? it.maxlen
                                            : 150,
                                          oninput:
                                            "value=value.replace(/[^0-9]/g,'')",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.listenBlur(it)
                                          },
                                          input: function ($event) {
                                            return _vm.listenInput(it)
                                          },
                                        },
                                        model: {
                                          value: _vm.model[it.keys],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, it.keys, $$v)
                                          },
                                          expression: "model[it.keys]",
                                        },
                                      }),
                                    ],
                                it.beizhu
                                  ? _c("span", [_vm._v(_vm._s(it.beizhu))])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      it.type == "switch"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: it.name, prop: it.keys } },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": "Y",
                                    "inactive-value": "N",
                                  },
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                                it.tips
                                  ? _c("span", { class: it.tipClass }, [
                                      _vm._v(_vm._s(it.tips)),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "select"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-select",
                                  {
                                    ref: it.keys,
                                    refInFor: true,
                                    attrs: {
                                      disabled: it.disabled,
                                      filterable: "",
                                      multiple: it.multiple,
                                      clearable: !it.notClear,
                                      "collapse-tags": !it.noTags,
                                      placeholder: "请选择" + it.name,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.listenChange(it)
                                      },
                                    },
                                    model: {
                                      value: _vm.model[it.keys],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, it.keys, $$v)
                                      },
                                      expression: "model[it.keys]",
                                    },
                                  },
                                  _vm._l(it.options, function (item, dex2) {
                                    return _c("el-option", {
                                      key: dex2,
                                      attrs: {
                                        label: item.name,
                                        value: item.valCode
                                          ? item.valCode
                                          : item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "devices"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-select",
                                  {
                                    ref: it.keys,
                                    refInFor: true,
                                    attrs: {
                                      disabled: it.disabled,
                                      filterable: "",
                                      multiple: it.multiple,
                                      clearable: "",
                                      "collapse-tags": "",
                                      placeholder: "请选择" + it.name,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.listenChange(it)
                                      },
                                    },
                                    model: {
                                      value: _vm.model[it.keys],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, it.keys, $$v)
                                      },
                                      expression: "model[it.keys]",
                                    },
                                  },
                                  _vm._l(
                                    _vm.$store.state.app.allDeviceArrs,
                                    function (item, dex2) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: dex2,
                                          attrs: {
                                            label: item.deviceName,
                                            value: item.deviceId,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                \t" +
                                              _vm._s(item.deviceName) +
                                              "\n                                 \t"
                                          ),
                                          item.isSmartDevice == "Y"
                                            ? _c(
                                                "span",
                                                { staticClass: "txBlue" },
                                                [_vm._v("智能")]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "cascader"
                        ? [
                            it.tips
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "tipTx",
                                    style: { left: it.tipleft },
                                  },
                                  [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(it.tips) },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: it.disabled,
                                    options: it.options,
                                    props: it.checkProps
                                      ? it.checkProps
                                      : _vm.checkProps,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.listenChange(it)
                                    },
                                  },
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "upload"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "formUpload",
                                    refInFor: true,
                                    attrs: {
                                      id: it.keys,
                                      action: it.upPath
                                        ? it.upPath
                                        : _vm.sameUpurl,
                                      "file-list": _vm.model[it.keys],
                                      "on-change": (file, fileList) =>
                                        _vm.fileChange(file, fileList, it.keys),
                                      "on-error": _vm.uploadFail,
                                      "with-credentials": true,
                                      "auto-upload": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v(_vm._s(it.btnTxt || "点击上传"))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "upload2"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "formUpload2",
                                    refInFor: true,
                                    attrs: {
                                      id: it.keys,
                                      action: it.upPath
                                        ? it.upPath
                                        : _vm.sameUpurl,
                                      "file-list": _vm.model[it.keys],
                                      "on-success": (rs, f, fArr) =>
                                        _vm.autoUpFileChange(
                                          rs,
                                          f,
                                          fArr,
                                          it.keys
                                        ),
                                      "on-remove": (file, fileList) =>
                                        _vm.autoUpFileRemove(
                                          file,
                                          fileList,
                                          it.keys
                                        ),
                                      "on-error": _vm.uploadFail,
                                      "with-credentials": true,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v(_vm._s(it.btnTxt || "点击上传"))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "stEndTime"
                        ? [
                            it.tips
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "tipTx",
                                    style: { left: it.tipleft },
                                  },
                                  [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(it.tips) },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c("el-date-picker", {
                                  ref: it.keys,
                                  refInFor: true,
                                  attrs: {
                                    disabled: it.disabled,
                                    type: "daterange",
                                    "range-separator": "-",
                                    "value-format": "yyyy-MM-dd",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                  },
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "time"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c("el-date-picker", {
                                  ref: it.keys,
                                  refInFor: true,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: it.disabled,
                                    type: it.pickerType
                                      ? it.pickerType
                                      : "date",
                                    "value-format": it.format
                                      ? it.format
                                      : "yyyy-MM-dd",
                                    placeholder: "请选择" + it.name,
                                  },
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "check"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    attrs: { disabled: it.disabled },
                                    model: {
                                      value: _vm.model[it.keys],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, it.keys, $$v)
                                      },
                                      expression: "model[it.keys]",
                                    },
                                  },
                                  _vm._l(it.options, function (ck, dex3) {
                                    return _c(
                                      "el-checkbox",
                                      { key: dex3, attrs: { label: ck.code } },
                                      [_vm._v(_vm._s(ck.name))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "checkD"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              _vm._l(it.options, function (ck, dex3) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dex3,
                                    attrs: {
                                      disabled: it.disabled,
                                      label: ck.code,
                                    },
                                    model: {
                                      value: _vm.model[it.keys],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, it.keys, $$v)
                                      },
                                      expression: "model[it.keys]",
                                    },
                                  },
                                  [_vm._v(_vm._s(ck.name))]
                                )
                              }),
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "employe"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                it.tips
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "tipTx",
                                        style: { left: it.tipleft },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(it.tips),
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "show-all-levels": false,
                                    disabled: it.disabled,
                                    filterable: "",
                                    options: _vm.$store.state.app.employeeArrs,
                                    props: it.checkProps
                                      ? it.checkProps
                                      : _vm.empProps,
                                    "collapse-tags": true,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.listenChange(it)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ node, data }) {
                                          return [
                                            !node.isLeaf
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t    \t" +
                                                      _vm._s(
                                                        data.deptName
                                                          ? data.deptName
                                                          : data.companyName
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t    "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t    \t" +
                                                      _vm._s(data.userName) +
                                                      "\n\t\t\t\t\t\t\t\t    "
                                                  ),
                                                ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "labelRadio"
                        ? [
                            !it.isHide
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: it.name, prop: it.keys } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: it.disabled },
                                        on: {
                                          change: function ($event) {
                                            return _vm.listenChange(it)
                                          },
                                        },
                                        model: {
                                          value: _vm.model[it.keys],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, it.keys, $$v)
                                          },
                                          expression: "model[it.keys]",
                                        },
                                      },
                                      _vm._l(it.options, function (ck, dex3) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: dex3,
                                            staticClass: "lbRadio",
                                            attrs: {
                                              label: ck.val,
                                              border: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t                            \t" +
                                                _vm._s(ck.name) +
                                                " \n\t                            \t"
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "r_tip" },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(ck.tips),
                                                  },
                                                }),
                                                it.isAudit &&
                                                it.auditkey &&
                                                ck.val == "Y"
                                                  ? _c("div", [
                                                      it.checkType == "select"
                                                        ? _c("div", [
                                                            _vm.model[
                                                              it.keys
                                                            ] == "Y"
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "请选择   "
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "200px",
                                                                            "margin-top":
                                                                              "10px",
                                                                          },
                                                                        attrs: {
                                                                          filterable:
                                                                            "",
                                                                          multiple:
                                                                            it.multiple,
                                                                          "collapse-tags":
                                                                            "",
                                                                          placeholder:
                                                                            "请选择",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .model[
                                                                              it
                                                                                .auditkey
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.model,
                                                                                it.auditkey,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "model[it.auditkey]",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        it.auditOptions,
                                                                        function (
                                                                          item,
                                                                          dex2
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: dex2,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    item.name,
                                                                                  value:
                                                                                    item.valCode
                                                                                      ? item.valCode
                                                                                      : item.code,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ])
                                                        : _vm._e(),
                                                      it.checkType == "autoTime"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t                        \t\t\t\t\t超过 \n\t                        \t\t\t\t\t"
                                                              ),
                                                              it.inpIsNum
                                                                ? _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "w90",
                                                                      attrs: {
                                                                        oninput:
                                                                          "value=value.replace(/[^0-9]/g,'')",
                                                                        placeholder:
                                                                          "请输入",
                                                                        maxlength:
                                                                          it.inpMaxlen
                                                                            ? it.inpMaxlen
                                                                            : 20,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .model[
                                                                            it
                                                                              .auditkey
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.model,
                                                                              it.auditkey,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "model[it.auditkey]",
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "el-input",
                                                                    {
                                                                      staticClass:
                                                                        "w90",
                                                                      attrs: {
                                                                        placeholder:
                                                                          "请输入",
                                                                        maxlength:
                                                                          it.inpMaxlen
                                                                            ? it.inpMaxlen
                                                                            : 20,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .model[
                                                                            it
                                                                              .auditkey
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.model,
                                                                              it.auditkey,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "model[it.auditkey]",
                                                                      },
                                                                    }
                                                                  ),
                                                              _vm._v(
                                                                "\n\t                        \t\t\t\t\t分钟，自动审核通过\n\t\t                            \t\t"
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      it.type == "lbRadioCader"
                        ? [
                            !it.isHide
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: it.name, prop: it.keys } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "line36",
                                        attrs: { disabled: it.disabled },
                                        on: {
                                          change: function ($event) {
                                            it.auditVal = []
                                            _vm.listenChange(it)
                                          },
                                        },
                                        model: {
                                          value: _vm.model[it.keys],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.model, it.keys, $$v)
                                          },
                                          expression: "model[it.keys]",
                                        },
                                      },
                                      [
                                        _vm._l(it.options, function (ck, dex3) {
                                          return _c(
                                            "el-radio",
                                            {
                                              key: dex3,
                                              attrs: { label: ck.code },
                                            },
                                            [_vm._v(_vm._s(ck.name))]
                                          )
                                        }),
                                        _vm.model[it.keys] &&
                                        _vm.model[it.keys] > 0
                                          ? [
                                              _vm.getlbRadioOptions(it).length >
                                              0
                                                ? [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        class: it.selectClass,
                                                        staticStyle: {
                                                          width: "280px",
                                                        },
                                                        attrs: {
                                                          filterable: "",
                                                          multiple: "",
                                                          "collapse-tags":
                                                            !it.notags,
                                                          "multiple-limit":
                                                            _vm.getlbRadioOptions(
                                                              it,
                                                              "limit1"
                                                            ),
                                                          placeholder: "请选择",
                                                        },
                                                        model: {
                                                          value: it.auditVal,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              it,
                                                              "auditVal",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "it.auditVal",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.getlbRadioOptions(
                                                          it
                                                        ),
                                                        function (item, dex2) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: dex2,
                                                              attrs: {
                                                                label:
                                                                  item.name,
                                                                disabled:
                                                                  item.disabled,
                                                                value:
                                                                  item.valCode
                                                                    ? item.valCode
                                                                    : item.code,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      it.type == "textarea"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _c("el-input", {
                                  ref: it.keys,
                                  refInFor: true,
                                  attrs: {
                                    type: "textarea",
                                    maxlength: it.maxlen ? it.maxlen : 255,
                                    "show-word-limit": "",
                                    disabled: it.disabled,
                                    autosize: it.autosize
                                      ? it.autosize
                                      : { minRows: 2, maxRows: 3 },
                                    placeholder: "请填写" + it.name,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.listenBlur(it)
                                    },
                                  },
                                  model: {
                                    value: _vm.model[it.keys],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, it.keys, $$v)
                                    },
                                    expression: "model[it.keys]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      it.type == "imgVideo"
                        ? [
                            it.formItem
                              ? _c(
                                  "el-form-item",
                                  {
                                    class: { hideLabel: it.labelHide },
                                    attrs: { label: it.name, prop: it.keys },
                                  },
                                  [
                                    _c("div", { staticClass: "t_row" }, [
                                      _c("div", { staticClass: "tr_name" }, [
                                        it.tips
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "tipTx",
                                                style: { left: it.tipleft },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(it.tips),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "tr_val" },
                                        [
                                          _c("TImgVideo", {
                                            attrs: {
                                              allowAdd: it.allowAdd,
                                              itWidth: it.imgWidth,
                                              itHeight: it.imgHeight,
                                              model: _vm.model[it.keys],
                                              maxlimt: it.maxlimt
                                                ? it.maxlimt
                                                : 9,
                                            },
                                          }),
                                          !it.allowAdd &&
                                          _vm.model[it.keys] &&
                                          _vm.model[it.keys].length < 1
                                            ? _c("span", [_vm._v("-")])
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("div", { staticClass: "t_row" }, [
                                  _c("div", { staticClass: "tr_name" }, [
                                    _vm._v(
                                      _vm._s(it.name) +
                                        " \n                        \t"
                                    ),
                                    it.tips
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "tipTx",
                                            style: { left: it.tipleft },
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(it.tips),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "tr_val" },
                                    [
                                      _c("TImgVideo", {
                                        attrs: {
                                          allowAdd: it.allowAdd,
                                          itWidth: it.imgWidth,
                                          itHeight: it.imgHeight,
                                          model: _vm.model[it.keys],
                                          maxlimt: it.maxlimt ? it.maxlimt : 9,
                                        },
                                      }),
                                      !it.allowAdd &&
                                      _vm.model[it.keys] &&
                                      _vm.model[it.keys].length < 1
                                        ? _c("span", [_vm._v("-")])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      it.type == "slot"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                class: { hideLabel: it.labelHide },
                                attrs: { label: it.name, prop: it.keys },
                              },
                              [
                                _vm._t(it.slotName, null, {
                                  $index: dex,
                                  current: it,
                                }),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }