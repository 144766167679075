import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 获取分组列表
 * @param params
 */
export function getGroupList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/group/list',
    method: 'get',
    params,
  });
}

/**
 * 保存分组
 * @param params
 */
export function groupSave(params) {
  return request({
    url: envInfo.bgApp.biPath + '/group/save',
    method: 'post',
    data: params,
  });
}

/**
 * 获取分组列表和分组下的卡片
 * @param params
 */
export function getGroupAndCards(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/preList',
    method: 'get',
    params,
  });
}

/**
 * 移动至分组
 * @param params
 *  cardId 指标ID
    groupId 移动至分组的分组ID
 */
export function moveToGroup(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/moveGroup',
    method: 'post',
    data: params,
  });
}

/**
 * 移动至最前
 * @param params
 *  cardId 指标ID
    groupId 移动至分组的分组ID
 */
export function moveToTop(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/sortTop',
    method: 'post',
    data: params,
  });
}

/**
 * 重命名
 * @param params
 *  cardId 
    cardName 
    cardStatus
 */
export function cardRename(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/rename',
    method: 'post',
    data: params,
  });
}

/**
 * 修改封面
 * @param params
    cardId 指标ID
    coverPicture 封面图片文件ID
    cardStatus 指标状态
 */
export function updateCoverPicture(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/updateCoverPicture',
    method: 'post',
    data: params,
  });
}

/**
 * 删除卡片
 * @param params
 cardId 指标ID
 */
export function deleteCard(cardId) {
  return request({
    url: envInfo.bgApp.biPath + '/card/delete/' + cardId,
    method: 'delete',
  });
}

/**
 * 设置共享
 * @param params
    cardId
    isShared Y or N
 */
export function setCardShareState(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/updateSharedStatus',
    method: 'post',
    data: params,
  });
}

/**
 * 共享指标列表
 */
export function getSharedList() {
  return request({
    url: envInfo.bgApp.biPath + '/card/sharedList',
    method: 'get',
  });
}

/**
 * 通过cardId获取卡片详情
 * cardId=
 */
export function getCardDetailById(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/findOne',
    method: 'get',
    params,
  });
}

/**
 * 通过cardId获取卡片详情
 * cardId=
 */
export function getSharedDetailById(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/shared/findOne',
    method: 'get',
    params,
  });
}

/**
 * 保存、更新卡片
 * cardId - 带Id更新，不带ID新增
 * cardStatus必填 - 状态:PUBLISH：发布，DRAFT：草稿
 */
export function cardSave(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/saveCard',
    method: 'post',
    data: params,
  });
}

/**
 * 获取发布记录
 * cardId
 */
export function getPublishRecord(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/publishRecord',
    method: 'get',
    params,
  });
}

/**
 * 获取指标指定版本的详细数据
 * cardId
 * versionId
 */
export function getRecordVersion(params) {
  return request({
    url: envInfo.bgApp.biPath + '/card/recordVersion',
    method: 'get',
    params,
  });
}

/**
    
    template/moveType 移动模版类型
 */

/**
 * 获取模版列表 - 有权限过滤
 */
export function getTemplateList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/list',
    method: 'get',
    params,
  });
}

/**
 * 获取模版列表 - 无权限过滤
 */
export function getTemplateManageList(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/manageList',
    method: 'get',
    params,
  });
}

/**
 * 获取模版列表
 * templateId
 */
export function getTemplateDetailById(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/details',
    method: 'get',
    params,
  });
}

/**
 * 设置为默认模版
 * templateId
 */
export function setDefaultTemplate(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/settingDefault',
    method: 'post',
    data: params,
  });
}

/**
 * 保存模板
 *
 */
export function saveTemplate(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/saveTemplate',
    method: 'post',
    data: params,
  });
}

/**
 * 模板修改封面
 *
 */
export function changeTemplateCover(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/updateCoverPicture',
    method: 'post',
    data: params,
  });
}

/**
 * 模板重命名
 *
 */
export function templateRename(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/rename',
    method: 'post',
    data: params,
  });
}

/**
 * 模板置顶
 *
 */
export function templateSortTop(params) {
  return request({
    url: envInfo.bgApp.biPath + '/template/sortTop',
    method: 'post',
    data: params,
  });
}

/**
 * 模版删除
 * templateId
 */
export function templateDelete(templateId) {
  return request({
    url: envInfo.bgApp.biPath + `/template/delete/${templateId}`,
    method: 'delete',
  });
}

/**
 *  查询用户自定义配置列表
 *
 */
export function findUserCustomList(params) {
  return request({
    url: envInfo.bgApp.tenantPath + '/userCustom/findUserCustomList',
    method: 'get',
    params,
  });
}
