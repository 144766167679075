var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.nodes && _vm.item.nodes.length > 0
    ? _c(
        "el-submenu",
        {
          key: _vm.item.sitemapId,
          staticClass: "nest-menu",
          attrs: { index: _vm.item.sitemapId + "" },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("item", {
                attrs: {
                  icon: _vm.item.icon,
                  title: _vm.generateTitle(_vm.item.displayText),
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.item.nodes, function (child) {
            return _c("Sidemenu", {
              key: child.sitemapId,
              attrs: { item: child },
            })
          }),
        ],
        2
      )
    : _c(
        "app-link",
        {
          key: _vm.item.sitemapId,
          attrs: { to: _vm.item.href ? _vm.item.href : "" },
        },
        [
          _c(
            "el-menu-item",
            { attrs: { index: _vm.item.href } },
            [
              _c("item", {
                attrs: {
                  icon: _vm.item.icon,
                  title: _vm.generateTitle(_vm.item.displayText),
                },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }