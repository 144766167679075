var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "O-addOrEdit" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "diyModel",
          attrs: {
            title: _vm.orderId ? "修改工单" : "新建工单",
            visible: _vm.$store.state.app.orderAddEdit.open,
            "close-on-click-modal": false,
            width: "728px",
            "before-close": _vm.closeMypup,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.$store.state.app.orderAddEdit, "open", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading",
                },
              ],
            },
            [
              _c(
                "div",
                { ref: "formMain", staticClass: "formMain" },
                [
                  _c("TForm", {
                    ref: "htmlFrom",
                    staticClass: "m_main",
                    attrs: {
                      lableRow: "",
                      autoCheck: true,
                      labelPosition: "left",
                      model: _vm.htmlDatas,
                      formlist: _vm.formlist,
                      "label-width": "88px",
                      rowGutter: 16,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btmBtn" },
                [
                  _c(
                    "el-button",
                    { staticClass: "searchbtn", on: { click: _vm.closeMypup } },
                    [_vm._v(" 取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isHttping },
                      on: { click: _vm.validateFroms },
                    },
                    [_vm._v(" 提交")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }