<template>
  <div>
    <app-link v-if="!item.nodes" :to="item.href ? item.href : ''">
      <el-menu-item :index="item.href">
        <item :icon="item.icon" :title="generateTitle(item.displayText)" />
      </el-menu-item>
    </app-link>
    <!-- <el-menu-item v-else :index="item.sitemapId + ''">
      <item :icon="item.icon" :title="generateTitle(item.displayText)" />
    </el-menu-item>-->
    <el-submenu v-else :index="item.sitemapId + ''">
      <template slot="title">
        <item :icon="item.icon" :title="generateTitle(item.displayText)" />
      </template>

      <template v-for="child in item.nodes">
        <sidebar-item
          v-if="child.nodes && child.nodes.length > 0"
          :is-nest="true"
          :item="child"
          :key="child.sitemapId"
          class="nest-menu"
        />
        <app-link
          v-else
          :to="child.href ? child.href : ''"
          :key="child.sitemapId"
        >
          <el-menu-item :index="child.href">
            <item
              :icon="child.icon"
              :title="generateTitle(child.displayText)"
            />
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>
</template>
<script>
import AppLink from "../Sidebar/Link";
import Item from "../Sidebar/Item";
import { generateTitle } from "@/utils/i18n";
import SidebarItem from "../Sidebar/SidebarItem";
export default {
  name: "navbar",
  components: { Item, AppLink, SidebarItem },
  props: {
    // 菜单节点
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    generateTitle,
  },
};
</script>
<style lang="scss" scoped>
div {
  display: inline-block;
}
</style>