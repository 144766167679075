var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainBox", class: _vm.mClass }, [
    _c(
      "div",
      {
        staticClass: "m-body",
        class: { padding0: _vm.noPadding },
        on: { scroll: _vm.listenScroll },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }