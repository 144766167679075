<template>
  <el-submenu
    v-if="item.nodes&&item.nodes.length>0"
    :key="item.sitemapId"
    :index="item.sitemapId+''"
    class="nest-menu"
  >
    <template slot="title">
      <item :icon="item.icon" :title="generateTitle(item.displayText)" />
    </template>
    <!--如果二级菜单有子菜单,遍历子菜单 -->
    <Sidemenu v-for="child in item.nodes" :key="child.sitemapId" :item="child" />
  </el-submenu>
  <app-link v-else :to="item.href ? item.href : ''" :key="item.sitemapId">
    <el-menu-item :index="item.href">
      <item :icon="item.icon" :title="generateTitle(item.displayText)" />
    </el-menu-item>
  </app-link>
</template>
<script>
import AppLink from "../Sidebar/Link";
import Item from "../Sidebar/Item";
import { generateTitle } from "@/utils/i18n";
export default {
  name: "Sidemenu",
  components: { Item, AppLink },
  props: {
    // 菜单节点
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    generateTitle
  }
};
</script>
