//消息提示国际化
export default {
  message: {
    // 切换语言成功
    switchLangSuccess: '切换语言成功',
    // 切换布局大小成功
    switchSizeSuccess: '切换布局大小成功',
    saveSuccess: '保存成功',
    saveFail: '保存失败',
    deleteSuccess: '删除成功',
    reportSuccess: '上报成功',
    moveSuccess: '迁移成功',
    openSuccess: '开启成功',
    suspendSuccess: '暂停成功',
    deleteConfirm: '确定要删除吗？',
    reportConfirm: '确定要立即上报吗？',
    CancelConfirm: '确定要取消隔离吗？',
    deleteAllConfirm: '确定要删除所有吗？',
    destroyConfirm: '确定要彻底删除吗？删除后无法恢复!',
    destroyAllConfirm: '确定要彻底删除所有吗？删除后无法恢复!',
    openConfirm: '确认开启吗？',
    editConfirm: '确定修改吗？',
    suspendConfirm: '确认暂停吗',
    resetConfirm: '确定要重置吗？',
    validSuccess: '验证成功',
    validFail: '验证失败',
    unfastenConfirm: '请确认对该账号执行解封？',
    sealConfirm: '确认封禁该账号吗？',
    operationSuccess: '操作成功',
    copySuccess: '已复制到剪贴板中',
    operationFailed: '操作失败',
    uploadSuccess: '上传成功',
    uploadFail: '上传失败',
    updateSuccess: '修改成功',
    updateFail: '修改失败',
    unknowException: '未知异常',
    errorPageTip: '抱歉，您访问的页面偷偷溜走了~~',
    invalidCaptcha: '请输入有效的验证码',
    commaTips: '不可以输入逗号!',
    submitSuccess: '提交申请成功',
    reSubmitSuccess: '重新提交成功',
    approveSuccess: '审批成功',
    moveConfirm: '确定要迁移设备吗？',
  },
  // 校验信息
  validate: {
    required: '此项必填',
    incorrectUrl: '不正确的URL',
    invalidEmail: '无效的邮箱',
  },
};
