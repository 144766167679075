var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          !_vm.item.nodes
            ? [
                _c(
                  "app-link",
                  { attrs: { to: _vm.item.href ? _vm.item.href : "" } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: { index: _vm.item.href },
                      },
                      [
                        _c("item", {
                          attrs: {
                            icon: _vm.item.icon,
                            title: _vm.generateTitle(_vm.item.displayText),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "el-submenu",
                { attrs: { index: _vm.item.sitemapId + "" } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("item", {
                        attrs: {
                          icon: _vm.item.icon,
                          title: _vm.generateTitle(_vm.item.displayText),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.item.nodes, function (child) {
                    return [
                      child.nodes && child.nodes.length > 0
                        ? _c("sidebar-item", {
                            key: child.sitemapId,
                            staticClass: "nest-menu",
                            attrs: { "is-nest": true, item: child },
                          })
                        : _c(
                            "app-link",
                            {
                              key: child.sitemapId,
                              attrs: { to: child.href ? child.href : "" },
                            },
                            [
                              _c(
                                "el-menu-item",
                                { attrs: { index: child.href } },
                                [
                                  _c("item", {
                                    attrs: {
                                      icon: child.icon,
                                      title: _vm.generateTitle(
                                        child.displayText
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }