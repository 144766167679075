var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Y-imgsVedio" },
    [
      _c(
        "viewer",
        { attrs: { images: _vm.model } },
        [
          _vm._l(_vm.model, function (src, index) {
            return [
              _c(
                "div",
                { staticClass: "itWarp", style: { width: _vm.itWidth } },
                [
                  _vm.allowAdd
                    ? _c("i", {
                        staticClass: "delIt el-icon-delete",
                        on: {
                          click: function ($event) {
                            return _vm.delItImg(index)
                          },
                        },
                      })
                    : _vm._e(),
                  !src.fileType ||
                  (src.fileType && src.fileType.includes("image"))
                    ? _c("img", {
                        key: index,
                        staticClass: "itImg",
                        style: { height: _vm.itHeight },
                        attrs: { src: _vm.getImageUrl(src.fileId) },
                      })
                    : _vm._e(),
                  src.fileType && src.fileType.includes("video")
                    ? _c(
                        "span",
                        {
                          staticClass: "itImg itVedio",
                          on: {
                            click: function ($event) {
                              return _vm.toPrevVedio(src)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "vimg",
                            style: { height: _vm.itHeight },
                            attrs: { src: _vm.getImageUrl(src.thumbnailId) },
                          }),
                          _c("i", {
                            staticClass: "playIcon el-icon-video-play",
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          }),
          _vm.allowAdd && _vm.maxlimt > _vm.model.length
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.uploading,
                      expression: "uploading",
                    },
                  ],
                  staticClass: "diyAdd",
                  style: { width: _vm.itWidth, height: _vm.itHeight },
                },
                [
                  _c("div", { staticClass: "add_icon" }, [_vm._v("+")]),
                  _c("input", {
                    staticClass: "hideInp",
                    attrs: { type: "file", disabled: _vm.uploading },
                    on: {
                      change: function ($event) {
                        return _vm.fileChecked($event.target.files)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }