import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import enLocale from './en/en-US';
import rugeLocale from './zh/ruge-Zh-CN';
import iotLocale from './zh/iot-Zh-CN';
import vlinkLocale from './zh/vlink-Zh-CN';
import tipLocale from './zh/tip-Zh-CN';
import prmLocale from './zh/prm-Zh-CN';
import rworkzh from './zh/rwork-Zh-CN.js';
import rworkus from './en/rwork-US.js';
import cmommonsCn from './zh/cmommons-CN.js';
Vue.use(VueI18n);
console.log('iotLocale', iotLocale);
const messages = {
  'en-US': {
    ...enLocale,
    ...rworkus,
    ...elementEnLocale,
  },
  'zh-CN': {
    ...cmommonsCn,
    ...vlinkLocale,
    ...tipLocale,
    ...prmLocale,
    ...elementZhLocale,
    ...iotLocale,
    ...rworkzh,
    ...rugeLocale,
  },
};

const i18n = new VueI18n({
  // set locale
  // options: en-US | zh-CN 默认中文
  locale: Cookies.get('language') || 'zh-CN',
  // set locale messages
  messages,
});

export default i18n;
