<template>
	<div class="Y-imgsVedio">
		<viewer :images="model">
			<template v-for="(src, index) in model">
				<div class="itWarp" :style="{width:itWidth}">
					<i v-if="allowAdd" class="delIt el-icon-delete" @click="delItImg(index)"></i>
					<img :style="{height:itHeight}"  v-if="!src.fileType ||(src.fileType && src.fileType.includes('image'))" class="itImg" :src="getImageUrl(src.fileId)" :key="index" />
					<span class="itImg itVedio" v-if="src.fileType && src.fileType.includes('video')" @click="toPrevVedio(src)">
						<img class="vimg" :style="{height:itHeight}" :src="getImageUrl(src.thumbnailId)" />
						<i  class="playIcon el-icon-video-play" ></i>
					</span>
				</div>
			</template>
			<div v-if="allowAdd&&maxlimt>model.length" class="diyAdd" :style="{width:itWidth,height:itHeight}" v-loading="uploading">
			<div class="add_icon">+</div>
			<input  type="file" :disabled="uploading"  @change="fileChecked($event.target.files)" class="hideInp"/>
		</div>
		</viewer>
	</div>

</template>

<script>
	import { envInfo } from "@/constants/envInfo";
	import { getVedioSrc } from '@/utils/download.js';
	export default {
		props: {
			itWidth:{
				type:String,
				default:'100%'
			},
			itHeight:{
				type:String,
				default:'100%'
			},
			allowAdd:{
				type:Boolean,
				default:false
			},
			model: {
				type: Array,
				default:()=>{
					return []
				}
			},
			maxlimt: {
				type: Number,
				default:9
			},
		},
		components: {},
		data() {
			return {
				uploading:false,
				headers:{},
				fileList:[
					{
						name: '1.jpeg', 
						url: '/saascloud/saas/archive/param/archive/download?dlType=DefaultDownload&fType=image&fi=31720'
					},
				],
			}
		},

		computed: {},
		watch: {
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
				}
			},

		},
		created() {
			this.headers['auth-token'] =this.$store.getters.baseAuthToken;
//			this.$store.getters.baseAuthToken;
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			delItImg(dex){
				this.model.splice(dex,1);
			},
			getImageUrl(fileId) {
				let rs='';
				if(fileId) {
					rs=envInfo.bgApp.archivePath +"/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +fileId;
				}
				return rs;
			},
			toPrevVedio(it) {
				let src = getVedioSrc(it.fileId)
				//		http://localhost:8080/saascloud/saas/archive/param/archive/download?dlType=DefaultDownload&fi=6584
				this.$store.commit('setVedioSrc', src);
			},
			handlePictureCardPreview(file) {
		        
	     	},
			async fileChecked(files, type) {
				let myFile=files.item(0);
				if(myFile) {
					if(myFile.size>10*1024*1024){
						this.$message('文件大小不能超过10M');
						return;
					}
					
					let formData=new FormData();
					formData.append('file',myFile);
					if(myFile.type=='video/mp4'){
						let res=await this.common.asyncImgChecked(myFile)
						formData.append('file',res.boldFile);
					}
					this.uploading=true
					let backRes = await this.ApiHttp('/archive/param/archive/upload?ulType=DefaultUpload',formData,'post',true);
					setTimeout(()=>{
						this.uploading=false;
					},500)
					if(backRes&&backRes.length>0){
						let it={
							fileId: backRes[0].fileId,
							contentType: backRes[0].contentType,
							fileType: backRes[0].contentType,
							thumbnailId: null,
						}
						if(backRes[1]&&myFile.type=='video/mp4'){
							it.thumbnailId=backRes[1].fileId
						}
						this.model.push(it)
					}
				}
			},
			tipsUpload(){
				this.$message({message:'上传失败',type:'error'})
			},
     	 	handleRemove(file, fileList) {
	      	},
		},

	}
</script>

<style lang="scss" scoped>
.Y-imgsVedio{
	padding-top: 8px;

.itWarp{
    vertical-align: middle;
    display: inline-block;
    margin:3px 6px 3px 0;
    position: relative;
    .delIt{
	    position: absolute;
	    right: 4px;
	    color: red;
	    top: 8px;
	    cursor: pointer;
	    display:none;
	    z-index: 999;
    }
}
.itWarp:hover .delIt{
	display: block;
}
.itImg{
	width: 100%;
    vertical-align: middle;
    border-radius: 3px;
}
.itVedio{
	position: relative;
	display: inline-block;
	text-align: center;
	font-size: 80px;
	border: 1px solid #CCCCCC;
	.vimg{
		width: 100%;
		height: 100%;
	}
	.playIcon{
		position: absolute;
	    top: 0;
	    left: 0;
	    font-size: 48px;
	    color: white;
	    width: 100%;
	    height: 100%;
	    padding-top: 20%;
	}
}
.diyAdd{
	display: inline-block;
	position: relative;
	vertical-align: middle;
	border-radius: 3px;
	border: 1px solid #1A4CEC;
	.add_icon{
		display: inline-block;
		font-size: 40px;
		color: #1A4CEC;
		position: absolute;
		top: 1px;
		left: 50%;
		top: 50%;
		text-align: center;
		width: 32px;
		height: 28px;
		line-height: 28px;
		margin-left: -16px;
		margin-top: -14px;
	}
	
	.hideInp{
		width: 100%;
		height: 100%;
		cursor: pointer;
		position: absolute;
		left: 0;
		opacity: 0;
		top: 0;
	}
}
.diyAdd:hover{
	background: #d7e0ff;
}
}
</style>