import Vue from 'vue';
import Router from 'vue-router';

// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

// 解决路由replace的时候如果是当前路由控制台报错问题
const originalReplace = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
Vue.use(Router);

/* Layout */
import Layout from './views/ruge/layout/Layout';

import rugeRouter from './routers/ruge';
import businessRouter from './routers/business';
import defaultRouter from './routers/default';
import legoRouter from './routers/lego/index.js'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    permissions: []              允许访问的权限列表(appName$Service$resourceCode$operationCode)
  }
 * 不需要动态判断权限的路由，如登录页、404、等通用页面
 **/
export const constantRouterMap = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/ruge/redirect/index'),
      },
    ],
  },
  {
    path: '/login',
    // component: () => import('@/views/ruge/login/indexNew'),
    // component: () => import('@/views/ruge/login/indexComponent'),
    component: () => import('@/views/ruge/login/bms'),
    hidden: true,
  },
  {
    path: '/oa',
    component: () => import('@/views/ruge/login/oa/index.vue'),
    hidden: true,
  },
  {
    path: '/forget',
    component: () => import('@/views/ruge/forget/index'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/ruge/errorPage/404'),
    hidden: true,
  },
  {
    path: '/403',
    component: () => import('@/views/ruge/errorPage/403'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home/index',
    name: 'RootHome',
    hidden: true,
    children: [
      // {
      //   path: 'personal',
      //   component: () => import('@/views/ruge/personal/indexNew'),
      // },
      // {
      //   path: '/personal',
      //   redirect: '/personal/basic',
      //   name: 'personal',
      //   hidden: true,
      //   children: [
      {
        path: '/personal',
        redirect: '/personal/basic',
        name: 'personal',
        hidden: true,
      },
      {
        path: '/personal/basic',
        name: 'personalBasic',
        component: () => import('@/views/ruge/personal/basic.vue'),
      },
      {
        path: '/personal/common',
        name: 'personalCommon',
        component: () => import('@/views/ruge/personal/common.vue'),
      },
      //   ],
      // },
    ],
  },
];

/**
 * 需要动态判断权限并通过 addRouters 动态添加的页面
 */
export const asyncRouterMap = [
  ...rugeRouter,
  ...businessRouter,
  ...defaultRouter,
  ...legoRouter
];

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});
