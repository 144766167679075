var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "div",
        {
          staticClass: "collapse-container",
          class: _vm.isCollapse ? "collapse-close" : "collapse-open",
          on: { click: _vm.collapseHandler },
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                "popper-class": "tooltipsClassName",
                effect: "dark",
                placement: "right",
              },
            },
            [
              _c("span", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(_vm._s(_vm.isCollapse ? "展开" : "收缩")),
              ]),
              _vm.isCollapse
                ? _c("i", { staticClass: "el-icon-arrow-right collapse-icon" })
                : _c("i", { staticClass: "el-icon-arrow-left collapse-icon" }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-menu",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listenSitemap,
              expression: "listenSitemap",
            },
          ],
          attrs: {
            "show-timeout": 100,
            "default-active": _vm.curActive,
            collapse: _vm.isCollapse,
            "background-color": _vm.variables.menuBg,
            "text-color": _vm.variables.menuText,
            "active-text-color": _vm.variables.menuActiveText,
            "element-loading-background": "rgba(48, 65, 86, 0.5)",
            mode: "vertical",
          },
        },
        [
          _c("LogoToggle", {
            attrs: { showStatus: _vm.isCollapse, logoMsg: _vm.logoMsg },
          }),
          _vm._l(_vm.sitemapList, function (sitemap) {
            return _c("sidebar-item", {
              key: sitemap.sitemapId,
              attrs: { item: sitemap },
            })
          }),
          _c("div", { staticClass: "divider-border" }),
          _c(
            "app-link",
            { attrs: { to: "/personal" } },
            [
              _c(
                "el-menu-item",
                {
                  staticClass: "submenu-title-noDropdown",
                  attrs: { index: "/personal" },
                },
                [
                  _c("item", {
                    attrs: {
                      icon: "home",
                      title: _vm.generateTitle(
                        "zh-CN=设置中心;en-US=Personal Center"
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }