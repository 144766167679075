var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "rlink-button-component",
      on: { click: _vm.clickHandler },
    },
    [
      _c(
        "div",
        {
          class: [
            "button-container",
            `button-component-${_vm.type}`,
            _vm.mini && "mini",
            _vm.disabled && "button-disabled",
            _vm.plain && "button-plain",
          ],
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }