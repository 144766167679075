const lego = {
  state: {
    functionList: [],
    bannerList: [],
    notifyList: [],
    newsList: [],
  },
  mutations: {
    LEGO_FUNCTION_LIST: (state, list) => {
      state.functionList = list;
    },
    LEGO_BANNER_LIST: (state, list) => {
      state.bannerList = list;
    },
    LEGO_NOTIFY_LIST: (state, list) => {
      state.notifyList = list;
    },
    LEGO_NEWS_LIST: (state, list) => {
      state.newsList = list;
    },
  },
};
export default lego;
