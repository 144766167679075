<template>
  <div>
    <LogoToggle
      :showStatus="isCollapse"
      :logoMsg="logoMsg"
      class="logo"
    ></LogoToggle>
    <el-menu
      class="navbar"
      :default-active="curActive"
      :background-color="variables.menuBg"
      :text-color="variables.menuText"
      :active-text-color="variables.topMenuActiveText"
      mode="horizontal"
      @select="handleSelect"
    >
      <navmenu
        v-for="sitemap in sitemapListCustomer"
        :key="sitemap.sitemapId"
        :item="sitemap"
        class="navmenu"
      />

      <!-- 设置中心菜单 -->
      <!-- <el-submenu class="navmenu">
        <template slot="title">
          <item
            :title="generateTitle('zh-CN=设置中心;en-US=Personal Center')"
          />
        </template>

        <app-link to="/personal/basic">
          <el-menu-item index="/personal/basic">
            <item
              :title="generateTitle('zh-CN=基本资料;en-US=basic setting')"
            />
          </el-menu-item>
        </app-link>

        <app-link to="/personal/common">
          <el-menu-item index="/personal/common">
            <item :title="generateTitle('zh-CN=通用;en-US=common')" />
          </el-menu-item>
        </app-link>
      </el-submenu> -->
    </el-menu>
    <rightmenu class="right-menu" />
  </div>
</template>
<script>
import { generateTitle } from "@/utils/i18n";
import { mapGetters } from "vuex";
import navmenu from "./navmenu";
import rightmenu from "./rightmenu";
import variables from "@/styles/variables.scss";
import LogoToggle from "@/components/LogoToggle";
import AppLink from "../Sidebar/Link";
import Item from "../Sidebar/Item";
export default {
  name: "NavbarTop",
  data() {
    return {
      currentThemeColor: "#ffffff",
      sitemapListCustomer: [],
      // 传入 logo 文件名
      logoMsg: {
        logoFileName: "defaultLogo",
        projectName: "",
      },
      curActive: decodeURIComponent(this.$route.fullPath),
      rugeRouter: require("@/routers/ruge"),
    };
  },
  components: { navmenu, LogoToggle, rightmenu, AppLink, Item },
  methods: {
    generateTitle,
    findDetailMatched(sitemap, path) {
      let matched;
      //   过滤iot的路由
      sitemap = sitemap.filter((item) => item.path === "/iot");
      let find = function (sitemap, path) {
        for (let item of sitemap) {
          if (
            item.path &&
            "/iot/" + item.path === path // 优先做路径完全匹配
            // || (item.href.indexOf('?') !== -1 && item.href.split('?')[0] === path) // 当路径中有问号带参时，忽略参数做匹配
          ) {
            matched = item.meta.goBackRouter;
            break;
          }
          let nodes = item.children;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
    handleSelect(key) {
      //将点击的一级菜单的id存入vuex
      this.$store.commit("SET_SELECT_MENU_ID", key + "");
    },
  },
  watch: {
    $route: {
      handler(val) {
        if (val.meta.detail) {
          let tempName = this.findDetailMatched(
            this.rugeRouter.default,
            val.path
          );
          console.log("tempName", tempName);
          this.curActive = tempName;
        } else {
          this.curActive = val.path;
        }
        console.log("this.curActive", this.curActive);
      },
      immediate: true,
    },
    sitemapList: {
      handler(value) {
        console.log("value", value);
        const personalCenterMenu = [
          {
            displayText: "zh-CN=设置中心;en-US=personal center",
            nodeLevel: 1,
            href: null,
            nodes: [
              {
                href: "/personal/basic",
                sitemapCode: "default-code-1",
                displayText: "zh-CN=基本资料;en-US=basic setting",
                nodeLevel: 2,
                activeNodes: false,
              },
              {
                href: "/personal/common",
                sitemapCode: "default-code-2",
                displayText: "zh-CN=通用;en-US=common setting",
                nodeLevel: 2,
                activeNodes: false,
              },
            ],
          },
        ];
        this.sitemapListCustomer = this.sitemapList.concat(personalCenterMenu);
        console.log("this.sitemapListCustomer", this.sitemapListCustomer);
      },
      immediate: true,
      deep: true,
    },
    theme: {
      handler(theme) {
        this.currentThemeColor = ["#ffffff", "#2a61ff", "#1d2d36"][theme - 1];
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["sitemapList", "sidebar", "selectMenuId", "theme"]),
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
 <style  lang="scss" scoped>
div {
  display: inline-block;
}
::v-deep .el-menu--horizontal .el-menu-item:hover {
  background: #e6f4fc !important;
  color: #000000 !important;
}
</style>