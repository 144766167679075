<template>
  <div :class="classObj" class="app-wrapper">
    <prevVedio />
    <!-- <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    /> -->

    <!-- 顶部一级菜单 -->
    <!-- <div v-if="displayLayout === 2">
      <NavbarTop v-if="!hideLayout" class="header-container" />
      <app-main />
    </div> -->

    <!-- 顶部所有菜单 -->
    <div v-if="displayLayout === 3">
      <NavbarTop v-if="!hideLayout" class="header-container" />
      <div class="bread-crumb-container">
        <Breadcrumb />
      </div>
      <div class="mainTop">
        <!-- <SidebarTop v-if="!hideLayout" class="sidebarTop" /> -->
        <div class="main">
          <NavMain class="navmain" />
          <app-main class="appmain" />
        </div>
      </div>
    </div>
    <!-- 左侧菜单 -->
    <div v-else class="layout-container">
      <!-- <sidebar v-if="!hideLayout" class="sidebar-container" /> -->
      <div
        class="side-bar-container"
        :style="{
          width: isCollapse ? '60px' : '250px',
        }"
        v-if="!hideLayout"
      >
        <SideBarNew />
      </div>
      <div
        class="layout-main-container"
        :style="{
          height: hideLayout && '100vh',
          width: hideLayout
            ? '100vw'
            : `calc(100vw - ${isCollapse ? 60 : 250}px)`,
        }"
      >
        <NavbarNew v-if="!hideLayout" />
        <app-main />
      </div>
    </div>
  </div>
</template>

<script>
import {
  // Navbar,
  NavbarNew,
  SideBarNew,
  // Sidebar,
  AppMain,
  SidebarTop,
  NavbarTop,
  NavMain,
} from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { getQueryParam } from "@/utils/global";
import { envInfo } from "@/constants/envInfo";
import Breadcrumb from "@/components/BreadcrumbNew";
import Cookies from "js-cookie";
import prevVedio from "@/components/YTable/prevVedio.vue";
import { checkSession } from "@/api/ruge/login.js";
import { mapGetters } from "vuex";
export default {
  name: "Layout",
  components: {
    NavbarNew,
    // Navbar,
    // Sidebar,
    AppMain,
    SidebarTop,
    NavbarTop,
    NavMain,
    Breadcrumb,
    prevVedio,
    SideBarNew,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      // 是否隐藏layout，当为true时，只显示主操作区域
      hideLayout: false,
      freshIntervalIns: null,
      freshStep: 1000 * 60 * 15,
      currentThemeColor: "#ffffff",
    };
  },
  computed: {
    ...mapGetters(["theme"]),
    sitemapList() {
      return this.$store.state.user.sitemapList;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    displayLayout() {
      return this.$store.state.app.displayLayout;
    },
    device() {
      return this.$store.state.app.device;
    },
    selectMenuId() {
      for (let item of this.sitemapList) {
        if (
          item.sitemapId == this.$store.state.app.selectMenuId &&
          item.nodes
        ) {
          return true;
        }
      }
      return false;
    },
    classObj() {
      return {
        hideLayout: this.hideLayout,
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        noActive: !this.selectMenuId,
        mobile: this.device === "mobile",
      };
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  beforeDestroy() {
    this.freshIntervalIns && window.clearInterval(this.freshIntervalIns);
  },
  created() {
    this.initFreshSession();
  },
  methods: {
    initFreshSession() {
      window.setInterval(() => {
        checkSession();
      }, this.freshStep);
    },
    handleClickOutside() {
      this.$store.dispatch("CloseSideBar", { withoutAnimation: false });
    },
  },
  watch: {
    //监听路由，点击设置中心时关闭侧边栏
    $route() {
      if (this.$route.path === envInfo.sitemap.personal.href) {
        this.$store.commit("SET_SELECT_MENU_ID", null);
      } else {
        for (let item of this.sitemapList) {
          if (item.href == this.$route.path) {
            this.$store.commit("SET_SELECT_MENU_ID", item.sitemapId + "");
          }
        }
      }
    },
    device: {
      handler(val) {
        // 设备变换时（视窗缩放）获取sidebarStatusBak，在缩放到desktop的时候还原之前的菜单
        const sidebarStatusBak = Cookies.get("sidebarStatusBak");
        if (val === "desktop" && sidebarStatusBak === "1") {
          this.$store.dispatch("OpenSideBar", { withoutAnimation: true });
        }
      },
    },
    theme: {
      handler(theme) {
        this.currentThemeColor = ["#ffffff", "#2a61ff", "#1d2d36"][theme - 1];
      },
      immediate: true,
    },
    "$route.query": {
      handler() {
        // 当前url中指定layout=hide,则隐藏layout，只显示主操作区域
        const urlParams = this.$route.query;
        this.hideLayout = urlParams.layout === "hide";
      },
      immediate: true,
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
  .layout-container {
    display: flex;
    .side-bar-container {
      transition: width 0.4s;
      width: 250px;
    }
    .layout-main-container {
      width: calc(100vw - 250px);
      transition: width 0.4s;
    }
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.bread-crumb-container {
  height: 56px;
  width: 100%;
  line-height: 56px;
}
</style>
