var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-menu" },
    [
      _vm.device !== "mobile"
        ? [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("navbar.screenfull"),
                  effect: "dark",
                  placement: "bottom",
                  "open-delay": _vm.openDelay,
                },
              },
              [_c("screenfull", { staticClass: "screenfull right-menu-item" })],
              1
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("personal.setting9"),
                  effect: "dark",
                  placement: "bottom",
                  "open-delay": _vm.openDelay,
                },
              },
              [
                _c("Information", {
                  staticClass: "information right-menu-item",
                }),
              ],
              1
            ),
            _vm.tenantList.length > 1
              ? [
                  _c("tenant-menu", {
                    staticClass: "international right-menu-item",
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "el-dropdown",
        { staticClass: "avatar-container right-menu-item" },
        [
          _c("div", { staticClass: "avatar-wrapper" }, [
            _c("img", {
              staticClass: "user-avatar",
              attrs: { src: _vm.avatarP, onerror: _vm.defaultAvatar },
            }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "router-link",
                { attrs: { to: "/personal" } },
                [
                  _c("el-dropdown-item", [
                    _c("i", { staticClass: "el-icon-user" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("home.personalCenter")) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
              _vm.isGuest
                ? _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.login },
                      },
                      [
                        _c("i", { staticClass: "el-icon-switch-button" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("login.logIn")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ])
                : _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [
                        _c("i", { staticClass: "el-icon-switch-button" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("navbar.logOut")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }