<template>
  <div class="right-menu">
    <template v-if="device !== 'mobile'">
      <!--
      <el-tooltip
        :content="$t('order.fill')"
        effect="dark"
        placement="bottom"
        :open-delay="openDelay"
      >
      </el-tooltip>
      -->
      <el-tooltip
        :content="$t('navbar.screenfull')"
        effect="dark"
        placement="bottom"
        :open-delay="openDelay"
      >
        <screenfull class="screenfull right-menu-item" />
      </el-tooltip>
      <el-tooltip
        :content="$t('personal.setting9')"
        effect="dark"
        placement="bottom"
        :open-delay="openDelay"
      >
        <Information class="information right-menu-item" />
      </el-tooltip>
      <!--
                <el-tooltip :content="$t('navbar.size.title')" effect="dark" placement="bottom" :open-delay="openDelay">
                    <size-select class="international right-menu-item"/>
                </el-tooltip>
      -->
      <!-- <lang-select class="international right-menu-item" /> -->
      <template v-if="tenantList.length > 1">
        <tenant-menu class="international right-menu-item" />
      </template>
    </template>
    <el-dropdown class="avatar-container right-menu-item">
      <div class="avatar-wrapper">
        <img :src="avatarP" :onerror="defaultAvatar" class="user-avatar" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <router-link to="/personal">
          <el-dropdown-item>
            <i class="el-icon-user"></i>
            {{ $t("home.personalCenter") }}
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item v-if="isGuest" divided>
          <span style="display: block" @click="login">
            <i class="el-icon-switch-button"></i>
            {{ $t("login.logIn") }}
          </span>
        </el-dropdown-item>
        <el-dropdown-item v-else divided>
          <span style="display: block" @click="logout">
            <i class="el-icon-switch-button"></i>
            {{ $t("navbar.logOut") }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import router from "@/router";
// import SizeSelect from '@/components/SizeSelect'
import LangSelect from "@/components/LangSelect";
import Screenfull from "@/components/Screenfull";
import Information from "@/components/Information";
import { envInfo } from "@/constants/envInfo";
import TenantMenu from "@/components/TenantMenu";
export default {
  data() {
    return {
      // 默认头像
      defaultAvatar:
        'this.src="' + require("@/assets/images/defaultAvatar.png") + '"',
      openDelay: 0,
    };
  },
  components: {
    // SizeSelect,
    LangSelect,
    TenantMenu,
    Screenfull,
    Information,
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "device",
      "userAccount",
      "isGuest",
      "avatar",
      "tenantList",
    ]),
    avatarP() {
      if (this.avatar) {
        return envInfo.bgApp.ssoPath + "/param/avatar?p=" + this.avatar;
      } else {
        return envInfo.bgApp.ssoPath + "/param/avatar?u=" + this.userAccount;
      }
    },
  },
  methods: {
    login() {
      // 跳转登录页面
      router.replace({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
      });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-left: 5px;
  position: relative;
  top: 2px;
  cursor: pointer;
}
</style>
