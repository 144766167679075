//IOT国际化
export default {
  //告警
  alarm: {
    details: '告警明细',
    faultTable: '故障告警列表',
    runTable: '运行告警列表',
    alarmType: '告警类型',
    runningAlarm: '运行时告警',
    faultAlarm: '故障告警',
    time: '告警时间',
    comment: '告警描述',
    faultConfirm: '故障确认',
    fireConfirm: '火警确认',
    misreportConfirm: '误报确认',
    equipmentIsolation: '设备隔离',
    confirmSuccess: '确认成功',
    tooHigh: '过高',
    tooLow: '过低',
    info: '告警',
    offline: '离线',
    fault: '故障',
    untreated: '未处理',
    handlerState: '处理状态',
    handlerTime: '处理时间',
    normalTime: '恢复时间',
    trend: '告警趋势',
    total: '总数',
    statistics: '告警统计',
    statisticsNum: '统计数量',
    strategy: '告警策略',
    sourceCode: '点位源标识',
    confirm: '确认',
    alarmConfirm: '告警确认',
    promptAlarm: '提示告警',
    commonlyAlarm: '一般告警',
    seriousAlarm: '严重告警',
    normal: '正常',
    alarmNotify: ',发生一条严重告警,告警状态:',
    recoveryNotify: ',严重告警已恢复,当前状态:',
    comfirmMsg: '确定要人工处理此告警吗?',
    isolateMsg: '确定要将设备隔离吗?',
    cancelIsolateMsg: '确定取消隔离吗?',
    isolateTime: '隔离时长',
    isolate: '隔离',
    cancelIsolate: '取消隔离',
    batchIsolate: '批量隔离',
    batchCancel: '批量取消',
    isolated: '已隔离',
    notIsolated: '未隔离',
    isolateShortcut: '隔离1小时',
  },
  //地图
  map: {
    manager: '地图管理',
    updateMap: '更换地图',
    loading: '加载中',
    uploadFile1: '将文件拖到此处，或',
    uploadFile2: '点击上传',
    coordinate: '位置坐标',
    deleteMapConfirm: '确定移除该地图设备么？',
    selectPosition: '请选择位置!',
    runningState: '运行状态',
    running: '运行',
    stop: '停止',
    display: '地图展示',
    alarm: '告警',
    normal: '正常',
    auto: '自动',
    manual: '手动',
    fault: '故障',
    isOpenConfirm: '确定打开吗？',
    isCloseConfirm: '确定关闭吗？',
    position: '选择位置',
    mapView: '地图模式',
    listView: '列表模式',
    scaleUp: '放大一级',
    scaleDown: '缩小一级',
    addDeviceTips: '双击设备图标，即可添加设备',
    removeDeviceTips: '拖拽设备图标至地图左侧或上侧边缘，即可移除设备',
  },
  iot: {
    tenantIndex: {
      gatewayTotal: '网关接入数',
      onlineGatewayTotal: '在线网关数',
      deviceTotal: '设备接入数',
      onlineDeviceTotal: '在线设备数',
      unhandledAlarmTotal: '待处理告警数',
      deviceProportion: '设备占比',
      deviceProportion1: '设备类型',
      deviceProportion2: '接入方式',
      alarmList: '告警看板',
    },
    position: {
      name: '空间',
      manager: '空间管理',
      subPosition: '上一级空间',
      code: '空间编码',
      label: '空间标签',
      desc: '描述',
      devicePosition: '空间位置',
      project: '所属项目',
      selectProject: '选择项目',
      prompt: '请选择或添加一个空间',
    },
    project: {
      current: '当前项目',
      name: '项目',
      code: '编码',
      label: '项目标签',
      desc: '描述',
      list: '项目列表',
      add: '添加项目',
      edit: '编辑项目',
      city: '所在城市',
      address: '详细地址',
      floorSpace: '占地面积(m²)',
      location: '经纬度',
      pleaseSelectCity: '请选择城市!',
      locationNotExist: '经纬度不存在，请设置经纬度!',
    },
    device: {
      level: '设备级别',
      advanced: '高级查找',
      number: '设备编号',
      select: '选择设备',
      name: '设备名称',
      position: '空间位置',
      type: '设备类型',
      label: '设备标签',
      deviceEUI: '设备EUI',
      protocolMeta: '协议元',
      state: '状态',
      state0: '离线',
      state1: '在线',
      state3: '未激活',
      issuedState: '下发状态',
      issuedState0: '未下发',
      issuedState1: '已下发',
      issuedState2: '有更新',
      issuedState3: '下发失败',
      add: '添加设备',
      edit: '编辑设备',
      copy: '复制设备',
      deviceManager: '设备管理',
      monitorManager: '监控点管理',
      monitor: '监控点',
      monitorPath: '控制路径',
      monitorCode: '监控点',
      startIcon: '运行图标',
      stopIcon: '停止图标',
      addMonitor: '添加监控点',
      editMonitor: '编辑监控点',
      addIcon: '添加图标',
      editIcon: '编辑图标',
      rtp: '视频地址',
      userName: '用户名',
      password: '密码',
      proxyIP: '代理IP',
      proxyPort: '代理端口',
      deviceIP: '设备IP',
      devicePort: 'PTZ端口',
      channelNum: '通道号',
      editCamera: '编辑视频',
      addCamera: '新增视频',
      viewShadow: '查看运行数据(设备影子)',
      viewHistory: '查看历史数据',
      propertyName: '属性名称',
      currentValue: '实时值',
      expectedValue: '预期值',
      priority: '优先级',
      presetTime: '预设时间',
      cancelPreset: '取消预设',
      message: {
        unOpenIOT: '您还没有开通IOT模块，请先开通后再配置监控点',
      },
      protocolMetaTips:
        '请输入设备驱动中使用到的参数或内容，主要用于驱动建立物理设备与云端设备的关联关系。',
    },
    monitor: {
      deviceName: '设备名称',
      deviceEUI: '设备EUI',
      monitorType: '监控类型',
      alarmLevel: '告警级别',
      alarmExpress: '告警规则',
      prompt: '提示',
      commonly: '一般',
      serious: '严重',
      unit: '单位',
      unitName: '单位名',
      dataType: '数据类型',
      arrayData: '数组类型',
      alarmFlag: '是否告警',
      normalMsg: '恢复消息',
      industryType: '工业类型',
      alarmDelay: '告警延迟',
      telecommand: '遥信',
      telemetering: '遥测',
      pleaseSelect: '请选择点位！',
      validMessage: {
        number: '请输入数字！',
      },
    },
    gateway: {
      add: '添加网关',
      edit: '编辑网关',
      delete: '删除网关',
      gateway: '网关',
      list: '网关列表',
      detail: '网关详情',
      debug: '调试日志',
      key: '网关KEY',
      name: '网关名称',
      host: '网关主机',
      linkUser: '接入用户',
      linkpwd: '接入密码',
      mqtt: 'MQTT服务',
      offAlarm: '离线报警',
      state: '状态',
      state0: '离线',
      state1: '在线',
      state2: '同步中',
      state3: '未激活',
      upTime: '上线时间',
      downTime: '离线时间',
      downConfirm: '网关下线后不可用，确认下线吗？',
      issuedCfg: '部署',
      downCfg: '下载配置文件',
      collectTime: '采集时间',
      logLevel: '级别',
      logContent: '日志内容',
      logDebug: '调试日志',
      talServer: 'TAL服务',
      logTip:
        '本功能有助于项目实施阶段远程调试，需要在网关详情中开启调试日志功能，并部署至网关，转产后建议关闭调试日志。',
      tips1: '请添加或选择网关。',
      noProject: '未绑定项目的网关',
    },
    mqtt: {
      createServer: '添加服务',
      name: '服务名',
      status: '服务状态',
      host: '服务主机',
      port: '端口',
      userName: '用户名',
      password: '密码',
      connectTimeout: '超时时间(s)',
      keepInterval: '心跳(s)',
      recoveryInterval: '恢复(s)',
      start: '启动',
      stop: '停止',
      connected: '已连接',
      unconnected: '未连接',
      connecting: '连接中',
      stoping: '停止中',
      connectfail: '连接失败',
    },
    thing: {
      model: '物模型',
      add: '添加物模型',
      modelInfo: '物模型详情',
      list: '模型列表',
      name: '模型名称',
      code: '模型编码',
      jsonStr: '模型定义',
      jsonDesc:
        '{\n' +
        '  "profile": {\n' +
        '    "detectType": "默认为device。配置为gateway时网关会主动探测设备属性和心跳；为device时为设备主动上报属性值和心跳",\n' +
        '    "peroid": "默认为60，单位为秒。设备属性的探测周期，当detectType为gateway时的探测周期",\n' +
        '    "beat": "默认为3，单位为秒。设备主动心跳反馈周期为2*beat秒，网关探测的反馈为beat秒"\n' +
        '  },\n' +
        '  "properties": [\n' +
        '    {\n' +
        '      "name": "属性名称(温度，亮度等等)",\n' +
        '      "type": "协议中定义的点类型值，同一物模型下唯一",\n' +
        '      "dataType": "属性类型: int、float、double、text(String)、bool(0/1的int类型,云端转换为true/false)、array(数组类型，支持int/double/float/text)",\n' +
        '      "peroid": "单位秒，可选配置, 没有配置取设备的peroid；配置为0表示不取，配置>0则周期为peroid",\n' +
        '      "specs": {\n' +
        '        "unit": "属性单位",\n' +
        '        "unitName": "单位名称",\n' +
        '        "item": {\n' +
        '          "type": "数组元素的类型"\n' +
        '        }\n' +
        '      },\n' +
        '      "industry": "telemetering/telecommand",\n' +
        '      "alarm": {\n' +
        '        "express": [\n' +
        '          {\n' +
        '            "express": "{$RV} >= 30 逻辑符号:且（and）、或(or)、非(!或NOT); 关系符号：等于（==）、不等于(!=)、大于(>)、大于等于(>=)、小于(<)、小于等于(<=)",\n' +
        '            "message": "High"\n' +
        '          },\n' +
        '          {\n' +
        '            "express": "{$RV} <= 10",\n' +
        '            "message": "Low"\n' +
        '          },\n' +
        '          {\n' +
        '            "express": "{$RV} == OK",\n' +
        '            "message": "Alarm"\n' +
        '          }\n' +
        '        ],\n' +
        '        "delay": "可选配置，单位秒，配置>0时，表示第一次报警发生时至delay*2秒内多次产生报警才为真正的报警，否则判定为误报",\n' +
        '        "normalMsg": "恢复消息（Normal）",\n' +
        '        "level": "info/warn/error",\n' +
        '        "type": "run/fault"\n' +
        '      }\n' +
        '    }\n' +
        '  ]\n' +
        '}',
      expressFormatError: '模型不能识别，请检查格式或属性是否存在错误',
      existThing: '模型编码已经存在',
      script: '解析脚本',
      scriptType: '脚本类型',
      run: '运行中',
      draft: '草稿',
      simulateInput: '模拟输入',
      simulateResult: '输出结果',
      simulateType: '模拟类型',
      protocolToRaw: '南向',
      rawToProtocol: '北向',
      fire: '执行',
    },
    scene: {
      sceneName: '场景名称',
      sceneDesc: '场景说明',
      condition: '场景条件(全部满足时触发)',
      express: '表达式',
      action: '输出动作',
      actionType: '输出类型',
      actionType1: '设备',
      actionType2: '告警',
      actionType3: '消息',
      outDevice: '目标设备EUI',
      monitorType: '监控类型',
      value: '输出值',
      alarm: '场景告警',
      alarmMsg: '告警消息',
      normalMsg: '恢复消息',
      alarmLevel: '告警等级',
      message: '输出消息',
      conditionNull: '场景条件填写不完整',
      actionNull: '场景输出填写不完整',
    },
    //告警
    alarm: {
      relationAlarms: '关联告警',
      faultTable: '故障告警列表',
      runTable: '运行告警列表',
      alarmType: '告警类型',
      runningAlarm: '运行时告警',
      faultAlarm: '故障告警',
      occurTime: '发生时间',
      value: '值',
      comment: '描述',
      faultConfirm: '处理',
      fireConfirm: '无需处理',
      misreportConfirm: '误报',
      equipmentIsolation: '设备隔离',
      confirmSuccess: '确认成功',
      untreated: '未处理',
      handlerState: '处理状态',
      handlerTime: '处理时间',
      handlerPerson: '处理人',
      alarmTime: '告警时间',
      normalTime: '恢复时间',
      trend: '告警趋势',
      total: '总数',
      statistics: '告警统计',
      statisticsNum: '统计数量',
      strategy: '告警策略',
      confirm: '确认',
      alarmConfirm: '告警确认',
      promptAlarm: '提示告警',
      commonlyAlarm: '一般告警',
      seriousAlarm: '严重告警',
      normal: '正常',
      alarmNotify: ',发生一条严重告警,告警状态:',
      recoveryNotify: ',严重告警已恢复,当前状态:',
      comfirmMsg: '确定要人工处理此告警吗?',
      isolateMsg: '确定要将设备隔离吗?',
      cancelIsolateMsg: '确定取消隔离吗?',
      isolateTime: '隔离时长',
      isolate: '隔离设备',
      cancelIsolate: '取消隔离',
      batchIsolate: '批量隔离',
      batchCancel: '批量取消',
      isolated: '已隔离',
      notIsolated: '未隔离',
      nonIsolate: '不隔离',
      isIsolated: '是否隔离',
      isolateEndTime: '隔离结束时间',
      isolateShortcut: '隔离1小时',
      info: '告警消息',
    },
    //地图
    map: {
      manager: '地图管理',
      updateMap: '更换地图',
      loading: '加载中',
      uploadFile1: '将文件拖到此处，或',
      uploadFile2: '点击上传',
      deleteMapConfirm: '确定移除该地图设备么？',
      selectPosition: '请选择位置!',
      runningState: '运行状态',
      running: '运行',
      stop: '停止',
      display: '地图展示',
      alarm: '告警',
      normal: '正常',
      auto: '自动',
      manual: '手动',
      fault: '故障',
      isOpenConfirm: '确定打开吗？',
      isCloseConfirm: '确定关闭吗？',
      position: '选择位置',
      mapView: '地图模式',
      listView: '列表模式',
      scaleUp: '放大一级',
      scaleDown: '缩小一级',
      addDeviceTips: '双击设备图标，即可添加设备',
      removeDeviceTips: '拖拽设备图标至地图左侧或上侧边缘，即可移除设备',
    },
    protocol: {
      addProtocol: '添加协议',
      deleteProtocol: '删除协议',
      existProtocol: '不能添加多个相同的协议',
      list: '协议列表',
      name: '协议',
      moduleboot: '引导模块',
      definedType: '定义类型',
      definedType1: '官方',
      definedType2: '私有',
      addChannel: '新增通道',
      editChannel: '编辑通道',
      existChannel: '不能存在多个相同名字的通道',
      channel: '通道',
      channelName: '通道名称',
      channelInfo: '通道详情',
      transferModal: '传输模式',
      transferTcp: 'TCP',
      transferRtu: 'RTU',
      host: 'IP地址',
      port: '端口',
      serialPort: '串口',
      baudRate: '波特率',
      dataBit: '数据位',
      checkBit: '校验位',
      stopBit: '停止位',
      daqIntvl: '采集间隔(毫秒)',
      transferIP: 'IP',
      transferMstp: 'MSTP',
      netBroad: '网卡',
      mstpAddress: 'MSTP网络地址',
      channelAddress: '通道地址',
      securityPolicy: '安全策略',
      securityMode: '安全模式',
      securityCert: '数字证书',
      signCert: '密钥证书',
      pkPassword: '私钥密码',
      username: '用户名',
      password: '密码',
      customize: '扩展配置(JSON格式)',
      timeout: '方法调用超时时间(s)',
      tips1: '请添加或选择协议。',
      tips2: '扩展配置必须是JSON格式。',
      selectFile: '上传证书',
      downloadFile: '查看文件',
    },
  },
};
