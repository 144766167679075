<template>
  <div class="side-bar-component" v-loading="listenSitemap">
    <div
      :class="['first-level-menu', `menu-theme-${theme}`]"
      @mouseenter="fistLevelEnter"
    >
      <img
        @click="jumpToHome"
        class="first-menu-logo"
        :src="customLogoPath"
        :onerror="SmallLogo"
        alt=""
      />
      <div class="first-menu-scroll">
        <div
          class="first-menu-container"
          v-for="(item, index) in sitemapList"
          :key="index"
          @click="firstMenuChange(index, item)"
        >
          <div
            :class="[
              'single-first-menu',
              index === firstActiveKey && 'first-menu-active',
            ]"
          >
            <!-- 如果能取到icon就展示icon，否则取首字母 -->
            <span
              class="head-icon"
              :title="generateTitle(item.displayText)"
              v-if="item.fileId"
            >
              <img
                :src="`${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`"
                alt=""
              />
            </span>
            <span
              v-else
              class="head-container"
              :title="generateTitle(item.displayText)"
            >
              {{ generateTitle(item.displayText, true) }}
            </span>
          </div>
          <div
            :class="[
              'menu-label',
              index === firstActiveKey && 'menu-label-active',
            ]"
          >
            {{ generateTitle(item.displayText).substring(0, 2) }}
          </div>
        </div>
      </div>

      <div
        :class="[
          'single-first-menu',
          'person-container',
          sitemapList.length === firstActiveKey && 'first-menu-active',
        ]"
        title="设置中心"
        @click="firstMenuChange(sitemapList.length)"
      >
        <img :src="avatarP" :onerror="defaultAvatar" class="user-avatar" />
      </div>
    </div>
    <div
      class="second-level-menu"
      @mouseleave="fistLevelLeave"
      :style="{
        width: isCollapse ? '0' : '190px',
      }"
    >
      <div
        class="second-title-container"
        :style="{
          width: isCollapse ? '0' : '190px',
        }"
      >
        <!-- <img
          v-if="
            allSitemapList[firstActiveKey] &&
            allSitemapList[firstActiveKey].fileId
          "
          :src="`${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${allSitemapList[firstActiveKey].fileId}`"
          class="second-title-icon"
          alt=""
        />
        <span class="second-title-label">
          {{
            allSitemapList[firstActiveKey] &&
            generateTitle(allSitemapList[firstActiveKey].displayText)
          }}
        </span> -->
        <el-input
          class="search-input"
          placeholder="搜索"
          prefix-icon="el-icon-search"
          clearable
          @input="searchHandler"
          v-model="menuSearchKey"
        >
        </el-input>
      </div>
      <!-- 二级菜单 -->
      <div class="second-menu-container">
        <div class="second-list-container" v-if="!menuSearchKey">
          <div
            class="single-second-menu"
            v-for="(item, index) in activeMenuList"
            :key="index"
          >
            <div
              :class="['second-menu-main', item.activeNodes && 'active']"
              @click="routerJump(item, activeMenuList)"
            >
              <span class="seconde-menu-image">
                <!-- <img
                  v-if="item.fileId"
                  :src="`${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`"
                  alt=""
                /> -->
                <svg-icon v-if="item.icon" :icon-class="item.icon" />
                <svg-icon v-else icon-class="zuzhiguanli" />
              </span>
              <span class="seconde-menu-label">
                {{ generateTitle(item.displayText) }}
              </span>
              <span
                class="seconde-menu-icons"
                v-show="item.nodes && item.nodes.length"
              >
                <i class="el-icon-arrow-down" v-show="!item.openNodes"></i>
                <i class="el-icon-arrow-up" v-show="item.openNodes"></i>
              </span>
            </div>
            <!-- 三级菜单 -->
            <div class="next-container" v-show="item.openNodes">
              <div
                :class="['single-one', subMenu.activeNodes && 'active']"
                v-for="(subMenu, subIndex) in item.nodes"
                :key="subIndex"
                @click="routerJump(subMenu, item.nodes)"
              >
                {{ generateTitle(subMenu.displayText) }}
              </div>
            </div>
          </div>
        </div>
        <div class="second-list-container" v-else>
          <div
            class="single-second-menu"
            v-for="(item, index) in filterMenuList"
            :key="index"
          >
            <div
              :class="['second-menu-main', item.activeNodes && 'active']"
              @click="
                item.href
                  ? routerJump(item, activeMenuList, true)
                  : (item.openNodes = !item.openNodes)
              "
            >
              <span class="seconde-menu-image">
                <!-- <img
                  v-if="item.fileId"
                  :src="`${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`"
                  alt=""
                /> -->
                <svg-icon v-if="item.icon" :icon-class="item.icon" />
                <svg-icon v-else icon-class="zuzhiguanli" />
              </span>
              <span class="seconde-menu-label">
                {{ generateTitle(item.displayText) }}
              </span>
              <span
                class="seconde-menu-icons"
                v-show="item.nodes && item.nodes.length"
              >
                <i class="el-icon-arrow-down" v-show="!item.openNodes"></i>
                <i class="el-icon-arrow-up" v-show="item.openNodes"></i>
              </span>
            </div>
            <!-- 三级菜单 -->
            <div class="next-container" v-show="item.openNodes">
              <div
                :class="['single-one', subMenu.activeNodes && 'active']"
                v-for="(subMenu, subIndex) in item.nodes"
                :key="subIndex"
                @click="routerJump(subMenu, item.nodes, true)"
                v-html="highlightRender(generateTitle(subMenu.displayText))"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="menu-toggle-container"
      :style="{
        right: isCollapse ? '173px' : '-17px',
      }"
      @click="collapseHandler"
    >
      <i
        class="el-icon-arrow-right"
        :style="{
          transform: `rotate(${isCollapse ? 0 : 180}deg)`,
        }"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { generateTitle } from "@/utils/i18n";
import { envInfo } from "@/constants/envInfo";
import { cloneDeep } from "lodash";
import { getUrlParams } from "@/utils/utils.js";
export default {
  name: "sidebarComponent",
  data() {
    return {
      envInfo,
      menuSearchKey: null,
      currentFullPath: "",
      menuLoaded: false,
      firstActiveKey: 0,
      SmallLogo: require("@/assets/images/defaultSmallAvatar.png"),
      // 默认头像
      defaultAvatar:
        'this.src="' + require("@/assets/images/defaultAvatar.png") + '"',
      defaultPersonalMenu: [
        {
          displayText: "zh-CN=设置中心;en-US=personal center",
          nodeLevel: 1,
          href: null,
          nodes: [
            {
              href: "/personal/basic",
              sitemapCode: "default-code-1",
              displayText: "zh-CN=基本资料;en-US=basic setting",
              nodeLevel: 2,
              activeNodes: false,
            },
            {
              href: "/personal/common",
              sitemapCode: "default-code-2",
              displayText: "zh-CN=通用;en-US=common setting",
              nodeLevel: 2,
              activeNodes: false,
            },
          ],
        },
      ],
      // 是否手动点开
      manualOpening: false,
      filterMenuList: [],
    };
  },
  computed: {
    ...mapGetters([
      "currentTenant",
      "sitemapList",
      "tenantList",
      "sidebar",
      "userAccount",
      "isGuest",
      "avatar",
      "theme",
    ]),
    // 监听菜单状态
    listenSitemap() {
      return !this.$store.state.user.isSitemapInit;
    },
    activeMenuList() {
      this.clearAllActiveStatus();
      return this.allSitemapList[this.firstActiveKey]
        ? cloneDeep(this.allSitemapList[this.firstActiveKey].nodes)
        : [];
    },
    avatarP() {
      if (this.avatar) {
        return envInfo.bgApp.ssoPath + "/param/avatar?p=" + this.avatar;
      } else {
        return envInfo.bgApp.ssoPath + "/param/avatar?u=" + this.userAccount;
      }
    },
    allSitemapList() {
      return [...this.sitemapList, ...this.defaultPersonalMenu];
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  watch: {
    currentTenant: {
      handler(val) {
        let curTenantList = this.tenantList.filter(
          (item) => item.tenantId == val
        )[0];
        if (!curTenantList) return;
        this.customLogoPath = curTenantList.tenantAvatar
          ? envInfo.bgApp.tenantPath +
            "/param/tenant/avatar?p=" +
            curTenantList.tenantAvatar
          : this.SmallLogo;
      },
      immediate: true,
      deep: true,
    },
    sitemapList: {
      handler(sites) {
        // 如果没有经过route获取当前路由，则return
        if (!this.currentFullPath) return;
        const urlParams = getUrlParams();
        this.setActiveMenu(urlParams, this.currentFullPath);
      },
      deep: true,
    },
    $route: {
      handler(val) {
        // 如果菜单已经加载完毕则获取url设置active
        if (this.menuLoaded) {
          const urlParams = getUrlParams();
          this.setActiveMenu(urlParams, this.currentFullPath);
        }
        const urlParams = getUrlParams();
        const fullPath = val.meta.detail ? val.meta.goBackRouter : val.path;
        // 如果是在中转路由，则不处理return
        if (fullPath === "/home/index") return;
        this.currentFullPath = fullPath;
        // 如果菜单数据没初始化完则return
        if (!this.sitemapList.length) return;
        this.setActiveMenu(urlParams, fullPath);
      },
      immediate: true,
    },
  },
  methods: {
    generateTitle,
    jumpToHome() {
      const tenantHomePage = this.$store.getters.tenantHomePage || "/personal";
      this.$router.push(tenantHomePage);
    },
    highlightRender(menuLabel) {
      console.log("menuLabel", menuLabel);
      menuLabel = menuLabel
        .split(this.menuSearchKey)
        .join("<span style='color:#2a61ff;'>" + this.menuSearchKey + "</span>");
      return menuLabel;
    },
    searchHandler(value = "") {
      value = value.trim();
      this.filterMenuList = [];
      this.allSitemapList.forEach((item) => {
        if (item.nodes) {
          item.nodes.forEach((secondItem) => {
            let tempList = cloneDeep(secondItem);
            tempList.nodes = [];
            if (secondItem.nodes) {
              secondItem.nodes.forEach((thirdItem) => {
                if (thirdItem.displayText.includes(value)) {
                  tempList.nodes.push(thirdItem);
                }
              });
            } else {
              if (secondItem.displayText.includes(value)) {
                this.filterMenuList.push(secondItem);
              }
            }
            if (tempList.nodes.length) {
              tempList.openNodes = true;
              this.filterMenuList.push(tempList);
            }
          });
        }
      });
      console.log("this.filterMenuList", this.filterMenuList);
    },
    setActiveMenu(urlParams, fullPath) {
      const { firstIndex, sitemapCode, nodeLevel, parentCode } =
        this.getFirstIndexByHref(fullPath, urlParams) || {};
      // if (!firstIndex) {
      //   //模拟手动触发一次
      //   // this.firstMenuChange(0);
      //   setTimeout(() => {
      //     this.setActiveMenu(urlParams, fullPath);
      //   }, 200);
      //   return;
      // }
      this.firstActiveKey = firstIndex;
      // this.$nextTick(() => {
      if (nodeLevel === 2) {
        this.setMenuActiveState(this.activeMenuList, sitemapCode);
        this.setParentActive();
      } else if (nodeLevel === 3) {
        this.setMenuActiveState(
          this.activeMenuList.filter(
            (item) => item.sitemapCode === parentCode
          )[0].nodes,
          sitemapCode
        );
        this.setParentActive(parentCode);
      }
      this.$forceUpdate();
      this.menuLoaded = true;
    },
    clearAllActiveStatus() {
      this.allSitemapList.forEach((first) => {
        if (first.nodes && first.nodes.length) {
          first.nodes.forEach((second) => {
            second.activeNodes = false;
            second.openNodes = false;
            if (second.nodes && second.nodes.length) {
              second.nodes.forEach((third) => {
                third.activeNodes = false;
              });
            }
          });
        }
      });
    },
    fistLevelEnter() {
      // manualOpening为true，说明是手动点开的，直接return
      if (!this.isCollapse) return;
      this.manualOpening = true;
      this.$store.commit("TOGGLE_SIDEBAR");
    },
    fistLevelLeave() {
      // 是缩起状态并且不是手动缩起的，才处理
      if (!this.isCollapse && !this.manualOpening) return;
      this.manualOpening = false;
      this.$store.commit("TOGGLE_SIDEBAR");
    },
    collapseHandler() {
      try {
        document.getElementsByClassName("tooltipsClassName")[0].style.display =
          "none";
      } catch (error) {
        // console.log("隐藏tooltips失败：", error);
      }
      this.$store.commit("TOGGLE_SIDEBAR");
    },
    firstMenuChange(index, item) {
      this.menuSearchKey = null;
      this.searchHandler("");
      const dealResult = this.routerJumpHandler(item);
      if (dealResult) return;
      this.firstActiveKey = index;
      item && this.$router.push(item.href);
    },
    urlAddToken(href) {
      if (!href) return "";
      if (href.includes("?")) {
        return `${href}&auth-token=${this.$store.getters.baseAuthToken}`;
      } else {
        return `${href}?auth-token=${this.$store.getters.baseAuthToken}`;
      }
    },
    routePathJump(path, target, needToken) {
      const { origin, pathname } = window.location;
      let targetUrl = path.startsWith("http")
        ? path
        : `${origin}${pathname}#${path}`;
      if (needToken) {
        targetUrl = this.urlAddToken(targetUrl);
      }
      window.open(targetUrl, target);
    },
    routerJumpHandler(item) {
      /**
       *
       *   urlType:
       *    内置 - built
       *    内嵌 - embedded
       *    外链 - backlinks
       *  openWith:
       *    当前窗口 - currentWindow
       *    新窗口 - newWindow
       */
      console.log("item", item);
      if (!item) return false;
      const { urlType, openWith, href } = item;
      if (!urlType && !openWith) {
        // 都没有配置按内置-当前窗口处理，直接return false
        return false;
      }
      const targetUrl = this.urlAddToken(href);
      switch (urlType) {
        case "built":
          // 内置 - 当前窗口
          if (openWith === "currentWindow") return false;
          // 内置 - 新窗口
          if (openWith === "newWindow") {
            if (href.includes("https") || href.includes("http")) {
              window.open(targetUrl, "_blank");
            } else {
              this.routePathJump(href, "_blank");
            }
          }
          break;
        case "embedded":
          if (!href) return false;
          // 内嵌 - 当前窗口
          this.routePathJump(
            `/common/frame?targetUrl=${encodeURIComponent(href)}`,
            openWith === "currentWindow" ? "_self" : "_blank",
            true
          );
          // 内嵌 - 新窗口
          break;
        case "backlinks":
          // 外链 - 当前窗口/新窗口
          this.routePathJump(
            href,
            openWith === "currentWindow" ? "_self" : "_blank",
            true
          );
          break;
      }
      return true;
      // 内置
      //   - 	当前窗口：当前窗口打开路由地址
      //   - 	新窗口：新窗口打开路由地址
      // 内嵌
      //   -	当前窗口：当前窗口弄个公共的frame组件打开链接，url后拼auth-token
      //   -	新窗口：新窗口弄个公共的frame组件打开链接，url后拼auth-token
      //   /common/frame?targetUrl=https://www.baidu.com
      //   /common/frame?targetUrl=/topu/detail?id=xxxx&lauout=hidden
      //   路由高亮逻辑：
      //     /common/frame加载的时候，把链接拼到地址后面，高亮时，当路由是/common/frame的时候，获取当前targetUrl和菜单链接比较，如果相同，则当前菜单高亮
      //     所有内嵌类型菜单的链接避免重复，否则高亮会有问题
      // 外链
      //   -	当前窗口: 当前窗口打开链接，url后拼auth-token
      //   -	新窗口：新窗口打开链接，url后拼auth-token
    },
    routerJumpByHref(href) {
      this.$router.push(href);
    },
    routerJump(item, menuDatas, resetFilter) {
      const dealResult = this.routerJumpHandler(item);
      if (dealResult) return;
      const { href, sitemapCode, nodeLevel, parentCode } = item;
      if (nodeLevel === 2) {
        if (href) {
          this.$router.push(item.href);
          this.setMenuActiveState(menuDatas, sitemapCode);
          this.setParentActive();
        } else {
          item.openNodes = !item.openNodes;
        }
      } else if (nodeLevel === 3) {
        this.$router.push(item.href);
        this.setMenuActiveState(menuDatas, sitemapCode);
        this.setParentActive(parentCode);
      }
      if (resetFilter) {
        this.menuSearchKey = null;
        this.searchHandler("");
      }
      this.$forceUpdate();
    },
    setParentActive(parentCode) {
      if (parentCode) {
        this.activeMenuList.forEach((menu) => {
          menu.openNodes = menu.sitemapCode === parentCode;
          menu.activeNodes = menu.sitemapCode === parentCode;
        });
      } else {
        this.activeMenuList.forEach((item) => {
          if (item.nodes && item.nodes.length) {
            item.nodes.forEach((node) => {
              node.activeNodes = false;
            });
          }
        });
      }
    },
    setMenuActiveState(menuDatas, setSitemapCode) {
      for (let i = 0; i < menuDatas.length; i++) {
        menuDatas[i].activeNodes = menuDatas[i].sitemapCode === setSitemapCode;
      }
      this.$nextTick(() => {
        this.activeMenuList.forEach((item) => {
          if (!item.activeNodes && item.nodes && item.nodes.length) {
            item.nodes.forEach((subItem) => {
              subItem.activeNodes = false;
            });
          }
        });
      });
    },
    getCommonFrameIndex(href, query) {
      const targetUrl = decodeURIComponent(query.targetUrl);
      for (
        let firstIndex = 0;
        firstIndex < this.allSitemapList.length;
        firstIndex++
      ) {
        const secondNodes = this.allSitemapList[firstIndex].nodes;
        if (secondNodes && secondNodes.length) {
          for (
            let secondIndex = 0;
            secondIndex < secondNodes.length;
            secondIndex++
          ) {
            const thirdNodes = secondNodes[secondIndex].nodes;
            if (
              "embedded" === secondNodes[secondIndex].urlType &&
              targetUrl === secondNodes[secondIndex].href
            ) {
              return {
                firstIndex,
                sitemapCode: secondNodes[secondIndex].sitemapCode,
                nodeLevel: secondNodes[secondIndex].nodeLevel,
              };
            }
            if (thirdNodes && thirdNodes.length) {
              for (
                let thirdIndex = 0;
                thirdIndex < thirdNodes.length;
                thirdIndex++
              ) {
                if (
                  "embedded" === thirdNodes[thirdIndex].urlType &&
                  targetUrl === thirdNodes[thirdIndex].href
                ) {
                  return {
                    firstIndex,
                    sitemapCode: thirdNodes[thirdIndex].sitemapCode,
                    nodeLevel: thirdNodes[thirdIndex].nodeLevel,
                    parentCode: thirdNodes[thirdIndex].parentCode,
                  };
                }
              }
            }
          }
        } else {
          // 如果没有nodes则匹配第一级
          if (
            "embedded" === this.allSitemapList[firstIndex].urlType &&
            targetUrl === this.allSitemapList[firstIndex].href
          ) {
            return {
              firstIndex,
              sitemapCode: this.allSitemapList[firstIndex].sitemapCode,
              nodeLevel: this.allSitemapList[firstIndex].nodeLevel,
            };
          }
        }
      }
    },
    // 通过路由的sitemapCode找到顶层菜单的index和他本身的sitemapCode
    getFirstIndexByHref(href, query) {
      if (href === "/common/frame") {
        return this.getCommonFrameIndex(href, query);
      }
      if (href === "/bms/commonDashboard") {
        href = `${href}?templateId=${query.templateId}`;
      }
      for (
        let firstIndex = 0;
        firstIndex < this.allSitemapList.length;
        firstIndex++
      ) {
        const secondNodes = this.allSitemapList[firstIndex].nodes;
        if (secondNodes && secondNodes.length) {
          for (
            let secondIndex = 0;
            secondIndex < secondNodes.length;
            secondIndex++
          ) {
            const thirdNodes = secondNodes[secondIndex].nodes;
            if (href === secondNodes[secondIndex].href) {
              return {
                firstIndex,
                sitemapCode: secondNodes[secondIndex].sitemapCode,
                nodeLevel: secondNodes[secondIndex].nodeLevel,
              };
            }
            if (thirdNodes && thirdNodes.length) {
              for (
                let thirdIndex = 0;
                thirdIndex < thirdNodes.length;
                thirdIndex++
              ) {
                if (href === thirdNodes[thirdIndex].href) {
                  return {
                    firstIndex,
                    sitemapCode: thirdNodes[thirdIndex].sitemapCode,
                    nodeLevel: thirdNodes[thirdIndex].nodeLevel,
                    parentCode: thirdNodes[thirdIndex].parentCode,
                  };
                }
              }
            }
          }
        } else {
          // 如果没有nodes则匹配第一级
          if (href === this.allSitemapList[firstIndex].href) {
            return {
              firstIndex,
              sitemapCode: this.allSitemapList[firstIndex].sitemapCode,
              nodeLevel: this.allSitemapList[firstIndex].nodeLevel,
            };
          }
        }
      }
    },
    // 通过sitemapCode设置openNodes属性
  },
};
</script>

<style lang="less" scoped>
.side-bar-component {
  height: 100vh;
  width: 250px;
  display: flex;
  position: relative;
  .first-level-menu {
    text-align: center;
    position: relative;
    width: 60px;
    height: 100%;
    box-shadow: 0px 4px 4px 0px rgba(8, 45, 72, 0.15);
    .person-container {
      position: absolute;
      bottom: 5px;
      left: 12px;
      img {
        border-radius: 50%;
        height: 32px;
        width: 32px;
      }
    }
    .first-menu-logo {
      cursor: pointer;
      width: 50px;
      height: 60px;
      margin: 25px 0 32px 0;
    }
    .first-menu-scroll {
      height: calc(100% - 170px);
      overflow: auto;
      .first-menu-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .single-first-menu {
          width: 48px;
          height: 48px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: #e6f4fc;
          }
          .head-container {
            height: 32px;
            width: 32px;
            line-height: 32px;
            text-align: center;
            background: #3d8cfa;
            border-radius: 8px 8px 8px 8px;
            font-size: 16px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .head-icon {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            img {
              height: 32px;
              width: 32px;
            }
          }
        }
        .first-menu-active {
          background: #e6f4fc;
        }
        .menu-label {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #5d687c;
          margin: 4px 0 8px;
        }
        .menu-label-active {
          color: #2a61ff;
        }
      }
    }
  }

  .menu-theme-1 {
    background: #ffffff;
  }
  .menu-theme-2 {
    background: #2a61ff;
  }
  .menu-theme-3 {
    background: #1d2d36;
  }
  .second-level-menu {
    width: 190px;
    height: 100%;
    text-align: center;
    overflow: hidden;
    .second-title-container {
      overflow: hidden;
      transition: width 0.2s;
      height: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .search-input {
        width: 150px;
        margin-left: 17px;
      }
      .second-title-label {
        font-size: 18px;
        font-family: Source Han Sans SC-Bold, Source Han Sans SC;
        font-weight: bold;
        color: #152c5b;
      }
      .second-title-icon {
        height: 22px;
        width: 22px;
        margin-right: 8px;
      }
    }
    .second-menu-container {
      position: relative;
      .second-list-container {
        height: calc(100vh - 96px);
        overflow-y: auto;
        overflow-x: hidden;
        .single-second-menu {
          position: relative;
          cursor: pointer;
          line-height: 40px;
          margin-bottom: 20px;
          text-align: left;
          .second-menu-main.active {
            background: #f4f7ff;
            color: #2a61ff;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 1px;
              height: 100%;
              width: 2px;
              background: #2a61ff;
            }
            .seconde-menu-label {
              font-weight: bold;
            }
          }
          .second-menu-main {
            color: #8190ac;
            &:hover {
              background: #f4f7ff;
              .seconde-menu-label {
                font-weight: bold;
              }
            }
          }
          .seconde-menu-image {
            width: 50px;
            display: inline-block;
            text-align: center;
            position: relative;
            top: 1px;
          }
          .seconde-menu-label {
            font-size: 14px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
          }
          .seconde-menu-icons {
            position: absolute;
            right: 20px;
          }
          .next-container {
            margin-left: 53px;
            font-size: 14px;
            font-family: Source Han Sans SC-Medium, Source Han Sans SC;
            font-weight: 500;
            color: #8190ac;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              height: calc(100% - 34px);
              width: 1px;
              top: 13px;
              left: -1px;
              background: #ebf0f3;
            }
            .single-one {
              height: 34px;
              line-height: 34px;
              padding-left: 20px;
              margin-top: 10px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 13px;
                left: -4px;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: #d3dae5;
              }
              &:hover {
                color: #152c5b;
              }
            }
            .single-one.active {
              color: #152c5b;
              &::before {
                content: "";
                position: absolute;
                top: 13px;
                left: -4px;
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background: #152c5b;
              }
            }
          }
        }
      }
    }
  }
  .menu-toggle-container {
    transition: all 0.4s;
    z-index: 1;
    position: absolute;
    top: 194px;
    right: -14px;
    width: 17px;
    height: 82px;
    cursor: pointer;
    background: url("../../../../../assets/images/menu_toggle_background.png");
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #93b0c8;
    }
    .el-icon-arrow-right {
      transition: all 0.4s;
    }
  }
}
</style>