<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <template v-if="!item.nodes">
      <app-link :to="item.href ? item.href : ''">
        <el-menu-item
          :index="item.href"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item :icon="item.icon" :title="generateTitle(item.displayText)" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else :index="item.sitemapId + ''">
      <template slot="title">
        <item :icon="item.icon" :title="generateTitle(item.displayText)" />
      </template>

      <template v-for="child in item.nodes">
        <sidebar-item
          v-if="child.nodes && child.nodes.length > 0"
          :is-nest="true"
          :item="child"
          :key="child.sitemapId"
          class="nest-menu"
        />
        <app-link
          v-else
          :to="child.href ? child.href : ''"
          :key="child.sitemapId"
        >
          <el-menu-item :index="child.href">
            <item
              :icon="child.icon"
              :title="generateTitle(child.displayText)"
            />
          </el-menu-item>
        </app-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
import Item from "./Item";
import AppLink from "./Link";
import { generateTitle } from "@/utils/i18n";

export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  props: {
    // 菜单节点
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    generateTitle,
  },
};
</script>
