<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.safariHacks();
  },
  methods: {
    safariHacks() {
      try {
        let windowsVH = window.innerHeight / 100;
        document
          .querySelector(".wrap")
          .style.setProperty("--vh", windowsVH + "px");
        window.addEventListener("resize", function () {
          document
            .querySelector(".wrap")
            .style.setProperty("--vh", windowsVH + "px");
        });
      } catch (error) {}
    },
  },
};
</script>
<style >
body {
  height: 100vh; /*給 Safari 以外的瀏覽器讀取*/
  height: calc(var(--vh, 1vh) * 100);
}
</style>
