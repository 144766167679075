<template>
  <div class="water-energy-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "waterEnergyTitle",
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>

<style lang="less" scoped>
.water-energy-title {
  width: 100%;
  height: 16px;
  font-size: 16px;
  font-family: Source Han Sans SC-Bold, Source Han Sans SC;
  font-weight: bold;
  color: #152c5b;
  display: inline;
}
</style>