<template>
  <div class="right-menu-component">
    <!-- <el-input
      class="search-input"
      :placeholder="inputPlaceHolder"
      suffix-icon="el-icon-search"
      v-model="searchValue"
    >
    </el-input> -->
    <div class="icon-list-container">
      <span class="single-icon">
        <el-tooltip
          :content="$t('navbar.screenfull')"
          effect="dark"
          placement="bottom"
          :open-delay="openDelay"
        >
          <Screenfull class="screenfull right-menu-item" />
        </el-tooltip>
      </span>
      <span class="single-icon">
        <el-tooltip
          content="快捷菜单"
          effect="dark"
          placement="bottom"
          :disabled="hideMenuToolTops"
          :open-delay="openDelay"
        >
          <ShortMenu @menuChange="MenuChangeHandler" />
        </el-tooltip>
      </span>
      <span class="single-icon">
        <el-tooltip
          :content="$t('personal.setting9')"
          effect="dark"
          placement="bottom"
          :disabled="hideToolTops"
          :open-delay="openDelay"
        >
          <Information
            @messageChange="messageChangeHandler"
            class="information right-menu-item"
          />
        </el-tooltip>
      </span>
      <span class="single-icon" v-if="tenantList.length > 1">
        <TenantMenu class="international right-menu-item" />
      </span>
    </div>
    <el-dropdown class="avatar-container right-menu-item">
      <div class="avatar-wrapper">
        <img :src="avatarP" :onerror="defaultAvatar" class="user-avatar" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <router-link to="/bms/dashboardEdit?layout=hide">
          <el-dropdown-item>
            <span style="display: block">
              <i class="el-icon-setting"></i>
              {{ $t("navbar.dashboardEdit") }}
            </span>
          </el-dropdown-item>
        </router-link>
        <router-link to="/personal">
          <el-dropdown-item divided>
            <i class="el-icon-user"></i>
            {{ $t("home.personalCenter") }}
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item v-if="isGuest" divided>
          <span style="display: block" @click="login">
            <i class="el-icon-switch-button"></i>
            {{ $t("login.logIn") }}
          </span>
        </el-dropdown-item>
        <el-dropdown-item v-else divided>
          <span style="display: block" @click="logout">
            <i class="el-icon-switch-button"></i>
            {{ $t("navbar.logOut") }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Screenfull from "@/components/Screenfull";
import Information from "@/components/Information";
import TenantMenu from "@/components/TenantMenu";
import ShortMenu from "@/components/ShortMenu";
import { mapGetters } from "vuex";
import { envInfo } from "@/constants/envInfo";
export default {
  name: "rightMenuComponent",
  components: {
    Screenfull,
    Information,
    TenantMenu,
    ShortMenu,
  },
  data() {
    return {
      hideMenuToolTops: false,
      hideToolTops: false,
      inputPlaceHolder: "请输入搜索内容",
      // 默认头像
      defaultAvatar:
        'this.src="' + require("@/assets/images/defaultAvatar.png") + '"',
      openDelay: 0,
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "device",
      "userAccount",
      "isGuest",
      "avatar",
      "tenantList",
    ]),
    avatarP() {
      if (this.avatar) {
        return envInfo.bgApp.ssoPath + "/param/avatar?p=" + this.avatar;
      } else {
        return envInfo.bgApp.ssoPath + "/param/avatar?u=" + this.userAccount;
      }
    },
  },
  methods: {
    MenuChangeHandler(flag) {
      this.hideMenuToolTops = flag;
      this.$forceUpdate();
    },
    messageChangeHandler(flag) {
      this.hideToolTops = flag;
      this.$forceUpdate();
    },
    login() {
      // 跳转登录页面
      router.replace({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath }, //登录成功后跳入浏览的当前页面
      });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
  },
};
</script>

<style lang="less" scoped>
.right-menu-component {
  .search-input {
    width: 310px;
    height: 40px;
    line-height: 40px;
    margin-right: 20px;
    ::v-deep .el-input__inner {
      border: none;
      border-radius: 12px;
      height: 40px;
      line-height: 40px;
    }
  }
  .icon-list-container {
    display: flex;
    align-items: center;
    margin-right: 40px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: -20px;
      height: 13px;
      width: 1px;
      height: 14px;
      background: #cdbbe9;
    }
    .single-icon {
      display: inline-block;
      height: 40px;
      width: 40px;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .single-icon + .single-icon {
      margin-left: 20px;
    }
  }
  .avatar-container {
    cursor: pointer;
    height: 40px;
    width: 40px;
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }
  }
}
</style>