var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listenSitemap,
          expression: "listenSitemap",
        },
      ],
      staticClass: "side-bar-component",
    },
    [
      _c(
        "div",
        {
          class: ["first-level-menu", `menu-theme-${_vm.theme}`],
          on: { mouseenter: _vm.fistLevelEnter },
        },
        [
          _c("img", {
            staticClass: "first-menu-logo",
            attrs: { src: _vm.customLogoPath, onerror: _vm.SmallLogo, alt: "" },
            on: { click: _vm.jumpToHome },
          }),
          _c(
            "div",
            { staticClass: "first-menu-scroll" },
            _vm._l(_vm.sitemapList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "first-menu-container",
                  on: {
                    click: function ($event) {
                      return _vm.firstMenuChange(index, item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "single-first-menu",
                        index === _vm.firstActiveKey && "first-menu-active",
                      ],
                    },
                    [
                      item.fileId
                        ? _c(
                            "span",
                            {
                              staticClass: "head-icon",
                              attrs: {
                                title: _vm.generateTitle(item.displayText),
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: `${_vm.envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "head-container",
                              attrs: {
                                title: _vm.generateTitle(item.displayText),
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.generateTitle(item.displayText, true)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        "menu-label",
                        index === _vm.firstActiveKey && "menu-label-active",
                      ],
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.generateTitle(item.displayText).substring(0, 2)
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              class: [
                "single-first-menu",
                "person-container",
                _vm.sitemapList.length === _vm.firstActiveKey &&
                  "first-menu-active",
              ],
              attrs: { title: "设置中心" },
              on: {
                click: function ($event) {
                  return _vm.firstMenuChange(_vm.sitemapList.length)
                },
              },
            },
            [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: _vm.avatarP, onerror: _vm.defaultAvatar },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "second-level-menu",
          style: {
            width: _vm.isCollapse ? "0" : "190px",
          },
          on: { mouseleave: _vm.fistLevelLeave },
        },
        [
          _c(
            "div",
            {
              staticClass: "second-title-container",
              style: {
                width: _vm.isCollapse ? "0" : "190px",
              },
            },
            [
              _c("el-input", {
                staticClass: "search-input",
                attrs: {
                  placeholder: "搜索",
                  "prefix-icon": "el-icon-search",
                  clearable: "",
                },
                on: { input: _vm.searchHandler },
                model: {
                  value: _vm.menuSearchKey,
                  callback: function ($$v) {
                    _vm.menuSearchKey = $$v
                  },
                  expression: "menuSearchKey",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "second-menu-container" }, [
            !_vm.menuSearchKey
              ? _c(
                  "div",
                  { staticClass: "second-list-container" },
                  _vm._l(_vm.activeMenuList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "single-second-menu" },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "second-menu-main",
                              item.activeNodes && "active",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.routerJump(item, _vm.activeMenuList)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "seconde-menu-image" },
                              [
                                item.icon
                                  ? _c("svg-icon", {
                                      attrs: { "icon-class": item.icon },
                                    })
                                  : _c("svg-icon", {
                                      attrs: { "icon-class": "zuzhiguanli" },
                                    }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "seconde-menu-label" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.generateTitle(item.displayText)) +
                                  "\n            "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.nodes && item.nodes.length,
                                    expression:
                                      "item.nodes && item.nodes.length",
                                  },
                                ],
                                staticClass: "seconde-menu-icons",
                              },
                              [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.openNodes,
                                      expression: "!item.openNodes",
                                    },
                                  ],
                                  staticClass: "el-icon-arrow-down",
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.openNodes,
                                      expression: "item.openNodes",
                                    },
                                  ],
                                  staticClass: "el-icon-arrow-up",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.openNodes,
                                expression: "item.openNodes",
                              },
                            ],
                            staticClass: "next-container",
                          },
                          _vm._l(item.nodes, function (subMenu, subIndex) {
                            return _c(
                              "div",
                              {
                                key: subIndex,
                                class: [
                                  "single-one",
                                  subMenu.activeNodes && "active",
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.routerJump(subMenu, item.nodes)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.generateTitle(subMenu.displayText)
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "second-list-container" },
                  _vm._l(_vm.filterMenuList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "single-second-menu" },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "second-menu-main",
                              item.activeNodes && "active",
                            ],
                            on: {
                              click: function ($event) {
                                item.href
                                  ? _vm.routerJump(
                                      item,
                                      _vm.activeMenuList,
                                      true
                                    )
                                  : (item.openNodes = !item.openNodes)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "seconde-menu-image" },
                              [
                                item.icon
                                  ? _c("svg-icon", {
                                      attrs: { "icon-class": item.icon },
                                    })
                                  : _c("svg-icon", {
                                      attrs: { "icon-class": "zuzhiguanli" },
                                    }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "seconde-menu-label" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.generateTitle(item.displayText)) +
                                  "\n            "
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.nodes && item.nodes.length,
                                    expression:
                                      "item.nodes && item.nodes.length",
                                  },
                                ],
                                staticClass: "seconde-menu-icons",
                              },
                              [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.openNodes,
                                      expression: "!item.openNodes",
                                    },
                                  ],
                                  staticClass: "el-icon-arrow-down",
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.openNodes,
                                      expression: "item.openNodes",
                                    },
                                  ],
                                  staticClass: "el-icon-arrow-up",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.openNodes,
                                expression: "item.openNodes",
                              },
                            ],
                            staticClass: "next-container",
                          },
                          _vm._l(item.nodes, function (subMenu, subIndex) {
                            return _c("div", {
                              key: subIndex,
                              class: [
                                "single-one",
                                subMenu.activeNodes && "active",
                              ],
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.highlightRender(
                                    _vm.generateTitle(subMenu.displayText)
                                  )
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.routerJump(
                                    subMenu,
                                    item.nodes,
                                    true
                                  )
                                },
                              },
                            })
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "menu-toggle-container",
          style: {
            right: _vm.isCollapse ? "173px" : "-17px",
          },
          on: { click: _vm.collapseHandler },
        },
        [
          _c("i", {
            staticClass: "el-icon-arrow-right",
            style: {
              transform: `rotate(${_vm.isCollapse ? 0 : 180}deg)`,
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }