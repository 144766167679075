import { listToTree } from "@/utils/tree";
const common={
	/**
	 * 返回格式化后的时间 yyyy-MM-dd HH:mm:ss
	 */
	getTimeMmss : function(Date1,type,midFlag){
		if(!Date1) return '';
		Date1=new Date(Date1)
		var year = Date1.getFullYear();
		var month = (Date1.getMonth() + 1) < 10 ? "0" + (Date1.getMonth() + 1) : (Date1.getMonth() + 1);
		var day = Date1.getDate()  < 10 ? "0" + Date1.getDate() : Date1.getDate();
		var hour = Date1.getHours() < 10 ? "0" + Date1.getHours() : Date1.getHours();
		var minute = Date1.getMinutes() < 10 ? "0" + Date1.getMinutes() : Date1.getMinutes();
		var second = Date1.getSeconds() < 10 ? "0" + Date1.getSeconds() : Date1.getSeconds();
		if(type === "hm"){
			return hour + (midFlag ? midFlag : ":") + minute;
		}
		if(type === "hms"){
			return hour + (midFlag ? midFlag : ":") + minute + (midFlag ? midFlag : ":") +second
		}
		if(type==='day'){
			return year+"-"+month+"-"+day;
		}
		return year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second;
	},
	//返回格式化后的时间 yyyy-MM-dd
	getTimeDay : function(date,type,flag){
		if(!date) return '';
		var year = date.getFullYear();
		var month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
		var day = date.getDate()  < 10 ? "0" + date.getDate() : date.getDate();
		if(flag){
			return year+'年'+month+'月'+day+'日';
		}
		if(type){
			return year+type+month+type+day;
		}
		return year+"-"+month+"-"+day;
	},
	getSEndTimeByArr(arr,type){
		let obj={
			startDate:'',
			endDate:'',
		}
		if(!arr) return obj;
		if(typeof(arr)=='object'){
			if(type=='mmss'){
				obj={
					startDate:common.getTimeMmss(arr[0]),
					endDate:common.getTimeMmss(arr[1]),
				}

			}
			else{
				obj={
					startDate:common.getTimeDay(arr[0]),
					endDate:common.getTimeDay(arr[1]),
				}
			}
		}
		return obj;

	},
	//时间戳转换成年月日中文格式
	timeNumtoStrYmd:function(num){
		let test = new Date(parseInt(num));
	    let year = test.getFullYear();
	    let month = parseInt(test.getMonth())+1;
	    let day = test.getDate();
	    let result = year +"-"+month+"-"+day;
	    if(!num) result='';
	    return result;
	},
	//校验时间格式 yyyy-MM-dd HH:mm:ss
	checkDateFormat:function(str){
		var reg = /^(\d+)-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/;
		var r = str.match(reg);
		if(r==null)return false;
		r[2]=r[2]-1;
		var d= new Date(r[1], r[2],r[3], r[4],r[5], r[6]);
		if(d.getFullYear()!=r[1])return false;
		if(d.getMonth()!=r[2])return false;
		if(d.getDate()!=r[3])return false;
		if(d.getHours()!=r[4])return false;
		if(d.getMinutes()!=r[5])return false;
		if(d.getSeconds()!=r[6])return false;
		return true;
	},
	//校验时间格式 yyyy-MM-dd
	checkDayFormat:function(str){
		var reg = /^(\d+)-(\d{1,2})-(\d{1,2})$/;
		var r = str.match(reg);
		if(r==null)return false;
		r[2]=r[2]-1;
		var d= new Date(r[1], r[2],r[3]);
		if(d.getFullYear()!=r[1])return false;
		if(d.getMonth()!=r[2])return false;
		if(d.getDate()!=r[3])return false;
		return true;
	},
	//判断正确的起始结束时间格式，maxDays 不能超过最大天数，
	correctTimeParams:function(startTime,endTime,maxDays){
		let result = {
			status:false,
			message:'',
		}
		
		if(!startTime&&!endTime){
			result.status=true;
			return result;
		}
		if(!startTime){
			result.message='开始时间不能为空';
			return result;
		}
		if(!endTime){
			result.message='结束时间不能为空';
			return result;
		}
		//获取时间戳
		let startTimestamp = new Date(startTime).getTime();
		let endTimestamp = new Date(endTime).getTime();
		let timeCha=endTimestamp-startTimestamp;
		if(timeCha <0){
			result.message='时间选择不正确,结束时间应大于起始时间';
			return result;
		}
		if(maxDays>0&&timeCha>maxDays*24*60*60*1000){
			result.message='时间区间不能超过'+maxDays+'天';
			return result;
		}
		result.status=true;
		return result;
	},
	/**
     * 给选择框注入选择项
     * params key:对应的键值名称
     * params option：动态渲染数据数组
     * params arr：自定义的search.js里的json
     * 效果 等同于将 option的值赋予 searchJS[i].key=option;
     */
    injectionOption(key,option,arr){
		for(let it of arr){
				if(it.bindKeys == key){
						it.option = option;
						break;
				}
		}
		return arr
	},
	 injectionOption2(key,options,arr){
		//作用于table2 行内查询条件
		for(let it of arr){
			if(it.prop == key){
				if(it.queryInfo){
					it.queryInfo.options = options;
					break;
				}
			}
		}
		return arr
	},
	insertOptions(arr,keys,options,keysValue = 'keys',optionsValue = 'options'){//把options 根据key 插入到指定数组的位置上  -一般作用与From表单
		for(let it of arr){
			if(it[keysValue] == keys){
				it[optionsValue] = options;
				return
			}
		}
	},
	//去空格
	stringTrim : function (str) {
    if (typeof str === 'undefined' || str === null) {
      return ''
    }
		var s = str + "";
		if(!s) {
			return s;
		}
    return s.replace(/(^\s*)|(\s*$)|(^\u3000*)/g, "");
	},
	
	//根据字段数组去重
	uniqueArray:function(arr,keys){
		if(!keys) keys='id';//默认id
		var obj = {};
		let result=[];
	   	for(let item of arr){
	   		if(!obj[item[keys]]){
	   			result.push(item);
	   			obj[item[keys]] = true;
	   		}
	   	}
		return result;
	},
	uniqueArrByKeys(Arrs,key='id'){
		//数组对象通过key去重 
		let hash = {}
		const newArr = Arrs.reduceRight((item, next) => {
	        hash[next[key]] ? '' : hash[next[key]] = true && item.push(next)
	        return item
	    }, [])
		return newArr;
	},
	//通过当前节点找到所有父节点
 	findAllParentsByNode(id,treeData,flag,childs='children'){
 		let arr = []; // 递归遍历时操作的数组
		let returnArr = []; //结果
		let depth = 0; //节点在第几层
 		//定义函数
		function findNodeDatas(dataArr, depthN) {
			for(let item of dataArr){
				depth = depthN; // 将执行的层级赋值 到 全局层级
				arr[depthN] = flag ? item[flag] : item.id;
				if(id==arr[depthN]){
					returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
				}else {
					if(item[childs]) {
						depth++;
						findNodeDatas(item[childs], depth);
					}
				}
			}
			return returnArr;
		}
		return findNodeDatas(treeData, depth);
 	},
 	//通过自己传入的key值查找出树形数据子级需要的值
 	findTreeValBykeys(treeData,keys,value,childs='children'){
 		let result='';
 		function findNodeDatas(dataArr,keys,value){
 			for(let item of dataArr){
 				if(result) break;
				if(value==item[keys]){
					result=item;
				}else {
					if(item[childs]) {
						findNodeDatas(item[childs],keys,value);
					}
				}
			}
 			return result;
 		}
 		return findNodeDatas(treeData,keys,value);
 	},
 	//数组根据指定字段排序
 	quickSort(arr,name){
  		if(arr.length<=1){
   			return arr;
  		}
	  	var pivotIndex=Math.floor(arr.length/2);
	  	var pivot=arr.splice(pivotIndex,1)[0];
	  	var middleNum=pivot[name];
	  	var left=[];
	  	var right=[];
	   	for(var i=0;i<arr.length;i++){
	    	if(arr[i][name]<=middleNum){
	     		left.push(arr[i]);
	    	}
		    else{
		     	right.push(arr[i]);
		    }
   		}
  		return common.quickSort(left,name).concat([pivot],common.quickSort(right,name));
 	},
 	 //校验是否手机号码
	checkPhone:function (phone){
		let result=true;
	    if(!(/^1[3456789]\d{9}$/.test(phone))){
	        result=false; 
	    }
	    return result;
	},
	//检验身份证号码
	checkIdCard:function (value){
		let reg=/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
		let result=true;
	    if(!(reg.test(value))){
	        result=false; 
	    }
	    return result;
	},
	
	//校验是否邮箱格式
	checkEmail:function (email){
		let result=true;
	    if(!( /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/.test(email))){
//		if(!( /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))){
	        result=false; 
	    }
	    return result;
	},
	
	formatSeconds(value) {
		// 需要转换的时间秒   转成 多少天 多少分 多少秒
		var theTime = parseInt(value); 
		var theTime1 = 0; // 分 
		var theTime2 = 0; // 小时 
		var theTime3 = 0; // 天
		if(theTime > 60) {
			theTime1 = parseInt(theTime / 60);
			theTime = parseInt(theTime % 60);
			if(theTime1 > 60) {
				theTime2 = parseInt(theTime1 / 60);
				theTime1 = parseInt(theTime1 % 60);
				if(theTime2 > 24) {
					//大于24小时
					theTime3 = parseInt(theTime2 / 24);
					theTime2 = parseInt(theTime2 % 24);
				}
			}
		}
		var result = '';
		if(theTime > 0) {
			result = "" + parseInt(theTime) + "秒";
		}
		if(theTime1 > 0) {
			result = "" + parseInt(theTime1) + "分" + result;
		}
		if(theTime2 > 0) {
			result = "" + parseInt(theTime2) + "小时" + result;
		}
		if(theTime3 > 0) {
			result = "" + parseInt(theTime3) + "天" + result;
		}
		return result;
	},
	hexToRgba(hex, opacity=0.3) {
		return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' +
			parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')';
	},
	shiftStyle(data){
		let color='#1a4cec';
		if(data&&data.shiftColor){
			color=data.shiftColor
		}
		let opacity=color+'40';
		let style1={
			'border-left':'3px solid '+color,
			'background':opacity,
		}
		return style1;
		
	},
	
	exportByTolink:function(url,data={}){
		//通过链接直接访问下载文件
		let localtionUrl = window.location.origin;
		url+='?';
		for(let keys in data){
			if(data[keys]){
				url=url+keys+'='+data[keys]+'&'
			}
		}
		window.open(encodeURI(url));
//		window.location.href=url;
//		var form = $("<form>"); //定义一个form表单
//		form.attr("style", "display:none");
//		form.attr("target", "");
//		form.attr("method", "get");
////		form.attr("action", localtionUrl + url);
//		form.attr("action", '/saas/scheduling/export/exportAll?year=2023&month=2&attendanceGroupId=60');
//		
//		$("body").append(form); //将表单放置在web中
//		form.submit(); //表单提交
	},
	diffTimeAllow(shiftListA,shiftListB){
		//作用域班次管理的函数   shiftListA 已经存在的班次   shiftListB即将添加的班次
		function toHhmmNumber(Arr){
			//先将每个 时间 转换成时间戳  dateNum 默认为1  带有次日标识的话 变成2 
			Arr.map(it=>{
				if(it.workTime){
					it.workTimeArray=JSON.parse(it.workTime)
				}
				if(it.workTimeArray){
					for(let _t of it.workTimeArray){
						for(let k1 in _t){
							let itk=_t[k1];
							let dateNum=1;
							if(itk.workDay=='nextDay') dateNum=2;
							let keyName=k1+'Num';
							_t[keyName]=new Date('2022-02-'+dateNum+' '+itk.time).getTime();
	//								console.log('tNum',itk.time,itk)
						}
					}
				}
			})
			console.log('Arr',JSON.parse(JSON.stringify(Arr)))
		}
		
		function checkisAllow(data,Arr){
			//开始比对
			if(!data||!Arr) return;
			let allow=true;
			const {startTimeNum,endTimeNum}=data;
			for(let shift of Arr){
				for(let it of shift.workTimeArray){
//					console.log('--bbbbb--',JSON.parse(JSON.stringify(it)))
//					it.startTimeNum(9:00) ~~~ it.endTimeNum(12:00)  例如已存在班次的某个时间段
//					data.startTimeNum(11:00) ~~data.endTimeNum(16:00)  例如即将添加班次的某个时间段   这种情况是不允许添加的
					if(data.endTimeNum>it.startTimeNum&&data.endTimeNum<it.endTimeNum){
						//第1种情况例如已存在班次  9-10，  即将添加的班次开始时间只要是在当天n-10点之间也不允许添加  即将添加的班次结束时间不能在已存在的班次时间区间内
						console.log('不允许添加的第1种情况 9-10 不能再添加结束时间为9~11之间的')
						allow=false;
					}
					if(data.startTimeNum>it.startTimeNum&&data.startTimeNum<it.endTimeNum){
						//第2种情况  例如已存在班次  9-10，   即将添加的班次开始时间只要是在当天9-11点之间 就不允许添加 既即将添加的班次开始时间不能在已存在的班次时间区间内
						console.log('不允许添加的第2种情况 9-10   不能再添加开始时间为9~11之间的')
						allow=false;
					}
					if(data.startTimeNum<it.startTimeNum&&data.endTimeNum>it.endTimeNum){
						//第3种情况  即将添加的时间段8-15  包含了 已有的班次时间段9-10
						console.log('不允许添加的第3种情况 8-15   不能再添加开始时间为9~11之间的');
						allow=false;
						
					}
					if(!allow) break;
				}
				if(!allow) break;
			}
			return allow;
		}
		let isAllow=true;
		toHhmmNumber(shiftListA);
		toHhmmNumber(shiftListB);
		for(let shift of shiftListB){
			if(shift.workTimeArray){
				for(let it of shift.workTimeArray){
					isAllow=checkisAllow(it,shiftListA);
					if(!isAllow){
						break;
					}
				}
			}
			
			if(!isAllow) break;
		}
		return isAllow
	},
	getOrderAuditUrlByType(auditType){
		const requestMaps = {
       		1: "/order/tenant/orderDelayed/auditDelayed",
        	2: "/order/tenant/orderAssist/auditAssist",
        	3: "/order/tenant/orderTransfer/auditTransfer",
        	4: "/order/tenant/orderMateriel/auditMateriel",
        	5: "/order/tenant/orderFill/auditImplement",
        	6: "/order/tenant/orderFill/reportAudit",
        	7: "/order/tenant/orderFill/auditCloseOrder",
      	};
		return requestMaps[auditType];
	},
	getOrderAuditParams(auditType){
		const objMaps = {
       		1: {name:'延时审核',urls:"/order/tenant/orderDelayed/auditDelayed",keys:'orderDelayedId',rejectKey:'examineFailReason'},
        	2: {name:'协助审核',urls:"/order/tenant/orderAssist/auditAssist",keys:'orderAssistId',rejectKey:'examineFailReason'},
        	3: {name:'转派审核',urls:"/order/tenant/orderTransfer/auditTransfer",keys:'orderTransferId',rejectKey:'refuseReason'},
        	4: {name:'物料审核',urls:"/order/tenant/orderMateriel/auditMateriel",keys:'orderMaterielId',rejectKey:'toExamineOpinion'},
        	5: {name:'验收审核',urls:"/order/tenant/orderFill/auditImplement",keys:'orderAssistId',rejectKey:'examineFailReason'},
        	6: {name:'报单审核',urls:"/order/tenant/orderFill/reportAudit",keys:'orderId',rejectKey:'examineFailReason'},
        	7: {name:'关单审核',urls:"/order/tenant/orderFill/auditCloseOrder",keys:'orderId',rejectKey:'examineFailReason'},
      	};
		return objMaps[auditType];
	},
	getOrderStatus(val=0,type='name'){
		const Arr=[
			{name:'报单待审核',val:0},
			{name:'待领取',val:1},
			{name:'待执行',val:2},
			{name:'执行中',val:3},
			{name:'待验收',val:4},
			{name:'已完成',val:5},
			{name:'报单被驳回',val:6},
			{name:'验收被驳回',val:7},
			{name:'已撤销',val:8},
			{name:'已关单',val:9},
		]
		return Arr[+val][type]
	},
	creadWebId(){
		let t=new Date().getTime();
		return 'webId'+t;
	},
	base64toBlob(dataurl){
		//base64转成bold文件
	    let arr = dataurl.split(','),
	        mime = arr[0].match(/:(.*?);/)[1],
	        bstr = atob(arr[1]),
	        n = bstr.length,
	        u8arr = new Uint8Array(n);
	    while (n--) {
	        u8arr[n] = bstr.charCodeAt(n);
	    }
	    return new Blob([u8arr], { type: mime });
	},
	 asyncImgChecked(file) {
	 	//获取视频文件的缩略图
      	return new Promise((resolve, reject) => {
        	let reader = new FileReader();
        	reader.readAsDataURL(file); // 必须用file.raw
        	reader.onload = ()=> { // 让页面中的img标签的src指向读取的路径
          		let video = document.createElement('video');
          		video.src = reader.result;
          		video.currentTime = 15; //截取缩略图时的视频时长，一定要设置，不然大概率白屏
          		video.oncanplay = ()=>{
            		const canvas = document.createElement('canvas');
            		const ctx = canvas.getContext('2d');
            		canvas.setAttribute("width",100);
            		canvas.setAttribute("height",100);
            		video.setAttribute('width', 100);
            		video.setAttribute('height', 100);
            		ctx.drawImage(video, 0, 0, video.width, video.height);
            		const imgSrc = canvas.toDataURL('image/png');
            		resolve({
          				duration:parseInt(video.duration % 60),//得到整数的视频时长
              			imgSrc,//base64的缩略图图片路径
              			width:video.videoWidth,
              			height:video.videoHeight,
              			videoName:file.name,
              			boldFile:common.base64toBlob(imgSrc),
            		})
          		}
        	};
      	})
    },
   
    bmsJumpDatas:{
    	//作用于BMS跳转Rwork 并且刷新后bms携带的参数需要清空
    	type:'',
    	startDate:'',
    	endDate:'',
    	statuArrs:[],
    },
    clearBmsDatas(){
    	common.bmsJumpDatas={
    		type:'',
	    	startDate:'',
	    	endDate:'',
	    	statuArrs:[],
    	}
    },
    getMonFristEndDay(isHms=true){
    	//获取本月的第一天跟最后一天
	 	var y = new Date().getFullYear(); //获取年份
	    var m = new Date().getMonth() + 1; //获取月份
	    var end = new Date(y, m, 0).getDate(); //获取当月最后一日
	    var frist = '01'
	    m = m < 10 ? '0' + m : m; //月份补 0
	    let d1=[y,m,frist].join("-");
     	let d2=[y,m,end].join("-");
     	if(isHms){
     		d1+=' 00:00:00';
     		d2+=' 23:59:59';
     	}
	    return {
	    	startDate:d1,
	    	endDate:d2,
	    }
    },
    listToTree:listToTree,
    	
}
export default common
