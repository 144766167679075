var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-screen-contaainer", on: { click: _vm.clickHandler } },
    [
      !_vm.isFullscreen
        ? _c("img", {
            attrs: {
              src: require("../../assets/images/menu_screen_icon.png"),
              alt: "",
            },
          })
        : _c("img", {
            attrs: {
              src: require("../../assets/images/menu_screen_cancel.png"),
              alt: "",
            },
            on: { click: _vm.clickHandler },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }