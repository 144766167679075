import Vue from 'vue';
import Cookies from 'js-cookie';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';

import i18n from './lang'; // Internationalization

import './icons'; // icon
import './permission'; // permission control
import * as filters from './filters'; // 全局过滤器
import './directive'; // 自定义指令

// echarts
import ECharts from 'vue-echarts';

// jsmind style
import './styles/jsmind.less';

// 按需引用相关图表
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/radar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/bar';

import TitleIcon from '@/components/TitleIcon'; // 标题图标

// 初始化自定义相关的API和组件
import { initCustomComponent } from '@/utils/customUtils.js';
initCustomComponent();

// console.log('FAQ', FAQ);
//打印
import Print from 'vue-print-nb';
//注册全局外层主盒子组件
import mainBox from '@/components/YTable/mainBox.vue';
Vue.component('mainBox', mainBox);
import ApiHttp from '@/api/apiHttp';
Vue.prototype.ApiHttp = ApiHttp;
//引用公共方法
import common from '@/common/common.js';
Vue.prototype.common = common;
// jquery ui
// import 'jquery-ui-dist/jquery-ui';

// jsplumb
// import 'jsplumb/dist/js/jsplumb.min.js';
// import 'jsplumb/css/jsplumbtoolkit-defaults.css';

// 阿里矢量图库
import '@/assets/css/iconfont.css';

import EventBus from './eventBus';
//图片预览
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
Vue.prototype.$eventBus = EventBus;
// brach commit update
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
});
Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source',
  },
});

import BaiduMap from 'vue-baidu-map';

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'gcz2nZsuNHNg4C6AGGQzAjuKhzu6ZPEG',
});

//注册到vue
import * as socketApi from '@/utils/socket';
Vue.prototype.socketApi = socketApi;

//拖拽 缩放
import VueDragResize from 'vue-drag-resize';
Vue.component('vue-drag-resize', VueDragResize);

//拖拽 缩放
import RButton from '@/components/RButton/index.vue';
Vue.component('r-button', RButton);

Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});

Vue.use(Print);

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 全局注册v-chart组件
Vue.component('v-chart', ECharts);
// 全局注册标题图标组件
Vue.component('title-icon', TitleIcon);

Vue.config.productionTip = false;

window.top.APP = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// console.log('merge test')
