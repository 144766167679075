var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.item.hidden && _vm.isCurrentMenu(_vm.item)
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        _vm._l(_vm.item.nodes, function (child, index) {
          return _c("Sidemenu", {
            key: index,
            staticClass: "nest-menu",
            attrs: { item: child },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }