import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询巡检完成数
 * @param params
 */
export function inspectionCompleteNumber(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/job/completeNumber',
        method: 'get',
        params
    })
}

/**
 * 查询维保完成数
 * @param params
 */
export function maintenanceCompleteNumber(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/job/completeNumber',
        method: 'get',
        params
    })
}

/**
 * 查询维保下发数
 * @param params
 */
export function maintenanceLowerHairNumber(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/job/lowerHairNumber',
        method: 'get',
        params
    })
}

/**
 * 查询工单完成数
 * @param params
 */
export function orderCompleteNumber(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/completeNumber',
        method: 'get',
        params
    })
}

/**
 * 查询设备数
 * @param params
 */
export function deviceTotal(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/total',
        method: 'get',
        params
    })
}

/**
 * http: //localhost:8080/saascloud/saas/order/tenant/order/completionRate?day=7
 * 查询工单完成率
 * @param params
 */
export function orderCompleteRate(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/completionRate',
        method: 'get',
        params
    })
}

/**
 * 查询巡检完成率
 * @param params
 */
export function inspectionCompleteRate(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/job/completionRate',
        method: 'get',
        params
    })
}

/**
 * 查询维保完成率
 * @param params
 */
export function maintenanceCompleteRate(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/job/completionRate',
        method: 'get',
        params
    })
}

/**
 * 查询工单填报趋势
 * @param params
 */
export function orderReportsList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/reportsList',
        method: 'get',
        params
    })
}

/**
 * 查询工单完成趋势
 * @param params
 */
export function orderCompleteList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/completeList',
        method: 'get',
        params
    })
}

/**
 * 查询工单完成量排行
 * @param params
 */
export function orderCompleteAmount(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/userCompleteAmount',
        method: 'get',
        params
    })
}

/**
 * 查询设备类型工单填报统计
 * @param params
 */
export function deviceOrderReportsList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/deviceReportsList',
        method: 'get',
        params
    })
}

/**
 * 查询设备类型工单完成统计
 * @param params
 */
export function deviceOrderCompleteList(params) {
    return request({
        url: envInfo.bgApp.orderPath + '/tenant/order/deviceCompleteList',
        method: 'get',
        params
    })
}

/**
 * 查询维保完成数统计
 * @param params
 */
export function maintenanceCompleteList(params) {
    return request({
        url: envInfo.bgApp.maintenancePath + '/tenant/maintenance/job/completeList',
        method: 'get',
        params
    })
}

/**
 * 查询巡检完成数统计
 * @param params
 */
export function inspectionCompleteList(params) {
    return request({
        url: envInfo.bgApp.inspectionPath + '/tenant/inspection/job/completeList',
        method: 'get',
        params
    })
}
