/* Layout */
import Layout from '@/views/ruge/layout/Layout';

const legoRouter = [
  {
    path: '/lego',
    component: Layout,
    redirect: '/lego/informationDelivery/serviceGuide',
    children: [
      // 商户号管理
      {
        path: 'merchant/list',
        name: 'legoMerchantList',
        component: () => import('@/views/ruge/lego/merchantManagement'),
      },
      // 体育场 - 场地配置
      {
        path: 'stadium/siteConfig',
        name: 'legoStadiumSiteConfig',
        component: () => import('@/views/ruge/lego/stadium/siteConfig'),
      },
      // 体育场 - 次卡配置
      {
        path: 'stadium/cardConfig',
        name: 'legoStadiumCardConfig',
        component: () => import('@/views/ruge/lego/stadium/cardConfig'),
      },
      // 体育场 - 场地日历
      {
        path: 'stadium/siteCalendar',
        name: 'legoStadiumSiteCalendar',
        component: () => import('@/views/ruge/lego/stadium/siteCalendar'),
      },
      // 体育场 - 场地预约
      {
        path: 'stadium/siteBooking',
        name: 'legoStadiumSiteBooking',
        component: () => import('@/views/ruge/lego/stadium/siteBooking'),
      },
      // 小程序配置 - 企业管理
      {
        path: 'enterprise/list',
        name: 'legoEnterpriseList',
        component: () => import('@/views/business/enterpriseNew'),
      },
      {
        path: 'enterprise/detail',
        name: 'enterpriseDeatil',
        meta: {
          detail: true,
          name: 'zh-CN=企业详情;en-US=Enterprise Detail',
          goBackRouter: '/lego/enterprise/list',
        },
        component: () => import('@/views/business/enterpriseDetail'),
      },
      // 小程序配置 - 招商管理
      {
        path: 'investment/list',
        name: 'legoInvestmentList',
        component: () => import('@/views/ruge/lego/investmentManagement'),
      },
      // 小程序配置 - 列表页
      {
        path: 'config/list',
        name: 'customerServiceConfigList',
        component: () => import('@/views/ruge/lego/config'),
      },
      // 小程序配置 - 列表页
      {
        path: 'releaseRecord',
        name: 'legoReleaseRecord',
        component: () => import('@/views/ruge/lego/releaseRecord'),
      },
      // 小程序配置 - 列表页
      {
        path: 'config/edit',
        name: 'customerServiceConfigEdit',
        meta: {
          detail: true,
          name: '小程序配置',
          goBackRouter: '/lego/config/list',
        },
        component: () => import('@/views/ruge/lego/edit'),
      },
      //ych小程序管理-start
      {
        path: 'miniMange/configure',
        name: 'configure', //功能配置
        component: () => import('@/views/ruge/lego/miniManage/configure'),
      },
      {
        path: 'miniMange/featureList',
        name: 'featureList', //功能列表[管理]
        component: () => import('@/views/ruge/lego/miniManage/featureList'),
      },
      {
        path: 'miniMange/parkingLot',
        name: 'parkingLot', //停车场管理
        component: () => import('@/views/ruge/lego/miniManage/parkingLot'),
      },
      {
        path: 'miniMange/callService',
        name: 'callService', //联系客服
        component: () => import('@/views/ruge/lego/miniManage/callService'),
      },
      {
        path: 'miniMange/evaluationManagement',
        name: 'evaluationManagement', //联系客服
        component: () =>
          import('@/views/ruge/lego/miniManage/evaluationManagement'),
      },
      //ych小程序管理-end
      // 园区办事 - 办事指南
      {
        path: 'informationDelivery/serviceGuide',
        name: 'BMSgsParkServiceGuide',
        component: () =>
          import('@/views/ruge/lego/informationDelivery/serviceGuide'),
      },
      // 信息发布 - 园区活动
      {
        path: 'informationDelivery/parkActivity',
        name: 'BMgsParkSparkActivity',
        component: () =>
          import('@/views/ruge/lego/informationDelivery/parkActivity'),
      },
      // 信息发布 - 园区公告
      {
        path: 'informationDelivery/parkNotice',
        name: 'BMSgsParkQrCodeRecord',
        component: () =>
          import('@/views/ruge/lego/informationDelivery/parkNotice'),
      },
      // 信息发布 - banner配置
      {
        path: 'informationDelivery/bannerConfig',
        name: 'BMSBannerConfig',
        component: () =>
          import('@/views/ruge/lego/informationDelivery/bannerConfig'),
      },
      // 会议室 - 会议预定配置
      {
        path: 'MeetingRoom/ReservationConfigura',
        name: 'BMSgsParkparkReservationConfigura',
        component: () =>
          import('@/views/ruge/lego/MeetingRoom/ReservationConfigura'),
      },
      // 会议室 - 会议列表
      {
        path: 'MeetingRoom/ReservationList',
        name: 'BMSgsParkparkReservationList',
        component: () =>
          import('@/views/ruge/lego/MeetingRoom/ReservationList'),
      },
      // 会议室 - 会议日历
      {
        path: 'MeetingRoom/ReservationCalendar',
        name: 'BMSgsParkparkReservationCalendar',
        component: () =>
          import('@/views/ruge/lego/MeetingRoom/ReservationCalendar'),
      },
      // 园区办事 - 缴费记录
      {
        path: 'paymentInvoice/paymentRecord',
        name: 'BMSgsParkPaymentRecord',
        component: () =>
          import('@/views/ruge/lego/paymentInvoice/paymentRecord'),
      },
      // 园区办事 - 充电扣费记录
      {
        path: 'paymentInvoice/chargPayment',
        name: 'BMSgsParkChargPayment',
        component: () =>
          import('@/views/ruge/lego/paymentInvoice/chargPayment'),
      },
      // 园区办事 - 发票开具记录
      {
        path: 'paymentInvoice/invoiceRecord',
        name: 'BMSgsParkInvoiceRecord',
        component: () =>
          import('@/views/ruge/lego/paymentInvoice/invoiceRecord'),
      },
      // 信息发布 -开票失败列表
      {
        path: 'paymentInvoice/invoicingFailure',
        name: 'BMSgsParkInvoicingFailure',
        component: () =>
          import('@/views/ruge/lego/paymentInvoice/invoicingFailure'),
      },
      // 园区办事 - 有偿工单记录
      {
        path: 'paymentInvoice/paidWorkOrder',
        name: 'BMSgsParkPaidWorkOrder',
        component: () =>
          import('@/views/ruge/lego/paymentInvoice/paidWorkOrder'),
      },
      // 园区办事 - 有偿工单详情
      {
        path: 'paymentInvoice/paidWorkOrder/paidWorkDetail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=有偿工单详情;en-US=Paid Work Order Details',
          goBackRouter: '/lego/paymentInvoice/paidWorkOrder',
        },
        name: 'legoPaidWorkDetail',
        component: () =>
          import(
            '@/views/ruge/lego/paymentInvoice/paidWorkOrder/paidWorkDetail.vue'
          ),
      },
      // 园区办事 - 用户评价
      {
        path: 'parkAffairs/userEvaluation',
        name: 'BMSgsParkUserEvaluation',
        component: () => import('@/views/ruge/lego/parkAffairs/userEvaluation'),
      },
      // 园区办事 - 投诉建议
      {
        path: 'parkAffairs/suggestions',
        name: 'BMSgsParkSuggestions',
        component: () => import('@/views/ruge/lego/parkAffairs/suggestions'),
      },
      // 园区办事- 停车月卡申请
      {
        path: 'parkAffairs/monthlyCardApplication',
        name: 'BMSgsParkMonthlyCardApplication',
        component: () =>
          import('@/views/ruge/lego/parkAffairs/monthlyCardApplication'),
      },
      // 园区办事- 停车月卡申请 - 查看详情
      {
        path: 'parkAffairs/monthlyCardApplication/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=停车月卡申请;en-US=parkAffairs MonthlyCardApplication',
          goBackRouter: '/lego/parkAffairs/monthlyCardApplication',
        },
        name: 'gsParkParkingDetails',
        component: () =>
          import(
            '@/views/ruge/lego/parkAffairs/monthlyCardApplication/detail.vue'
          ),
      },
      // 园区办事 - 地面行车审核
      {
        path: 'parkAffairs/groundRunReview',
        name: 'BMSgsParkGroundRunReview',
        component: () =>
          import('@/views/ruge/lego/parkAffairs/groundRunReview'),
      },
      // 园区办事 - 货梯使用
      {
        path: 'parkAffairs/freightElevators',
        name: 'BMSgsParkFreightElevators',
        component: () =>
          import('@/views/ruge/lego/parkAffairs/freightElevators'),
      },
      // // 园区办事 - 空调加时
      {
        path: 'parkAffairs/airConOvertime',
        name: 'BMSgsParkAirConOvertime',
        component: () => import('@/views/ruge/lego/parkAffairs/airConOvertime'),
      },
      // // 园区办事 - 物品放行
      {
        path: 'parkAffairs/releaseItems',
        name: 'BMSgsParkReleaseItems',
        component: () => import('@/views/ruge/lego/parkAffairs/releaseItems'),
      },
      // 客服坐席记录 - 工单事件
      {
        path: 'customerSeats/workOrderEvent',
        name: 'gsParkWorkOrderEvent',
        component: () =>
          import('@/views/ruge/lego/customerSeats/workOrderEvent'),
      },
      {
        path: 'customerSeats/workOrderEvent/detail',
        // 如果是详情页，需要配置detail:true
        meta: {
          detail: true,
          name: 'zh-CN=详情页;en-US=Work Order Details',
          goBackRouter: '/lego/customerSeats/workOrderEvent',
        },
        name: 'gsParkactivityRecordDetails',
        component: () =>
          import('@/views/ruge/lego/customerSeats/workOrderEvent/detail.vue'),
      },
    ],
  },
];

export default legoRouter;
