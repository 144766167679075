const workflow = {
    state : {
        flowVariable : null,//流程变量 {procInstId:xx, businessKey: xx, sourceApp: xx, porcDefKey: xx}
    },
    mutations: {
        SET_FLOW_VARIABLE: (state, variable) => {
            state.flowVariable = variable;
        },
    },
    actions: {
        SetFlowVariable({commit}, variable){
            commit('SET_FLOW_VARIABLE', variable);
        },
    }
}

export default workflow
