import request from '@/utils/request';
import { BaseURL } from '@/constants/envInfo';

const apiHttp = function (url, data, type = 'get',isFile=false) {
  let dflodading = true;
  if (data && !data.isloading){
  	dflodading = false;
  	delete data.isloading;
  }
  if (type == 'get') {
    let paramStr = '';
    for (let keys in data) {
      if (data[keys] == null || data[keys] == undefined) data[keys] = '';
      paramStr = paramStr + keys + '=' + data[keys] + '&';
    }
    if (paramStr) {
      paramStr = paramStr.substring(0, paramStr.length - 1);
      url = url + '?' + paramStr;
    }
  }
  let sendObject = {
		url: BaseURL + url,
    method: type,
    data: data,
    isloading: dflodading,
	};
	if(isFile){
		sendObject.headers={
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}
  //请求数据
  return request(sendObject);
};
export default apiHttp;
