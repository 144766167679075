const notify = {
    state :{
        exportTaskList:[],//导出任务通知列表
    },
    mutations: {
        //导出任务
        PUSH_EXPORTTASKLIST: (state, exportTask) => {
            state.exportTaskList.push(exportTask);
        },
        SET_EXPORTTASK: (state, exportTask) => {
            state.exportTaskList[state.exportTaskList.length - 1] = exportTask;
        },
        REMOVE_EXPORTTASK: (state, i) => {
            state.exportTaskList.splice(i,1);
        },
    },
    actions: {
        //导出任务
        PushExportTaskList({commit},exportTask){
            commit('PUSH_EXPORTTASKLIST', exportTask);
        },
        SetExportTask({commit},exportTask){
            commit('SET_EXPORTTASK', exportTask);
        },
        RemoveExportTask({commit},i){
            commit('REMOVE_EXPORTTASK', i);
        },
    }
}

export default notify
