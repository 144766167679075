import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 查询实时运行数据分页
 * @param params
 */
export function findRealAlarm(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/list',
    method: 'get',
    params,
  });
}

/**
 * 查询历史告警分页
 * @param params
 */
export function getHistoryAlarmPage(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/history/page/list',
    method: 'get',
    params,
  });
}

/**
 * 告警趋势
 * @param params
 */
export function getAlarmTrend(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/history/alarmTrend',
    method: 'get',
    params,
  });
}

/**
 * 导出告警列表
 * @param params
 */
export function exportAlarm(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/history/export',
    method: 'post',
    data: params,
  });
}

/**
 * 各级别告警个数
 * @param params
 */
export function alarmLevelCount(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/history/alarmLevelCount',
    method: 'get',
    params,
  });
}

/**
 * 告警确认
 * @param params
 */
export function confirmAlarm(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/confirm',
    method: 'post',
    data: params,
  });
}

/**
 * 告警确认New
 * @param params
 */
export function confirmAlarmNew(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/alarm/confirmAlarm',
    method: 'post',
    data: params,
  });
}

/**
 * 点位报警批量确认
 * @param params
 */
export function batchConfirmAlarm(params) {
  return request({
    url: envInfo.bgApp.apiPath + '/alarm/batchConfirm',
    method: 'post',
    data: params,
  });
}

/**
 * 隔离告警确认
 * @param params
 */
// export function isolateConfirm(params){
//     return request({
//         url: envInfo.bgApp.apiPath + '/isolate/confirm',
//         method: 'post',
//         data:params
//     })
// }
