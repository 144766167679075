<template>
  <div class="shortcut-container">
    <img
      src="@/assets/images/shotcut_menu_icon.png"
      alt=""
      style="cursor: pointer"
      @click="openShormenu"
    />
    <el-drawer
      :size="400"
      style="line-height: 16px"
      :visible.sync="shormenuShow"
      :before-close="
        () => {
          shormenuShow = false;
        }
      "
      direction="rtl"
    >
      <template slot="title">
        <span><title-icon />快捷菜单</span>
      </template>
      <span class="edit-label" @click="openMenuDialog"> 编辑 </span>
      <div class="menu-list" v-if="menuList.length">
        <div
          class="single-one"
          @click="routePathJump(item.href)"
          v-for="(item, index) in menuList"
          :key="index"
        >
          <div class="icon-line">
            <svg-icon v-if="item.icon" :icon-class="item.icon" />
            <svg-icon v-else icon-class="zuzhiguanli" />
          </div>
          <div class="label-line">
            {{ generateTitle(item.displayText) }}
          </div>
        </div>
      </div>
      <div v-else class="nodata-container">
        <div class="nodata-add" @click="openMenuDialog">
          <div class="icon-line">
            <i class="el-icon-plus"></i>
          </div>
          <div class="label-line">
            您暂未添加快捷菜单，点击“+”立即添加快捷菜单
          </div>
        </div>
      </div>
    </el-drawer>
    <ShortMenuDialog
      v-if="menuDialogShow"
      :dataset="menuDialogShow"
      @close="dialogClose"
    />
  </div>
</template>

<script>
import { generateTitle } from "@/utils/i18n";
import ShortMenuDialog from "@/components/ShortMenuDialog";
export default {
  components: {
    ShortMenuDialog,
  },
  data() {
    return {
      menuDialogShow: false,
      shormenuShow: false,
      menuList: [],
      currentUser: localStorage.getItem("application:lu"),
      currentTenant: this.$store.getters.currentTenant,
    };
  },
  watch: {
    shormenuShow: {
      handler(val) {
        this.$emit("menuChange", val);
        this.initDatas();
      },
      immediate: true,
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    generateTitle,
    routePathJump(path, target = "_blank") {
      const { origin, pathname } = window.location;
      let targetUrl = path.startsWith("http")
        ? path
        : `${origin}${pathname}#${path}`;
      window.open(targetUrl, target);
    },
    openMenuDialog() {
      this.menuDialogShow = true;
    },
    dialogClose(fresh) {
      this.menuDialogShow = false;
      if (fresh === true) {
        this.initDatas();
      }
    },
    openShormenu() {
      this.shormenuShow = true;
    },
    initDatas() {
      try {
        const localDatas = localStorage.getItem("shortcut-mune-store");
        if (localDatas) {
          let localMenu =
            JSON.parse(localDatas)[
              `${this.currentUser}-${this.currentTenant}`
            ] || [];
          this.menuList = localMenu;
          console.log("this.menuList", this.menuList);
        }
      } catch (error) {
        console.log("初始化本地数据失败：", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shortcut-container {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .edit-label {
    position: absolute;
    right: 70px;
    top: 25px;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #2a61ff;
  }
  .menu-list {
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .single-one {
      width: 160px;
      height: 84px;
      background: #ffffff;
      border-radius: 10px;
      opacity: 1;
      border: 1px solid rgba(42, 97, 255, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        border: 1px solid #2a61ff;
        .label-line {
          color: #2a61ff;
        }
      }
      .icon-line {
        height: 40px;
        width: 40px;
        font-size: 20px;
        background-color: #f4f7fe;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        color: #2a61ff;
      }
      .label-line {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #252d3d;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
  .nodata-container {
    padding: 0 30px;
    .nodata-add {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 340px;
      height: 84px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid rgba(42, 97, 255, 0.2);
      cursor: pointer;
      &:hover {
        border: 1px solid #2a61ff;
        .label-line {
          color: #2a61ff;
        }
      }
      .el-icon-plus {
        color: #1a4cec;
        font-size: 20px;
        font-weight: bold;
      }
      .label-line {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a7b4c7;
      }
    }
  }
}
</style>
