import request from '@/utils/request';
import {
  envInfo
} from '@/constants/envInfo';

export function getList(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/logger/findLogger/list',
    method: 'get',
    params,
  });
}

/**
 * 查询应用列表
 * @param params
 */
export function findAppList(params) {
  return request({
    url: envInfo.bgApp.lookupPath +
      `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  });
}

export function getLogDetail(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/logger/getLogDetail',
    method: 'get',
    params,
  });
}

export function getModelList() {
  return request({
    url: envInfo.bgApp.dorisPath + '/logger/getModelList',
    method: 'get',
  });
}

export function createAudit(params) {
  return request({
    url: envInfo.bgApp.loggerPath + '/logger/createAudit',
    method: 'post',
    data: params,
  });
}