var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.$store.state.app.isVedioSrc !== "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.$store.state.app, "isVedioSrc!==''", $event)
            },
            close: _vm.closeModel,
          },
        },
        [
          _c("div", [
            _c("video", {
              attrs: {
                id: "video",
                src: _vm.$store.state.app.isVedioSrc,
                controls: "controls",
                autoPlay: false,
                width: "100%",
                preload: "auto",
                u: "image",
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closeModel } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }