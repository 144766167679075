<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumbList"
        :key="item.sitemapId"
      >
        <span
          v-if="
            item.href === null ||
            item.href === '' ||
            index == breadcrumbList.length - 1
          "
          class="no-redirect"
          >{{ generateTitle(item.displayText) }}</span
        >
        <a v-else @click.prevent="handleLink(item)">{{
          generateTitle(item.displayText)
        }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import store from "@/store";
import { generateTitle } from "@/utils/i18n";
import { envInfo } from "@/constants/envInfo";

export default {
  data() {
    return {
      breadcrumbList: null,
    };
  },
  watch: {
    // 路由变化时刷新面包屑
    $route(route) {
      // 重写向的变化不用更新面包屑
      if (!route.path.startsWith("/redirect/")) {
        this.getBreadcrumb();
      }
    },
    // 菜单初始化后刷新面包屑
    listenSitemap: function () {
      this.getBreadcrumb();
    },
    // 切换语言刷新网页Title
    language: function () {
      this.refreshTitle();
    },
  },
  computed: {
    // 监听菜单状态
    listenSitemap() {
      return this.$store.state.user.isSitemapInit;
    },
    // 监听语言变化
    language() {
      return this.$store.getters.language;
    },
  },
  created() {
    // 组件初始化
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.findSitemapMatched(
        store.getters.sitemapList,
        decodeURIComponent(this.$route.fullPath)
      );
      // 当页面没有配置菜单时，获取指定面包屑
      if (!matched) {
        // 设置中心
        if (this.$route.path === envInfo.sitemap.personal.href) {
          matched = [envInfo.sitemap.personal];
        }
      }
      if (matched) {
        // 面包屑第一个应该是首页，如果不是则按首页配置填充
        const first = matched[0];
        if (first && first.href !== envInfo.sitemap.home.href) {
          matched = [...[envInfo.sitemap.home], ...matched];
        }
        this.breadcrumbList = matched;
        // 刷新网页title
        this.refreshTitle();
      }
    },
    // 刷新网页title
    refreshTitle() {
      // 显示当前菜单-应用名称
      let pageTitle = [];
      pageTitle.push(generateTitle(envInfo.appName));
      pageTitle.push(
        generateTitle(
          this.breadcrumbList[this.breadcrumbList.length - 1].displayText
        )
      );
      pageTitle.reverse();
      document.title = pageTitle.join("-");
    },
    handleLink(item) {
      const { href } = item;
      this.$router.push(href);
    },
    // 获取当前菜单的matched
    findSitemapMatched(sitemap, path) {
      let matched;
      let find = function (sitemap, path) {
        for (let item of sitemap) {
          if (
            item.href &&
            item.href === path // 优先做路径完全匹配
            // || (item.href.indexOf('?') !== -1 && item.href.split('?')[0] === path) // 当路径中有问号带参时，忽略参数做匹配
          ) {
            matched = item.matched;
            break;
          }
          let nodes = item.nodes;
          if (nodes && nodes.length > 0) {
            find(nodes, path);
          }
        }
      };
      find(sitemap, path);
      return matched;
    },
    generateTitle,
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
