import {
  login,
  logout,
  getHavePermission,
  writeCookie,
} from '@/api/ruge/login';
import {
  getCurrentTenantList,
  setCurrentTenant,
} from '@/api/ruge/tenant/tenant';
import { getSiteMapList } from '@/api/ruge/sitemap/sitemap';
import { encrypt2RSA } from '@/utils/encrypt';
import { Base64 } from 'js-base64';
import { close } from '@/utils/socket';
import { envInfo } from '@/constants/envInfo';

/**
 * 处理菜单全路径，为方便渲染面包屑
 * @param sitemap
 * @param parent
 */
function handleSitemap(sitemap, parent) {
  sitemap.forEach((item) => {
    if (item.homePage === 'true' && item.href) {
      // 设置首页路由
      user.state.tenantHome = item.href;
    }
    let arr = [
      {
        sitemapId: item.sitemapId,
        displayText: item.displayText,
        href: item.href,
      },
    ];
    item.matched = parent && parent.matched ? parent.matched.concat(arr) : arr;
    let nodes = item.nodes;
    if (nodes && nodes.length > 0) {
      handleSitemap(nodes, item);
    }
  });
}

// 过滤黑名单
function filterBlacklist(sitemapList) {
  return new Promise((resolve) => {
    const blacklist = envInfo.sitemap.blacklist;
    blacklist.forEach((item) => {
      let blackIndex = sitemapList.findIndex((v) => {
        return v.displayText == item;
      });
      if (blackIndex >= 0) {
        sitemapList.splice(blackIndex, 1);
      }
    });
    resolve(sitemapList);
  });
}

const user = {
  state: {
    // 当前用户是否为游客,默认为游客
    isGuest: true,
    // 当前账号
    userAccount: localStorage.getItem('application:lu') || '',
    // 角色列表
    roleList: [],
    tenantList: [],
    localTenant: new Map(),
    currentTenant: null,
    //当前租户对应的PK码
    currentProductKey: null,
    // 是否已初始化用户空间 false:未初始化，true:已初始化
    isHomeInit: false,
    // 用户权限
    havePermissions: null,
    // 是否已初始化菜单 false:未初始化，true:已初始化
    isSitemapInit: false,
    // 菜单
    sitemapList: [],
    // 租户首页路由, 默认设置中心
    tenantHome: '/personal',
    //Base64的authToken
    baseAuthToken: localStorage.getItem('application:au') || null,
    //头像
    avatar: null,
  },

  mutations: {
    SET_TENANT_HOME_PAGE: (state, homePage) => {
      state.tenantHome = homePage;
    },
    SET_HOME_INFO_INIT: (state, isHomeInit) => {
      state.isHomeInit = isHomeInit;
    },
    SET_HAVE_PERMISSIONS: (state, havePermissions) => {
      state.havePermissions = havePermissions;
    },
    SET_IS_GUEST: (state, isGuest) => {
      state.isGuest = isGuest;
    },
    SET_USER_ACCOUNT: (state, userAccount) => {
      state.userAccount = userAccount;
      localStorage.setItem('application:lu', userAccount);
    },
    SET_ROLE_LIST: (state, roleList) => {
      state.roleList = roleList;
    },
    SET_TENANT_LIST: (state, tenantList) => {
      state.tenantList = tenantList;
      let localTenantList = localStorage.getItem('application:lt');
      localTenantList = new Map(localTenantList && JSON.parse(localTenantList));
      let localCurrentTenant =
        localTenantList && localTenantList.get(state.userAccount);
      let productKey;
      if (localCurrentTenant) {
        let exist = false;
        for (let i = 0; i < tenantList.length; i++) {
          if (tenantList[i].tenantId == localCurrentTenant) {
            exist = true;
            productKey = tenantList[i].productKey;
          }
        }
        if (!exist) {
          localTenantList.delete(state.userAccount);
          localCurrentTenant = null;
        }
      }
      if (tenantList && tenantList.length > 0) {
        if (!localCurrentTenant && tenantList && tenantList[0]) {
          localCurrentTenant = tenantList[0].tenantId;
          productKey = tenantList[0].productKey;
          localTenantList.set(state.userAccount, localCurrentTenant);
        }
        state.currentTenant = localCurrentTenant;
        console.log('productKey', productKey);
        state.currentProductKey = productKey;
        localStorage.setItem(
          'application:lt',
          JSON.stringify([...localTenantList])
        );
      }
    },
    SET_CURRENT_TENANT: (state, tenant) => {
      let tenantId = tenant.tenantId;
      if (tenantId) {
        state.currentTenant = tenantId;
        state.currentPK = tenant.productKey;
        let localTenantList = localStorage.getItem('application:lt');
        localTenantList = new Map(
          localTenantList && JSON.parse(localTenantList)
        );
        localTenantList.set(state.userAccount, tenantId);
        localStorage.setItem(
          'application:lt',
          JSON.stringify([...localTenantList])
        );
      } else {
        let localTenantList = localStorage.getItem('application:lt');
        localTenantList = new Map(
          localTenantList && JSON.parse(localTenantList)
        );
        localTenantList && localTenantList.delete(state.userAccount);
        localStorage.setItem(
          'application:lt',
          JSON.stringify([...localTenantList])
        );
      }
    },
    SET_SITEMAP_INIT: (state, isSitemapInit) => {
      state.isSitemapInit = isSitemapInit;
    },
    SET_SITEMAP_LIST: (state, sitemapList) => {
      state.sitemapList = sitemapList;
    },
    SET_AUTH_TOKEN: (state, bastAuthToken) => {
      if (bastAuthToken) {
        state.baseAuthToken = bastAuthToken;
        localStorage.setItem('application:au', state.baseAuthToken); //解决页面刷新会丢失的问题
      } else {
        state.baseAuthToken = null;
        localStorage.removeItem('application:au');
      }
    },
    SET_AVATOR: (state, avatar) => {
      state.avatar = avatar;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        let password = encrypt2RSA(userInfo.password);
        if (userInfo.noNeedEncrypt2RSA) {
          password = userInfo.password;
        }
        login(username, password, userInfo.authType)
          .then((r) => {
            let bastAuthToken = r.authToken;
            localStorage.setItem('userId', r.userId);
            writeCookie(bastAuthToken).then(() => {
              // 登录后要重新初始化用户空间和菜单
              commit('SET_HOME_INFO_INIT', false);
              commit('SET_SITEMAP_INIT', false);
              commit('SET_USER_ACCOUNT', r.userAccount);
              commit('SET_AUTH_TOKEN', bastAuthToken);
              resolve(r);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //二维码登录
    QrLogin({ commit }, loginInfo) {
      return new Promise((resolve) => {
        let bastAuthToken = Base64.encode(loginInfo.token);
        writeCookie(bastAuthToken);
        commit('SET_HOME_INFO_INIT', false);
        commit('SET_SITEMAP_INIT', false);
        commit('SET_USER_ACCOUNT', loginInfo.userAccount);
        commit('SET_AUTH_TOKEN', bastAuthToken);
        commit('SET_CURRENT_TENANT', {
          tenantId: loginInfo.tenantId,
          productKey: loginInfo.productKey,
        });
        resolve();
      });
    },
    // 更新头像
    ChangeAvator({ commit }, avatar) {
      return new Promise((resolve) => {
        commit('SET_AVATOR', avatar);
        resolve();
      });
    },
    // 获取用户空间信息
    GetHavePermissions({ commit }) {
      return new Promise((resolve, reject) => {
        getHavePermission()
          .then((response) => {
            commit('SET_HAVE_PERMISSIONS', response.haveOperationList);
            commit('SET_IS_GUEST', response.userAccount === 'guest');
            commit('SET_HOME_INFO_INIT', true);
            commit('SET_ROLE_LIST', response.roleList);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 初始化用户菜单
    InitSitemap({ commit }) {
      return new Promise((resolve, reject) => {
        // 获取菜单配置 XXX 正常情况下平台的菜单根的parentId是0，这里写成-1是获取测试菜单数据
        getSiteMapList({ parentCode: 'rootNo', status: 1 })
          .then((response) => {
            // 过滤黑名单
            filterBlacklist(response).then((sitemapList) => {
              // 处理菜单国际化以及面包屑
              handleSitemap(sitemapList);
              //            console.log('sitemapList', sitemapList);
              commit('SET_SITEMAP_LIST', sitemapList);
              commit('SET_SITEMAP_INIT', true);
              resolve();
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 租户列表
    InitTenantList({ commit }) {
      return new Promise((resolve, reject) => {
        getCurrentTenantList()
          .then((response) => {
            let tenantList =
              response &&
              response.map((r) => {
                return {
                  tenantId: r.tenantId,
                  tenantName: r.tenantName,
                  tenantAvatar: r.tenantAvatar,
                  tenantAvatarOpen: r.tenantAvatarOpen,
                  productKey: r.productKey,
                };
              });
            commit('SET_TENANT_LIST', tenantList);
            resolve(tenantList);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 设置当前租户
    SetCurrentTenant({ commit }, param) {
      return new Promise((resolve, reject) => {
        setCurrentTenant(param.tenantId, param.productKey)
          .then(() => {
            param.menu && commit('SET_CURRENT_TENANT', param);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    LogOut({ commit /*, state*/ }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_USER_ACCOUNT', '');
            commit('SET_IS_GUEST', true);
            // commit('SET_ROLE_LIST', []);
            commit('SET_SITEMAP_LIST', []);
            commit('SET_SITEMAP_INIT', false);
            commit('SET_HOME_INFO', {});
            commit('SET_HOME_INFO_INIT', false);
            commit('SET_AUTH_TOKEN', null);
            close();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_USER_ACCOUNT', '');
        commit('SET_IS_GUEST', true);
        // commit('SET_ROLE_LIST', [])
        commit('SET_SITEMAP_LIST', []);
        commit('SET_SITEMAP_INIT', false);
        commit('SET_HOME_INFO', {});
        commit('SET_HOME_INFO_INIT', false);
        commit('SET_AUTH_TOKEN', null);
        resolve();
      });
    },
  },
};

export default user;
