//vlink国际化
export default {
  vlink: {
    product: {
      list: '产品列表',
      detail: '产品详情',
      summary: '产品概述',
      tslManagement: '模型管理',
      topicManagement: 'Topic管理',
      ScriptParsing: '脚本解析',
      info: '产品信息',
      description: '产品描述',
      productName: '产品名称',
      productKey: '产品标识码',
      nodeType: '节点类型',
      productSecret: '产品密钥',
      productStatus: '产品状态',
      dataType: '数据格式',
      authType: '认证方式',
      protocol: '协议类型',
      dynamicRegistration: '设备动态注册',
      add: '添加产品',
      copy: '复制产品',
      sourceProduct: '源产品',
      newProduct: '新产品名称',
      productNameRule1: '最少2个字符',
      productNameRule2: '支持英文字母、数字、汉字、和特殊字符_-#@()',
      label: '产品标签',
      OtaManagement: '固件管理',
      OTA: 'OTA升级',
      deviceLog: '设备日志',
      driverManagement: '驱动管理',
      firmwareVersion: '固件版本',
      firmwareStatus: '固件状态',
      OTADescription: '描述',
      programmingLanguage: '编程语言',
      enableStatus: '启用状态',
      OTADetail: '详情',
      addOta: '添加升级包',
      createCustomTopic: '定义Topic类',
      deviceOperationAuthority: '设备操作权限',
      topicClass: 'Topic类',
      customTopicDescription: '描述',
      deleteProduct: '删除产品',
      basicInformation: '基础信息',
      basicInfoTips: '添加产品时用户填写的信息，产品添加完成后不能修改',
      certificateAndTime: '证书和时间',
      certificateAndTimeTips: '产品添加完成后系统自动生成的信息，用户不能手动修改',
      productConfig: '产品配置',
      productConfigTips: '用户自由配置的产品设置和描述',
      seeProductSecret: '查看密钥',
    },
    device: {
      parentDeviceName:'所属网关',
      groupName: '分组名称',
      groupParent: '选择父类分组',
      deviceDescriptionLabel: '设备描述',
      deviceSecret: '设备密钥',
      createTime: '创建时间',
      basicInfoTips: '添加设备时用户填写的信息，设备添加完成后不能修改',
      certificateAndTimeTips: '设备添加完成后系统自动生成的信息，用户不能手动修改',
      productConfigTips: '用户自由配置的设备设置和描述',
      list: '设备列表',
      detail: '设备详情',
      summary: '设备概述',
      info: '设备信息',
      shadow: '设备影子',
      gwInfo: '所属网关信息',
      protocolChannel: '协议通道',
      channelConfig: '通道配置',
      deviceConfig: '设备配置',
      addChannel: '添加通道',
      editChannel: '编辑通道',
      protocol: '协议',
      protocolName: '协议名称',
      protocolAdd: '添加协议',
      editProtocol: '修改协议',
      protocolType: '归属',
      slaveId: '设备从站号',
      nodeName: '节点名称',
      communicationMode: '通讯方式',
      orient: '定向',
      unOrient: '非定向',
      maxApdu: '最大报文长度',
      deviceId: '设备ID',
      channelName: '通道名称',
      subDevice: '子设备',
      deviceName: '设备标识码',
      deviceDescription: '设备名称',
      location: '经纬度',
      deviceCode: '设备编码',
      deviceStatus: '设备状态',
      enableStatus: '启用状态',
      localLogReport: '本地日志上报',
      activationTime: '激活时间',
      lastOnlineTime: '最后上下线时间',
      product: '所属产品',
      addParentGroup: '添加父类分组',
      addSubGroup: '添加子分组',
      groupLevel: '分组级别',
      addGroup: '添加分组',
      editSubGroup: '修改分组',
      bindDevice: '绑定设备',
      add: '添加设备',
      edit: '编辑设备',
      viewHistory: '查看历史数据',
      clearDesired: '清除期望值',
      setDesired: '设置期望值',
      setProperty: '设置属性值',
      clearDesiredConfirm: '确定要清除相应属性期望值吗？',
      deviceNameRule1: '最少4个字符',
      deviceNameRule2: '支持英文字母、数字和特殊字符-#@()',
      label: '设备标签',
      deploy: '部署',
      cancelDeploy: '取消部署',
      underway: '部署中',
      deployed: '已部署',
      deployFail: '部署失败',
      notDeploy: '未部署',
      deployStatus: '部署状态',
      deployDynamic: '部署动态',
      configView: '配置预览',
      exceptionMessage: '异常信息',
      distributed_deployment_issue: '下发部署指令',
      gateway_getconfig_processing: '开始获取网关配置',
      gateway_getconfig_success: '获取网关配置成功',
      gateway_getconfig_fail: '获取网关配置失败',
      gateway_deploy_cancel: '取消部署',
      gateway_deploy_success: '部署成功',
      gateway_deploy_fail: '部署失败',
      otaTask: '升级记录',
      otaTaskId: '升级编号',
      otaTaskLog: '升级日志',
      otaTaskDate: '升级时间',
      otaTaskProgress: '升级进度',
      otaTaskContent: '日志内容',
      otaTaskReportTime: '日志上报时间',
      rule: '规则引擎',
      moveDevice: '设备迁移',
      move: '迁移',
      terminal: '终端',
    },
    consumerGroup: {
      list: '消费组列表',
      consumerGroupKey: '消费组Key',
      consumerGroupName: '消费组名称',
      consumerGroupSecret: '消费组密钥',
      shared: '共享',
      broadcast: '广播',
    },
    videoSquare: {
      list: '办公区实时画面',
      deviceName: '位置',
      deviceLocation: '地址',
      deviceCode: '设备编号',
      deviceManufacturer: '厂商',
      deviceStatus: '状态',
      deviceFirmwareVersion: '固件版本',
      status: '状态',
      operate: '操作',
      flush: '刷新',
      online: '在线',
      offline: '离线',
      requestFailed: '请求失败',
    },
    rule: {
      list: '规则列表',
      ruleKey: '规则标识码',
      ruleName: '规则名称',
      ruleType: '规则类型',
      ruleScope: '作用域',
      linkage: '联动规则',
      alarmRule: '告警规则',
      schedule: '时间表',
      dataflow: '数据流转',
      serverSub: '服务端订阅',
      deviceCall: '设备通信',
      ruleStatus: '规则状态',
      ruleConfig: '规则配置',
      add: '添加规则',
      edit: '编辑规则',
      productSelect: '产品选择',
      deviceSelect: '设备选择',
      timerSelect: '定时触发',
      description: '规则描述',
      logic: '规则逻辑',
      all: '所有',
      only: '任意一个',
      triggerType: '触发条件类型',
      addCondition: '添加条件',
      conditionList: '条件列表',
      addRule: '添加规则',
      ruleList: '规则列表',
      propertySelect: '模块/属性名称',
      eventSelect: '模块/事件名称',
      serviceSelect: '模块/服务名称',
      operator: '操作符号',
      compareValue: '比较值',
      addAction: '添加动作',
      actionList: '动作列表',
      actionType: '动作类型',
      alarm: '告警',
      normal: '恢复',
      service: '调用服务',
      expressGre: '大于',
      expressLe: '小于',
      expressEq: '等于',
      expressGreEq: '大于等于',
      expressLeEq: '小于等于',
      alarmName: '告警名称',
      alarmStatus: '告警状态',
      alarmLevel: '告警级别',
      alarmType: '告警类型',
      alarmContent: '告警内容',
      run: '运行',
      fault: '故障',
      info: '提示',
      warn: '一般',
      error: '严重',
      datasource: '数据源',
      status: '状态',
      strategy: '触发机制',
      validTime: '数据时效(秒)',
      pulse: '每次触发',
      reverse: '变更触发',
      propertySet: '属性设置',
      message: '消息',
      setValue: '设置值',
      relationAlarm: '关联告警',
      relationRules: '关联规则',
      deviceLog: '设备日志',
      product: '产品',
      device: '设备',
      deviceTopo: '设备Topo',
      protocolChannel: '协议通道',
      productTag: '产品标签',
      deviceTag: '设备标签',
      gatewayDeploy: '网关部署',
      stationSub: '数据流转到Rdata',
      triggerCron: 'Cron表达式',
      enableDate: '生效日期',
      isNull: '为空',
      isNotNull: '不为空',
      cloud: '云端',
      edge: '边缘',

      tip1: '自定义【配置必须为JSON格式',
    },
    alarm: {
      list: '告警列表',
      value: '值',
      timeLength: '时长(分钟)',
      count: '连续触发次数',
      alarmTime: '告警时间',
      alarmValue: '告警值',
      normalTime: '恢复时间',
      normalValue: '恢复值',
      normalContent: '恢复内容',
    },
    visualization: {
      name: '名称',
      createTime: '创建时间',
      createPerson: '创建人',
      updateTime: '更新时间',
      updatePerson: '更新人',
      description: '描述',
    },
    meterial: {
      meterialName: '素材名称',
      meterialCatelog: '素材分类',
      upload: '图片上传',
      state: '是否启用',
      description: '描述',
    },
    tsl: {
      trigger: '上报方式',
      trigger1: '按时间上报',
      trigger2: '变更上报',
      property: '属性',
      propertyValue: '属性值',
      event: '事件',
      service: '服务',
      module: '模块',
      moduleName: '模块名称',
      propertyName: '属性名称',
      propertDescription: '属性描述',
      eventType: '事件类型',
      eventName: '事件名称',
      serviceName: '服务名称',
      callType: '调用方式',
      syncCall: '同步调用',
      serviceCall: '服务调用',
      executionServiceCall: '执行服务调用中',
      inputData: '下发参数',
      outputData: '上报数据',
      outputParam: '上报参数',
      responseParam: '响应参数',
      addInputParam: '添加下发参数',
      addOutputParam: '添加上报参数',
      addResponseParam: '添加响应参数',
      paramName: '参数名称',
      addParam: '添加参数',
      editParam: '编辑参数',
      paramDescription: '参数描述',
      description: '描述',
      dataType: '数据类型',
      originalDataType: '原始数据类型',
      accessMode: '访问权限',
      valueRange: '取值范围',
      step: '步长',
      unit: '单位',
      maxLength: '最大长度',
      enumList: '枚举列表',
      addEnumItem: '添加枚举项',
      extendConfig: '扩展描述',
      customConfig: '自定义配置',
      reported: '上报值',
      desired: '期望值',
      addModel: '自定义物模型',
      addModule: '添加模块',
      addObjectModule: '添加物模型',
      deleteModule: '删除模块',
      editModule: '编辑模块',
      addProperty: '添加属性',
      editProperty: '编辑属性',
      addEvent: '添加事件',
      editEvent: '编辑事件',
      addService: '添加服务',
      editService: '编辑服务',
      viewTsl: '查看物模型Tsl',
      tslInfo: '物模型详情',
      tslJson: 'TSL JSON',
      tips1: '模块名称不能重复',
      tips2: '删除模块将同时删除该模块下定义的属性、事件和服务，平台将无法解析相关设备上报数据，请谨慎操作！',
      tips3: '模块不存在',
      tips4: '属性名称不能重复',
      tips5: '扩展描述必须为JSON格式',
      tips6: '参数名称不能重复',
      tips7: 'Tsl必须为JSON格式',
      tips8: '请输入整数',
      tips9: '请输入小数',
      tips10: '请输入正整数',
      tips11: '请选择产品!',

      //modbus扩展配置
      swap: '数据转换类型',
      operateType: '操作类型',
      registerAddress: '寄存器地址',
      bitMask: '掩码',

      coilStatus: 'coilStatus（线圈状态）',
      inputStatus: 'inputStatus（离散量输入）',
      holdingRegister: 'holdingRegister（保持寄存器）',
      inputRegister: 'inputRegister（输入寄存器）',

      bigEndian: 'BIG_ENDIAN（大端序）',
      littleEndian: 'LITTLE_ENDIAN（小端序）',
      bigEndianSwap: 'BIG_ENDIAN_BYTE_SWAP（大端字节转换）',
      littleEndianSwap: 'LITTLE_ENDIAN_BYTE_SWAP（小端字节转换）',

      //bacnet扩展配置
      objectType: '对象类型',
      objectId: '对象ID',

      analogInput: 'AnalogInput（模拟输入对象）',
      analogOutput: 'AnalogOutput（模拟输出对象）',
      analogValue: 'AnalogValue（模拟值对象）',
      binaryInput: 'BinaryInput（二进制输入对象）',
      binaryOutput: 'BinaryOutput（二进制输出对象）',
      binaryValue: 'BinaryValue（二进制值对象）',
      multiStateInput: 'MultiStateInput(多态输入对象)',

      //opcua扩展配置
      displayName: '节点名称',

      //协议扩展配置
      scaling: '缩放因子',
      precision: '精度位数',
      timingAppear: '按时上报',
      changeAppear: '变更上报',
      reportCycle: '上报周期(毫秒)',
      responseData: '响应数据',
      showReportData: '查看数据',
      requestData: '下发数据',
      requestTime: '下发时间',
      responseTime: '响应时间',
      callServiceSuccess: '调用服务成功',
      callServiceFail: '调用服务失败',
      virtual: '是否虚拟点',
      virtualExpression: '虚拟表达式',
    },
    topic: {
      gatewayRule: '告警',
      offline: '离线',
      basicTopic: '基础通信Topic',
      modelTopic: '物模型通信Topic',
      customTopic: '自定义Topic',
      selectTopic: '选择Topic',
      feature: '功能',
      topicClass: 'Topic类型',
      security: '操作权限',
      desc: '描述',
      deviceJoin: '设备接入',
      deviceConfig: '设备配置',
      propertyAppear: '属性上报',
      propertySet: '属性设置',
      eventAppear: '事件上报',
      serviceCall: '服务调用',
      publish: '发布',
      subscribe: '订阅',
      directLinkRegister: '直连设备动态注册',
      directLinkRegisterResponse: '直连设备动态注册响应',
      subDeviceRegister: '子设备动态注册',
      subDeviceRegisterResponse: '子设备动态注册响应',
      directLinkLogin: '直连设备上线',
      directLinkLoginResponse: '直连设备上线响应',
      directLinkLogout: '直连设备下线',
      directLinkLogoutResponse: '直连设备下线响应',
      subDeviceLogin: '子设备上线',
      subDeviceLoginResponse: '子设备上线响应',
      subDeviceLogout: '子设备下线',
      subDeviceLogoutResponse: '子设备下线响应',
      cloudDeployDeviceConfig: '云端部署设备配置',
      cloudDeployDeviceConfigResponse: '云端部署设备配置响应',
      deviceObtainConfig: '设备获取配置',
      deviceObtainConfigResponse: '设备获取配置响应',
      getawayObtainSubDeviceConfig: '网关获取子设备配置',
      getawayObtainSubDeviceConfigResponse: '网关获取子设备配置响应',

      directLinkPropertyAppear: '直连设备属性上报',
      directLinkPropertyAppearResponse: '直连设备属性上报响应',
      subDevicePropertyAppear: '子设备属性上报',
      subDevicePropertyAppearResponse: '子设备属性上报响应',
      directLinkPropertySet: '平台设置直连设备属性',
      directLinkPropertySetResponse: '平台设置直连设备属性响应',
      subDevicePropertySet: '平台设置子设备属性',
      subDevicePropertySetResponse: '平台设置子设备属性响应',
      deviceExpectedDelete: '删除期望值',
      deviceExpectedGet: '获取设备期望值',
      deviceExpectedGetResponse: '获取设备期望值响应',
      ruleAlarmReport: '规则告警上报',
      ruleAlarmReportResponse: '规则告警上报响应',
      offlinePropertyReport: '离线属性上报',
      offlineEventReport: '离线事件上报',
      offlineServiceReport: '离线服务上报',
      offlineStatusReport: '离线状态上报',
      directLinkEventAppear: '直连设备事件上报',
      directLinkEventAppearResponse: '直连设备事件上报响应',
      subDeviceEventAppear: '子设备事件上报',
      subDeviceEventAppearResponse: '子设备事件上报响应',
      directLinkServiceCall: '直连设备服务调用',
      directLinkServiceCallResponse: '直连设备服务调用响应',
      subDeviceServiceCall: '子设备服务调用',
      subDeviceServiceCallResponse: '子设备服务调用响应',

      OriginDirectLinkPropertyAppear: '直连设备属性透传上报',
      OriginSubDevicePropertyAppear: '网关子设备属性透传上报',
      OriginDirectLinkPropertySet: '平台透传设置直连设备属性',
      OriginSubDevicePropertySet: '平台透传设置网关子设备属性',

      OtaDeviceConfigGet: '直连设备获取指定版本固件配置信息',
      OtaDeviceConfigGetResponse: '直连设备获取最新固件配置信息响应',
      OtaDeviceConfigDeploy: '直连设备云端部署固件配置信息',
      OtaDeviceConfigDeployResponse: '直连设备云端部署固件配置信息响应',
      OtaDeviceLogReport: '直连设备上报升级进度日志',
      OtaSubConfigGet: '子设备获取固件配置信息',
      OtaSubConfigGetResponse: '子设备获取指定版本固件配置信息响应',
      OtaSubConfigDeploy: '子设备云端部署固件配置信息',
      OtaSubConfigDeployResponse: '子设备云端部署固件配置信息响应',
      OtaSubLogReport: '子设备上报升级进度日志',

      logDeviceReport: '直连设备日志上报',
      logDeviceReportResponse: '直连设备日志上报响应',
      logSubReport: '子设备日志上报',
      logSubReportResponse: '子设备日志上报响应',

      deviceOTA: '设备OTA',
      deviceLog: '设备日志',
    },
    scripting: {
      scriptingLanguage: '脚本语言',
      scriptTitle: '脚本编辑',
      analogInput: '模拟输入',
      imitateType: '模拟类型',
      deviceReportData: '设备上报数据',
      deviceReceiveData: '设备接收数据',
      runningResult: '运行结果',
      save: '保存',
      saveAsDraft: '暂存',
      debugging: '调试',
      checkCompareScript: '对比代码',
      checkHexToIndicateHexadecimalInput: '勾选hex表示16进制输入',
      inputStringDataIntoOriginalString: '字符串数据输入原始字符串',
      cancel: '取消',
      sure: '确定',
      areYouSureYouWantToSwitch: '您确定要切换吗?',
      switchingLanguagesWillClearUnsavedDrafts: '切换语言将会清空未保存的草稿',
      Script: '草稿',
      draftScript: '已保存草稿脚本',
      publishedScript: '运行脚本',
    },
    log: {
      version: '版本',
      type: '类型',
      content: '内容',
      level: '级别',
      logDetails: '日志详情',
    },
    dataType: {
      int: 'int(整数)',
      decimal: 'decimal(小数)',
      string: 'string(字符串)',
      dateTime: 'dateTime(日期时间)',
      enum: 'enum(枚举)',
      jsonObject: 'jsonObject(JSON结构体)',
      stringList: 'stringList(字符串数组)',
      img: 'img(图片)'
    },
    originalDataType: {
      int8: 'int8',
      uint8: 'uint8',
      int16: 'int16',
      uint16: 'uint16',
      int32: 'int32',
      uint32: 'uint32',
      int64: 'int64',
      uint64: 'uint64',
      float: 'float',
      double: 'double',
      string: 'string',
      bool: 'bool',
      customized: 'customized',

      enum: 'enum',
      date: 'date(UTC时间截，毫秒)',
      text: 'text',
    },
    // 公用的内容
    commons: {
      call: '调用',
      close: '关闭',
      registTime: '注册时间',
      inactive: '未激活',
      online: '在线',
      offline: '离线',
      enable: '启用',
      disable: '禁用',
      draft: '草稿',
      released: '发布',
      directDevice: '直连设备',
      gateway: '网关设备',
      subDevice: '网关子设备',
      json: '标准数据格式（Rlink JSON）',
      binary: '透传/自定义',
      secretkey: '密钥',
      x509: 'x.509证书',
      custom: '自定义协议',
      modbus: 'Modbus',
      bacnet: 'BACnet',
      opcua: 'OPC UA',
      viewCert: '查看证书',
      tagInfo: '标签信息',
      info: '信息',
      alarm: '告警',
      fault: '故障',
      reportTime: '上报时间',
      desiredTime: '设置时间',
      requestId: '请求ID',
      async: '异步',
      sync: '同步',
      r: '只读',
      w: '只写',
      rw: '读写',
      formatJson: '格式化JSON',
      maxProduct: '最大产品数',
      maxDevice: '最大设备数',
      maxCustomTopic: '最大产品自定义Topic数',
      maxConsumerGroup: '最大消费组数',
      maxRule: '最大规则数',
      true: '是',
      false: '否',
    },
    // 驱动管理
    app: {
      appString: '应用标识',
      appManagement: '应用管理',
      appName: '应用名称',
      appTag: '应用标识码',
      language: '语言',
      parameter: '下发参数',
      status: '状态',
      appType: '应用类型',
      addApp: '添加应用',
      addAppImage: '添加应用镜像',
      editApp: '修改应用',
      editAppImage: '修改应用镜像',
      imageName: '镜像名称',
      tagName: '镜像标签名称',
      extendConfig: '扩展配置指令',
      jarName: 'jar包文件名',
      publishStatus: '发布状态',
      appImageFile: '应用镜像文件',
      fileSize: '文件大小',
      bindStatus: '绑定状态'
    },
  },
};