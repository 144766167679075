
import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 批量设备隔离
 * @param params
 */
export function batchIsolateDevice(params) {
    return request({
        url: envInfo.bgApp.apiPath + '/isolate/batchIsolate',
        method: 'post',
        data: params
    })
}

/**
 * 批量取消隔离
 * @param params
 */
export function batchCancelIsolateDevice(params) {
    return request({
        url: envInfo.bgApp.apiPath + '/isolate/batchCancelIsolate',
        method: 'post',
        data: params
    })
}