/**
 * 国际化配置 => 英文
 */
export default {
  // 首页
  home: {
    title: 'Home',
    backHome: 'Return to the home page',
    personalCenter: 'Personal center',
  },
  personal: {
    common: 'Common',
    basicData: 'Basic data',
    setting1: 'Avatar',
    setting2: 'Personal data',
    setting3: 'Password',
    setting4: 'Phone number',
    setting5: 'Replace logo',
    setting6: 'Basic information',
    setting7: 'Export list',
    setting8: 'Import list',
    setting9: 'News',
    setting10: 'Personalization',
    logoChangeTitle1: 'When the left menu is folded',
    logoChangeTitle2: 'When the left menu expands',
  },
  // 顶部导航栏
  navbar: {
    logOut: 'Log Out',
    home: 'Home',
    screenfull: 'Screenfull',
    theme: 'Theme',
    // 布局大小
    size: {
      title: 'Global Size',
      default: 'Default',
      medium: 'Medium',
      small: 'Small',
      mini: 'Mini',
    },
    i18n: 'Internationalization',
    uptInfo: 'Settings',
  },
  // 登录页
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    username: 'Username',
    password: 'Password',
    qrLogin: 'Scanning Login',
    pwdLogin: 'Password Login',
    openAPPLogin: 'Scan QR Code in platform app to log in',
    forScanQR: 'Scan the QR Code to log in.',
    notAccount: "If you don't have an account?",
    addUser: 'Free registration',
    registUser: 'Agree terms and register',
    registSuccess: 'Regist was successful',
    registFailed: 'Regist has failed',
    agreeServiceTerms: 'I have read and agree to the ',
    serviceTerms: 'Platform service terms',
    haveAccount: 'Existing accounts?',
    toLoginPage: 'Quick login',
    error1: 'QR code is invalid, please click refresh',
    error2: 'Account number can only be a combination of letters and numbers',
    error3:
      'This account has been blocked. Please contact the administrator if you have any questions!',
    error4: 'The user is locked. Please try again in ',
    error5: ' seconds',
    error6: 'Wrong account or password, you can try ',
    error7: ' times',
    loginNow: 'Log in now?',
    ldapLogin: 'LDAP Login',
  },
  //找回密码
  forget: {
    entryAccount: 'Please fill in the account number you need to find',
    entryCaptcha: 'Please fill in the verification code you received',
    entryPassword: 'Please reset your password',
    password: 'Forgot Password?',
    resetSuccess: 'congratulations! The password has been reset!',
    retryLogin: 'Relog in',
    accountInfo: 'Account information',
    captcha: 'Verification code',
    validMessage: {
      nExistAccount: 'Account does not exist',
      unMatchCaptch: 'Verification code mismatch',
      captchExpire: 'The verification code has expired',
      unMatchPwd: 'Password mismatch',
    },
  },
  // 操作日志
  auditLog: {
    creationDate: 'Date',
    userAccount: 'Account',
    ip: 'IP',
    host: 'Host',
    operation: 'Operation',
    module: 'Module',
    message: 'Message',
  },
  // lookup
  lookup: {
    // 添加分类
    addClassify: 'Add Classify',
    // 分类列表
    classifyList: 'List',
    // 项列表
    itemList: 'Item list',
    classifyCode: 'Classify Code',
    classifyName: 'Classify Name',
    classifyDesc: 'Description',
    // 添加项
    addItem: 'Add Item',
    editItem: 'Edit Item',
    itemCode: 'Item Code',
    itemName: 'Item Name',
    attr1: 'Attribute11',
    attr2: 'Attribute12',
    language: 'Language',
    itemDesc: 'Description',
  },
  //菜单管理
  sitemap: {
    addRootSitemap: 'Add root menu',
    isHomePage: 'Set as home page',
    name: 'Menu name',
    href: 'Href',
    permission: 'Permission',
    icon: 'Icon',
    policy: 'According to policy',
    parentLevel: 'The parent',
    sonShow: 'Displays when a submenu is available',
    everShow: 'Always show',
    tenantShow: 'Only tenant show',
    platformShow: 'Only platform show',
  },
  //注册表
  registry: {
    addRootProperty: 'Add root attribute',
    name: 'Name',
    displayName: 'Display name',
    parentLevel: 'The parent',
    value: 'Value',
    parentPath: 'The parent path',
    description: 'Describe',
  },
  //系统日志
  system: {
    instructions: 'System log list, please click the file name to download.',
    downloadPrompt: 'Are you sure you want to download this system log?',
  },
  //用户管理
  userManager: {
    addVirtual: 'Add virtual user',
    manager: 'User management',
    userName: 'Name',
    userAccount: 'Account',
    email: 'email',
    zoneCode: 'Area code',
    phone: 'phone',
    enabled: 'enabled',
    userType: 'User type',
    realUser: 'Real user',
    virtualUser: 'Virtual user',
    captcha: 'Verification code',
    validMessage: {
      userAccountOutLen: 'Must be less than 60 characters in length',
      userAccountRegx:
        'The user name can only contain English letters and numbers',
      phoneRegx: 'Please enter a telephone number for mainland China',
      HKPhoneRegx: 'Please enter a telephone number for mainland Hong Kong',
    },
    message: {
      noneAccount: 'Please fill in the account number',
      existAccount: 'The account already exists.',
      existPhone: 'The phone number already exists',
      illegePhone: 'The cell phone number is illegal.',
      resetPwd: 'Password Reset Successful! Your new password is ',
      captcha_1: 'Verification code error',
      captcha_2: 'Please refresh the verification code',
    },
    resetPwd: 'reset Password',
    regist: 'Registered user',
    downTemplate: 'Download template',
    oldPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    userNameRequired: 'Please enter your name',
    phoneRequired: 'Please enter your phone number',
    emailRequired: 'Please enter your email',
    passwordRequired: 'Please enter your password',
    newPasswordRequired: 'Please enter a new password',
    passwordReconfirm: 'Reconfirm password',
    captchaRequired: 'Please enter Graphic Verification Code',
    authCaptchaRequired: 'Please enter SMS Verification Code',
    getCaptcha: 'Get captcha',
    resetTokenTips:
      'This user has not generated a token. If you need to use a token, please reset the token first.',
    resetTokenConfirmTitle: 'Are you sure you want to reset the token?',
    resetTokenConfirm:
      'If a token has been previously generated, the previous token will be invalidated after reset.',
  },
  //权限
  permission: {
    resetToken: 'Reset token',
    validToken: 'Verification token',
    tokenExpire: 'Token valid until: ',
    permissionManager: 'Authority management',
    viewToken: 'View tokens',
    allotRole: 'Assign role',
    allotDataRange: 'Data range',
    validMessage: {
      dateNull: 'Validity cannot be empty',
    },
    list: 'Permission list',
    havePermission: 'Have permissions',
    grantPermission: 'assign permissions',
    syncPermission: 'Sync permissions',
    delUnActPermission: 'Delete invalid permissions',
  },
  //角色管理
  roleManager: {
    manager: 'Role management',
    name: 'Role Name',
    desc: 'describe',
    addRole: 'Adding roles',
  },
  //租户管理
  tenant: {
    list: 'Tenant list',
    manager: 'Tenant management',
    tenantName: 'Name',
    tenantAccount: 'Account',
    addAccount: 'Add account',
    addTenant: 'Add tenant',
    grantManager: 'Grant management',
    allotUser: 'Add user',
    setting: 'Basic settings',
    message: {
      existUser: 'Account already exists',
      notNull: 'Tenant name cannot be empty',
    },
  },
  //数据范围管理
  program: {
    manager: 'Data Scope Management',
    addProgram: 'Adding Data Scope',
    name: 'Data Scope Name',
    dataDimension: 'Data dimension',
    viewDimension: 'Dimension List',
  },
  //维度管理
  dimension: {
    code: 'Dimensional encoding',
    name: 'Dimension Name',
    url: 'Dimensional Services',
    syncDimension: 'Sync dimension',
    type: 'Dimensional type',
    general: 'General Dimension',
    personnel: 'Personnel Dimension',
  },
  export: {
    totalCount: 'Total',
  },
  import: {
    remark: 'Error Download',
  },
  // 运维工具
  ops: {
    // 异步任务监控
    asyncTaskTrace: {
      taskUUID: 'ID',
      brokerUrl: 'Broker url',
      queueName: 'Queue',
      messageType: 'Message type',
      consumerType: 'Consumer type',
      taskStatus0: 'Creating',
      taskStatus1: 'Waiting execution',
      taskStatus2: 'Create failure',
      taskStatus3: 'In execution',
      taskStatus4: 'Execution success',
      taskStatus5: 'Execution failure',
      messageContent: 'Message content',
      showMsgContent: 'Show message content',
      openTrace: 'Open Trace',
      closeTrace: 'Close Trace',
      setOpenCloseConfirm: 'Are you sure you want to open or close Trace?',
    },
    redisManager: {
      redisList: 'RedisList',
      redisContent: 'Redis Content',
      redisName: 'Redis Name',
      redisValue: 'Redis Value',
      redisNameColon: 'Redis Name:',
      redisValueColon: 'Redis Value:',
      key: 'key',
      value: 'value',
    },
    consulManager: {
      agentChecks: 'Agent information',
      serviceName: 'Service name',
      serviceId: 'Service ID',
      healthChecks: 'Health examination',
      serviceTags: 'Service label',
      serviceList: 'Service list',
      nodeName: 'Node name',
      address: 'Address',
      nodeChecks: 'Node check',
      serviceChecks: 'Service check',
      enableMaintenance: 'Set to Maintenance Status',
      cancelMaintenance: 'Cancel maintenance status',
      setMaintenanceConfirm:
        'Determine the maintenance mode for switching services?',
      deregister: 'Cancellation of this service',
      deregisterConfirm1: 'Are you sure you want to cancel this service?',
      deregisterConfirm2:
        'After logout, this service will be offline and can only be restarted by restarting the service instance!',
    },
  },
  // 定时任务
  clocky: {
    addJob: 'Add Job',
    jobName: 'Name',
    jobType: 'Type',
    statefulFlag: 'Stateful',
    groupName: 'Group',
    cron: 'Cron',
    jobContent: 'Content',
    jobStatus0: 'Invalid',
    jobStatus1: 'Running',
    jobStatus2: 'Pause',
    triggerOnce: 'Trigger once',
    batchTrigger: 'Batch Trigger',
    viewTrace: 'View trace',
    viewTraceTitle: "{jobName}'s trace",
    statefulFlagY: 'Stateful',
    statefulFlagN: 'Stateless',
    jobTypeREST: 'RESTful Service',
    jobTypeCLOUD: 'Cloud',
    pauseConfirm: 'Are you sure want to pause {jobName}?',
    batchPauseConfirm: 'Are you sure you want to pause the selected jobs?',
    pauseSuccess: 'Job paused',
    resumeConfirm: 'Are you sure want to resume {jobName}?',
    batchResumeConfirm: 'Are you sure you want to resume the selected jobs?',
    resumeSuccess: 'Job resumed',
    triggerConfirm: 'Are you sure want to trigger once {jobName}?',
    batchTriggerConfirm: 'Are you sure you want to trigger the selected jobs?',
    triggerSuccess: 'Job triggered',
    deleteConfirm: 'Are you sure you want to delete {jobName}?',
    totalTime: 'Total Time(ms)',
    returnValue: 'Return Value',
    jobTraceStatus0: 'Fail',
    jobTraceStatus1: 'Success',
  },
  //通知
  notify: {
    manager: {
      info: 'Notify manager',
      send: 'Sending notice',
      add: 'Add notification',
      edit: 'Edit notification',
      copy: 'Copy notification',
      title: 'Title',
      content: 'Content',
      objectType: 'Notify type',
      privateLetters: '',
      notice: '',
      platformPrivateLetters: 'Platform Private letter',
      platformNotice: 'Platform Notice',
      sysNotice: 'Sys Notice',
      sysPrivateLetters: 'Sys Private letter',
      channel: 'Notify channel',
      SMS: 'Short message',
      ifRecord: 'Is it recorded?',
      sender: 'Sender',
      sendTime: 'Send Time',
      sendRecord: ' Send Record',
      receiver: 'Receiver',
      readStatus: 'Read Status',
      unread: 'Unread',
      read: 'Read',
      notifyTime: 'Notify Time',
      readTime: 'Read Time',
      sms: 'SMS',
      sendConfirmPrompt: 'Are you sure to send?',
      findContent: 'Find Content',
      templateMax: 'Template large',
    },
  },
  //标签
  tag: {
    create: 'New Tag',
    tagName: 'Tag Name',
    tagType: 'Tag Type',
    timesCited: 'Times Cited',
    userName: 'Last Update Name',
    tagIsExist: 'The type label name already exists',
  },
  //告警
  alarm: {
    details: 'Alarm details',
    faultTable: 'Fault Alarm Table',
    runTable: 'Fault Running Table',
    alarmType: 'Alarm Type',
    runningAlarm: 'Running Alarm',
    faultAlarm: 'Fault Alarm',
    time: 'Alarm Time',
    comment: 'Alarm Desc',
    faultConfirm: 'Fault Confirm',
    fireConfirm: 'Fire Confirm',
    misreportConfirm: 'Misreport Confirm',
    equipmentIsolation: 'Equipment isolation',
    confirmSuccess: 'Confirm success',
    tooHigh: 'Too high',
    tooLow: 'Too low',
    info: 'Alarm',
    offline: 'Offline',
    fault: 'Fault',
    untreated: 'Untreated',
    handlerState: 'Handler State',
    handlerTime: 'Handler Time',
    normalTime: 'Normal Time',
    trend: 'Alarm Trend',
    total: 'Total',
    statistics: 'Alarm Statistics',
    statisticsNum: 'Statistics Num',
    strategy: 'Alarm strategy',
    monitorCode: 'Monitor Code',
    confirm: 'Confirm',
    alarmConfirm: 'Alarm Confirm',
    promptAlarm: 'Prompt Alarm',
    commonlyAlarm: 'Commonly Alarm',
    seriousAlarm: 'Serious Alarm',
    normal: 'Normal',
    alarmNotify: ', a serious alarm occurs, alarm status: ',
    recoveryNotify: ', critical alarm recovered, current state: ',
    comfirmMsg: 'Are you sure you want to handle this alarm manually?',
    isolateMsg: 'Are you sure you want to isolate the equipment?',
    cancelIsolateMsg: 'Are you sure to cancel the isolation?',
    isolateTime: 'Isolation time',
    isolate: 'Isolate',
    cancelIsolate: 'Cancel isolate',
    batchIsolate: 'Batch isolate',
    batchCancel: 'Batch cancel',
    isolated: 'Isolated',
    notIsolated: 'Not isolated',
    isolateShortcut: 'Isolation for 1 hour',
  },
  //地图
  map: {
    manager: 'Map manager',
    updateMap: 'Update Map',
    loading: 'Loading',
    uploadFile1: 'Drag the file here or click',
    uploadFile2: 'Upload',
    coordinate: 'Position coordinates',
    deleteMapConfirm: 'Are you sure you want to remove the map device?',
    selectPosition: 'Please choose the position!',
    runningState: 'Running State',
    running: 'Start',
    stop: 'Stop',
    display: 'Map Display',
    alarm: 'Alarm',
    normal: 'Normal',
    auto: 'Auto',
    manual: 'Manual',
    fault: 'Fault',
    isOpenConfirm: 'Are you sure you want to open it?',
    isCloseConfirm: 'Are you sure you want to close it?',
    position: 'Select Position',
    mapView: 'Map view',
    listView: 'List view',
    scaleUp: 'Scale up',
    scaleDown: 'Scale down',
    addDeviceTips: 'Double click the device icon to add',
    removeDeviceTips:
      'Drag the device icon to the left or top edge of the map to remove the device',
  },
  // 公用的内容
  commons: {
    moveConfirm: 'Are you sure you want to migrate the device?',
    completed: 'completed',
    upload: 'Upload',
    send: 'Send',
    sendSuccess: 'Send success',
    sent: 'Sent',
    waiting: 'Waiting',
    exporting: 'Exporting',
    cancelled: 'Cancelled',
    reading: 'Reading',
    importing: 'Importing',
    search: 'Search',
    reset: 'reset',
    refresh: 'Refresh',
    copy: 'Copy',
    copyLink: 'Copy link',
    dongjie: 'Frozen',
    jiedong: 'Unfreezing',
    searchPhrase: 'Search Phrase',
    searchAccount: 'Please enter name or account',
    selectTenant: 'Please select tenant',
    selectPlease: 'Select...',
    index: 'Index',
    actions: 'Actions',
    multipleSelectionTwo: 'Please choose at least two items.',
    multipleSelectionOne: 'Please choose at least one items.',
    clear: 'clear',
    clearAll: 'Clear all',
    more: 'View more',
    print: 'print',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    batchDelete: 'Batch Delete',
    destroy: 'Destroy',
    batchDestroy: 'Batch destroy',
    restore: 'Restore',
    batchRestore: 'Batch restore',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    warning: 'Warning',
    prompt: 'Prompt',
    competed: 'competed',
    // 范围分隔
    rangeSeparator: '-',
    // 开始日期
    startDate: 'Start date',
    // 结束日期
    endDate: 'End date',
    startTime: 'Start time',
    endTime: 'End time',
    completeDate: 'Complete Date',
    creationTime: 'Creation Time',
    lastUpdateTime: 'Last update time',
    creationDate: 'Creation Date',
    lastUpdateDate: 'Last update date',
    day: 'day',
    calendarDay: 'day',
    // 最近一天
    lastDay: 'Last day',
    // 最近一周
    lastWeek: 'Last week',
    // 最近一个月
    lastMonth: 'Last month',
    fullChoice: 'All election',
    unFullChoice: 'Cancel full election',
    dragSort: 'Drag sort',
    status: 'Status',
    active: 'Effective',
    unActive: 'Failure',
    noRecords: 'No data',
    empty: 'empty',
    export: 'export',
    import: 'import',
    importTemplate: 'Import template',
    chooseFile: 'Choose file',
    uploadToBg: 'Upload server',
    uploadFile: 'Upload file',
    fileName: 'File name',
    type: 'Type',
    appName: 'Application',
    profileName: 'Enviroment',
    errorMsg: 'Error message',
    info: 'news',
    pause: 'Pause',
    batchPause: 'Batch Pause',
    resume: 'Resume',
    batchResume: 'Batch Resume',
    importInfo: {
      success: ' import success',
      error: ' import failure',
    },
    errMsg: 'Error message',
    exportInfo: {
      success: ' export success',
      error: ' export failure',
      process: ' in the process of exporting',
    },
    inA: 'Change one',
    nextStep: 'Next step',
    yes: 'yes',
    no: 'no',
    notDeleteParent: 'There are child nodes that cannot be deleted',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Annual',
    quarterly: 'Quarterly',
    halfYear: 'Semiannual',
    each: 'Every time',
    minute: 'Minute',
    period: 'Period',
    overView: 'Overview',
    details: 'Details',
    actualStartTime: 'Actual start time',
    actualEndTime: 'Actual end time',
    costTime: 'Time consuming',
    enable: 'Enable',
    selectDate: 'Choose date',
    template: 'template',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    preview: 'Preview',
    defaultSelect: 'Default selection',
    remark: 'Remark',
    notifyList: 'News',
    recycleBin: 'Recycle bin',
    qrCode: 'QR code',
    min30: '30 Minutes',
    min60: '1 hour',
    min120: '2 hours',
    min180: '3 hours',
    min360: '6 hours',
    min720: '12 hours',
    min1440: '24 hours',
    viewDetails: 'View details',
    clickToDownload: 'Click to download',
    pleaseInput: 'Please input...',
    hour720: '1 month (30 days)',
    hour2160: '3 month (90 days)',
    hour4320: '6 month (180 days)',
    hour8760: '1 years (365 days)',
    hour26280: '3 years (1095 days)',
    hour43800: '5 years (1825 days)',
    hour87600: '10 years (3650 days)',
    hour262800: '30 years (10950 days)',
    noData: 'No data',
    location: 'Location',
    submit: 'Submit',
    select: 'Select',
    free: 'Free',
    displayLayout: 'Display layout',
    displayLayout_1: 'Left display menu',
    displayLayout_2: 'First level menu at the top',
    displayLayout_3: 'Show all menus at top',
    one_level: 'one-level',
    two_level: 'two-level',
    three_level: 'three-level',
  },
  // 提示信息
  message: {
    // 切换语言成功
    switchLangSuccess: 'Switch Language Success',
    // 切换布局大小成功
    switchSizeSuccess: 'Switch Size Success',
    saveSuccess: 'Save success',
    saveFail: 'Save failed',
    deleteSuccess: 'Delete success',
    openSuccess: 'Open success',
    suspendSuccess: 'Suspend success',
    deleteConfirm: 'Are you sure you want to delete it?',
    deleteAllConfirm: 'Are you sure you want to delete all?',
    destroyConfirm:
      'Are you sure you want to delete it?Cannot restore after deletion!',
    destroyAllConfirm:
      'Are you sure you want to delete all?Cannot restore after deletion!',
    openConfirm: 'Are you sure you want to open it?',
    editConfirm: 'Are you sure about the changes?',
    suspendConfirm: 'Are you sure you want to suspend it?',
    resetConfirm: 'Are you sure you want to reset it?',
    validSuccess: 'Verification success',
    validFail: 'Validation failure',
    unfastenConfirm: 'Please confirm that the account is unsealed?',
    sealConfirm: 'Do you confirm that the account is blocked?',
    operationSuccess: 'Successful operation',
    copySuccess: 'Copied to clipboard',
    operationFailed: 'Operation failed',
    uploadSuccess: 'Upload Success',
    uploadFail: 'Upload failure',
    updateSuccess: 'Successful revision',
    updateFail: 'Modification failed',
    unknowException: 'Unknown anomaly',
    errorPageTip: 'Sorry, the page you visited slipped away ~~',
    invalidCaptcha: 'Please enter a valid validation code',
    commaTips: "you can't enter a comma!",
  },
  // 校验信息
  validate: {
    required: 'Please enter a value',
    incorrectUrl: 'Incorrect URL',
    invalidEmail: 'Invalid mailbox',
  },
  facilityHome: {
    deviceTotal: 'Total number of equipment/sets',
    orderCompleteNumber: 'Work order completion number/item',
    inspectionCompleteNumber: 'Inspection completions/bar',
    maintenanceCompleteNumber: 'Maintenance completions/bar',
    maintenanceLowerHairNumber: 'Number of issued Maintenance/bar',
    jobCompleteRate: 'Task Completion Rate Statistics',
    orderCompleteTrend: 'Work Order fill/Completions trend',
    orderCompleteRank: 'Work order completion ranking',
    deviceTypeOrder: 'Equipment Type Worksheet Statistics',
    imStatistics: 'Inspection/Maintenance completions statistics',
    fillNumber: 'Number of Fills',
    completeNumber: 'Complete Number',
    order: 'Order',
    inspection: 'Inspection',
    maintenance: 'Maintenance',
    number: 'Number',
    actualCompleteNumber: 'Actual Complete Number',
    shouldFinishNumber: 'Uncompleted Number',
    item: '',
  },
  base: {
    category: {
      addRoot: 'Add Root Type',
      systemCategory: 'System classify',
      parentCategory: 'Upper level classify',
      code: 'Encoded',
      workTime: 'Maintenance hours',
      desc: 'Describe',
      system: 'Subordinate system',
    },
    device: {
      level: 'Device level',
      advanced: 'Advanced search',
      number: 'Device Number',
      select: 'Device select',
      name: 'Name of device',
      position: 'Equipment location',
      dutyDept: 'Responsible department',
      factoryCode: 'Exit Number',
      principal: 'Person in charge',
      type: 'Equipment type',
      label: 'Equipment label',
      deviceEui: 'Device EUI',
      protocol: 'Protocol mode',
      add: 'Adding equipment',
      edit: 'Editing equipment',
      copy: 'Replication equipment',
      deviceManager: 'Device manager',
      monitorManager: 'Monitor manager',
      monitor: 'Monitor',
      monitorPath: 'Control path',
      monitorCode: 'Monitor Code',
      startIcon: 'Start icon',
      stopIcon: 'Stop icon',
      addMonitor: 'Add monitor',
      editMonitor: 'Edit monitor',
      addIcon: 'Add icon',
      editIcon: 'Edit icon',
    },
    monitor: {
      monitorPath: 'Monitor Path',
      name: 'Monitor Name',
      monitorCode: 'Monitor Code',
      controlSource: 'Control source',
      alarmLevel: 'Alarm level',
      prompt: 'Prompt',
      commonly: 'Commonly',
      serious: 'Serious',
      monitorType: 'Monitor Type',
      alarmFlag: 'Whether alarm',
      autoOrder: 'Generate Order',
      industryType: 'Industrial type',
      alarmVoteValue: 'Alarm value',
      lowValue: 'Over critical',
      heightValue: 'Excessively critical',
      alarmVoteDelay: 'Alarm delay',
      toleranceValue: 'Alarm tolerance',
      isShow: 'Map markup',
      desc: 'Remarks',
      telecommand: 'Telecommand',
      telemetering: 'Telemetering',
      pleaseSelect: 'Please choose Monitor!',
      validMessage: {
        number: 'Please input number!',
      },
    },
    source: {
      name: 'Source name',
      userAccount: 'Source Authenticated',
      userToken: 'Source Password',
      type: 'Source type',
    },
    position: {
      name: 'Position',
      manager: 'Location management',
      subPosition: 'Upper Level Position',
      code: 'Location coding',
      label: 'Location label',
      desc: 'Describe',
      devicePosition: 'Equipment location',
      project: 'Projects',
      selectProject: 'Select Project',
      prompt: 'Please select or add a location',
    },
    project: {
      name: 'Project',
      code: 'Encoded',
      label: 'Project label',
      desc: 'Describe',
      list: 'Project list',
      add: 'Add project',
      edit: 'Edit project',
      city: 'City',
      address: 'Address',
      floorSpace: 'Floor Space(m²)',
      location: 'Location',
      pleaseSelectCity: 'Please select city!',
      locationNotExist:
        'Latitude and longitude do not exist, please set latitude and longitude!',
    },
    employee: {
      user: 'User',
      userName: 'Username',
      phone: 'phone',
      email: 'email',
      organiza: 'organization',
      add: 'Adding staff',
      edit: 'Editors',
      prompt: ' Account Binded Organizations',
    },
    company: {
      name: 'Company',
      companyName: 'Company name',
      companyCode: 'Company encoded',
      companyEmployee: 'Company personnel',
      organiza: 'Organization structure',
      create: 'Create Company',
      edit: 'Editorial Company',
      delete: 'Delete Company',
      details: 'Company Details',
      area: 'region',
      prompt: 'Please select company or department.',
      label: 'Company label',
    },
    dept: {
      name: 'Dept',
      subDept: 'Subordinate departments',
      supDept: 'Superior Departments',
      subText:
        'Current department does not include subordinate department, Click',
      createSon: 'Create subdivisions',
      deptName: 'Department name',
      deptCode: 'Departmental Coding',
      create: 'Creating Departments',
      edit: 'Editorial Department',
      delete: 'Delete Dept',
      details: 'Dept Details',
      deptEmployee: 'Department personnel',
      notSupDept: 'No Superior Department',
      responsibleDept: 'Responsible Department',
      responsiblePerson: 'Responsible Personnel',
      handlePerson: 'Handler Personnel',
      dutyName: 'Duty',
    },
    materiel: {
      list: 'Materiel List',
      materielName: 'Material name',
      name: 'Material',
      edit: 'Editing Material',
      create: 'Creating Material',
      code: 'Encoded',
      type: 'Material Classify',
      mode: 'Specification type',
      model: 'Model',
      specification: 'Specifications',
      unit: 'Company',
      desc: 'Describe',
      collarDetails: 'Details of usage',
      label: 'Materiel label',
      category: {
        edit: 'Editing Material Classify',
        create: 'Creating Material Classify',
        label: 'Classify label',
      },
    },
  },
  maintenance: {
    template: {
      name: 'Maintenance template',
      resultManager: 'Maintenance Result Management',
      itemNamePrompt: 'Do maintenance items use template name?',
    },
    code: 'Maintenance code',
    list: 'Maintenance list',
    generate: 'Generate maintenance',
    dynamic: 'Maintenance dynamic',
    details: 'Maintenance details',
    company: 'Maintenance Company',
    self: 'Self dimension',
    external: 'Outer dimension',
    contact: 'Contacts',
    name: 'Maintenance Name',
    planName: 'Maintenance plan',
    cycle: 'Maintenance cycle',
    type: 'Maintenance type',
    status: 'Maintenance status',
    claim: 'Maintenance requirements',
    position: 'Maintenance location',
    ifScan: 'Whether to scan code or not',
    addItem: 'New maintenance items',
    addDevice: 'New maintenance equipment',
    editItem: 'Editorial Maintenance Items',
    editDevice: 'Editorial Maintenance Equipment',
    result: 'Maintenance results',
    device: 'Maintenance equipment',
    item: 'Maintenance item',
    ifFailure: 'Whether it is invalid or not',
    checkDuty: 'Verification post',
    serviceTerm: 'Term of service',
    waitSuperviseCheck: 'To be audited by supervisors',
    waitCheckDuty: 'Pending verification',
    timePrompt: 'Start time:',
  },
  inspection: {
    plan: {
      add: 'New construction plan',
      edit: 'Editorial Plan',
      name: 'Name of plan',
      cycle: 'Planning cycle',
      executeDept: 'Responsibility Department',
      executeDuty: 'Responsibility Duty',
      superviseDuty: 'Supervisory post',
      executePerson: 'Executives',
      executePosition: 'Execution location',
      jobCycle: 'Task cycle',
      ifEnable: 'Enabled',
      list: 'List of plans',
      advanceNotify: 'Advance notice',
      effectiveTime: 'Effective time',
      invalidTime: 'Invalid time',
      day: 'day',
      crossDay: 'Cross day',
    },
    template: {
      templateName: 'Template name',
      name: 'Patrol template',
      manager: 'Template management',
      pleaseInputName: 'Please enter the template name',
      pleaseSelectLib: 'Please select the template library',
      editLib: 'Editing Template Library',
      add: 'New template',
      edit: 'Editing template',
      notRemark: 'No remarks',
      systemLib: 'System template library',
      lib: 'Template Library',
      libManager: 'Template Library Management',
      remark: 'Stencil Remarks',
      isNotes: 'Do you take notes?',
      checkResult: 'Examination results',
      checkItem: 'Check item',
      checkContent: 'Check Content',
      scoreContent: 'Score Content',
      scoreStandard: 'Score Standard',
      maxScore: 'Max Score',
      copyContent: '抄录内容',
      scoreItem: 'Score item',
      editItem: 'Edit Options',
      copyItem: 'Transcripts',
      resultItem: 'Result item',
      resultManager: 'Management of inspection results',
      recodeResult: 'Record results',
      qualified: 'Qualified',
      failed: 'Unqualified',
      ifCopyPrompt: 'Are you sure about the copy template?',
      notNull: 'Template name cannot be empty',
      containPrompt: 'Template libraries contain templates!',
      itemNamePrompt: 'Do inspection items use template name?',
    },
    code: 'Inspection code',
    list: 'Inspection list',
    generate: 'Generate inspection',
    dynamic: 'Inspection dynamic',
    details: 'Inspection details',
    name: 'Inspection name',
    planName: 'Inspection plan',
    cycle: 'Inspection cycle',
    status: 'Inspection status',
    claim: 'Inspection requirements',
    position: 'Inspection location',
    ifScan: 'Whether to scan code or not',
    addItem: 'New patrol items',
    addDevice: 'New patrol equipment',
    editItem: 'Editorial Patrol Items',
    editDevice: 'Editorial Inspection Equipment',
    result: 'Inspection results',
    device: 'Inspection equipment',
    item: 'Inspection items',
    ifPhoto: 'Do you take photos?',
    leastOne: 'Keep at least one',
    ifEnablePrompt: 'Are you sure about the activation plan?',
    ifStopPrompt: 'Do you have a suspension plan?',
    invalidTimePrompt:
      'The effective time should not be greater than the failure time!',
    unGreatDate: ' should not be greater than the end time:',
    monthCyclePrompt: 'Monthly cycle time, start time:',
    weekCyclePrompt: 'Weekly cycle time, start time:',
    dayCyclePrompt:
      'Daily cycle time, in the case of non-cross-sky, the start time:',
    onceCyclePrompt: 'Each cycle time, start time:',
  },
  order: {
    orderCode: 'Order Code',
    other: 'Other',
    materielDetail: 'Materiel Detail',
    workTimeConfigure: 'Work Time configure',
    configure: 'Order configure',
    makeMe: 'Make your own order',
    uploadLimit: 'Currently, upload 3 files at most!',
    submitSuccess: 'Work order submitted successfully.',
    cancelPrompt:
      'The work order has not been submitted. Are you sure to close it?',
    fill: 'Order fill',
    place: 'Place order',
    list: 'Order List',
    details: 'Order details',
    completeDetail: 'Complete detail',
    dynamic: 'Order dynamic',
    approve: 'Submit approve',
    waitReceive: 'unclaimed',
    waitExecute: 'To be implemented',
    executing: 'Execution in progress',
    completed: 'Completed',
    completedWaitCheck: 'Completed approve',
    declareCheckFailure: 'Report audit failed',
    completeCheckFailure: 'Complete audit failed',
    generateTime: 'Generation time',
    declareTime: 'Payment time',
    declareDept: 'Reporting Department',
    declarePeople: 'Bill Reporter',
    level: 'Order Level',
    status: 'Order status',
    faultDesc: 'Problem Description',
    faultPhoto: 'Problem photos',
    faultReason: 'Causes of failure',
    serviceDept: 'Responsibility Department',
    servicePeople: 'Repair personnel',
    serviceMethod: 'Maintenance methods',
    completedTime: 'Require completion time',
    submitTime: 'Completion of submission time',
    completedPhoto: 'Complete photos',
    qualityLevel: 'Quality Level',
    questionDesc: 'Problem Description',
    livePhoto: 'Live photos',
    qualityType: 'Quality type',
    advertising: 'Advertising',
    clearService: 'Cleaning services',
    buildDecoration: 'Civil/Decoration',
    beautyChemical: 'Beautify',
    device: 'Equipment facilities',
    operationEnvironment: 'Operating environment',
    merchantService: 'Merchant Quality/Service',
    completedHappening: 'Completion',
    assistDept: 'Assistance Department',
    assistPerson: 'Assistant Personnel',
    workMatters: 'Work matters',
    assistMatters: 'Assistance matters',
    safetyLevel: 'Security Level',
    dangerLevel: 'Hidden danger level',
    safetyQuestion: 'safety problem',
    safetyType: 'Security type',
    performanceManager: 'Performance management',
    merchantManager: 'Business management',
    publicSafety: 'Public safety',
    fireSafety: 'Fire safety',
    quality: 'Ambient quality',
    orderType: 'Order type',
    constructSafety: 'Construction safety',
    appDept: 'Application Department',
    appPerson: 'Applicant employee',
    appTime: 'Applicant time',
    auditDept: 'Audit Department',
    auditPerson: 'Audit employee',
    auditTime: 'Audit time',
    transfer: 'transfer',
    assist: 'Assist',
    delayed: 'Delayed',
    materiel: 'Materiel',
    complete: 'Complete',
    config: {
      declareIfReview: 'Whether the report is audited or not',
      declareSuccessDesc:
        '(Note: After the successful submission, it will be sent directly without audit)',
      orderSuccessReview: 'Work order completion audit',
      orderReportReview: 'Work order report audit',
      executeDeptSupervisor: 'Executive Head',
      declarePerson: 'Newspaper',
      designationPerson: 'Designator',
      designationDept: 'Designated department head',
      deviceOrder: 'Equipment and Facilities Work Order',
      fireSafetyOrder: 'Fire Safety Work Order',
      qualityOrder: 'Environmental Quality Work Order',
      orderExecuteHour: 'Work order execution hours',
      workConnectOrder: 'Work contact sheet',
      orderExecuteDept: 'Work order Responsibility Department',
      orderAssistDept: 'Work Order Assistance Department',
      saveConfig: 'Save Settings',
      ordinary: 'ordinary',
      urgent: 'urgent',
      alarm: 'alarm',
      defDept: 'Default Execution Department',
      generalSafety: 'General safety hazards',
      largerSafety: 'Greater potential safety hazards',
      majorSafety: 'Greater potential safety hazards',
      observedItem: 'Observations',
      generalFailed: 'General disqualification',
      majorFailed: 'Serious disqualification',
      dangerLevelDesc: '(Note: According to hidden danger level)',
      orderLevelDesc: '(Note: by work order level)',
      waitReceiveConfig1:
        'If the work order is not received, it will be regarded as overtime every',
      waitReceiveConfig2: 'minutes.',
      waitExecuteConfig1:
        'Work orders that have not been executed for more than',
      waitExecuteConfig2: 'minutes are regarded as a time-out, and every',
      waitExecuteConfig3: 'minutes is considered as a time-out.',
      executingConfig1:
        'The deadline for completion of the work order in execution is not submitted as a time-out, and every',
      executingConfig2: 'minutes is considered as a time-out.',
      overtimeConfig1: 'Time out for the',
      overtimeConfig2: 'time, report to the post leader.',
      overtimeConfig3: 'time, report to the department head.',
      overtimeConfig4:
        'time, report to the person in charge of the superior department,Report over',
      overtimeConfig5: 'levels at most.',
      addDeviceBySelf: 'Executor add device',
      configTips:
        '(Note: if it is enabled, when the work order is executed, the executor can add the equipment by himself when he cannot find the related equipment in the work order.)',
      timeoutAudit1: 'Over',
      timeoutAudit2: 'hours, automatic audit.',
      autoAudit: 'Audit auto',
      reportAutoAudit: 'Report Audit auto',
      completeAutoAudit: 'Completed Audit auto',
      receiveTopLimit: 'Rob TopLimit',
      receiveTopLimitTips:
        '(Note: if you exceed this quantity, you will not be able to get a new work order.)',
      pleaseSelectProject: 'Please select project',
      personIsNullTip: ' designee cannot be empty!',
      deptIsNullTip: ' to designated department head cannot be empty!',
      completeIfReview: 'Completed approval',
      completeIfReviewTip:
        '(Note: if it is closed, it will be completed directly without approval after implementation.)',
      materielIfReview: 'Materiel approve',
      materielIfReviewTip:
        '(Note: if it is closed, the material requisition does not need to be approved and is collected directly.)',
      orderMaterielAudit: 'Material audit',
      delayedIfReview: 'Delay approval',
      delayedIfReviewTip:
        '(Note: if it is closed, after the application is delayed, it does not need to be approved, but directly delayed)',
      orderDelayedAudit: 'Delay audit',
      assistIfReview: 'Assist approval',
      assistIfReviewTip:
        '(Note: if it is closed, it will be distributed directly after application without approval)',
      orderAssistAudit: 'Assist audit',
      assistPersonAudit: 'Assistant audit',
      assistPersonDeptAudit: 'Assist head',
      assistPersonIfReview: 'Assistant approval',
      assistPersonIfReviewTip:
        '(Note: if it is closed, after the application for assistance, the assistant does not need to be reviewed and distributed directly)',
      transferIfReview: 'Transfer approval',
      transferIfReviewTip:
        '(Note: if it is closed, after the transfer application, it does not need to be approved and directly transferred)',
      orderTransferAudit: 'Transfer audit',
      recipientAudit: 'Receiver audit',
      recipientDeptAudit: 'Receiver head',
      recipientIfReview: 'Receiver approval',
      recipientIfReviewTip:
        '(Note: if it is closed, after the transfer application, the receiver does not need to approve it, and transfers directly)',
    },
    statistics: {
      orderStatistics: 'Work order statistics',
      reportsNumber: 'Fill in the Work Form (individual)',
      completeNumber: 'Completed work orders (individual)',
      receiveOvertimeNumber: 'Overtime receipt of work order (individual)',
      implementOvertimeNumber: 'Overtime execution of work orders (individual)',
      completeOvertimeNumber: 'Overtime completion of work orders (individual)',
      completeOrderTrend: 'Completion of Work Order Trend',
      completeOrderStatistics: 'Completion of Work Order Statistics',
      reportOrderTrend: 'Trends in Filling in Work Forms',
      reportOrderStatistics: 'Statistics of Filling in Work Forms',
    },
  },
  pump: {
    mqtt: {
      createServer: 'Create service',
      clientId: 'Client ID',
      serverStatus: 'Service state',
      serverAddress: 'Service address',
      userName: 'User name',
      password: 'Password',
      topicSpec: 'Subscription sign',
      connectTimeout: 'Overtime (s)',
      keepInterval: 'Heartbeat (s)',
      recoveryInterval: 'Recovery (s)',
      feedHandle: 'Analytic example',
      start: 'Start',
      stop: 'Stop',
      connected: 'Connected',
      unconnected: 'Unconnected',
    },
  },
  //后台服务模块
  ruge: {
    resource: {
      common: 'Platform*Common',
      baseSetting: 'Platform*Basic setting',
      userSetting: 'Platform*User setting',
      ops: 'Platform*OPS',
      tenantBaseSetting: 'Tenant*Basic setting',
      tenantUserSetting: 'Tenant*User setting',
      tenantBasicManagement: 'Tenant*Basic management',
      tenantOrderManagement: 'Tenant*Order management',
      tenantInspectionManagement: 'Tenant*Inspection management',
      tenantMaintenanceManagement: 'Tenant*Maintenance management',
      iot: {
        tenantDataCenter: 'Tenant*Data Center',
      },
      tenantIoTManagement: 'Tenant*IoT management',
    },
    operation: {
      findSitemap: 'Sitemap query',
      manageSitemap: 'Sitemap management',
      searchOrg: 'Organization query',
      manageOrg: 'Organization management',
      managePermission: 'Privilege management',
      manageTenant: 'Tenant management',
      manageLookup: 'Lookup management',
      manageRegistry: 'Registry management',
      manageTag: 'Tag management',
      manageNotify: 'Notify management',
      manageLogger: 'Logger management',
      manageJob: 'Job management',
      findLogger: 'Log management',
      manageAsyncTask: 'Async task management',
      manageRedis: 'Cache management',
      manageConsul: 'Service management',

      searchDevice: 'Device query',
      manageDevice: 'Device management',
      searchCategory: 'Category query',
      manageCategory: 'Category management',
      searchMonitor: 'Monitor query',
      manageMonitor: 'Device binding',
      searchDeviceMap: 'Device Map query',
      manageDeviceMap: 'Device Map management',
      searchMaterielCategory: 'Materiel Category query',
      manageMaterielCategory: 'Materiel Category management',
      searchMateriel: 'Materiel query',
      manageMateriel: 'Materiel management',
      searchPosition: 'Position query',
      managePosition: 'Position management',
      searchProject: 'Project query',
      manageProject: 'Project management',
      searchControlSource: 'Control Source query',
      manageControlSource: 'Control Source management',
      setPoint: 'Point Control',

      orderSetting: 'Order Setting',
      orderSearch: 'Order Search',

      inspectionSearch: 'Inspection Search',
      inspectionManage: 'Inspection Manage',
      maintenanceSearch: 'Maintenance Search',
      maintenanceManage: 'Maintenance Manage',

      iot: {
        nanhai: {
          findPoint: 'Point query',
          findAlarm: 'Alarm query',
        },
      },
    },
    sso: {
      operation: {
        login: 'Login',
        register: 'register',
        forgetpwd: 'Forget password',
        searchSsoUser: 'Account query',
        manageSsoUser: 'Account management',
      },
    },
  },
  ginkgo: {
    ibms: {
      resource: {
        controlSourceService: 'IBMS*Control Source manager',
        deviceMapService: 'IBMS*Map Manager',
      },
      operation: {
        findControlSource: 'Find Control Source',
        deleteControlSource: 'Delete Control Source',
        createControlSource: 'Create Control Source',
        updateControlSource: 'Update Control Source',
        setPoint: 'Monitor Control',

        createDeviceMap: 'Create Map',
        updateDeviceMap: 'Update Map',
        deleteDeviceMap: 'Delete Map',
        findDeviceMap: 'Search Map',
      },
    },
    bas: {
      system: {
        existhead: 'There are leaders in companies, departments and posts',
      },
      resource: {
        monitorService: 'Basic*Monitor manager',
        deviceCategoryService: 'Basic*Equipment Classify Management',
        deviceService: 'Basic*Device management',
        deviceMonitorService: 'Basic*Monitoring Point Management',
        materielCategoryService: 'Basic*Material Classify Management',
        materielService: 'Basic*Material management',
        positionService: 'Basic*Location management',
        projectService: 'Basic*Project management',
      },
      operation: {
        findMonitor: 'Find Monitor',
        updateMonitor: 'Update Monitor',
        createMonitor: 'Create Monitor',
        deleteMonitor: 'Delete Monitor',

        findDeviceCategory: 'Query Equipment Classification',
        createDeviceCategor: 'Creating Device Classification',
        deleteDeviceCategor: 'Delete device classification',
        updateDeviceCategor: 'Editorial Equipment Classification',

        findDevice: 'Query equipment',
        createDevice: 'Creating Devices',
        deleteDevice: 'Delete device',
        exportDevice: 'Export equipment',
        updateDevice: 'Editing equipment',

        findDeviceMonitor: 'Query Monitoring Points',
        createDeviceMonitor: 'Create monitoring points',
        deleteDeviceMonitor: 'Delete monitoring points',
        updateDeviceMonitor: 'Editorial Monitoring Point',

        findMaterielCategory: 'Query material classify',
        createMaterielCategory: 'Creating Material classify',
        deleteMaterielCategory: 'Delete material classify',
        updateMaterielCategory: 'Editing Material classify',

        findMateriel: 'Query Material',
        createMateriel: 'Creating Material',
        deleteMateriel: 'Delete Material',
        updateMateriel: 'Edit Material',

        findPosition: 'Query Position',
        createPosition: 'Creating Position',
        deletePosition: 'Delete Position',
        updatePosition: 'Edit Position',
        exportPosition: 'Export Position',

        findProject: 'Query Project',
        createProject: 'Creating Project',
        deleteProject: 'Delete Project',
        updateProject: 'Edit Project',
      },
    },
    order: {
      resource: {
        orderService: 'Equipment and facilities*Work order management',
      },
      operation: {
        executeorder: 'Executing Order',
        findorderlist: 'Query Order',
        configure: 'Order Setting',
        createorder: 'Creating Order',
      },
    },
    inspection: {
      resource: {
        jobService: 'Equipment and facilities*Inspection task',
        planService: 'Equipment and facilities*Inspection plan',
        templateService: 'Equipment and facilities*Inspection template',
      },
      operation: {
        findInspectionJob: 'Query Inspection Task',
        implementInspectionJob: 'Executing Inspection tasks',
        createInspectionJob: 'Creating Inspection Tasks',
        export: 'Inspection Export',

        deleteInspectionPlan: 'Delete inspection plan',
        updateInspectionPlan: 'Editorial inspection plan',
        addInspectionPlan: 'Adding inspection plan',
        findInspectionPlan: 'Query Patrol Plan',

        findInspectionTemplate: 'Query Inspection Template',
        addInspectionTemplate: 'Adding Inspection Template',
        updateInspectionTemplate: 'Editorial Inspection Template',
        deleteInspectionTemplate: 'Delete Inspection Template',
      },
    },
    maintenance: {
      resource: {
        jobService: 'Equipment and facilities*Maintenance tasks',
        planService: 'Equipment and facilities*Maintenance plan',
        templateService: 'Equipment and facilities*Maintenance template',
      },
      operation: {
        findJob: 'Query Maintenance Task',
        implementJob: 'Implementing maintenance tasks',
        createJob: 'Create maintenance tasks',
        export: 'Maintenance Export',

        findPlan: 'Query Maintenance Plan',
        addPlan: 'Adding maintenance plan',
        deletePlan: 'Delete maintenance plan',
        updatePlan: 'Editorial Maintenance Plan',

        findTemplate: 'Query Maintenance Template',
        addTemplate: 'Add maintenance template',
        deleteTemplate: 'Delete maintenance template',
        updateTemplate: 'Editor Maintenance Template',
      },
    },
  },
  iot: {
    pump: {
      resource: {
        mqttServerService: 'Platform*MQTT Listener Config',
        tenantIoTAlarm: 'Tenant*Alarm Config',
      },
      operation: {
        findMqttServer: 'Search Listener',
        createMqttServer: 'Create Listener',
        delMqttServer: 'Delete Listener',
        updateMqttServer: 'Update Listener',
        controlMqttServer: 'MQTT Server Config',

        confirmAlarm: 'Confirm Alarm',
      },
    },
    nanhai: {
      resource: {
        pointCacheService: 'Nanhai Data*Point Cache',
        historyService: 'Nanhai Data*History Data',
        realtimeService: 'Nanhai Data*Real time Data',
      },
      operation: {
        findPointCache: 'Search Point Cache',
        historyPoint: 'History Point',
        historyAlarm: 'History Alarm',

        pointInformation: 'Real time Point',
        alarmInformation: 'Real time Alarm',
      },
    },
  },
  //地点管理模块
  location: {
    deleteConfirm: 'Unrecoverable after deletion. Are you sure to delete it?',
    deleteConfirm1: 'Included under the location tag',
    deleteConfirm2: 'Site, are you sure you want to delete?',
  },
  //站点管理模块
  site: {
    longitude: 'Example:121°38′E',
    latitude: 'Example:25°2′N ',
    address: 'Please enter your full address',
    siteName: 'please enter siteName',
    validate: {
      placeNameRequired: 'The location name field is required！',
      siteNameRequired: 'The siteName field is required!',
      siteNameRule:
        'The siteName supports only Chinese characters, upper - and lower-case English characters, numbers, and punctuation marks',
      siteNameRuleMax: 'siteName support 10 characters or less!',
      addressRequired: 'The detailed address field is required!',
      addressRule:
        'The detailed address supports only Chinese characters, uppercase and lowercase English characters, numbers, and punctuation marks',
      longitudeRequired: 'Longitude field is required!',
      latitudeRequired: 'The latitude field is required!',
    },
    formGroup: {
      serialNumber: 'serialNumber',
      placeName: 'placeName',
      siteName: 'siteName',
      address: 'address',
      pointNumber: 'pointNumber',
      operation: 'operation',
    },
    deleteConfirm: 'Unrecoverable after deletion. Are you sure to delete it?',
    deleteConfirm1: 'This site contains',
    deleteConfirm2: 'Are you ready to delete it？',
  },
};
