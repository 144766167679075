<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!-- or name="fade" -->
      <!-- <router-view :key="key"></router-view> -->
      <router-view />
    </transition>

    <!--消息通知详情-->
    <information-details
      :informationDialog.sync="informationDialog"
      :informationInfo="informationInfo"
    />
    <!--确认告警弹窗-->
    <confirm-alarm
      :alarmConfirmRecord="alarmConfirmRecord"
      :alarmConfirmDialog.sync="alarmConfirmDialog"
    />
    <addOrEditOrder v-if="$store.state.app.orderAddEdit.open"></addOrEditOrder>
  </section>
</template>
<script>
import { envInfo } from "@/constants/envInfo";
import store from "@/store/index";
import InformationDetails from "@/components/Information/components/informationDetails";
import ConfirmAlarm from "@/views/ruge/iot/alarm/confirmAlarm";
import addOrEditOrder from "@/views/business/order/orderQuery/order/tenant/detail/model/addOrEditOrder.vue";
import { updateNotifySignRead } from "@/api/ruge/notify/notify";

export default {
  name: "AppMain",
  components: {
    InformationDetails,
    ConfirmAlarm,
    addOrEditOrder,
  },
  data() {
    return {
      informationDialog: false,
      informationInfo: null,
      alarmConfirmRecord: {},
      alarmConfirmDialog: false,
    };
  },
  computed: {
    // key() {
    //   return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
    orientationMessage() {
      return this.$store.getters.orientationMessage;
    },
    alarmMessage() {
      return this.$store.getters.alarmMessage;
    },
    alarmSceneMessage() {
      return this.$store.getters.alarmSceneMessage;
    },
    exportNoticeList() {
      return this.$store.getters.exportNoticeList;
    },
    alarmNoticeList() {
      return this.$store.getters.alarmNoticeList;
    },
    exportTaskList() {
      return this.$store.getters.exportTaskList;
    },
  },
  watch: {
    orientationMessage(message) {
      this.orientationNotice(message);
    },
    alarmMessage(alarm) {
      this.alarmMessageNotice(alarm);
    },
    exportNoticeList(notifys) {
      this.showExcelNotice(notifys);
    },
    alarmNoticeList(alarms) {
      this.showAlarmNotice(alarms);
    },
    exportTaskList(notifys) {
      this.exportNotifyList(notifys);
    },
  },
  methods: {
    exportNotifyList(notifys) {
      //取最后一条任务
      let obj = notifys[notifys.length - 1];
      //未发布时才发布通知
      if (obj != undefined) {
        let notifyObj = this.$notify.info({
          title: this.$t("commons.info"),
          dangerouslyUseHTMLString: true,
          message: `<div style="text-align: left;"><i class="el-icon-loading"></i>${
            obj.taskName
          }${this.$t(
            "commons.exportInfo.process"
          )}</div><a style="color: #45a0ff;" href="#/personal/common?id=7">[${this.$t(
            "commons.viewDetails"
          )}]</a>`,
          position: "bottom-right",
          duration: 0,
        });
        obj.notifyObj = notifyObj;
        store.dispatch("SetExportTask", obj);
      }
    },
    orientationNotify(message) {
      //定向消息均有子类型，统一所有定向消息入口
      if (message.messageType == "ExcelImport") {
        //Excel导入
        this.importExcelNotifyTip(message);
      } else if (message.messageType == "ExcelExport") {
        //Excel导出
        this.exportExcelNotifyTip(message);
      } else if (message.messageType == "webNotifyMessage") {
        //通知
        this.webNotifyMessage(message);
      }
    },
    exportExcelNotifyTip(message) {
      //取出所有导出任务
      let exportTaskList = this.$store.getters.exportTaskList;
      for (let i = 0; i < exportTaskList.length; i++) {
        //寻找对应的任务通知
        if (exportTaskList[i].taskId == message.message.taskId) {
          //关闭导出中通知
          exportTaskList[i].notifyObj.close();
          if (message.message.exportFlag == "2") {
            //完成
            //发布新的通知
            this.$notify({
              title: this.$t("commons.competed"),
              dangerouslyUseHTMLString: true,
              message: `<div style="text-align: left;">${
                exportTaskList[i].taskName
              }${this.$t(
                "commons.exportInfo.success"
              )}</div><a style="color: #45a0ff;" href="${
                envInfo.bgApp[exportTaskList[i].rootPath]
              }/param/excel/download?dlType=Excel&excelFlag=EXP&excelId=${
                message.message.taskId
              }">[${this.$t("commons.clickToDownload")}]</a>`,
              position: "bottom-right",
              type: "success",
              duration: 0,
            });
          } else if (message.message.exportFlag == 3) {
            //失败
            //发布新的通知
            this.$notify({
              title: this.$t("commons.prompt"),
              dangerouslyUseHTMLString: true,
              message: `<div style="text-align: left;">${
                exportTaskList[i].taskName
              }${this.$t(
                "commons.exportInfo.error"
              )}</div><a style="color: #45a0ff;" href="#/personal/common?id=7">[${this.$t(
                "commons.viewDetails"
              )}]</a>`,
              position: "bottom-right",
              type: "warning",
              duration: 0,
            });
          }
          //将当前已处理的通知，在导出任务列表中移除
          store.dispatch("RemoveExportTask", i);
          break;
        }
      }
    },
    showExcelNotice(notifys) {
      //取最后一条任务
      let exportNotice = notifys[notifys.length - 1];
      //未发布时才发布通知
      if (exportNotice != undefined) {
        let notifyComponent = this.$notify.info({
          title: this.$t("commons.info"),
          dangerouslyUseHTMLString: true,
          message: `<div style="text-align: left;"><i class="el-icon-loading"></i>${
            exportNotice.taskName
          }${this.$t(
            "commons.exportInfo.process"
          )}</div><a style="color: #45a0ff;" href="#/personal/common?id=7">[${this.$t(
            "commons.viewDetails"
          )}]</a>`,
          position: "bottom-right",
          duration: 0,
        });
        exportNotice.notifyComponent = notifyComponent;
      }
    },
    orientationNotice(message) {
      //定向消息均有子类型，统一所有定向消息入口
      if (message.messageType == "ExcelImport") {
        //Excel导入
        this.importExcelNotice(message);
      } else if (message.messageType == "ExcelExport") {
        //Excel导出
        this.exportExcelNotice(message);
      } else if (message.messageType == "webNotifyMessage") {
        //通知
        this.webMessageNotice(message);
      }
    },
    importExcelNotice(message) {
      if (message.message.importStatus == 2) {
        //成功
        this.$notify({
          title: this.$t("commons.competed"),
          dangerouslyUseHTMLString: true,
          message: `${message.message.typeId}${this.$t(
            "commons.importInfo.success"
          )}`,
          position: "bottom-right",
          type: "success",
        });
      } else if (message.message.importStatus == 4) {
        //失败
        this.$notify({
          title: this.$t("commons.prompt"),
          dangerouslyUseHTMLString: true,
          message: `<div style="text-align: left;">${
            message.message.typeId
          }${this.$t(
            "commons.importInfo.error"
          )}</div><a style="color: #45a0ff;" href="#/personal/common?id=8">[${this.$t(
            "commons.viewDetails"
          )}]</a>`,
          position: "bottom-right",
          type: "error",
        });
      }
    },
    exportExcelNotice(message) {
      //取出所有导出任务
      let rworkExports = this.$store.getters.exportTaskList;
      let exportNoticeList =
        this.$store.getters.exportNoticeList.concat(rworkExports);
      for (let i = 0; i < exportNoticeList.length; i++) {
        //寻找对应的任务通知
        if (exportNoticeList[i].taskId == message.message.taskId) {
          //关闭导出中通知
          exportNoticeList[i].notifyComponent &&
            exportNoticeList[i].notifyComponent.close();
          let url = envInfo.bgApp.ssoPath; //只要这些节点端口是通的，任意一个有excel下载服务的就可以
          // if(message.message.appName == 'iapi') {
          //     url = envInfo.bgApp.apiPath
          // } else if(message.message.appName == 'iorganization') {
          //     url = envInfo.bgApp.organizationPath
          // } else {
          //     url = envInfo.bgApp.ssoPath
          // }
          if (message.message.exportFlag == "2") {
            //完成
            //发布新的通知
            this.$notify({
              title: this.$t("commons.competed"),
              dangerouslyUseHTMLString: true,
              message: `<div style="text-align: left;">${
                exportNoticeList[i].taskName
              }${this.$t(
                "commons.exportInfo.success"
              )}</div><a style="color: #45a0ff;" href="${
                envInfo.bgSuffixPath[message.message.sourceApp]
              }/param/excel/download?dlType=Excel&excelFlag=EXP&excelId=${
                message.message.taskId
              }">[${this.$t("commons.clickToDownload")}]</a>`,
              position: "bottom-right",
              type: "success",
              duration: 0,
            });
          } else if (message.message.exportFlag == 3) {
            //失败
            //发布新的通知
            this.$notify({
              title: this.$t("commons.prompt"),
              dangerouslyUseHTMLString: true,
              message: `<div style="text-align: left;">${
                exportNoticeList[i].taskName
              }${this.$t(
                "commons.exportInfo.error"
              )}</div><a style="color: #45a0ff;" href="#/personal/common?id=7">[${this.$t(
                "commons.viewDetails"
              )}]</a>`,
              position: "bottom-right",
              type: "warning",
              duration: 0,
            });
          }
          //将当前已处理的通知，在导出任务列表中移除
          // store.dispatch("RemoveExportNotice", i);
          break;
        }
      }
    },
    webMessageNotice(message) {
      let notifyComponent = this.$notify({
        title: this.ellipsis(message.message.notifyTitle, 15),
        customClass: "canClickNotify",
        dangerouslyUseHTMLString: true,
        message: this.$t("commons.viewDetails"),
        position: "bottom-right",
        type: "info",
        duration: 5000,
      });
      notifyComponent.onClick = () => {
        updateNotifySignRead({
          userAccount: event.$store.getters.userAccount,
          batchNo: message.message.batchNo,
        }).then(() => {
          event.informationDialog = true;
          event.informationInfo = message.message;
          notifyComponent.close();
        });
      };
    },
    showAlarmNotice(notifys) {
      //取最后一条告警通知
      let alarm = notifys[notifys.length - 1];
      if (alarm) {
        let notifyComponent;
        if (alarm.alarmStatus == "alarm") {
          notifyComponent = this.$notify({
            title: this.$t("iot.alarm.info"),
            customClass: "canClickNotify",
            message: this.ellipsis(
              // `${alarm.productKey} [${alarm.deviceName}] [${alarm.value}] [${alarm.alarmContent}]`,
              `${alarm.deviceDescription || alarm.deviceName}产生了${
                alarm.alarmType === "run" ? "运行" : "故障"
              }严重告警，告警内容：${alarm.alarmContent}`,
              50
            ),

            position: "bottom-right",
            duration: 0,
            type: "warning",
          });
        } else {
          notifyComponent = this.$notify.success({
            title: this.$t("commons.info"),
            message: this.ellipsis(
              // `${alarm.productKey} [${alarm.deviceName}] [${alarm.value}] [${alarm.alarmContent}]`,
              `${
                alarm.deviceDescription || alarm.deviceName
              }产生的严重告警已恢复，恢复内容：${
                alarm.normalContent || alarm.alarmContent
              }`,
              50
            ),
            position: "bottom-right",
            duration: 5000,
          });
        }
        if (notifyComponent) {
          notifyComponent.onClick = () => {
            // this.alarmConfirm(alarm);
            this.$router.push({
              path: "/iot/alarm/detail?alarmId=" + alarm.alarmUUID,
              query: {
                goBackFlag: true,
              },
            });
          };
          alarm.notifyComponent = notifyComponent;
        }
      }
    },
    //告警确认
    alarmConfirm(alarm) {
      alarm.notifyComponent && alarm.notifyComponent.close();
      this.alarmConfirmRecord = alarm;
      this.alarmConfirmDialog = true;
    },
    //告警消息
    alarmMessageNotice(alarm) {
      console.log(alarm);
      if (alarm.alarmLevel == "error") {
        let alarmNoticeList = this.$store.getters.alarmNoticeList;

        let alarmIndex = alarmNoticeList.findIndex((item) => {
          return (
            item.productKey == alarm.productKey &&
            item.deviceName == alarm.deviceName
          );
        });
        if (alarmIndex > -1) {
          //将之前同一个设备同一个点的关闭掉
          let notifyComponent = alarmNoticeList[alarmIndex].notifyComponent;
          notifyComponent && notifyComponent.close();
          store.dispatch("RemoveAlarmNotice", alarmIndex);
        }
        //超过3个告警时，清除第一个告警
        if (alarmNoticeList.length == 3) {
          alarmNoticeList[0].notifyComponent &&
            alarmNoticeList[0].notifyComponent.close();
          store.dispatch("RemoveAlarmNotice", 0);
        }
        //将告警消息丢入告警通知列表中
        store.dispatch("PushAlarmNotice", alarm);
      }
    },
    ellipsis(value, max) {
      if (!value) return "";
      if (value.length > max) {
        return value.slice(0, max) + "...";
      }
      return value;
    },
  },
};
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  /* min-height: calc(100vh - 50px); */
  position: relative;
  overflow: hidden;
}
</style>
