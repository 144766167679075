import request from '@/utils/request';
import {
  envInfo
} from '@/constants/envInfo';
import {
  Base64
} from 'js-base64';

/**
 * 登录
 * @param username
 * @param password
 */
export function login(username, password, authType) {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/login?wk=1',
    method: 'post',
    data: {
      userAccount: username,
      password: password,
      authType: authType,
    },
  });
}

/**
 * 登出
 */
export function logout() {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/logout',
    method: 'get',
  });
}

/**
 * 检查session
 */
export function checkSession() {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/user/checkSession',
    method: 'get',
  });
}

/**
 * 获取二维码
 */
export function getQRInfo() {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/qrlogin?t=1',
    method: 'get',
  });
}

export function qrForward(qrToken) {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/qrlogin?t=4&qrToken=' + qrToken,
    method: 'get',
  });
}

/**
 * 巡查二维码
 * @param {String} qrToken
 */
export function inspectQr(qrToken) {
  return request({
    url: envInfo.bgApp.ssoPath + '/servlet/qrlogin?t=2&qrToken=' + qrToken,
    method: 'get',
  });
}

/**
 * 获取当前用户拥有的权限码
 */
export function getHavePermission() {
  return request({
    url: envInfo.bgApp.securityPath + '/permission/findCurrentPermission',
    method: 'get',
  });
}

/**
 * 跨域需要重写cookie
 */
export function writeCookie(authToken) {
  if (!envInfo.cookieDomain || envInfo.cookieDomain.length == 0) {
    envInfo.cookieDomain = [envInfo.bgApp.ssoPath];
  }
  let cookieReqs = envInfo.cookieDomain.map((path) => {
    console.log(authToken)
    request({
      url: path + '/servlet/cookie?t=' + Base64.encode(authToken),
      method: 'get',
    });
  });
  return Promise.all(cookieReqs);
}