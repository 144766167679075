import { createAudit } from '@/api/ruge/logger/auditLog';
/**
 * {
    "userId" :1026, - 不传
    "userAccount":"li.xiaoqing",
    "ip":"127.1.1.1", - 不传
    "host":"sit.rlinkiot.com",
    "object" :"页面url", - location.hash
    "module" :"customer", - 一级菜单名称
    "message" :"场地预约列表", - 页面说明（一二三级菜单名：物联网平台 - 设备 - 列表）
    "type":3 , - 写死3
    "operation":"QUERY", - 写死PAGEVIEW
    "operationTime":"2023-06-26 08:08:08", - 当前操作时间
    "appName" :"customer", - 一级菜单名称
    "source":"page", - 写死page
    "stayTime":"123" - 写死0
}
 */
export default function dataReporting(paths) {
  if (!paths) return;
  const { host, hash } = location || {};
  const [module] = paths.split('-');
  let params = {
    userAccount: localStorage.getItem('application:lu') || '',
    host: host,
    object: hash,
    module: module, //一级菜单名称
    message: paths, // 页面说明（一二三级菜单名：物联网平台 - 设备 - 列表）
    type: 3,
    operation: 'PAGEVIEW',
    operationTime: new Date().getTime(),
    appName: module, //一级菜单名称
    source: 'page',
    stayTime: 0,
  };
  createAudit(params);
}
