// 公用的内容
export default {
  commons: {
    completed: '已完成',
    addNew: '新增',
    disabled: '禁用',
    reset: '重置',
    upload: '上传',
    send: '发送',
    sendSuccess: '发送成功',
    sent: '已发送',
    waiting: '等待中',
    exporting: '导出中',
    cancelled: '已取消',
    reading: '读取中',
    importing: '导入中',
    search: '搜索',
    refresh: '刷新',
    copy: '复制',
    copyLink: '复制链接',
    dongjie: '冻结',
    jiedong: '解除冻结',
    searchPhrase: '请输入关键字搜索',
    searchAccount: '请输入姓名或账号',
    searchAccountNew: '请输入账号',
    selectTenant: '请选择租户',
    selectPlease: '请选择...',
    inputPlease: '请输入',
    index: '序号',
    actions: '操作',
    multipleSelectionTwo: '请至少选择两项',
    multipleSelectionOne: '请至少选择一项',
    clear: '清除',
    close: '关闭',
    clearAll: '清除所有',
    more: '查看更多',
    print: '打印',
    add: '添加',
    edit: '编辑',
    delete: '删除',
    batchDelete: '批量删除',
    destroy: '彻底删除',
    batchDestroy: '批量彻底删除',
    restore: '还原',
    batchRestore: '批量还原',
    confirm: '确定',
    cancel: '取消',
    save: '保存',
    warning: '警告',
    prompt: '提示',
    competed: '完成',
    pass: '通过',
    reject: '拒绝',
    // 范围分隔
    rangeSeparator: '至',
    // 开始日期
    startDate: '开始日期',
    // 结束日期
    endDate: '结束日期',
    startTime: '开始时间',
    endTime: '结束时间',
    completeDate: '完成日期',
    creationTime: '创建时间',
    lastUpdateTime: '更新时间',
    creationDate: '创建日期',
    lastUpdateDate: '更新日期',
    day: '天',
    calendarDay: '日',
    // 最近一天
    lastDay: '最近一天',
    // 最近一周
    lastWeek: '最近一周',
    // 最近一个月
    lastMonth: '最近一个月',
    fullChoice: '全选',
    unFullChoice: '取消全选',
    dragSort: '拖拽排序',
    top: '置顶',
    levelUp: '升级',
    status: '状态',
    active: '有效',
    unActive: '失效',
    noRecords: '没有数据',
    empty: '无',
    export: '导出',
    import: '导入',
    importTemplate: '导入模版',
    chooseFile: '选取文件',
    uploadToBg: '上传到服务器',
    uploadFile: '上传文件',
    fileName: '文件名',
    type: '类型',
    tenant: '租户',
    appName: '所属应用',
    profileName: '运行环境',
    errorMsg: '异常信息',
    info: '消息',
    play: '播放',
    pause: '暂停',
    batchPause: '批量暂停',
    resume: '恢复',
    batchResume: '批量恢复',
    importInfo: {
      success: '导入成功',
      error: '导入失败',
    },
    errMsg: '错误信息',
    exportInfo: {
      success: '导出成功',
      error: '导出失败',
      process: '正在导出中...',
    },
    inA: '换一张',
    nextStep: '下一步',
    yes: '是',
    no: '否',
    notDeleteParent: '有子节点，不可删除',
    daily: '每日',
    weekly: '每周',
    monthly: '每月',
    yearly: '每年',
    quarterly: '每季',
    halfYear: '半年',
    each: '每次',
    minute: '分钟',
    period: '时间段',
    overView: '总览',
    details: '详情',
    actualStartTime: '实际开始时间',
    actualEndTime: '实际结束时间',
    costTime: '耗时',
    enable: '启用',
    selectDate: '选择日期',
    template: '模版',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
    preview: '预览',
    defaultSelect: '默认选中',
    remark: '备注',
    notifyList: '消息列表',
    recycleBin: '回收站',
    qrCode: '二维码',
    min30: '30分钟',
    min60: '1小时',
    min120: '2小时',
    min180: '3小时',
    min360: '6小时',
    min720: '12小时',
    min1440: '24小时',
    viewDetails: '查看详情',
    clickToDownload: '点击下载',
    pleaseInput: '请输入...',
    hour720: '1个月(30天)',
    hour2160: '3个月(90天)',
    hour4320: '6个月(180天)',
    hour8760: '1年(365天)',
    hour26280: '3年(1095天)',
    hour43800: '5年(1825天)',
    hour87600: '10年(3650天)',
    hour262800: '30年(10950天)',
    noData: '暂无数据',
    location: '所在位置',
    submit: '提交',
    select: '选择',
    free: '免费',
    displayLayout: '显示布局',
    displayLayout_1: '左侧显示菜单',
    displayLayout_2: '顶部显示一级菜单',
    displayLayout_3: '顶部显示所有菜单',
    theme_1: '月白',
    theme_2: '宝蓝',
    theme_3: '青灰',
    one_level: '一级',
    two_level: '二级',
    three_level: '三级',
    project: '项目',
    activated: '已开启',
    unopened: '未开启',
    general: '共',
    stretch: '张',
    stringRule: '支持英文字母、数字和特殊字符-#@()',
    pleaseInputExpression: '请输入虚拟表达式',
  },
};
