var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "el-menu",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listenSitemap,
              expression: "listenSitemap",
            },
          ],
          attrs: {
            "show-timeout": 200,
            collapse: _vm.isCollapse,
            "default-active": decodeURIComponent(_vm.$route.fullPath),
            router: "",
            "background-color": "#415c7e",
            "text-color": _vm.variables.menuText,
            "active-text-color": _vm.variables.menuActiveText,
            mode: "vertical",
            "element-loading-background": "#304156",
          },
        },
        _vm._l(_vm.sitemapList, function (sitemap) {
          return _c("SidebarItem", {
            key: sitemap.sitemapId,
            attrs: { item: sitemap },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }