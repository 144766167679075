var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c("Rightmenu", { staticClass: "right-menu" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }