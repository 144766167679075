/**
 * 国际化配置 => 简体中文
 */
export default {
  // 首页
  home: {
    title: '首页',
    backHome: '返回首页',
    personalCenter: '设置中心',
  },
  personal: {
    common: '通用设置',
    basicData: '账户设置',
    setting1: '更换头像',
    setting2: '个人资料',
    setting3: '修改密码',
    setting4: '修改手机',
    setting5: '更换Logo',
    setting6: '我的租户',
    setting7: '导出列表',
    setting8: '导入列表',
    setting9: '我的消息',
    setting10: '个性设置',
    setting11: '权限申请',
    logoChangeTitle1: '左侧菜单折叠时（可展示大小：36 * 36）',
    logoChangeTitle2: '左侧菜单展开时（可展示大小：140 * 32）',
    displaySet: '布局设置',
    themeSet: '主题设置',
  },
  // 顶部导航栏
  navbar: {
    dashboardEdit: '首页编辑',
    logOut: '退出登录',
    home: '首页',
    screenfull: '全屏',
    theme: '换肤',
    // 布局大小
    size: {
      title: '布局大小',
      default: '默认',
      medium: '中',
      small: '小',
      mini: '迷你',
    },
    i18n: '国际化',
    tenant: '租户',
    uptInfo: '设置',
  },
  // 登录页
  login: {
    title: '欢迎使用Rlink',
    logIn: '登录',
    username: '请填写账号/手机号',
    password: '请填写密码',
    companyTemplate: '模版',
    templateTotalPrice: '价格',
    qrLogin: '扫码登录',
    pwdLogin: '密码登录',
    openAPPLogin: '打开平台APP',
    forScanQR: '扫一扫登录',
    notAccount: '若没有账号？',
    addUser: '注册账号',
    addUser1: '账号信息',
    addUser2: '确认注册',
    addCompany: '免费注册公司',
    registUser: '注册', // registUser: '同意条款并注册',
    registSuccess: '注册成功',
    registFailed: '注册失败',
    agreeServiceTerms: '点击 “注册” 即表示您同意并愿意遵守RUGE', // agreeServiceTerms: '我已阅读并同意',
    serviceTerms: '《服务条款》', // serviceTerms: '《平台服务条款》',
    andTerms: '和',
    privacyPolicy: '《隐私政策》',
    haveAccount: '已有账号？',
    toLoginPage: '快捷登录',
    error1: '二维码已失效，请点击刷新',
    error2: '账号只能为字母和数字的组合',
    error3: '该账号已被封禁，如有疑问请联系管理员！',
    error4: '用户已被锁定，请',
    error5: '秒后重试',
    error6: '账号或密码错误，还可以尝试',
    error7: '次',
    loginNow: '是否现在登录？',
    companyHolder: '请输入公司名称',
    templateHolder: '请选择服务模版',
    ldapLogin: 'OA账号登录',
  },
  //找回密码
  forget: {
    entryAccount: '请填写您需要找回的帐号',
    entryCaptcha: '请填写您收到的验证码',
    entryPassword: '请重置您的密码',
    password: '忘记密码',
    resetSuccess: '恭喜您！密码已重置！',
    retryLogin: '返回登录',
    accountInfo: '账号信息',
    captcha: '验证码',
    validMessage: {
      nExistAccount: '账号不存在',
      unMatchCaptch: '验证码不匹配',
      captchExpire: '验证码已过期',
      unMatchPwd: '密码不匹配',
    },
  },
  // 操作日志
  auditLog: {
    creationDate: '操作时间',
    userAccount: '操作账号',
    ip: 'IP地址',
    host: '操作主机',
    operation: '操作',
    module: '模块',
    message: '信息',
    viewDetails: '查看详情',
    columnName: '字段名',
    columnDesc: '字段描述',
    newValue: '新值',
    oldValue: '旧值',
    source: '来源',
    description: '描述',
  },
  // 操作日志
  streamManager: {
    creationDate: '操作时间',
    userAccount: '操作账号',
    serverIp: '流媒体服务器IP地址',
    streamId: '流ID',
    streamServerIp: '服务器IP',
    onlineNumber: '在线播放人数',
    play: '播放',
    viewDetails: '查看详情',
    columnName: '字段名',
    columnDesc: '字段描述',
    newValue: '新值',
    oldValue: '旧值',
    source: '来源',
  },
  wxManage: {
    applet: '名称',
    appletCode: '微信代码',
    appId: '小程序appId',
    appSecret: '小程序密钥',
    mchId: '支付商户号',
    appletKey: '商户支付秘钥',
    state: '启用状态',
    type: '类型',
    tokenChange: '自动刷新token',
    addWX: '添加微信小程序/公众号',
    editWX: '修改微信小程序/公众号',
  },
  sms: {
    phone: '手机号',
    comment: '内容',
    sendTime: '发送时间',
    sendStatus: '发送状态',
    templateCode: '短信模板ID',
    templateName: '模板名称',
    remark: '备注',
    addSms: '添加短信模板',
    editSms: '修改短信模板',
    templateComment: '模板内容',
  },
  // lookup
  lookup: {
    // 添加分类
    addClassify: '添加分类',
    // 分类列表
    classifyList: '分类列表',
    // 项列表
    itemList: '项列表',
    classifyCode: '类别编码',
    classifyName: '类别名称',
    classifyDesc: '描述',
    // 添加项
    addItem: '添加项',
    editItem: '编辑项',
    itemCode: '项编码',
    itemName: '项名称',
    attr1: '属性1',
    attr2: '属性2',
    language: '语言',
    itemDesc: '描述',
    tenantName: '租户',
  },
  //菜单管理
  sitemap: {
    addRootSitemap: '添加主菜单',
    isHomePage: '是否设置为首页',
    name: '菜单名',
    href: '链接',
    urlType: '链接类型',
    openWith: '链接打开方式',
    permission: '权限',
    icon: '图标',
    policy: '显示策略',
    parentLevel: '父级',
    sonShow: '有子菜单时显示',
    everShow: '总是显示',
    tenantShow: '仅租户可见',
    platformShow: '仅平台管理可见',
  },
  //注册表
  registry: {
    addRootProperty: '添加根属性',
    name: '名称',
    displayName: '显示名称',
    parentLevel: '父级',
    value: '值',
    parentPath: '父路径',
    description: '描述',
  },
  //系统日志
  system: {
    instructions: '系统日志列表，请点击文件名进行下载。',
    downloadPrompt: '确定要下载此系统日志吗？',
  },
  //用户管理
  userManager: {
    addVirtual: '添加虚拟用户',
    manager: '用户管理',
    userName: '真实姓名',
    userAccount: '账号',
    email: '邮箱',
    zoneCode: '区号',
    phone: '电话',
    enabled: '启用',
    userType: '用户类型',
    realUser: '真实用户',
    virtualUser: '虚拟用户',
    captcha: '验证码',
    validMessage: {
      userAccountOutLen: '长度必须小于60个字符',
      userAccountRegx: '账号必须以英文字母开头，并且只能包含英文字母和数字',
      phoneRegx: '请输入一个中国大陆的电话号码',
      HKPhoneRegx: '请输入一个中国香港的电话号码',
    },
    message: {
      noneAccount: '请填写账号',
      existAccount: '账号已经存在',
      existPhone: '该手机号已经存在',
      illegePhone: '手机号不合法',
      resetPwd: '密码重置成功！您的新密码为',
      captcha_1: '验证码错误',
      captcha_2: '请刷新验证码',
    },
    resetPwd: '重置密码',
    regist: '注册用户',
    downTemplate: '下载模版',
    oldPassword: '当前密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码',
    userNameRequired: '请输入真实姓名',
    phoneRequired: '请输入手机号',
    emailRequired: '请输入电子邮箱',
    passwordRequired: '请输入密码',
    newPasswordRequired: '请输入新密码',
    passwordReconfirm: '请再次确认密码',
    captchaRequired: '请输入图形验证码',
    authCaptchaRequired: '请输入短信验证码',
    getCaptcha: '获取验证码',
    resetTokenTips: '此用户未生成过令牌，如需要使用令牌，请先重置令牌。',
    resetTokenConfirmTitle: '确定要重置令牌吗？',
    resetTokenConfirm: '如果之前已生成令牌，重置后，之前的令牌将失效。',
  },
  //权限
  permission: {
    resetToken: '重置令牌',
    validToken: '验证令牌',
    tokenExpire: '令牌有效期至：',
    permissionManager: '权限管理',
    viewToken: '查看令牌',
    allotRole: '分配角色',
    allotDataRange: '数据范围',
    validMessage: {
      dateNull: '有效期不能为空',
      noneTenant: '请先选择一个租户',
    },
    list: '权限列表',
    havePermission: '拥有权限',
    grantPermission: '分配权限',
    syncPermission: '同步权限',
    delUnActPermission: '删除无效权限',
    applyUser: '申请人:',
    applyTime: '申请时间:',
  },
  //角色管理
  roleManager: {
    manager: '角色管理',
    name: '角色名',
    desc: '描述',
    addRole: '添加角色',
  },
  //租户管理
  tenant: {
    noBelongingTenant: '暂无所属租户和角色',
    belongingToTenant: '权限列表',
    list: '租户列表',
    manager: '租户管理',
    tenantName: '租户名称',
    productKey: 'PK码',
    tenantAccount: '租户账号',
    userAccount: '管理账号',
    moduleTemplate: '服务模版',
    addAccount: '添加账号',
    addTenant: '添加租户',
    grantManager: '授权管理',
    allotUser: '添加用户',
    setting: '基本设置',
    module: '模块',
    selectModule: '分配模块',
    message: {
      existUser: '账号已经存在',
      notNull: '租户名称不能为空',
      openSuccess: '开通模块成功',
      openPartSuccess: '开通部分模块成功',
      openFail: '开通模块失败',
      closeSuccess: '关闭模块成功',
      closePartSuccess: '关闭模块部分成功',
      closeFail: '关闭模块失败',
      haveTenant:
        '您已经注册了自己的租户，如果想修改它请切换到你想操作的租户，如果您想注册多个租户请联系我们。',
      notHaveP1: '您还没有自己的租户，可以点击这里将当前账号。',
      upgrade: '升级',
      notHaveP2: '为租户',
    },
  },
  //模块模版
  template: {
    templateTabName: '套餐管理',
    addTemplate: '创建套餐',
    templateName: '套餐名字',
    totalPrice: '总价',
    comment: '说明',
    type1: '默认套餐',
    type2: '定制套餐',
  },
  //模块
  module: {
    freeModule: '免费模块',
    riseModule: '增值模块',
    moduleStatus1: '开通中',
    moduleStatus2: '已开通',
    moduleStatus3: '开通失败',
    moduleStatus5: '关闭中',
    moduleStatus6: '已关闭',
    moduleStatus7: '关闭失败',
    price: '单价',
    pricing: '定价',
    unitPcs: '期数',
    unPricing: '未定价',
    freeTips: '不限时长',
    durationTips: '请设置使用时长',
    duration: '使用时长',
    duration1: '年',
    duration2: '个月',
    duration3: '周',
    unitPrice: '周期',
    unitPrice1: '每年',
    unitPrice2: '每月',
    unitPrice3: '每周',
    expireDate: '到期日',
    moduleName: '模块名',
    comment: '说明',
    renewal: '续费',
    openTips1: '此模块为增值模块，需要付费使用。',
    openTips2: '请填写需要开通的服务周期后提交。',
    openTips3: '此模块为免费模块，点击提交即可开通使用。',
    openTips4:
      '您将购买付费模块，请填写需要开通的服务周期后提交订单，支付订单后系统将在1个小时内为您开通。',
    openTips5: '我们将在开通后的第二天0点整开始计费，感谢您的使用！',
    openTips6: '您开通的模块为免费模块，点击提交即可开通使用，感谢您的使用！',
    open: '开通',
    notOpen: '未开通',
    close: '关闭',
    perpetual: '永久',
    choice: '组合模块',
    totalPrice: '总价',
    message: {
      closeConfirm1: '确认关闭此模块吗？',
      closeConfirm2:
        '请慎重执行此操作！关闭后将无法继续使用该模块，相应的数据也将清除，并且无法恢复！',
      unsupport: '功能暂未开放，请耐心等待',
    },
  },
  //数据范围管理
  program: {
    manager: '数据范围管理',
    addProgram: '添加数据范围',
    name: '数据范围名',
    dataDimension: '数据维度',
    viewDimension: '查看维度列表',
  },
  //维度管理
  dimension: {
    code: '维度编码',
    name: '维度名称',
    url: '维度服务',
    syncDimension: '同步维度',
    type: '维度类型',
    general: '普通维度',
    personnel: '人员',
  },
  export: {
    totalCount: '导出总数',
  },
  import: {
    remark: '错误下载',
  },
  // 运维工具
  ops: {
    // 异步任务监控
    asyncTaskTrace: {
      taskUUID: '任务ID',
      brokerUrl: 'Broker地址',
      queueName: '队列名称',
      messageType: '消息类型',
      consumerType: '消费类型',
      taskStatus0: '创建中',
      taskStatus1: '待执行',
      taskStatus2: '创建失败',
      taskStatus3: '执行中',
      taskStatus4: '执行成功',
      taskStatus5: '执行失败',
      messageContent: '消息内容',
      showMsgContent: '查看消息内容',
      openTrace: '开启监控',
      closeTrace: '关闭监控',
      setOpenCloseConfirm: '确定要开启或关闭监控吗？',
    },
    redisManager: {
      redisList: 'Redis列表',
      redisContent: 'Redis内容',
      redisName: 'Redis名称',
      redisNameColon: 'Redis名称:',
      redisValue: 'Redis值',
      redisValueColon: 'Redis值:',
      key: '键',
      value: '值',
    },
    consulManager: {
      agentChecks: '代理信息',
      serviceName: '服务名称',
      serviceId: '服务ID',
      healthChecks: '健康检查',
      serviceTags: '服务标签',
      serviceList: '服务列表',
      nodeName: '节点名称',
      address: '地址',
      nodeChecks: '节点检查',
      serviceChecks: '服务检查',
      enableMaintenance: '设置为维护状态',
      cancelMaintenance: '取消维护状态',
      setMaintenanceConfirm: '确定切换服务的维护模式吗?',
      deregister: '注销此服务',
      deregisterConfirm1: '确定注销此服务吗?',
      deregisterConfirm2: '注销后，此服务将下线，只能重启服务实例才可恢复!',
    },
  },
  workflow: {
    processDefinitionKey: '流程定义键',
    processDefinitionName: '流程定义名',
    version: '流程版本',
    category: '类型',
    suspensionState1: '激活',
    suspensionState0: '挂起',
    suspensionState: '流程状态',
    resName: '流程资源',
    deploy: '流程部署',
    todoTitle: '待办标题',
    taskName: '任务名称',
    createTime: '任务时间',
    deploySuccess: '部署成功',
    deployFail: '部署失败',
  },
  // 定时任务
  clocky: {
    addJob: '添加定时任务',
    editJob: '编辑定时任务',
    jobName: '名称',
    jobType: '服务类型',
    jobShard: '任务类型',
    statefulFlag: '执行方式',
    groupName: '组',
    cron: 'Cron',
    jobContent: '内容',
    jobStatus0: '未启动',
    jobStatus1: '启动中',
    jobStatus2: '运行中',
    jobStatus3: '停止中',
    triggerOnce: '执行一次',
    batchTrigger: '批量执行',
    viewTrace: '查看执行记录',
    viewTraceTitle: '{jobName}的执行记录',
    statefulFlagY: '顺序执行',
    statefulFlagN: '并发执行',
    jobTypeREST: 'RESTful',
    jobTypeCLOUD: 'Cloud',
    jobTypeRReason: '外网服务',
    jobTypeCReason: 'Ribbon内网服务',
    shardFlagY: '共享任务',
    shardFlagN: '独立任务',
    jobShardYReason: '按租户隔离执行',
    jobShardNReason: '独立执行一次',
    pauseConfirm: '确定要暂停{jobName}吗?',
    batchPauseConfirm: '确定要暂停选中的任务吗?',
    pauseSuccess: '任务已暂停',
    resumeConfirm: '确定要重启{jobName}吗?',
    batchResumeConfirm: '确定要重启选中的任务吗?',
    resumeSuccess: '任务已重启',
    triggerConfirm: '确定要执行一次{jobName}吗?',
    batchTriggerConfirm: '确定要执行选中的任务吗?',
    triggerSuccess: '任务已执行',
    deleteConfirm: '确定要删除{jobName}吗？',
    totalTime: '用时(毫秒)',
    returnValue: '返回值',
    jobTraceStatus0: '失败',
    jobTraceStatus1: '成功',
    jobTraceStatus2: '部分失败',
  },
  //通知
  notify: {
    manager: {
      info: '通知管理',
      send: '发送通知',
      add: '添加通知',
      edit: '编辑通知',
      copy: '复制通知',
      title: '标题',
      content: '内容',
      objectType: '通知类型',
      platformPrivateLetters: '平台私信',
      platformNotice: '平台公告',
      sysNotice: '系统公告',
      sysPrivateLetters: '系统私信',
      channel: '通知渠道',
      SMS: '短信',
      ifRecord: '是否记录',
      sender: '发送人',
      sendTime: '发送时间',
      sendRecord: '发送记录',
      receiver: '接收人',
      readStatus: '读取状态',
      unread: '未读',
      read: '已读',
      notifyTime: '通知时间',
      readTime: '读取时间',
      sms: '短信',
      sendConfirmPrompt: '确定发送吗？',
      findContent: '查看内容',
      templateMax: '模版过大',
    },
  },
  //标签
  tag: {
    create: '添加标签',
    tagName: '标签名称',
    tagType: '标签类型',
    timesCited: '引用次数',
    userName: '最后更新人',
    tagIsExist: '该类型标签名称已存在',
  },
  //组织结构
  organization: {
    employee: {
      user: '账号',
      userName: '姓名',
      phone: '手机',
      email: '邮箱',
      organiza: '组织',
      add: '添加人员',
      edit: '编辑人员',
      prompt: '账号已绑定组织',
    },
    company: {
      name: '公司',
      companyName: '公司名称',
      companyCode: '公司编码',
      companyEmployee: '公司人员',
      organiza: '组织结构',
      create: '添加公司',
      edit: '编辑公司',
      delete: '删除公司',
      details: '公司详情',
      area: '区域',
      prompt: '请选择公司或部门',
      label: '公司标签',
    },
    dept: {
      name: '部门',
      subDept: '下级部门',
      supDept: '上一级部门',
      subText: '当前部门不包含下级部门，可点击',
      createSon: '添加子部门',
      deptName: '部门名称',
      deptCode: '部门编码',
      create: '添加部门',
      edit: '编辑部门',
      delete: '删除部门',
      details: '部门详情',
      deptEmployee: '部门人员',
      notSupDept: '无上级部门',
      responsibleDept: '责任部门',
      responsiblePerson: '责任人员',
      handlePerson: '处理人员',
      dutyName: '岗位',
    },
  },
  //地点管理模块
  location: {
    deleteConfirm: '删除后将不可恢复，确认删除吗？',
    deleteConfirm1: '该地点标签下包含',
    deleteConfirm2: '个站点，确定删除么？',
  },
  //站点管理模块
  site: {
    longitude: '例:121°38′E',
    latitude: '例:25°2′N ',
    address: '请输入详细地址',
    siteName: '请输入站点名称',
    validate: {
      placeNameRequired: '地点名称字段为必填字段！',
      siteNameRequired: '站点名称字段为必填字段！',
      siteNameRule: '站点名称仅支持汉字、大小写英文，数字或标点符号',
      siteNameRuleMax: '站点名称仅支持10位以内!',
      addressRequired: '详细地址字段为必填字段！',
      addressRule: '详细地址仅支持汉字、大小写英文，数字或标点符号',
      longitudeRequired: '经度字段为必填字段！',
      latitudeRequired: '纬度字段为必填字段！',
    },
    formGroup: {
      serialNumber: '序号',
      placeName: '地点名称',
      siteName: '站点名称',
      address: '地址',
      pointNumber: '点位数量',
      operation: '操作',
    },
    deleteConfirm: '删除后将不可恢复，确认删除吗？',
    deleteConfirm1: '该站点下包含',
    deleteConfirm2: '个点位，确认删除吗？',
  },
};
