var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "short-menu-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset,
            width: "900px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dataset = $event
            },
          },
        },
        [
          _c("div", { staticClass: "dialog-title" }, [
            _c("span", { staticClass: "title-label" }, [
              _vm._v(" 快捷菜单编辑 "),
            ]),
            _c("i", {
              staticClass: "el-icon-close",
              on: { click: _vm.handleClose },
            }),
          ]),
          _c("div", { staticClass: "menu-container" }, [
            _c("div", { staticClass: "left-part" }, [
              _c("div", { staticClass: "title-line" }, [_vm._v("菜单列表")]),
              _c("div", { staticClass: "card-container" }, [
                _c(
                  "div",
                  { staticClass: "search-line" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        clearable: "",
                        "suffix-icon": "el-icon-search",
                      },
                      on: { input: _vm.searchChange },
                      model: {
                        value: _vm.searchKey,
                        callback: function ($$v) {
                          _vm.searchKey = $$v
                        },
                        expression: "searchKey",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tree-container" },
                  _vm._l(_vm.treeList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.show,
                                expression: "item.show",
                              },
                            ],
                            staticClass: "common-label cursor-pinter",
                            style: {
                              paddingLeft: `${(item.nodeLevel - 1) * 20}px`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.menuOpenClick(item)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "first-title" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.nodes,
                                      expression: "item.nodes",
                                    },
                                  ],
                                },
                                [
                                  item.opened
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ])
                                    : _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-arrow-up",
                                        }),
                                      ]),
                                ]
                              ),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.generateTitle(item.displayText)) +
                                  "\n                "
                              ),
                            ]),
                          ]
                        ),
                        item.opened
                          ? _vm._l(
                              item.nodes,
                              function (secondItem, secondIndex) {
                                return _c(
                                  "div",
                                  { key: secondIndex },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: secondItem.show,
                                            expression: "secondItem.show",
                                          },
                                        ],
                                        staticClass:
                                          "common-label cursor-pinter",
                                        style: {
                                          paddingLeft: `${
                                            (secondItem.nodeLevel - 1) * 20
                                          }px`,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.labelLineClick(
                                              secondItem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "second-title" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "seconde-menu-image",
                                              },
                                              [
                                                secondItem.icon
                                                  ? _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          secondItem.icon,
                                                      },
                                                    })
                                                  : _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class":
                                                          "zuzhiguanli",
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.generateTitle(
                                                    secondItem.displayText
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        !secondItem.nodes && secondItem.href
                                          ? _c("el-checkbox", {
                                              attrs: {
                                                disabled:
                                                  !secondItem.checked &&
                                                  _vm.selectedList.length > 9,
                                              },
                                              model: {
                                                value: secondItem.checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    secondItem,
                                                    "checked",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "secondItem.checked",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      secondItem.nodes,
                                      function (thirdItem, thirdIndex) {
                                        return _c("div", { key: thirdIndex }, [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: thirdItem.show,
                                                  expression: "thirdItem.show",
                                                },
                                              ],
                                              staticClass:
                                                "common-label cursor-pinter",
                                              style: {
                                                paddingLeft: `${
                                                  (thirdItem.nodeLevel - 1) * 20
                                                }px`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.labelLineClick(
                                                    thirdItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "second-title" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "seconde-menu-image",
                                                    },
                                                    [
                                                      thirdItem.icon
                                                        ? _c("svg-icon", {
                                                            attrs: {
                                                              "icon-class":
                                                                thirdItem.icon,
                                                            },
                                                          })
                                                        : _c("svg-icon", {
                                                            attrs: {
                                                              "icon-class":
                                                                "zuzhiguanli",
                                                            },
                                                          }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.generateTitle(
                                                          thirdItem.displayText
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              !thirdItem.nodes && thirdItem.href
                                                ? _c("el-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        !thirdItem.checked &&
                                                        _vm.selectedList
                                                          .length > 9,
                                                    },
                                                    model: {
                                                      value: thirdItem.checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          thirdItem,
                                                          "checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "thirdItem.checked",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _c("img", {
              staticClass: "transfer-icon",
              attrs: {
                src: require("@/assets/images/short_menu_icon.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "right-part" }, [
              _c("div", { staticClass: "title-line" }, [
                _vm._v(
                  "\n          已选菜单 （" +
                    _vm._s(_vm.selectedList.length) +
                    "/10）\n        "
                ),
              ]),
              _c("div", { staticClass: "card-container" }, [
                _c(
                  "div",
                  { staticClass: "select-container" },
                  _vm._l(_vm.selectedList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "single-select" },
                      [
                        _c("span", [
                          _c(
                            "span",
                            { staticClass: "seconde-menu-image" },
                            [
                              item.icon
                                ? _c("svg-icon", {
                                    attrs: { "icon-class": item.icon },
                                  })
                                : _c("svg-icon", {
                                    attrs: { "icon-class": "zuzhiguanli" },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.generateTitle(item.displayText)) +
                              "\n              "
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "remove-span",
                            on: {
                              click: function ($event) {
                                return _vm.removeHandler(item)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-close" }),
                            _vm._v("\n                移除\n              "),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "button-line" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.menuSubmit } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }